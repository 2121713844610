import VueRouter from 'vue-router'
import COMPONENT_ROUTES from './routes'
import store from '@/store'
import { applicableRoute, hasRouteAccess } from '../helpers'

import AdminLayout from '../components/layout/admin-layout.vue'

import { Dashboard, NotFound, Login, Help, Account } from '../components'
import { ExternalAccountsService } from '@/services'

const routes = [
  {
    path: '',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Dashboard,
        name: 'dashboard',
        meta: { permission: '' },
      },
      {
        path: 'account',
        component: Account,
        name: 'account',
        meta: { permission: '' },
      },
      {
        path: 'help',
        component: Help,
        name: 'help',
        meta: { permission: '' },
      },
      {
        path: 'login',
        component: Login,
        name: 'login',
      },
      ...COMPONENT_ROUTES,
      {
        path: '*',
        component: NotFound,
        name: 'not-found',
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active',
})

router.beforeEach(async (to, _, next) => {
  let accounts = store.state.accounts
  let mustAuthenticate =
    (to.name !== 'login' && !store.state.authenticated) ||
    !hasRouteAccess(to.meta.permission, to.params.id)

  if (!mustAuthenticate && !accounts.length && to.name !== 'login') {
    await new ExternalAccountsService()
      .list()
      .then((data) => (accounts = data))
      .catch((_) => {
        store.dispatch('SET_AUTHENTICATED', null)
        next({ name: 'login' })
      })
  }

  let hasNoAccountSet = store.state.authenticated && !store.state.account

  if (mustAuthenticate) {
    next({ name: 'login' })
  } else if (hasNoAccountSet) {
    if (accounts.length === 1) {
      store.dispatch('SET_ACCOUNT', accounts[0])
      next()
    } else if (to.name !== 'account' && to.name !== 'help') {
      next({ name: 'account' })
    } else {
      next()
    }
  } else {
    if (
      applicableRoute(
        to.meta.applicable,
        accounts,
        store.state.account,
        store.getters.isAdmin
      )
    ) {
      next()
    } else {
      next({ name: 'dashboard' })
    }
  }
})

export default router

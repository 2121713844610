import HttpClient from './http-client'
import store from '@/store'
import router from '@/router'

export default class NexusHttpClient extends HttpClient {
  constructor(baseUrl, timeout, headers) {
    super($config.nexus.api + baseUrl, timeout, headers)
  }

  async get(url, data, timeout) {
    return await super.get(url, data, timeout, async (res) => {
      this.checkAuthenticated(res)
      return res
    })
  }

  async post(url, data, timeout) {
    return await super.post(url, data, timeout, async (res) => {
      this.checkAuthenticated(res)
      return res
    })
  }

  async put(url, data, timeout) {
    return await super.put(url, data, timeout, async (res) => {
      this.checkAuthenticated(res)
      return res
    })
  }

  async delete(url, timeout) {
    return await super.delete(url, timeout, async (res) => {
      this.checkAuthenticated(res)
      return res
    })
  }

  checkAuthenticated(response) {
    if (!response || response.status === 401 || response.status === 403) {
      this.autoSignOut()
    }
  }

  autoSignOut() {
    store.commit('authenticated', null)
    if (router.currentRoute.name !== 'login') {
      router.push({ name: 'login' })
    }
  }
}

import moment from 'moment'
import 'moment-timezone'

export default class Preload {
  id = ''
  name = ''
  openStayDate = null
  closeStayDate = null
  openStayDateToken = ''
  closeStayDateToken = ''
  minimumNightsStay = null
  maximumNightsStay = null
  minimumGuests = null
  maximumGuests = null
  stayNightsToBlock = []
  promotionCode = ''
  countries = []
  lastAttemptUTC = null
  isEnabled = false
  dateCreated = null
  dateModified = null

  constructor(model) {
    this.id = model ? model.id : null
    this.name = model ? model.name : ''
    this.openStayDate = model ? model.openStayDate : null
    this.closeStayDate = model ? model.closeStayDate : null
    this.openStayDateToken = model ? model.openStayDateToken : ''
    this.closeStayDateToken = model ? model.closeStayDateToken : ''
    this.minimumNightsStay = model ? model.minimumNightsStay : null
    this.maximumNightsStay = model ? model.maximumNightsStay : null
    this.minimumGuests = model ? model.minimumGuests : null
    this.maximumGuests = model ? model.maximumGuests : null
    this.stayNightsToBlock = model
      ? model.stayNightsToBlock.map((x) => x.toString())
      : []
    this.promotionCode = model ? model.promotionCode : ''
    this.countries = model ? model.countries : []
    this.lastAttempt =
      model && model.lastAttemptUTC
        ? moment(`${model.lastAttemptUTC}Z`)
            .tz('Australia/Melbourne')
            .format('lll')
        : 'Never'
    this.isEnabled = model ? model.isEnabled : false
    this.dateCreated =
      model && model.dateCreated
        ? moment(`${model.dateCreated}Z`)
            .tz('Australia/Melbourne')
            .format('lll')
        : null
    this.dateModified =
      model && model.dateModified
        ? moment(`${model.dateModified}Z`)
            .tz('Australia/Melbourne')
            .format('lll')
        : null
  }
}

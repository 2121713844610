<template>
  <div>
    <quest-card panel v-if="!p_any">
      <h4 class="text-center h4 text-gray-400 mt-2 mb-2">
        You do not have access to modify any fields in this tab
      </h4>
    </quest-card>
    <template v-if="p_any">
      <template v-if="roomTypes.length > 0">
        <div class="row">
          <div class="col-md-6 offset-md-6">
            <div class="form-group text-right">
              <quest-toggle
                id="currentView"
                v-model="editMode"
                checked="Edit Mode"
                unchecked="Sort Mode"
                :checked-icon="['fas', 'edit']"
                :unchecked-icon="['fas', 'sort']"
                checked-theme="light"
                unchecked-theme="dark"
                :inline="true"
                minTextWidth="120"
              />
            </div>
          </div>
        </div>
        <div v-if="editMode">
          <room-type-card
            v-for="(room, index) in roomTypes"
            :key="index"
            :room="room"
            :collapsable="true"
          >
            <div class="row" v-if="[p_isEnabled].some((x) => x)">
              <div class="col-md-6" v-if="p_isEnabled">
                <quest-toggle
                  :id="`${room.siteminderRoomId || index}-isEnabled`"
                  label="Status"
                  v-model="room.isEnabled"
                  checked="Enabled"
                  unchecked="Disabled"
                  nooption="Deleted"
                  :deleted="room.isInactive"
                  help="Enable room type throughout Quest"
                />
              </div>
            </div>

            <div
              class="row"
              v-if="[p_occupancy, p_numberofrooms].some((x) => x)"
            >
              <div class="col-md-6" v-if="p_occupancy">
                <quest-input
                  :id="`${room.siteminderRoomId || index}-maxOccupancy`"
                  label="Max Occupancy"
                  v-model="room.maxOccupancy"
                  help="Maximum booking capacity for a room"
                ></quest-input>
              </div>
              <div class="col-md-6" v-if="p_numberofrooms">
                <quest-input
                  :id="`${room.siteminderRoomId || index}-p_numberofrooms`"
                  label="Number of Rooms"
                  v-model="room.numberOfRooms"
                  help="Number of rooms in the apartment"
                ></quest-input>
              </div>
            </div>

            <div class="row" v-if="[p_tag, p_sqm].some((x) => x)">
              <div class="col-md-6" v-if="p_tag">
                <quest-input
                  :id="`${room.siteminderRoomId || index}-tag`"
                  label="Tag"
                  name="Room tag"
                  v-model="room.tag"
                  help="Short, descriptive tagline to attract target audience"
                  :rules="{ [MAX]: { length: 36 } }"
                ></quest-input>
              </div>
              <div class="col-md-6" v-if="p_sqm">
                <quest-input
                  :id="`${room.siteminderRoomId || index}-sqm`"
                  label="Area (m²)"
                  v-model="room.sqm"
                  help="Area in square metres"
                ></quest-input>
              </div>
            </div>

            <template v-if="p_beds">
              <hr />
              <h5 class="mb-3">Beds</h5>

              <div class="row">
                <div class="col-md-6 offset-md-6">
                  <div class="form-group text-right">
                    <a
                      @click="addBed(room)"
                      class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                      ><font-awesome-icon
                        icon="plus"
                        size="sm"
                        class="text-white-50"
                      ></font-awesome-icon>
                      Add
                    </a>
                  </div>
                </div>
              </div>
              <template v-if="room.beds.length > 0">
                <div class="row" v-for="(bed, index) in room.beds" :key="index">
                  <div class="col-md-6">
                    <quest-single-select
                      v-model="bed.key"
                      label="Bed size"
                      :options="beds"
                      help=""
                      @update="(val) => updateBed(val, bed)"
                    ></quest-single-select>
                  </div>
                  <div class="col-md-6">
                    <quest-input
                      v-model="bed.value"
                      label="Quantity"
                      type="number"
                    ></quest-input>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="row">
                  <div class="col">
                    <small class="text-center d-block"
                      >No beds configured</small
                    >
                  </div>
                </div>
              </template>
              <hr />
            </template>

            <quest-multi-select
              v-if="p_facilities"
              :id="`${room.siteminderRoomId || index}-facilities`"
              label="Room Features"
              v-model="room.facilities"
              :options="facilities"
              help="Features listed for this room type"
              :max="10"
            ></quest-multi-select>

            <quest-text-area
              v-if="p_description"
              name="Room description"
              id="description"
              label="Description"
              v-model="room.description"
              :rules="{ [MAX]: { length: 2500 } }"
            ></quest-text-area>

            <template v-if="p_imagery">
              <div class="form-group rounded border p-2">
                <label>Hero Image</label>
                <div class="d-flex h-100">
                  <quest-image
                    :image="room.image"
                    single
                    @change="openPicker(room, true)"
                  ></quest-image>
                </div>
              </div>

              <div class="form-group rounded border p-2">
                <div class="d-flex justify-content-between">
                  <label>Gallery</label>
                  <button
                    @click="openPicker(room, false)"
                    class="btn btn-primary btn-sm"
                  >
                    Update Gallery
                  </button>
                </div>
                <template v-if="room.gallery.length > 0">
                  <div class="d-flex h-100 flex-wrap mt-2">
                    <div
                      class="col-12 col-md-4 col-lg-3 mb-3 text-center"
                      v-for="item in room.gallery"
                      :key="item.mediaId"
                    >
                      <quest-image :image="item.mediaId"></quest-image>

                      <ValidationProvider
                        name="Room image tag"
                        :rules="{ [MAX]: { length: 36 } }"
                        v-slot="{ errors, classes }"
                      >
                        <input
                          type="text"
                          class="form-control"
                          :class="classes"
                          placeholder="Image Tag"
                          v-model="item.label"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <small>No images added to gallery</small>
                </template>
              </div>
            </template>
          </room-type-card>
        </div>
        <template v-if="!editMode">
          <sortable
            disabled
            v-for="(room, index) in roomTypes"
            :key="room.id"
            v-model="dragData"
            :index="index"
            drag-direction="vertical"
            replace-direction="vertical"
            @sortend="sortend($event)"
          >
            <room-type-card :room="room" :collapsable="false"></room-type-card>
          </sortable>
        </template>
      </template>
      <template v-if="roomTypes.length === 0">
        <div class="small text-center">No rooms were found</div>
      </template>

      <quest-image-picker
        ref="roomPicker"
        :title="picker.title"
        :propertyId="
          property.siteminderPropertyId !=
          '00000000-0000-0000-0000-000000000000'
            ? property.siteminderPropertyId
            : property.id
        "
        :albumName="property.name"
        :current="picker.current"
        @update="updateImages"
        :required="picker.required"
        :multiple="picker.multiple"
        manage
      ></quest-image-picker>
    </template>
  </div>
</template>

<script>
import View from './view'

import { RoomTypesManagementService, FacilityService } from '../../services'
import { QuestTable } from '../structure'
import Sortable from 'vue-drag-sortable'
import RoomTypeCard from '../property-cards/room-type-card.vue'
import QuestSingleSelect from '../form/quest-single-select.vue'
import { Rooms } from '@/router/permission-types'
import { hasFieldAccess } from '@/helpers'

export default {
  extends: View,
  name: 'property-rooms-view',
  components: {
    Sortable,
    QuestTable,
    RoomTypeCard,
    QuestSingleSelect,
  },
  data() {
    return {
      dragData: {},
      columns: ['Name', 'Occupany', 'Status'],
      editMode: true,
      roomTypes: this.rooms || [],
      picker: this.emptyPicker(),
      facilities: [],
      beds: [
        { key: 0, value: 'Single' },
        { key: 1, value: 'Single XL' },
        { key: 2, value: 'King Single' },
        { key: 3, value: 'Double' },
        { key: 4, value: 'Queen' },
        { key: 5, value: 'King' },
        { key: 6, value: 'Super King' },
      ],
    }
  },
  computed: {
    p_any() {
      return Object.entries(this._computedWatchers)
        .filter((x) => x[0] != 'p_any' && x[0].startsWith('p_'))
        .some((x) => x[1].getter())
    },
    p_isEnabled() {
      return hasFieldAccess(Rooms, 'isEnabled')
    },
    p_occupancy() {
      return hasFieldAccess(Rooms, 'occupancy')
    },
    p_numberofrooms() {
      return hasFieldAccess(Rooms, 'numberOfRooms')
    },
    p_tag() {
      return hasFieldAccess(Rooms, 'tag')
    },
    p_sqm() {
      return hasFieldAccess(Rooms, 'sqm')
    },
    p_beds() {
      return hasFieldAccess(Rooms, 'beds')
    },
    p_description() {
      return hasFieldAccess(Rooms, 'description')
    },
    p_facilities() {
      return hasFieldAccess(Rooms, 'facilities')
    },
    p_imagery() {
      return hasFieldAccess(Rooms, 'imagery')
    },
  },
  mounted() {
    if (this.roomTypes.length == 0) {
      Promise.all([
        new RoomTypesManagementService().list(this.property.id),
        new FacilityService().all(),
      ]).then((data) => {
        this.roomTypes = data[0].map((x) => {
          x.beds = x.beds.filter((p) => !!p.value)
          return x
        })
        this.facilities = data[1]
      })
    }
  },
  methods: {
    sortend(e) {
      const { oldIndex, newIndex } = e
      this.rearrange(oldIndex, newIndex)
    },
    rearrange(oldIndex, newIndex) {
      if (oldIndex > newIndex) {
        this.roomTypes.splice(newIndex, 0, this.roomTypes[oldIndex])
        this.roomTypes.splice(oldIndex + 1, 1)
      } else {
        this.roomTypes.splice(newIndex + 1, 0, this.roomTypes[oldIndex])
        this.roomTypes.splice(oldIndex, 1)
      }
    },
    addBed(room) {
      room.beds.push({ key: 0, value: 0 })
    },
    updateBed(value, bed) {
      bed.key = value
    },
    updateImages(selected) {
      if (this.picker.multiple) {
        this.picker.roomType.gallery = selected.map((x) => ({
          mediaId: x,
          label: ((x) => {
            const g = this.picker.roomType.gallery.find((p) => p.mediaId === x)
            return g ? g.label : ''
          })(x),
        }))
      } else {
        this.picker.roomType.image = selected
      }
      this.picker = this.emptyPicker()
    },
    emptyPicker() {
      return {
        title: '',
        multiple: false,
        required: false,
        current: [],
        roomType: null,
      }
    },
    getRoomTypes() {
      return (
        this.roomTypes.map((r, i) => {
          r.beds = r.beds.filter((x) => !!x.value)
          r.sortOrder = i + 1
          return r
        }) || []
      )
    },
    openPicker(room, isHero) {
      this.picker = {
        title: isHero ? 'Select hero image' : 'Select gallery images',
        multiple: !isHero,
        required: isHero,
        current: isHero ? [room.image] : room.gallery.map((s) => s.mediaId),
        roomType: room,
      }
      this.$refs.roomPicker.open()
    },
  },
}
</script>

<template>
  <div class="row">
    <div class="col-sm-12 col-md-5">
      <div
        class="dataTables_info"
        id="dataTable_info"
        role="status"
        aria-live="polite"
      >
        Showing {{ pageSize * (currentPage - 1) + 1 }} to
        {{ pageSize * currentPage }} of {{ itemCount }} records
      </div>
    </div>
    <div class="col-sm-12 col-md-7">
      <div
        class="dataTables_paginate paging_simple_numbers"
        id="dataTable_paginate"
      >
        <ul
          class="pagination"
          v-if="pageCount > 1"
          style="justify-content: flex-end"
        >
          <li
            class="paginate_button page-item previous"
            id="dataTable_previous"
            :class="{ disabled: currentPage === 1 }"
          >
            <a
              @click.prevent="goToPreviousPage"
              aria-controls="dataTable"
              data-dt-idx="0"
              tabindex="0"
              class="page-link"
            >
              <font-awesome-icon icon="angle-left"></font-awesome-icon>
              <span class="sr-only">Previous page</span>
            </a>
          </li>

          <li
            v-for="pageNum in pagesForPager"
            :key="pageNum"
            class="paginate_button page-item"
            :class="{ active: pageNum === currentPage }"
          >
            <a
              @click.prevent="changePage(pageNum)"
              aria-controls="dataTable"
              :data-dt-idx="pageNum"
              tabindex="0"
              class="page-link"
            >
              <span class="sr-only">Page </span> {{ pageNum }}
              <span class="sr-only" v-if="pageNum === currentPage"
                >(current)</span
              >
            </a>
          </li>

          <li
            class="paginate_button page-item next"
            id="dataTable_next"
            :class="{ disabled: currentPage === pageCount }"
          >
            <a
              @click.prevent="gotoNextPage"
              aria-controls="dataTable"
              :data-dt-idx="pageCount + 1"
              tabindex="0"
              class="page-link"
            >
              <font-awesome-icon icon="angle-right"></font-awesome-icon>
              <span class="sr-only">Next page</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'quest-pager',
  props: {
    currentPage: Number,
    itemCount: Number,
    pageSize: { default: 12 },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  computed: {
    isMobile() {
      return this.windowWidth <= 767
    },
    pageCount() {
      return Math.ceil(this.itemCount / this.pageSize)
    },
    firstPageNumber() {
      return Math.max(this.currentPage - (this.isMobile ? 2 : 4), 1)
    },
    lastPageNumber() {
      return Math.min(
        this.currentPage + (this.isMobile ? 2 : 4),
        this.pageCount
      )
    },
    pagesForPager() {
      return Array.from(
        {
          length: this.lastPageNumber - this.firstPageNumber + 1,
        },
        (_, i) => this.firstPageNumber + i
      )
    },
  },
  methods: {
    changePage(pageNum) {
      this.$emit('changePage', pageNum)
    },
    goToPreviousPage() {
      this.$emit('changePage', this.currentPage - 1)
    },
    gotoNextPage() {
      this.$emit('changePage', this.currentPage + 1)
    },
  },
}
</script>

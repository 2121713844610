<template>
  <ValidationObserver ref="vobserver" v-slot="{ errors }">
    <quest-validation-summary :errors="errors"></quest-validation-summary>
    <quest-main
      :title="property ? property.name || 'Error' : ''"
      :loading="loading"
      :noconnect="noconnect"
      :saving="saving"
      :copy="
        property && property.isMirrored && !property.isActivated
          ? 'This property is a \'Ghost\'. There is an active record in another account.'
          : ''
      "
    >
      <template v-slot:head>
        <div>
          <button
            v-if="
              currentAccount?.service === ExternalServiceType.TBB &&
              !property.isMirrored
            "
            @click="toClone()"
            class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm mr-2"
          >
            <font-awesome-icon
              icon="clone"
              size="sm"
              fixed-width
              class="text-white-50"
            ></font-awesome-icon>
            Clone to RMS
          </button>
          <!-- <button
            v-if="currentAccount?.service === ExternalServiceType.RMS"
            @click="toLink()"
            class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm mr-2"
          >
            <font-awesome-icon
              icon="link"
              size="sm"
              fixed-width
              class="text-white-50"
            ></font-awesome-icon>
            Linked Rate Tables
          </button> -->
          <button
            @click="save()"
            class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
            v-bind="{ disabled: saving }"
          >
            <font-awesome-icon
              icon="save"
              size="sm"
              fixed-width
              class="text-white-50"
              :class="{ 'animated--heart-beat': saving }"
            ></font-awesome-icon>
            {{ saving ? 'Saving...' : 'Save' }}
          </button>
        </div>
      </template>
      <template v-slot:body>
        <template v-if="property.id">
          <div class="row">
            <quest-stat-card
              label="Created"
              :value="dateCreated"
              :icon="['fas', 'calendar-plus']"
              card="col-xl-4 col-md-4"
            ></quest-stat-card>

            <quest-stat-card
              label="Last Modified"
              :value="dateModified"
              :icon="['fas', 'calendar-edit']"
              card="col-xl-4 col-md-4"
            ></quest-stat-card>

            <quest-stat-card
              label="Last Sync"
              :value="lastSync"
              :icon="['fad', 'sync']"
              card="col-xl-4 col-md-4"
            ></quest-stat-card>
          </div>
          <div class="row" v-if="isAdmin">
            <quest-stat-card
              label="Sync Status"
              :value="syncStatus.value"
              :theme="syncStatus.theme"
              card="col-12"
            >
              <template>
                <button
                  @click="sync()"
                  class="btn btn-primary btn-sm"
                  v-bind="{ disabled: syncing }"
                >
                  <font-awesome-icon
                    :icon="['fad', 'sync']"
                    size="sm"
                    fixed-width
                    class="text-white-50"
                    v-bind="{ spin: syncing }"
                  ></font-awesome-icon>
                  {{ syncing ? 'Syncing...' : 'Sync' }}
                </button>
              </template>
            </quest-stat-card>
          </div>
        </template>
        <property-crud
          :property="property"
          :configuration="configuration"
          :csr="csr"
          :announcement="announcement"
          :view="view"
          :load="(_) => load()"
          @loaded="loaded"
          @activate="activate"
          ref="crud"
        ></property-crud>
      </template>
    </quest-main>
    <b-modal
      v-model="showLinkingModal"
      centered
      :title="'RMS Rate tables linked'"
    >
      <div class="list-group" style="max-height: 50vh; overflow: auto">
        <label
          class="list-group-item"
          v-for="table in rateTables"
          :key="'link-' + table.id"
        >
          <input
            type="checkbox"
            v-model="table.isAssociated"
            :id="'link-' + table.id"
          />
          <span style="padding-left: 12px"
            >{{ table.name }}&nbsp;<small>({{ table.externalId }})</small></span
          >
        </label>
      </div>
      <template v-slot:modal-footer>
        <button
          class="btn btn-secondary btn-sm"
          type="button"
          @click="cancelLinking"
        >
          Cancel</button
        ><button
          class="btn btn-primary btn-sm"
          type="button"
          @click="confirmLinking"
        >
          Confirm
        </button>
      </template>
    </b-modal>
    <b-modal v-model="showCloneModal" centered :title="'Clone to RMS Account'">
      <div class="row">
        <div class="col-12">
          <quest-single-select
            v-model="accountToCloneTo"
            :options="cloneAccountSelection"
            okey="id"
            otext="label"
            label="RMS Account"
            id="accountToCloneTo"
            placeholder="No account selected"
            help="Set the account you wish to send a clone of this property"
            @update="updateCloneAccount"
          ></quest-single-select>
          <quest-input
            id="clientIdForClone"
            label="RMS Client No."
            v-model="clientIdForClone"
            help="Set the RMS Client No."
          ></quest-input>
          <quest-input
            id="externalIdForClone"
            label="Property Id"
            v-model="externalIdForClone"
            help="Set the Id of the property"
          ></quest-input>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12">
          <p>Set the unique RMS Id per room type</p>
        </div>
      </div>
      <div class="row" v-for="roomType in roomTypes" :key="roomType.key">
        <div class="col-12">
          <quest-input
            :label="roomType.name"
            :id="'room-' + roomType.id"
            v-model="roomType.clientId"
          ></quest-input>
        </div>
      </div>
      <template v-slot:modal-footer>
        <button
          class="btn btn-secondary btn-sm"
          type="button"
          @click="cancelClone"
        >
          Cancel</button
        ><button
          :disabled="
            !accountToCloneTo ||
            !clientIdForClone ||
            !externalIdForClone ||
            roomTypes.some((x) => !x.clientId)
          "
          class="btn btn-primary btn-sm"
          type="button"
          @click="confirmClone"
        >
          Confirm
        </button>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import { toasted, success, error, PropertyView } from '../helpers'
import { mapActions, mapGetters, mapState } from 'vuex'
import { INIT_PROPERTY } from '../store/action-types'
import moment from 'moment'
import 'moment-timezone'

import {
  PropertyManagementService,
  SyncService,
  RoomTypesManagementService,
  FAQService,
  ModuleConfigurationService,
  PropertyLocalityService,
  BenefitsService,
  CSRManagementService,
  AnnouncementManagementService,
} from '../services'

import { QuestMain, QuestStatCard, QuestValidationSummary } from './structure'
import PropertyCrud from './property-crud.vue'
import { QuestImageUpload, QuestSingleSelect, QuestInput } from './form'
import { ExternalServiceType } from '@/models'

export default {
  name: 'property',
  components: {
    QuestMain,
    QuestStatCard,
    PropertyCrud,
    QuestImageUpload,
    QuestSingleSelect,
    QuestValidationSummary,
    QuestInput,
  },
  data() {
    return {
      rateTables: [],
      showLinkingModal: false,
      showCloneModal: false,
      ExternalServiceType,
      view: PropertyView.Config,
      configuration: {
        enableConferences: false,
        enableLongerStay: false,
        enableLocalDiscovery: false,
      },
      announcement: {},
      csr: {},
      property: null,
      loading: true,
      noconnect: false,
      syncing: false,
      saving: false,
      accountToCloneTo: '',
      clientIdForClone: '',
      externalIdForClone: '',
      roomTypes: [],
    }
  },
  mounted() {
    this.INIT_PROPERTY()
    this.load()
  },
  computed: {
    ...mapState(['account', 'accounts']),
    ...mapGetters(['isAdmin', 'currentAccount']),
    lastSync() {
      return this.property.lastSyncSuccessUTC
        ? moment(`${this.property.lastSyncSuccessUTC}Z`)
            .tz('Australia/Melbourne')
            .format('lll')
        : 'Never'
    },
    syncStatus() {
      let theme = 'dark'
      let value = 'Never'

      if (this.syncing) {
        value = 'Syncing'
        theme = 'secondary'
      } else if (this.property.lastSyncAttemptUTC) {
        const duration = moment
          .duration(moment().diff(`${this.property.lastSyncAttemptUTC}Z`))
          .asHours()

        if (this.property.sync === 'Synced' && duration < 4) {
          value = 'Synced'
          theme = 'primary'
        } else if (this.property.sync === 'Syncing') {
          value = 'Syncing'
          theme = 'secondary'
        } else {
          value = 'Unsynced'
          theme = 'danger'
        }
      }
      return {
        theme,
        value,
      }
    },
    cloneAccountSelection() {
      return this.accounts.filter((x) => x.service === 'RMS')
    },
    dateCreated() {
      return moment(`${this.property.dateCreated}Z`)
        .tz('Australia/Melbourne')
        .format('lll')
    },
    dateModified() {
      return moment(`${this.property.dateModified}Z`)
        .tz('Australia/Melbourne')
        .format('lll')
    },
  },
  methods: {
    ...mapActions([INIT_PROPERTY]),
    toClone() {
      if (!this.property.isMirrored) {
        this.showCloneModal = true
      }
    },
    activate() {
      this.loading = true

      new PropertyManagementService()
        .activate(this.property.id)
        .then(async (data) => {
          if (data === true) {
            this.load()
            success(`Successfully activated #`, this.property.name)
          } else {
            this.loading = false
            error(`Failed to activate #`, this.property.name)
          }
        })
    },
    updateCloneAccount(update) {
      this.accountToCloneTo = update
    },
    // toLink() {
    //   this.showLinkingModal = false
    //   new PropertyManagementService()
    //     .getPropertyRateTables(this.property.id)
    //     .then(async (data) => {
    //       this.rateTables = data
    //       this.showLinkingModal = true
    //     })
    // },
    cancelClone() {
      this.showCloneModal = false
      this.accountToCloneTo = ''
      this.clientIdForClone = ''
      this.externalIdForClone = ''
    },
    confirmClone() {
      new PropertyManagementService()
        .clone(
          this.property.id,
          this.accountToCloneTo,
          this.clientIdForClone,
          this.externalIdForClone,
          this.roomTypes
        )
        .then(async (data) => {
          if (data === true) {
            this.loading = true
            this.showCloneModal = false
            this.accountToCloneTo = ''
            this.load()
            success(`Successfully cloned #`, this.property.name)
          } else {
            error(`Failed to clone #`, this.property.name)
          }
        })
    },
    // cancelLinking() {
    //   this.showLinkingModal = false
    //   this.rateTables = []
    // },
    // confirmLinking() {
    //   let linked = this.rateTables
    //     .filter((x) => x.isAssociated)
    //     .map((x) => x.id)
    //   new PropertyManagementService()
    //     .setPropertyRateTables(this.property.id, linked)
    //     .then(async (data) => {
    //       if (data) {
    //         this.loading = true
    //         this.showLinkingModal = false
    //         this.rateTables = []
    //         this.load()
    //         success(
    //           `Updates linked rate tables for property #`,
    //           this.property.name
    //         )
    //       } else {
    //         error(
    //           `Failed to update linked rate tables for property #`,
    //           this.property.name
    //         )
    //       }
    //     })
    // },
    sync() {
      this.syncing = true

      if (this.property.service === 'RMS') {
        if (this.property.id) {
          new SyncService()
            .synthesisFullProperty(
              this.currentAccount.externalId,
              this.property.channelCode
            )
            .then(async (data) => {
              if (data) {
                this.loading = true
                this.load()
                success(`Synced property #`, this.property.name)
              } else {
                this.property.sync = 'Unsynced'
                error(`Failed to sync property #`, this.property.name)
              }
            })
            .finally((_) => {
              this.syncing = false
            })
        }
      } else {
        if (this.property.siteminderPropertyId) {
          new SyncService()
            .fullProperty(this.property.siteminderPropertyId)
            .then(async (data) => {
              if (data) {
                this.loading = true
                this.load()
                success(`Synced property #`, this.property.name)
              } else {
                this.property.sync = 'Unsynced'
                error(`Failed to sync property #`, this.property.name)
              }
            })
            .finally((_) => {
              this.syncing = false
            })
        }
      }
    },
    async save() {
      if (!this.$refs.vobserver) return
      let validated = await this.$refs.vobserver.validate()
      if (!validated) {
        error('Please ensure all errors are resolved')
        return
      }
      let values = this.$refs.crud.getValues()

      this.saving = true

      if (this.property.id) {
        Promise.all([
          new FAQService().set(values.faqs),
          new BenefitsService().set(values.benefits),
          new ModuleConfigurationService().set(values.config),
          new PropertyLocalityService().set(values.locality),
          new RoomTypesManagementService().update(values.roomTypes),
          new CSRManagementService().setForProperty(values.csr),
          new AnnouncementManagementService().setForProperty(
            values.announcement
          ),
          new PropertyManagementService().update(this.property),
        ])
          .then((data) => {
            if (data.every((p) => p)) {
              this.load()
              success(`Updated property #`, this.property.name)
            } else {
              error(`Failed to update property #`, this.property.name)
            }
          })
          .finally(() => {
            this.saving = false
          })
      }
    },
    preview() {
      this.$refs.crud.preview()
    },
    loaded() {
      if (this.$refs && this.$refs.vobserver) {
        this.$refs.vobserver.validate()
      }
      if (!this.$route.hash) {
        if (this.$refs.crud.$refs[PropertyView.Content].p_any) {
          this.view = PropertyView.Content
        }
      } else {
        switch (this.$route.hash) {
          case '#content':
            this.view = PropertyView.Content
            break
          case '#conferencing':
            this.view = PropertyView.Conference
            break
          case '#explorer':
            this.view = PropertyView.Explorer
            break
          case '#locality':
            this.view = PropertyView.Locality
            break
          case '#rooms':
            this.view = PropertyView.Rooms
            break
          case '#preview-web':
            this.preview()
            break
          case '#preview-metadata':
            this.view = PropertyView.MetaPreview
            break
          case '#rates':
            if (this.currentAccount?.service === ExternalServiceType.RMS) {
              this.view = PropertyView.Rates
              break
            }
            break
          case '#extras':
            if (this.currentAccount?.service === ExternalServiceType.RMS) {
              this.view = PropertyView.Extras
              break
            }
          default:
            this.view = PropertyView.Config
            break
        }
      }
    },
    load() {
      const id = this.$route.params.id

      Promise.all([
        new PropertyManagementService().getConfiguration(id),
        new PropertyManagementService().getProperty(id),
        new PropertyManagementService().getCSR(id),
        new PropertyManagementService().getAnnouncement(id),
        new RoomTypesManagementService().list(id),
      ])
        .then((data) => {
          this.configuration = data[0]
          this.csr = data[2] || {}
          this.announcement = data[3] || {}
          this.roomTypes =
            data[4].map((x) => ({ id: x.id, clientId: '', name: x.name })) || []

          toasted(
            data[1],
            null,
            'Failed to load property',
            null,
            null,
            (property) => {
              this.property = property
            }
          )
        })
        .finally(() => {
          this.$nextTick(() => {
            this.loading = false
          })
        })
    },
  },
}
</script>

<template>
  <span>
    <a
      :id="roomId + '-' + rate.rateTypeId + '-status-popover-target'"
      class="pointer text-secondary mr-1"
    >
      <font-awesome-icon
        :icon="['fas', 'info-circle']"
        fixed-width
      ></font-awesome-icon> </a
    ><b-popover
      :target="roomId + '-' + rate.rateTypeId + '-status-popover-target'"
      triggers="hover"
      placement="top"
    >
      <template #title>{{ status.key }}</template>
      {{ status.value }} </b-popover
    ><span
      :class="[
        rate.rateStatus == RateQuoteStatus.Success &&
        rate.status == QuoteStatus.Success
          ? 'text-success'
          : 'text-danger',
      ]"
      >{{ status.key }}</span
    >
  </span>
</template>

<script>
import { QuoteStatus, RateQuoteStatus } from '../../models'

export default {
  name: 'rate-explorer-status',
  props: {
    roomId: { required: true },
    rate: { required: true },
  },
  data() {
    return {
      QuoteStatus,
      RateQuoteStatus,
    }
  },
  computed: {
    status() {
      if (this.rate.rateStatus == RateQuoteStatus.Success) {
        switch (this.rate.status) {
          case QuoteStatus.MissingContext:
            return {
              key: 'Missing pricing',
              value:
                'There is limited pricing available given the selected rate table. This may indicate an issue with the RMS sync, requiring a resync to realign Nexus data',
            }
          case QuoteStatus.NoContext:
            return {
              key: 'No pricing',
              value:
                'There is no pricing available given the selected rate table. This may indicate an issue with the RMS sync, requiring a resync to realign Nexus data.',
            }
          case QuoteStatus.Success:
            return {
              key: 'Successful',
              value: 'A quote has been successfully provided',
            }
          case QuoteStatus.Blocked:
            return {
              key: 'Blocked',
              value:
                'Rate table is not associated with property or RMS booking rules have blocked this rate from producing a quote. This is due to a stop sell on arrival, departure or within the date range.',
            }
          case QuoteStatus.NoTable:
            return {
              key: 'No valid rate table',
              value:
                "The rates' linked RMS rate table is missing. Please ensure that it is mapped in RMS and/or not deleted. You may change the RMS rate table connected to this Nexus rate at any time.",
            }
          case QuoteStatus.NoCancellationPolicy:
            return {
              key: 'No valid cancellation policy',
              value:
                "The rates' linked RMS rate table is in turn linked to a valid cancellation policy. Please ensure that it is mapped in RMS and/or not deleted. You may change the RMS rate table connected to this Nexus rate at any time.",
            }
          case QuoteStatus.BelowMinimumThreshold:
            return {
              key: 'Below minimum threshold',
              value:
                'Any quote supplied that has a daily gross below the minimum threshold, after discounts, is automatically locked out and unable to be reserved.',
            }
          case QuoteStatus.Check:
          case QuoteStatus.Error:
          default:
            return {
              key: 'System Error',
              value:
                'There was an unexpected error encountered when processing this rate. Please lodge a ticket with support detailing your search and issue.',
            }
        }
      } else {
        switch (this.rate.rateStatus) {
          case RateQuoteStatus.Inactive:
            return {
              key: 'Rate inactive',
              value: 'This rate is currently disabled',
            }
          case RateQuoteStatus.CodeBlocked:
            return {
              key: 'Required group code',
              value:
                'A promotional / corporate / group code is required for this rate',
            }
          case RateQuoteStatus.NoSell:
            return {
              key: 'No sell',
              value: 'Date range excludes rates due to no sell rules',
            }
          case RateQuoteStatus.NoStay:
            return {
              key: 'No stay',
              value: 'Date range excludes rates due to no stay rules',
            }
          case RateQuoteStatus.OutOfNightRange:
            return {
              key: 'Outside of nights',
              value: 'This rate has a limited stay night range',
            }
          case RateQuoteStatus.LoginOnly:
            return {
              key: 'Requires login',
              value: 'This rate requires user login',
            }
          case RateQuoteStatus.StayPayBlocked:
            return {
              key: 'Stay night restriction',
              value: 'This rate has a Stay X PAy Y restriction',
            }
          case RateQuoteStatus.StayNightBlocked:
            return {
              key: 'Invalid stay night',
              value: 'Stay night exclusions have denied this rate',
            }
          case RateQuoteStatus.AdvanceBlock:
            return {
              key: 'Advance nights restricted',
              value: 'An advance stay restriction has denied this rate',
            }
          case RateQuoteStatus.LastMinuteBlock:
            return {
              key: 'Last minute restricted',
              value: 'A last minute restriction has denied this rate',
            }
          case RateQuoteStatus.OccupancyBlock:
            return {
              key: 'Occupancy restricted',
              value:
                'Listed occupants is outside of the listed range for this rate',
            }
          case RateQuoteStatus.Error:
          default:
            return {
              key: 'System Error',
              value:
                'There was an unexpected error encountered when processing this rate. Please lodge a ticket with support detailing your search and issue.',
            }
        }
      }
    },
  },
}
</script>

<template>
  <div class="border bg-white p-3 my-3 rounded" v-if="!loading">
    <div class="row">
      <div class="d-flex col-12 gap-2 justify-content-between mb-3">
        <label>Extras / Inclusions</label>
        <div class="d-flex gap-2">
          <quest-single-select
            placeholder="Select an extra to configure"
            :value="extra"
            :options="extras"
            id="extra"
            @update="updateExtra"
            okey="id"
            otext="name"
            wrapperClass="form-group m-0"
          ></quest-single-select>
          <button class="btn btn-primary btn-sm" @click="() => add()">
            <span class="icon text-white-50">
              <font-awesome-icon
                fixed-width
                :icon="['fas', 'plus']"
              ></font-awesome-icon>
            </span>
            <span class="text">Add</span>
          </button>
        </div>
      </div>
    </div>

    <small class="text-center d-block" v-if="!extras.length"
      >No extras are available for this property</small
    >
    <template v-if="items.length">
      <b-table
        :items="items"
        :fields="[
          { key: 'name' },
          { key: 'relationship' },
          {
            key: 'options',
            label: '',
            thStyle: { width: '50px' },
            tdClass: 'text-center',
          },
        ]"
        small
        hover
        fixed
        stacked="md"
        borderless
        tbody-tr-class="align-middle"
      >
        <template #cell(name)="data">{{
          extras.find((x) => x.id === data.item.key).name
        }}</template>
        <template #cell(relationship)="data">
          <span v-if="data.item.isDefault">Optional (Default)</span>
          <quest-single-select
            v-if="!data.item.isDefault"
            :value="data.item.value"
            :options="
              extras.find((x) => x.id === data.item.key).interval ===
              ExtraInterval.Booking
                ? bookingRelationships
                : relationships
            "
            id="extra"
            @update="(e) => (data.item.value = e)"
            okey="key"
            otext="value"
            wrapperClass="form-group m-0"
          ></quest-single-select></template
        ><template #cell(options)="data"
          ><a
            @click="remove(data.item)"
            v-if="!data.item.isDefault"
            class="pointer"
            ><font-awesome-icon
              icon="times"
              size="sm"
            ></font-awesome-icon></a></template
      ></b-table>
    </template>
  </div>
</template>

<script>
import { QuestDatePicker, QuestToggle, QuestSingleSelect } from '../form'
import { ExtrasService } from '@/services'
import {
  ExtraRelationship,
  ExtraRelationshipConvert,
  ExtraInterval,
} from '@/models'

export default {
  name: 'rate-extras-list',
  components: {
    QuestDatePicker,
    QuestToggle,
    QuestSingleSelect,
  },
  props: {
    records: { default: () => [] },
    base: { default: null },
    override: { default: false },
    disabled: { default: false },
    id: { required: true },
    property: { required: true },
  },
  computed: {
    items() {
      return [
        ...this.extras
          .filter((x) => x.isDefault)
          .map((x) => ({
            key: x.id,
            isDefault: x.isDefault,
            value: ExtraRelationship.Optional,
          })),
        ...(this.records || []),
      ]
    },
    relationshipOptions() {
      return (key) =>
        this.extras.find((x) => x.id === key).interval === ExtraInterval.Booking
          ? bookingRelationships
          : relationships
    },
  },
  data() {
    return {
      ExtraRelationshipConvert,
      ExtraInterval,
      loading: true,
      extras: [],
      extra: '',
      relationships: Object.entries(ExtraRelationshipConvert).map((x) => ({
        key: x[0].toString(),
        value: x[1],
      })),
      bookingRelationships: Object.entries(ExtraRelationshipConvert)
        .filter((x) =>
          [ExtraRelationship.Optional, ExtraRelationship.Excluded].includes(
            +x[0]
          )
        )
        .map((x) => ({
          key: x[0].toString(),
          value: x[1],
        })),
    }
  },
  mounted() {
    new ExtrasService().forProperty(this.property).then((data) => {
      this.extras = data
      this.loading = false
    })
  },
  methods: {
    updateExtra(selected) {
      this.extra = selected
    },
    add() {
      if (this.extra) {
        let record = {
          key: this.extra,
          value: ExtraRelationship.Optional,
          isDefault: false,
        }
        this.records?.push(record)
        this.$emit('replace', this.records || [record])
        this.extra = ''
      }
    },
    remove(record) {
      this.$emit(
        'replace',
        this.records.filter((x) => x !== record)
      )
    },
  },
}
</script>

<template>
  <b-modal ref="modal" title="Create rate" @hidden="hidden">
    <b-alert show variant="primary">
      <p><b>Select the scope for the new rate.</b></p>
      <p>
        Global or "multi-property" scopes will be applicable over all properties
        within the specified region(s).
      </p>
      <p>
        A property specific scope will be managed and displayed only for the
        selected hotel.
      </p>
    </b-alert>
    <quest-toggle
      :checked-icon="['fas', 'globe-asia']"
      :unchecked-icon="['fas', 'hotel']"
      checked="Global"
      unchecked="Property"
      v-model="scope"
      unchecked-theme="dark"
    ></quest-toggle>
    <div class="row">
      <template v-if="scope">
        <div class="col-12">
          <quest-single-select
            v-model="country"
            :options="countries"
            label="Country"
            id="country"
            placeholder="Any"
            :keepPlaceholder="true"
            @update="updateCountry"
          ></quest-single-select>
        </div>

        <div class="col-12">
          <quest-single-select
            v-model="state"
            :options="calculatedStates"
            label="State"
            id="state"
            placeholder="Any"
            :keepPlaceholder="true"
            @update="updateState"
          ></quest-single-select>
        </div>
      </template>

      <template v-if="!scope">
        <div class="col-12">
          <quest-single-select
            v-model="property"
            :options="properties"
            label="Property"
            id="property"
            placeholder="None"
            okey="id"
            otext="name"
            :keepPlaceholder="true"
            @update="updateProperty"
          ></quest-single-select>
        </div>
      </template>
    </div>
    <template v-slot:modal-footer>
      <button class="btn btn-secondary btn-sm" type="button" @click="close">
        Cancel</button
      ><button
        class="btn btn-primary btn-sm"
        type="button"
        @click="confirm"
        :disabled="scope === false && property === ''"
      >
        Confirm
      </button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'

import {
  QuestInput,
  QuestSingleSelect,
  QuestToggle,
  QuestTextArea,
  QuestMultiSelect,
  QuestDatePicker,
} from '../form'

export default {
  name: 'create-rate-selection',
  props: {
    properties: [],
  },
  components: {
    QuestInput,
    QuestSingleSelect,
    QuestToggle,
    QuestTextArea,
    QuestMultiSelect,
    QuestDatePicker,
  },
  data() {
    return {
      scope: true,
      country: '',
      state: '',
      property: '',
    }
  },
  computed: {
    ...mapState(['countries', 'states']),
    calculatedStates() {
      const states = this.states.find((x) => x.key == this.country)
      return states ? states.value : []
    },
  },
  methods: {
    hidden() {
      this.scope = true
      this.country = ''
      this.state = ''
      this.property = ''
    },
    open() {
      this.$refs['modal'].show()
    },
    close() {
      this.$refs['modal'].hide()
    },
    confirm() {
      this.$emit('confirm', {
        scope: this.scope,
        country: this.country,
        state: this.state,
        property: this.property,
      })
      this.close()
    },
    updateCountry(value) {
      this.property = ''
      this.state = ''
      this.country = value
    },
    updateState(value) {
      this.state = value
    },
    updateProperty(value) {
      this.country = ''
      this.state = ''
      this.property = value
    },
  },
}
</script>

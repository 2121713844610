import AdminNexusHttpClient from './admin-nexus-http-client'
import PagedResult from '../models/paged-result'

export default class AnnouncementManagementService {
  constructor() {
    this.httpClient = new AdminNexusHttpClient('/admin/announcements')
  }

  async list(page = 1, pageSize = 10, term = '') {
    const response = await this.httpClient.get('', {
      page,
      pageSize,
      term,
    })
    let result = new PagedResult()
    if (response && response.items) {
      result.total = response.total
      result.items = response.items
    }
    return result
  }

  async all() {
    return await this.httpClient.get('all')
  }

  async get(id) {
    return await this.httpClient.get(`${id}`)
  }

  async add(preload) {
    return await this.httpClient.put(``, preload)
  }

  async update(payload) {
    return await this.httpClient.post(``, payload)
  }

  async delete(id) {
    return await this.httpClient.delete(`${id}`)
  }

  async setForProperty(payload) {
    return await this.httpClient.post('property', payload)
  }
}

<template>
  <quest-main
    title="Hosts"
    copy="Compose each of the hosting platforms and how they interact with their individual booking journeys"
    :loading="loading"
    :saving="saving"
  >
    <template v-slot:head> </template>
    <template v-slot:body>
      <quest-card title="Available hosts">
        <quest-table id="host-table" :columns="columns">
          <template v-if="hosts.length > 0">
            <tr class="small align-middle" v-for="host in hosts" :key="host.id">
              <td>
                <router-link
                  :to="{ name: 'host-edit', params: { id: host.id } }"
                  >{{ host.name }}</router-link
                >
              </td>
            </tr>
          </template>
          <template v-else>
            <tr class="align-middle">
              <td :colspan="columns.length">
                <div class="small text-center py-5">
                  There are no hosts in the system
                </div>
              </td>
            </tr>
          </template>
        </quest-table>
      </quest-card>
    </template>
  </quest-main>
</template>

<script>
import { toasted, success, error } from '../helpers'
import { QuestInput, QuestSingleSelect, QuestToggle } from './form'
import { SitesManagementService } from '../services'

import {
  QuestMain,
  QuestTable,
  QuestPager,
  QuestCard,
  QuestModal,
} from './structure'

export default {
  name: 'hosts',
  components: {
    QuestMain,
    QuestTable,
    QuestPager,
    QuestCard,
    QuestModal,
    QuestSingleSelect,
    QuestInput,
    QuestToggle,
  },
  data() {
    return {
      loading: true,
      saving: false,
      hosts: [],
      columns: ['Name'],
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      new SitesManagementService()
        .list()
        .then((data) => (this.hosts = data))
        .finally((_) => (this.loading = false))
    },
  },
}
</script>

<template>
  <quest-main
    title="External Accounts"
    copy="Manage external intergration accounts"
    :loading="loading"
    :saving="saving"
  >
    <template v-slot:head>
      <router-link
        :to="{ name: 'external-accounts-add' }"
        class="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
      >
        <font-awesome-icon
          icon="plus"
          size="sm"
          class="text-white-50"
        ></font-awesome-icon>
        Add</router-link
      >
    </template>
    <template v-slot:body>
      <quest-card title="Existing accounts">
        <quest-table id="external-account-table" :columns="columns">
          <template v-if="accounts.length > 0">
            <tr
              class="small align-middle"
              v-for="account in accounts"
              :key="account.id"
            >
              <td>{{ account.label }}</td>
              <td>{{ account.externalId }}</td>
              <td>{{ account.service }}</td>
              <td>{{ account.totalProperties }}</td>
              <td>{{ account.totalRateTables }}</td>
              <td>{{ account.autoCreationEnabled ? 'True' : 'False' }}</td>
              <td>{{ account.minimumQuoteThreshold }}</td>
              <td>
                <b-dropdown
                  no-caret
                  variant="link"
                  v-if="account.service === 'RMS'"
                >
                  <template #button-content>
                    <font-awesome-icon
                      icon="ellipsis-v"
                      size="sm"
                      fixed-width
                      class="text-gray-400"
                    ></font-awesome-icon>
                  </template>
                  <b-dropdown-header>Actions</b-dropdown-header>
                  <b-dropdown-item-button @click="syncAll(account.externalId)"
                    >Sync all</b-dropdown-item-button
                  ><b-dropdown-item-button
                    v-if="account.service === 'RMS'"
                    @click="excludeRates(account)"
                    >Exclude rates</b-dropdown-item-button
                  >
                  <b-dropdown-item-button
                    v-if="account.service === 'RMS'"
                    @click="updateMinimumQuoteThreshold(account)"
                    >Update minimum quote threshold</b-dropdown-item-button
                  >
                  <b-dropdown-item-button
                    v-if="account.service === 'RMS'"
                    @click="toggleAutoCreation(account)"
                    >Toggle auto creation</b-dropdown-item-button
                  >
                </b-dropdown>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr class="align-middle">
              <td :colspan="columns.length">
                <div class="small text-center py-5">
                  There are no accounts in the system
                </div>
              </td>
            </tr>
          </template>
        </quest-table>
      </quest-card>
      <b-modal
        v-model="showRateManageModal"
        centered
        :title="'Exclude rates from ' + toRateManage?.label || ''"
      >
        <div class="row">
          <div class="col-md-12">
            <quest-multi-select
              id="rateTables"
              label="Excluded rate tables"
              v-model="toExclude"
              :options="rateTables"
              help="Select rate tables to exclude"
              @update="updateRateTables"
              :uid="'id'"
              :text="'name'"
            ></quest-multi-select>
          </div>
        </div>
        <template v-slot:modal-footer>
          <button
            class="btn btn-secondary btn-sm"
            type="button"
            @click="cancelRateExclude"
          >
            Cancel</button
          ><button
            class="btn btn-primary btn-sm"
            type="button"
            @click="confirmRateExclude"
          >
            Update
          </button>
        </template>
      </b-modal>
      <b-modal
        v-model="showMinQuoteModal"
        centered
        :title="
          'Update minimum quote threshold for ' + toRateManage?.label || ''
        "
      >
        <div class="row">
          <div class="col-md-12">
            <quest-input
              id="minQuoteThreshold"
              label="Minimum Quote Threshold"
              name="Minimum Quote Threshold"
              v-model="minQuoteThreshold"
              help="Set the minimum quote threshold for all rates"
            ></quest-input>
          </div>
        </div>
        <template v-slot:modal-footer>
          <button
            class="btn btn-secondary btn-sm"
            type="button"
            @click="cancelMinimumQuoteThreshold"
          >
            Cancel</button
          ><button
            class="btn btn-primary btn-sm"
            type="button"
            @click="confirmMinimumQuoteThreshold"
          >
            Update
          </button>
        </template>
      </b-modal>
    </template>
  </quest-main>
</template>

<script>
import {
  QuestInput,
  QuestSingleSelect,
  QuestToggle,
  QuestMultiSelect,
} from './form'
import { ExternalAccountsService, SyncService } from '../services'

import {
  QuestMain,
  QuestTable,
  QuestPager,
  QuestCard,
  QuestModal,
} from './structure'
import { success, error } from '@/helpers'

export default {
  name: 'external-accounts',
  components: {
    QuestMain,
    QuestTable,
    QuestPager,
    QuestCard,
    QuestModal,
    QuestSingleSelect,
    QuestInput,
    QuestToggle,
    QuestMultiSelect,
  },
  data() {
    return {
      loading: true,
      saving: false,
      accounts: [],
      columns: [
        'Label',
        'External Id',
        'Service',
        'Properties',
        'Rates',
        'Auto Creation',
        'Min. Quote Threshold',
        '',
      ],
      toRateManage: null,
      toExclude: [],
      rateTables: [],
      showRateManageModal: false,
      showMinQuoteModal: false,
      minQuoteThreshold: 0,
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    toggleAutoCreation(account) {
      this.toRateManage = account
      new ExternalAccountsService()
        .toggleAccountService(account.id, !account.autoCreationEnabled)
        .then((_) => {
          account.autoCreationEnabled = !account.autoCreationEnabled
        })
        .catch((_) =>
          error(`Failed to toggle auto creation for #`, account.label)
        )
    },
    updateMinimumQuoteThreshold(account) {
      this.toRateManage = account
      this.showMinQuoteModal = true
      this.minQuoteThreshold = account.minimumQuoteThreshold
    },
    confirmMinimumQuoteThreshold() {
      new ExternalAccountsService()
        .setMinQuoteThreshold(this.toRateManage.id, this.minQuoteThreshold)
        .then((data) => {
          if (!data) {
            error(
              `Failed to save minimum quote threshold for #`,
              this.toRateManage?.label
            )
          } else {
            this.showMinQuoteModal = false
            this.toRateManage.minimumQuoteThreshold = this.minQuoteThreshold
            this.toRateManage = null
            this.minQuoteThreshold = 0
          }
        })
        .catch((ex) => {
          console.log(ex)
          error(
            `Failed to save minimum quote threshold for #`,
            this.toRateManage?.label
          )
        })
    },
    cancelMinimumQuoteThreshold() {
      this.toRateManage = null
      this.showMinQuoteModal = false
      this.minQuoteThreshold = 0
    },
    updateRateTables() {},
    excludeRates(account) {
      this.toRateManage = account
      new ExternalAccountsService()
        .getRates(account.id)
        .then((data) => {
          this.rateTables = data
          this.toExclude = data.filter((x) => x.isExcluded).map((x) => x.id)
          this.showRateManageModal = true
        })
        .catch((_) => error(`Failed to access rates from #`, account.label))
    },
    confirmRateExclude() {
      new ExternalAccountsService()
        .setRates(this.toRateManage.id, this.toExclude)
        .then((data) => {
          if (!data) {
            error(`Failed to save rate exclusions from #`, account.label)
          } else {
            this.showRateManageModal = false
            this.toRateManage = null
            this.toExclude = []
            this.rateTables = []
          }
        })
        .catch((_) =>
          error(`Failed to save rate exclusions from #`, account.label)
        )
    },
    cancelRateExclude() {
      this.toRateManage = null
      this.toExclude = []
      this.rateTables = []
      this.showRateManageModal = false
    },
    syncAll(clientId) {
      new SyncService()
        .synthesisFull(clientId)
        .then((data) =>
          data
            ? success('Synced #', clientId)
            : error(`Failed to sync #`, clientId)
        )
        .catch((_) => error(`Failed to sync #`, clientId))
    },
    load() {
      new ExternalAccountsService()
        .list()
        .then((data) => (this.accounts = data))
        .finally((_) => (this.loading = false))
    },
  },
}
</script>

import HttpClient from '../utilities/http-client'

export default class ResourcesService {
  constructor() {
    this.httpClient = new HttpClient(`${$config.cms.uri}/quest-api/resources`)
  }

  async stories() {
    return await this.httpClient.get(`stories`)
  }

  async factsheets() {
    return await this.httpClient.get(`factsheets`)
  }

  async restaurants() {
    return await this.httpClient.get(`restaurants`)
  }

  async image(id) {
    return await this.httpClient.get(`image`, { id })
  }

  async gallery(id, propertyName, folder = '') {
    return await this.httpClient.get(`gallery`, {
      id: id || '',
      propertyName,
      folder,
    })
  }

  async inclusions() {
    return await this.httpClient.get(`dealinclusions`)
  }

  async roomfeatures() {
    return await this.httpClient.get(`roomfeatures`)
  }
}

<template>
  <ValidationObserver ref="vobserver" v-slot="{ errors }">
    <quest-validation-summary :errors="errors"></quest-validation-summary>
    <quest-main
      id="proof-point-form"
      :title="
        point && point.id ? point.name || '(untitled)' : 'Create Proof Point'
      "
      :loading="loading"
      :saving="saving"
    >
      <template v-slot:head>
        <a
          @click="save()"
          class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
          ><font-awesome-icon
            icon="save"
            size="sm"
            class="text-white-50"
          ></font-awesome-icon>
          Save
        </a>
      </template>
      <template v-slot:body>
        <quest-card title="Basic Information">
          <div class="row">
            <div class="col-md-6">
              <quest-input
                id="name"
                label="Name"
                v-model="point.name"
                help="Name of Proof Point as displayed to users"
                :rules="{
                  [REQUIRED]: true,
                  [MAX]: { length: 38 },
                }"
              ></quest-input>
            </div>
            <div class="col-md-6">
              <quest-toggle
                id="isEnabled"
                label="Enable"
                v-model="point.isEnabled"
                checked="Enabled"
                unchecked="Disabled"
                help="Is the proof point enabled for public display"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <quest-input
                id="link"
                label="Link"
                v-model="point.link"
                help="Link to proof points destination - internal/external"
              ></quest-input>
            </div>
            <div class="col-md-6">
              <quest-toggle
                id="isMandatory"
                label="Mandatory"
                v-model="point.isMandatory"
                checked="True"
                unchecked="False"
                help="Mandatory proof points are displayed automatically for all properties within the selected sites"
              />
            </div>
          </div>

          <div class="row">
            <div class="col">
              <quest-multi-select
                id="sites"
                label="Displayed sites"
                v-model="point.sites"
                :options="sites"
                help="The sites which may display this proof point"
                uid="id"
                text="name"
              ></quest-multi-select>
            </div>
          </div>

          <quest-text-area
            id="summary"
            label="Summary"
            v-model="point.summary"
            help="Description of the proof point, as shown to users"
          ></quest-text-area>

          <div class="form-group">
            <label>Hero Image</label>
            <div class="d-flex h-100">
              <quest-image
                :image="point.image"
                single
                @change="$refs.hero.open()"
                required
              ></quest-image>
            </div>
          </div>

          <quest-image-picker
            ref="hero"
            title="Select hero image"
            albumName="proof-points"
            :current="[point.image]"
            @update="updateHero"
            manage
          ></quest-image-picker>
        </quest-card>
      </template>
    </quest-main>
  </ValidationObserver>
</template>

<script>
import { REQUIRED, MIN, MAX } from '@/validation-types'
import { success, error } from '../helpers'
import { mapState } from 'vuex'
import { ProofPointService, ThemeService } from '../services'
import {
  QuestCard,
  QuestMain,
  QuestStatCard,
  QuestValidationSummary,
} from './structure'
import {
  QuestInput,
  QuestTextArea,
  QuestEditor,
  QuestDatePicker,
  QuestToggle,
  QuestSingleSelect,
  QuestMultiSelect,
  QuestImagePicker,
  QuestImage,
} from './form'

export default {
  name: 'proof-point',
  components: {
    QuestCard,
    QuestMain,
    QuestInput,
    QuestTextArea,
    QuestEditor,
    QuestDatePicker,
    QuestToggle,
    QuestSingleSelect,
    QuestMultiSelect,
    QuestStatCard,
    QuestImagePicker,
    QuestImage,
    QuestValidationSummary,
  },
  data() {
    return {
      REQUIRED,
      MIN,
      MAX,
      themes: [],
      point: {
        themeId: '',
        image: '',
      },
      loading: true,
      saving: false,
    }
  },
  mounted() {
    new ThemeService()
      .all()
      .then((data) => (this.themes = data))
      .finally((_) => this.load())
  },
  computed: {
    ...mapState(['sites']),
  },
  methods: {
    updateHero(selected) {
      this.point.image = selected
    },
    updateTheme(val) {
      this.point.themeId = val
    },
    async save() {
      if (!this.$refs.vobserver) return
      let validated = await this.$refs.vobserver.validate()
      if (!validated) {
        error('Please ensure all errors are resolved')
        return
      }
      this.saving = true
      try {
        if (this.point.id) {
          new ProofPointService().update(this.point).then((data) => {
            if (data) {
              this.load()
              success(`Updated proof point`, this.point.name)
            } else {
              error(`Failed to update proof point #`, this.point.name)
            }
          })
        } else {
          new ProofPointService().add(this.point).then((data) => {
            if (data) {
              this.$router.push({
                name: 'proof-points-edit',
                params: { id: data.value },
              })
              this.load()
              success(`Created proof point #`, this.point.name)
            } else {
              error(`Failed to create proof point #`, this.point.name)
            }
          })
        }
      } finally {
        this.saving = false
      }
    },
    load() {
      if (this.$route.params.id) {
        new ProofPointService()
          .get(this.$route.params.id)
          .then((data) => (this.point = data))
          .catch((_) => (this.point = null))
          .finally((_) => {
            this.loading = false
            this.$nextTick(() => {
              if (this.$refs && this.$refs.vobserver) {
                this.$refs.vobserver.validate()
              }
            })
          })
      } else {
        this.loading = false
      }
    },
  },
}
</script>

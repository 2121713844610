import Vue from 'vue'
import { PropertyView } from './property-view.enum'

const slugify = (string) => {
  // https://mhagemann.medium.com/the-ultimate-way-to-slugify-a-url-string-in-javascript-b8e4a0d849e1
  const a =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const success = (message, name) => {
  let title = name || '(untitled)'
  Vue.$toast.success(message.replace('#', `'${title}'`))
}

const error = (message, name) => {
  let title = name || '(untitled)'
  Vue.$toast.error(message.replace('#', `'${title}'`))
}

const toasted = (data, success, error, name, callbackpre, callbackpost) => {
  if (data) {
    callbackpre && callbackpre(data)
    success && this.success(success, name)
    callbackpost && callbackpost(data)
  } else {
    error && this.error(error, name)
  }
}

const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  )
}

export {
  slugify,
  toasted,
  success,
  error,
  PropertyView,
  capitalizeFirstLetter,
  uuidv4,
}
export * from './permissions-functions'
export * from './strip-html'
export * from './assign-defined'
export * from './applicable-route'
export * from './format-currency'

import AdminNexusHttpClient from './admin-nexus-http-client'

export default class BenefitsService {
  constructor() {
    this.httpClient = new AdminNexusHttpClient('/admin/benefits')
  }

  async get(property) {
    return await this.httpClient.get(`${property}`, {})
  }

  async set(payload) {
    return await this.httpClient.post('', payload)
  }
}

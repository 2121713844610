import moment from 'moment'
import 'moment-timezone'

export default class PropertyOverviewItem {
  id = null
  siteminderPropertyId = ''
  channelCode = ''
  name = ''
  isEnabled = false
  isDeleted = false
  state = ''
  country = ''
  modified = null
  isGhost = false

  constructor(model) {
    this.id = model ? model.id : null
    this.siteminderPropertyId = model ? model.siteminderPropertyId : ''
    this.channelCode = model ? model.channelCode : ''
    this.name = model ? model.name : ''
    this.isEnabled = model ? model.isEnabled : false
    this.isDeleted = model ? model.isDeleted : false
    this.state = model ? model.state : ''
    this.isGhost = model ? model.isGhost : false
    this.country = model ? model.country : ''
    this.modified =
      model && model.modifiedUTC
        ? moment(`${model.modifiedUTC}Z`)
            .tz('Australia/Melbourne')
            .format('lll')
        : null
  }
}

<template>
  <ValidationObserver ref="vobserver" v-slot="{ errors }">
    <quest-validation-summary :errors="errors"></quest-validation-summary>
    <quest-main
      id="external-account-form"
      :title="
        account && account.id
          ? account.externalId || '(untitled)'
          : 'Create External Account'
      "
      :loading="loading"
      :saving="saving"
    >
      <template v-slot:head>
        <a
          @click="save()"
          class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
          ><font-awesome-icon
            icon="save"
            size="sm"
            class="text-white-50"
          ></font-awesome-icon>
          Save
        </a>
      </template>
      <template v-slot:body>
        <quest-card title="Basic Information">
          <quest-input
            id="label"
            label="Label"
            name="Label"
            v-model="account.label"
            :rules="{ [REQUIRED]: true }"
            help="User readable label describing the account"
          ></quest-input>

          <quest-input
            id="externalId"
            label="External Id"
            name="ExternalId"
            v-model="account.externalId"
            :rules="{ [REQUIRED]: true }"
            help="The account identifier"
          ></quest-input>

          <quest-single-select
            v-model="account.service"
            :options="services"
            id="service"
            label="Service"
            placeholder="Select a service"
            help="The service integration for this account"
            @update="updateService"
            :rules="{ [REQUIRED]: true }"
          ></quest-single-select>
        </quest-card>
      </template>
    </quest-main>
  </ValidationObserver>
</template>

<script>
import { REQUIRED } from '@/validation-types'
import { success, error } from '../helpers'
import { ExternalAccountsService } from '../services'
import {
  QuestCard,
  QuestMain,
  QuestStatCard,
  QuestValidationSummary,
} from './structure'
import {
  QuestInput,
  QuestTextArea,
  QuestEditor,
  QuestDatePicker,
  QuestToggle,
  QuestSingleSelect,
  QuestMultiSelect,
  QuestImagePicker,
  QuestImage,
} from './form'

export default {
  name: 'external-account',
  components: {
    QuestCard,
    QuestMain,
    QuestInput,
    QuestTextArea,
    QuestEditor,
    QuestDatePicker,
    QuestToggle,
    QuestSingleSelect,
    QuestMultiSelect,
    QuestStatCard,
    QuestImagePicker,
    QuestImage,
    QuestValidationSummary,
  },
  data() {
    return {
      REQUIRED,
      loading: true,
      saving: false,
      account: {
        service: '',
      },
      services: [
        { key: 0, value: 'RMS' },
        { key: 1, value: 'TBB' },
      ],
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    updateService(val) {
      this.account.service = val
    },
    async save() {
      if (!this.$refs.vobserver) return
      let validated = await this.$refs.vobserver.validate()
      if (!validated) {
        error('Please ensure all errors are resolved')
        return
      }
      this.saving = true
      try {
        if (this.account.id) {
          new ExternalAccountsService().update(this.account).then((data) => {
            if (data) {
              this.load()
              success(`Updated external account #`, this.account.externalId)
            } else {
              error(
                `Failed to update external account #`,
                this.account.nexternalIdame
              )
            }
          })
        } else {
          new ExternalAccountsService().add(this.account).then((data) => {
            if (data) {
              this.$router.push({
                name: 'external-accounts-edit',
                params: { id: data.value },
              })
              this.load()
              success(`Created external account #`, this.account.externalId)
            } else {
              error(
                `Failed to create external account #`,
                this.account.externalId
              )
            }
          })
        }
      } finally {
        this.saving = false
      }
    },
    load() {
      if (this.$route.params.id) {
        new ExternalAccountsService()
          .get(this.$route.params.id)
          .then((data) => (this.account = data))
          .catch((_) => (this.account = null))
          .finally((_) => {
            this.loading = false
            this.$nextTick(() => {
              if (this.$refs && this.$refs.vobserver) {
                this.$refs.vobserver.validate()
              }
            })
          })
      } else {
        this.loading = false
      }
    },
  },
}
</script>

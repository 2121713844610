import { mapState } from 'vuex'
import { RateView } from '@/models'
import { NexusRatesService } from '@/services'

export default {
  mounted() {
    this.$emit('init')
  },
  data() {
    return {
      /* VIEW SCOPE */
      scope: false,
      country: null,
      state: null,
      property: null,
      room: null,
      hash: null,

      /* LOGIC PROPERTIES */
      selected: null,
      rates: new RateView(),
      checkRecords: [],

      /* STATE PROPERTIES */
      loading: true,

      /* TABLE FIELDS */
      fields: [
        {
          key: 'name',
          formatter: (_a, _b, item) => {
            return { id: item.id, name: item.name }
          },
        },
        {
          key: 'status',
        },
        {
          key: 'base',
        },
        {
          key: 'groupCode',
          label: 'Code',
        },
        {
          key: 'context',
        },
        {
          key: 'options',
          label: '',
          thStyle: { width: '50px' },
        },
      ],
    }
  },
  computed: {
    ...mapState(['account']),
  },
  methods: {
    /* BASIC MODAL REQUESTS */
    remove(value) {
      this.selected = value
      this.$refs['delete'].show()
    },
    optIn(value) {
      this.selected = value
      this.$refs['optIn'].show()
    },
    optOut(value) {
      this.selected = value
      this.$refs['optOut'].show()
    },
    enable(value) {
      this.selected = value
      this.$refs['enable'].show()
    },
    disable(value) {
      this.selected = value
      this.$refs['disable'].show()
    },
    check(value) {
      this.checkRecords = value || []
      this.$refs['check'].show()
    },
    /* EVENTS */
    saved() {
      this.$refs['saving'].hide()
      this.refresh()
    },
    async confirm(value) {
      if (value === 'check') {
        this.$refs['editor'].commit()
      }
      if (value === 'delete') {
        await new NexusRatesService()
          .delete(this.account, this.selected?.id || this.selected?.master)
          .then()
          .catch()
      } else if (value === 'enable') {
        await new NexusRatesService()
          .enable(this.account, this.selected?.id || this.selected?.master)
          .then()
          .catch()
      } else if (value === 'disable') {
        await new NexusRatesService()
          .disable(this.account, this.selected?.id || this.selected?.master)
          .then()
          .catch()
      } else if (value === 'optIn') {
        await new NexusRatesService()
          .optin(
            this.account,
            this.selected?.master,
            this.selected?.id,
            this.property,
            this.room
          )
          .then()
          .catch()
      } else if (value === 'optOut') {
        await new NexusRatesService()
          .optout(
            this.account,
            this.selected?.master,
            this.selected?.id,
            this.property,
            this.room
          )
          .then()
          .catch()
      }

      this.selected = null
      await this.refresh()
      return true
    },
    cancel() {
      this.selected = null
    },
    add(value) {
      this.$refs['editor'].new(
        value.scope,
        value.country,
        value.state,
        value.property
      )
    },
    override(value) {
      this.$refs['editor'].override(value.master, this.property, this.room)
    },
  },
}

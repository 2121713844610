<template>
  <ValidationObserver ref="vobserver" v-slot="{ errors }">
    <quest-validation-summary :errors="errors"></quest-validation-summary>
    <quest-main
      id="announcement-form"
      :title="
        announcement && announcement.id
          ? stripHtml(announcement.body).substring(0, 15) || '(untitled)'
          : 'Create Announcement'
      "
      :loading="loading"
      :saving="saving"
    >
      <template v-slot:head>
        <button
          @click="save()"
          class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
          v-bind="{ disabled: saving }"
        >
          <font-awesome-icon
            icon="save"
            size="sm"
            fixed-width
            class="text-white-50"
            :class="{ 'animated--heart-beat': saving }"
          ></font-awesome-icon>
          {{ saving ? 'Saving...' : 'Save' }}
        </button>
      </template>
      <template v-slot:body>
        <quest-card title="Basic Information">
          <div class="row">
            <div class="col-md-4">
              <quest-single-select
                v-model="announcement.state"
                :options="options"
                label="Filter State"
                id="filterState"
                placeholder="None"
                help="State to apply the announcement"
                :keepPlaceholder="true"
                @update="updateState"
              ></quest-single-select>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <quest-editor
                id="body"
                label="Body"
                v-model="announcement.body"
              ></quest-editor>
            </div>
          </div>
        </quest-card>
      </template>
    </quest-main>
  </ValidationObserver>
</template>

<script>
import { REQUIRED } from '@/validation-types'
import { success, error, stripHtml } from '../helpers'
import { mapState } from 'vuex'
import { AnnouncementManagementService } from '../services'
import {
  QuestCard,
  QuestMain,
  QuestStatCard,
  QuestValidationSummary,
} from './structure'
import {
  QuestInput,
  QuestTextArea,
  QuestEditor,
  QuestDatePicker,
  QuestToggle,
  QuestSingleSelect,
  QuestMultiSelect,
  QuestImagePicker,
  QuestImage,
} from './form'

export default {
  name: 'announcement',
  components: {
    QuestCard,
    QuestMain,
    QuestInput,
    QuestTextArea,
    QuestEditor,
    QuestDatePicker,
    QuestToggle,
    QuestSingleSelect,
    QuestMultiSelect,
    QuestStatCard,
    QuestImagePicker,
    QuestImage,
    QuestValidationSummary,
  },
  data() {
    return {
      REQUIRED,
      announcement: {
        state: '',
      },
      loading: true,
      saving: false,
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    ...mapState(['states']),
    options() {
      return [...new Set([...this.states.map((x) => x.value).flat()])]
    },
  },
  methods: {
    stripHtml,
    updateState(value) {
      this.announcement.state = value
    },
    async save() {
      if (!this.$refs.vobserver) return
      let validated = await this.$refs.vobserver.validate()
      if (!validated) {
        error('Please ensure all errors are resolved')
        return
      }
      this.saving = true
      try {
        if (this.announcement.id) {
          new AnnouncementManagementService()
            .update(this.announcement)
            .then((data) => {
              if (data) {
                success(
                  `Updated announcement`,
                  stripHtml(this.announcement.body).substring(0, 15)
                )
              } else {
                error(
                  `Failed to update announcement #`,
                  stripHtml(this.announcement.body).substring(0, 15)
                )
              }
            })
        } else {
          new AnnouncementManagementService()
            .add(this.announcement)
            .then((data) => {
              if (data) {
                this.$router.push({
                  name: 'announcements-edit',
                  params: { id: data.value },
                })
                success(
                  `Created announcement #`,
                  stripHtml(this.announcement.body).substring(0, 15)
                )
              } else {
                error(
                  `Failed to create announcement #`,
                  stripHtml(this.announcement.body).substring(0, 15)
                )
              }
            })
        }
      } finally {
        this.saving = false
      }
    },
    load() {
      if (this.$route.params.id) {
        new AnnouncementManagementService()
          .get(this.$route.params.id)
          .then((data) => (this.announcement = data))
          .catch((_) => (this.announcement = null))
          .finally((_) => {
            this.loading = false
            this.$nextTick(() => {
              if (this.$refs && this.$refs.vobserver) {
                this.$refs.vobserver.validate()
              }
            })
          })
      } else {
        this.loading = false
      }
    },
  },
}
</script>

import * as actionTypes from './action-types'
import { ExternalAccountsService, ResourcesService } from '../services'
import {
  GeoService,
  SitesManagementService,
  StayNightService,
  EngineService,
  FacilityService,
} from '../services'

export default {
  async [actionTypes.INIT]({ dispatch }) {
    await dispatch(actionTypes.GET_COUNTRIES)
    await dispatch(actionTypes.GET_STATES)
    await dispatch(actionTypes.GET_STAYNIGHTS)
    await dispatch(actionTypes.GET_SITES)
    await dispatch(actionTypes.GET_ENGINE_DYNAMIC_TOKENS)
    await dispatch(actionTypes.GET_ENGINE_IDENTIFIERS)
    await dispatch(actionTypes.GET_ENGINE_PROMOTIONS)
    await dispatch(actionTypes.GET_EXTERNAL_ACCOUNTS)
    dispatch(actionTypes.SET_LOADED)
  },
  async [actionTypes.INIT_PROPERTY]({ dispatch }) {
    await dispatch(actionTypes.GET_STORIES)
    await dispatch(actionTypes.GET_RESTAURANTS)
    await dispatch(actionTypes.GET_FACTSHEETS)
    await dispatch(actionTypes.GET_ROOM_FEATURES)
    dispatch(actionTypes.SET_LOADED)
  },
  async [actionTypes.GET_ENGINE_DYNAMIC_TOKENS]({ commit }) {
    await new EngineService()
      .dynamicTokens()
      .then((data) => commit('setDynamicTokens', data))
  },
  async [actionTypes.GET_ENGINE_IDENTIFIERS]({ commit }) {
    await new EngineService()
      .identifiers()
      .then((data) => commit('setIdentifiers', data))
  },
  async [actionTypes.GET_ENGINE_PROMOTIONS]({ commit }) {
    await new EngineService()
      .promotionTypes()
      .then((data) => commit('setPromotionTypes', data))
  },
  async [actionTypes.GET_COUNTRIES]({ commit, dispatch }) {
    await new GeoService()
      .getCountries()
      .then((data) => commit('setCountries', data))
  },
  async [actionTypes.GET_STATES]({ commit, dispatch }) {
    await new GeoService().getStates().then((data) => commit('setStates', data))
  },
  async [actionTypes.GET_STAYNIGHTS]({ commit, dispatch }) {
    await new StayNightService()
      .getStayNights()
      .then((data) => commit('setStayNights', data))
  },
  async [actionTypes.GET_SITES]({ commit, dispatch }) {
    await new SitesManagementService()
      .list()
      .then((data) => commit('setSites', data))
  },
  async [actionTypes.GET_STORIES]({ commit, dispatch }) {
    await new ResourcesService()
      .stories()
      .then((data) => commit('setStories', data))
  },
  async [actionTypes.GET_RESTAURANTS]({ commit, dispatch }) {
    await new ResourcesService()
      .restaurants()
      .then((data) => commit('setRestaurants', data))
      .catch((_) => dispatch(actionTypes.DISCONNECT))
  },
  async [actionTypes.GET_FACTSHEETS]({ commit }) {
    await new ResourcesService()
      .factsheets()
      .then((data) => commit('setFactsheets', data))
  },
  async [actionTypes.GET_EXTERNAL_ACCOUNTS]({ commit, _ }) {
    await new ExternalAccountsService()
      .list()
      .then((data) => commit('setExternalAccounts', data))
  },
  async [actionTypes.GET_ROOM_FEATURES]({ commit }) {
    await new ResourcesService()
      .roomfeatures()
      .then((data) => commit('setRoomFeatures', data))
  },
  async [actionTypes.GET_FACILITIES]({ commit }) {
    await new FacilityService()
      .all()
      .then((data) => commit('setFacilities', data))
  },
  [actionTypes.SET_AUTHENTICATED]({ commit, dispatch }, payload) {
    if (!!payload) {
      dispatch(actionTypes.SET_UNLOADED)
      dispatch(actionTypes.INIT)
    }
    commit('authenticated', payload)
  },
  [actionTypes.SET_ACCOUNT]({ commit, _ }, payload) {
    commit('account', payload)
  },
  [actionTypes.DISCONNECT]({ commit }) {
    commit('disconnect')
  },
  [actionTypes.SET_LOADED]({ commit }) {
    commit('setLoading', false)
  },
  [actionTypes.SET_UNLOADED]({ commit }) {
    commit('setLoading', true)
  },
}

import HttpClient from '../utilities/http-client'

export default class PropertyCreationService {
  constructor() {
    this.httpClient = new HttpClient(
      `${$config.cms.uri}/quest-api/generateproperty`
    )
  }

  async link(data) {
    return await this.httpClient.post(`new`, data)
  }
}

<template>
  <ValidationObserver ref="vobserver" v-slot="{}">
    <quest-main :loading="loading" :saving="saving">
      <template v-slot:body>
        <div class="row justify-content-center">
          <div class="col-xl-10 col-lg-12 col-md-9">
            <div class="card o-hidden border-0 shadow-lg my-5">
              <div class="card-body p-0">
                <div class="row">
                  <div class="col">
                    <div class="p-5">
                      <div class="text-center">
                        <h1 class="h4 text-gray-900 mb-4">Choose a property</h1>
                      </div>
                      <quest-single-select
                        v-model="id"
                        :options="options"
                        id="newProperty"
                        placeholder="Select a property"
                        @update="updateSelected"
                        large
                      ></quest-single-select>
                      <quest-single-select
                        v-if="channels && channels.length && id"
                        v-model="channelCode"
                        :options="channels"
                        id="channelCode"
                        placeholder="Select a channel code"
                        @update="updateChannelCode"
                        large
                      ></quest-single-select>
                      <div class="form-group">
                        <button
                          @click="link"
                          v-bind="{ disabled: !id || !channelCode }"
                          class="btn btn-primary btn-lg btn-block"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'link']"
                            size="lg"
                          ></font-awesome-icon>
                          Link property
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </quest-main>
  </ValidationObserver>
</template>

<script>
import { PropertyManagementService, PropertyCreationService } from '../services'
import { QuestMain } from './structure'
import { QuestSingleSelect } from './form'

export default {
  name: 'property-link',
  components: {
    QuestMain,
    QuestSingleSelect,
  },
  data() {
    return {
      options: [],
      channels: [],
      loading: true,
      saving: false,
      id: '',
      channelCode: '',
    }
  },
  mounted() {
    new PropertyManagementService()
      .new()
      .then((data) => {
        this.options = data
      })
      .finally((_) => (this.loading = false))
  },
  methods: {
    updateSelected(value) {
      this.channels = []
      this.id = value
      this.loadChannels()
    },
    updateChannelCode(value) {
      this.channelCode = value
    },
    loadChannels() {
      this.channels = []
      new PropertyManagementService()
        .channelCodes(this.id)
        .then((channels) => (this.channels = channels))
    },
    link() {
      this.saving = true
      new PropertyCreationService()
        .link({
          SiteminderPropertyId: this.id,
          ChannelCode: this.channelCode,
        })
        .then((_) => {
          this.$router.push({
            name: 'properties-edit',
            params: { id: this.id },
          })
        })
        .finally((_) => {
          this.saving = false
        })
    },
  },
}
</script>

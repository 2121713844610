export const QuoteStatus = {
  Error: 0,
  Check: 1,
  Success: 2,
  NoTable: 3,
  Blocked: 4,
  NoContext: 5,
  MissingContext: 6,
  NoCancellationPolicy: 7,
  BelowMinimumThreshold: 8,
}

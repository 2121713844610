<template>
  <ValidationObserver ref="vobserver" v-slot="{ errors }">
    <quest-validation-summary :errors="errors"></quest-validation-summary>
    <quest-main
      id="preload-form"
      :title="
        preload && preload.id ? preload.name || '(untitled)' : 'Create Preload'
      "
      :loading="loading"
      :saving="saving"
    >
      <template v-slot:head>
        <a
          @click="save()"
          class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
          ><font-awesome-icon
            icon="save"
            size="sm"
            class="text-white-50"
          ></font-awesome-icon>
          Save
        </a>
      </template>
      <template v-slot:body>
        <div class="row" v-if="preload.id">
          <quest-stat-card
            label="Created"
            :value="preload.dateCreated"
            :icon="['fas', 'calendar-plus']"
            card="col-xl-4 col-md-4"
          ></quest-stat-card>

          <quest-stat-card
            label="Last Modified"
            :value="preload.dateModified"
            :icon="['fas', 'calendar-edit']"
            card="col-xl-4 col-md-4"
          ></quest-stat-card>

          <quest-stat-card
            label="Last Run"
            :value="preload.lastAttempt || 'Never'"
            :icon="['fad', 'sync']"
            card="col-xl-4 col-md-4"
          ></quest-stat-card>
        </div>

        <quest-card title="Basic Information">
          <div class="row">
            <div class="col-md-6">
              <quest-input
                id="name"
                label="Name"
                v-model="preload.name"
                name="Name"
                help="Admin friendly name for quick identification"
                :rules="{ [REQUIRED]: true }"
              ></quest-input>
            </div>
            <div class="col-md-6">
              <quest-toggle
                id="isEnabled"
                label="Run Preload"
                v-model="preload.isEnabled"
                checked="Enabled"
                unchecked="Disabled"
                help="Whether the criteria should preload or not"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <quest-date-picker
                id="openStayDate"
                label="Open stay date"
                v-model="preload.openStayDate"
                clearable="true"
                help="Literal date to start preloading"
              ></quest-date-picker>
            </div>
            <div class="col-md-6">
              <quest-input
                id="openStayDateToken"
                label="Open stay date token"
                v-model="preload.openStayDateToken"
                help="A dynamic token date to start preloading"
              ></quest-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <quest-date-picker
                id="closeStayDate"
                label="Close stay date"
                v-model="preload.closeStayDate"
                clearable="true"
                help="Literal date to stop preloading"
              ></quest-date-picker>
            </div>
            <div class="col-md-6">
              <quest-input
                id="closeStayDateToken"
                label="Close stay date token"
                v-model="preload.closeStayDateToken"
                help="A dynamic token date to stop preloading"
              ></quest-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <quest-input
                id="minimumNightsStay"
                label="Minimum nights stay"
                v-model="preload.minimumNightsStay"
                help="Minimum nights stay (default: 1)"
              ></quest-input>
            </div>
            <div class="col-md-6">
              <quest-input
                id="maximumNightsStay"
                label="Maximum nights stay"
                v-model="preload.maximumNightsStay"
                help="Maximum nights stay (default: 1)"
              ></quest-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <quest-input
                id="minimumGuests"
                label="Minimum guests"
                v-model="preload.minimumGuests"
                type="number"
                help="Minimum guests (default: 1)"
              ></quest-input>
            </div>
            <div class="col-md-6">
              <quest-input
                id="maximumGuests"
                label="Maximum guests"
                v-model="preload.maximumGuests"
                type="number"
                help="Maximum guests (default: 1)"
              ></quest-input>
            </div>
          </div>
        </quest-card>

        <quest-card
          title="Scope"
          id="scope-card"
          :collapsable="true"
          :collapsed="true"
        >
          <quest-multi-select
            id="stayNightsToBlock"
            label="Stay nights to block"
            v-model="preload.stayNightsToBlock"
            :options="stayNights"
            help="The nights of the week to exclude from stays"
          ></quest-multi-select>

          <quest-input
            id="promotionCode"
            label="Promotion code"
            v-model="preload.promotionCode"
            help="Promo code to scope the preload rates"
          ></quest-input>

          <quest-multi-select
            id="countries"
            label="Countries"
            v-model="preload.countries"
            :options="countries"
            help="Only properties in listed countries will preload quotes"
          ></quest-multi-select>
        </quest-card>
      </template>
    </quest-main>
  </ValidationObserver>
</template>

<script>
import { REQUIRED } from '@/validation-types'
import { success, error } from '../helpers'
import { mapState } from 'vuex'
import { PreloadService } from '../services'
import Preload from '../models/preload'
import {
  QuestCard,
  QuestMain,
  QuestStatCard,
  QuestValidationSummary,
} from './structure'
import {
  QuestInput,
  QuestTextArea,
  QuestEditor,
  QuestDatePicker,
  QuestToggle,
  QuestMultiSelect,
} from './form'

export default {
  name: 'preload',
  components: {
    QuestCard,
    QuestMain,
    QuestInput,
    QuestTextArea,
    QuestEditor,
    QuestDatePicker,
    QuestToggle,
    QuestMultiSelect,
    QuestStatCard,
    QuestValidationSummary,
  },
  data() {
    return {
      REQUIRED,
      preload: new Preload(),
      loading: true,
      saving: false,
    }
  },
  computed: {
    ...mapState(['stayNights', 'countries']),
  },
  methods: {
    async save() {
      if (!this.$refs.vobserver) return
      let validated = await this.$refs.vobserver.validate()
      if (!validated) {
        error('Please ensure all errors are resolved')
        return
      }
      this.saving = true
      try {
        if (this.preload.id) {
          new PreloadService().update(this.preload).then((data) => {
            if (data) {
              success(`Updated preload`, this.preload.name)
            } else {
              error(`Failed to update preload #`, this.preload.name)
            }
          })
        } else {
          new PreloadService().add(this.preload).then((data) => {
            if (data) {
              success(`Created preload #`, this.preload.name)
            } else {
              error(`Failed to create preload #`, this.preload.name)
            }
          })
        }
      } finally {
        this.saving = false
      }
    },
    load() {
      if (this.$route.params.id) {
        new PreloadService()
          .get(this.$route.params.id)
          .then((data) => (this.preload = data))
          .catch((_) => (this.preload = null))
          .finally((_) => {
            this.loading = false
            this.$nextTick(() => {
              if (this.$refs && this.$refs.vobserver) {
                this.$refs.vobserver.validate()
              }
            })
          })
      } else {
        this.loading = false
      }
    },
  },
  mounted() {
    this.load()
  },
}
</script>

<template>
  <quest-card
    title="Corporate social responsibility (CSR)"
    :collapsable="true"
    :collapsed="true"
    id="csrForm"
  >
    <div class="row">
      <div class="col-md-4">
        <quest-toggle
          id="enableCSROverride"
          label="Override CSR"
          v-model="enableOverride"
          checked="Enabled"
          unchecked="Disabled"
          help="Allows overriding of the default CSR content"
        />
      </div>
    </div>

    <div class="row" v-if="enableOverride">
      <div class="col-12">
        <quest-input
          id="csrTitle"
          label="Title"
          name="CSR Title"
          v-model="csr.title"
          help="A heading describing the properties' corporate social responsibility"
          :rules="{ [REQUIRED]: true }"
        >
        </quest-input>
      </div>
      <div class="col-12">
        <quest-text-area
          label="Summary"
          name="CSR Summary"
          id="csrCopy"
          help="Summary of the properties' social responsibility and commitment"
          v-model="csr.copy"
          :rules="{ [REQUIRED]: true, [MAX]: { length: 170 } }"
        ></quest-text-area>
      </div>
    </div>
    <div class="row" v-if="enableOverride">
      <div class="col-12">
        <div class="form-group">
          <label>Banner Image</label>
          <div class="d-flex h-100">
            <quest-image
              :image="csr.image"
              single
              @change="$refs.hero.open()"
            ></quest-image>
          </div>
        </div>
      </div>
    </div>
    <quest-image-picker
      ref="hero"
      title="Select banner image"
      :propertyId="
        property.siteminderPropertyId != '00000000-0000-0000-0000-000000000000'
          ? property.siteminderPropertyId
          : property.id
      "
      :albumName="property.name"
      :current="[csr.image]"
      @update="updateImage"
      manage
    ></quest-image-picker>
  </quest-card>
</template>

<script>
import { REQUIRED, MAX } from '@/validation-types'
import Card from './property-card'

export default {
  extends: Card,
  props: {
    csr: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      REQUIRED,
      MAX,
      enableOverride: !!this.csr.title && !!this.csr.copy && !!this.csr.image,
    }
  },
  methods: {
    getValue() {
      return {
        id: this.property.id,
        payload: this.enableOverride ? this.csr : null,
      }
    },
    updateImage(selected) {
      this.csr.image = selected
    },
  },
  watch: {
    enableOverride(value) {
      if (!value) {
        this.csr.title = null
        this.csr.copy = null
        this.csr.image = null
      }
    },
  },
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card mb-1 card-slim"},[_c('a',{staticClass:"card-header py-3 card-header-flex pointer",on:{"click":function($event){_vm.collapse = !_vm.collapse}}},[_c('h6',{staticClass:"m-0 font-weight-bold text-primary"},[_vm._v(" "+_vm._s(_vm.room.name)),(_vm.room.externalId)?_c('small',[_vm._v(" ("+_vm._s(_vm.room.externalId)+")")]):_vm._e()]),_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"ml-2 order-2"},[(!_vm.collapsable)?_c('font-awesome-icon',{staticClass:"text-gray-400",attrs:{"icon":['fal', 'bars'],"size":"sm","fixed-width":""}}):_vm._e(),(_vm.collapsable && _vm.collapse)?_c('font-awesome-icon',{staticClass:"text-gray-400",attrs:{"icon":['fas', 'angle-right'],"fixed-width":""}}):_vm._e(),(_vm.collapsable && !_vm.collapse)?_c('font-awesome-icon',{staticClass:"text-gray-400",attrs:{"icon":['fas', 'angle-down'],"fixed-width":""}}):_vm._e()],1),_c('small',{staticClass:"order-1",class:[
          _vm.room.isEnabled
            ? 'text-primary'
            : !_vm.room.isInactive
            ? 'text-danger'
            : 'text-secondary',
        ]},[_vm._v(_vm._s(_vm.room.isEnabled ? 'Enabled' : _vm.room.isInactive ? 'Deleted' : 'Disabled'))])])]),(this.collapsable)?_c('div',{staticClass:"collapse",class:{ show: !_vm.collapse }},[_c('div',{staticClass:"card-body"},[_vm._t("default")],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <ValidationObserver ref="vobserver" v-slot="{ errors }">
    <quest-validation-summary :errors="errors"></quest-validation-summary>
    <quest-main
      id="theme-form"
      :title="theme && theme.id ? theme.name || '(untitled)' : 'Create Theme'"
      :loading="loading"
      :saving="saving"
    >
      <template v-slot:head>
        <button
          @click="save()"
          class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
          v-bind="{ disabled: saving }"
        >
          <font-awesome-icon
            icon="save"
            size="sm"
            fixed-width
            class="text-white-50"
            :class="{ 'animated--heart-beat': saving }"
          ></font-awesome-icon>
          {{ saving ? 'Saving...' : 'Save' }}
        </button>
      </template>
      <template v-slot:body>
        <quest-card title="Basic Information">
          <quest-input
            id="name"
            label="Name"
            v-model="theme.name"
            help=""
            :rules="{ [REQUIRED]: true }"
          ></quest-input>
          <div class="row">
            <div class="col-md-6">
              <quest-single-select
                v-model="theme.backgroundClass"
                :options="colours"
                id="backgroundClass"
                label="Background"
                help="Primary theme colour"
                @update="updateBackgroundClass"
                :rules="{ [REQUIRED]: true }"
              ></quest-single-select>
            </div>
            <div class="col-md-6">
              <quest-single-select
                v-model="theme.accentClass"
                :options="colours"
                id="accentClass"
                label="Accent"
                help="Complementing accent colour"
                @update="updateAccentClass"
                :rules="{ [REQUIRED]: true }"
              ></quest-single-select>
            </div>
          </div>
          <div class="form-group">
            <label>Pattern</label>
            <div class="d-flex h-100">
              <quest-image
                :image="theme.pattern"
                single
                @change="$refs.pattern.open()"
                isPattern
              ></quest-image>
            </div>
          </div>
          <quest-image-picker
            ref="pattern"
            title="Select pattern"
            albumName="patterns"
            :current="[theme.pattern]"
            @update="updatePattern"
          ></quest-image-picker>
        </quest-card>
      </template>
    </quest-main>
  </ValidationObserver>
</template>

<script>
import { REQUIRED } from '@/validation-types'
import { success, error } from '../helpers'
import { mapState } from 'vuex'
import { ThemeService } from '../services'
import {
  QuestCard,
  QuestMain,
  QuestStatCard,
  QuestValidationSummary,
} from './structure'
import { COLOURS } from '../data'
import {
  QuestInput,
  QuestTextArea,
  QuestEditor,
  QuestDatePicker,
  QuestToggle,
  QuestSingleSelect,
  QuestMultiSelect,
  QuestImagePicker,
  QuestImage,
} from './form'

export default {
  name: 'theme',
  components: {
    QuestCard,
    QuestMain,
    QuestInput,
    QuestTextArea,
    QuestEditor,
    QuestDatePicker,
    QuestToggle,
    QuestSingleSelect,
    QuestMultiSelect,
    QuestStatCard,
    QuestImagePicker,
    QuestImage,
    QuestValidationSummary,
  },
  data() {
    return {
      REQUIRED,
      theme: {
        pattern: '',
      },
      loading: true,
      saving: false,
      colours: COLOURS.map((x) => ({ key: x.name, value: x.label })),
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    updatePattern(selected) {
      this.theme.pattern = selected
    },
    updateBackgroundClass(val) {
      this.theme.backgroundClass = val
    },
    updateAccentClass(val) {
      this.theme.accentClass = val
    },
    async save() {
      if (!this.$refs.vobserver) return
      let validated = await this.$refs.vobserver.validate()
      if (!validated) {
        error('Please ensure all errors are resolved')
        return
      }
      this.saving = true
      try {
        if (this.theme.id) {
          new ThemeService().update(this.theme).then((data) => {
            if (data) {
              success(`Updated theme`, this.theme.name)
            } else {
              error(`Failed to update theme #`, this.theme.name)
            }
          })
        } else {
          new ThemeService().add(this.theme).then((data) => {
            if (data) {
              this.$router.push({
                name: 'themes-edit',
                params: { id: data.value },
              })
              success(`Created theme #`, this.theme.name)
            } else {
              error(`Failed to create theme #`, this.theme.name)
            }
          })
        }
      } finally {
        this.saving = false
      }
    },
    load() {
      if (this.$route.params.id) {
        new ThemeService()
          .get(this.$route.params.id)
          .then((data) => (this.theme = data))
          .catch((_) => (this.theme = null))
          .finally((_) => {
            this.loading = false
            this.$nextTick(() => {
              if (this.$refs && this.$refs.vobserver) {
                this.$refs.vobserver.validate()
              }
            })
          })
      } else {
        this.loading = false
      }
    },
  },
}
</script>

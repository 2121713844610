export const ExtraRelationship = {
  Optional: 0,
  Required: 1,
  Bonus: 2,
  Excluded: 3,
}

export const ExtraRelationshipConvert = Object.keys(ExtraRelationship).reduce(
  function (acc, key) {
    return (acc[ExtraRelationship[key]] = key), acc
  },
  {}
)

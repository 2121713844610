<template>
  <quest-main
    title="Booking Engines"
    copy="Configure various booking engine configurations to use for reserving properties"
    :loading="loading"
  >
    <template v-slot:head> </template>
    <template v-slot:body>
      <quest-card title="Engine configurations">
        <quest-table id="engine-table" :columns="columns">
          <template v-if="engines.length > 0">
            <tr
              class="small align-middle"
              v-for="engine in engines"
              :key="engine.key"
            >
              <td>
                <router-link
                  :to="{ name: 'engine-edit', params: { id: engine.key } }"
                  >{{ engine.value }}</router-link
                >
              </td>
            </tr>
          </template>
          <template v-else>
            <tr class="align-middle">
              <td :colspan="columns.length">
                <div class="small text-center py-5">
                  There are no booking engines in the system
                </div>
              </td>
            </tr>
          </template>
        </quest-table>
      </quest-card>
    </template>
  </quest-main>
</template>

<script>
import { toasted, success, error } from '../helpers'
import { EngineService } from '../services'
import { QuestInput, QuestSingleSelect, QuestToggle } from './form'

import {
  QuestMain,
  QuestTable,
  QuestPager,
  QuestCard,
  QuestModal,
} from './structure'

export default {
  name: 'engines',
  components: {
    QuestMain,
    QuestTable,
    QuestPager,
    QuestCard,
    QuestModal,
    QuestSingleSelect,
    QuestInput,
    QuestToggle,
  },
  data() {
    return {
      loading: true,
      engines: [],
      columns: ['Name'],
    }
  },
  mounted() {
    new EngineService()
      .list()
      .then((data) => (this.engines = data))
      .finally((_) => (this.loading = false))
  },
}
</script>

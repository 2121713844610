import AdminNexusHttpClient from './admin-nexus-http-client'

export default class EngineService {
  constructor() {
    this.httpClient = new AdminNexusHttpClient('/admin/engine')
  }

  async list() {
    return await this.httpClient.get('')
  }

  async get(id) {
    return await this.httpClient.get(`${id}`)
  }

  async update(payload) {
    return await this.httpClient.post('', payload)
  }

  async dynamicTokens() {
    return await this.httpClient.get('tokens')
  }

  async identifiers() {
    return await this.httpClient.get('identifiers')
  }

  async promotionTypes() {
    return await this.httpClient.get('promotion-types')
  }
}

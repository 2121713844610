import AdminNexusHttpClient from './admin-nexus-http-client'

export default class SupportService {
  constructor() {
    this.httpClient = new AdminNexusHttpClient('/support')
  }

  async send(message) {
    return await this.httpClient.get('', { message })
  }
}

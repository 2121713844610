export default class RateOverride {
  variance = false
  rateTables = false
  discountDealType = false
  depositType = false
  leadTimeType = false
  sellDates = false
  excludeSellDates = false
  stayDates = false
  excludeStayDates = false
  stayNightsToBlock = false
  name = false
  description = false
  loggedInDiscount = false
  stay = false
  groupCode = false
  stayXPayY = false
  occupants = false
  slug = false
  disclaimer = false
  image = false
  metaTitle = false
  metaDescription = false
}

import AdminNexusHttpClient from './admin-nexus-http-client'

export default class RateService {
  constructor() {
    this.httpClient = new AdminNexusHttpClient('/rates')
  }

  async unlinked(id) {
    return await this.httpClient.get(`${id}/plans/unlinked`)
  }
}

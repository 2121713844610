import AdminNexusHttpClient from './admin-nexus-http-client'

export default class SyncService {
  constructor() {
    this.httpClient = new AdminNexusHttpClient('/sync')
  }

  async fullProperty(id) {
    return await this.httpClient.get(`property/${id}/full`)
  }

  async synthesisFull(client) {
    return await this.httpClient.get(`rms/${client}`)
  }

  async synthesisFullProperty(client, id) {
    return await this.httpClient.get(`rms/${client}/${id}`)
  }
}

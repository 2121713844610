<template>
  <div class="main-loading text-gray-400 text-center">
    <font-awesome-icon
      :icon="saving ? 'save' : 'spinner'"
      size="4x"
      fixed-width
      v-bind="{ pulse: !saving }"
      :class="{ 'animated--heart-beat': saving }"
    ></font-awesome-icon>
    <p class="pt-3 pb-0" v-if="header">{{ header }}</p>
    <small v-if="copy">{{ copy }}</small>
  </div>
</template>

<script>
export default {
  name: 'quest-preloader',
  props: {
    header: String,
    copy: String,
    saving: Boolean,
  },
}
</script>

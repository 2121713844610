var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"wrapper"}},[(!_vm.loading || _vm.$route.name == 'login')?[(
        this.authenticated &&
        this.$router.currentRoute.name !== 'account' &&
        _vm.hasSideBarView()
      )?_c('admin-sidebar',{attrs:{"toggled":_vm.toggled}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column",attrs:{"id":"content-wrapper"}},[_c('div',{attrs:{"id":"content"}},[(_vm.authenticated)?_c('admin-header',{attrs:{"showDashboard":_vm.authenticated &&
            this.$router.currentRoute.name !== 'account' &&
            !(
              this.authenticated &&
              this.$router.currentRoute.name !== 'account' &&
              _vm.hasSideBarView()
            )},on:{"toggle":function($event){_vm.toggled = !_vm.toggled},"changeAccount":function($event){return _vm.setAccount($event)}}}):_vm._e(),_c('router-view',{on:{"deleteAccount":function($event){return _vm.deleteAccount()}}})],1),(_vm.authenticated)?_c('admin-footer'):_vm._e()],1),_c('scroll-to-top')]:_vm._e(),(_vm.loading && _vm.$route.name != 'login')?_c('quest-preloader',{attrs:{"header":"Loading"}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
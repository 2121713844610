export default class RateRecord {
  name = null
  base = null
  discount = null
  loginOnly = null
  groupCode = null
  isDeal = null
  status = null
  parentId = null
  countryId = null
  state = null
  propertyId = null
  roomTypeId = null

  static from(json) {
    return Object.assign(new RateRecord(), json)
  }
}

export const Properties = 'Properties'
export const ProofPoints = 'ProofPoints'
export const Announcements = 'Announcements'
export const Deals = 'Deals'
export const Themes = 'Themes'
export const Localities = 'Localities'
export const Snippets = 'Snippets'
export const Amenities = 'Amenities'
export const Facilities = 'Facilities'
export const Preloads = 'Preloads'
export const Hosts = 'Hosts'
export const Engines = 'Engines'
export const Rooms = 'Rooms'
export const ExternalAccounts = 'ExternalAccounts'
export const Rates = 'Rates'
export const Extras = 'Extras'

<template>
  <div v-if="value">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper modal-open">
          <div
            class="modal fade"
            :class="[value ? 'show' : '']"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ title }}
                  </h5>
                  <button
                    class="close"
                    type="button"
                    aria-label="Close"
                    @click="close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <slot name="body"></slot>
                </div>
                <div class="modal-footer">
                  <slot name="footer"></slot>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'quest-modal',
  props: {
    value: Boolean,
    title: String,
  },
  methods: {
    close() {
      this.$emit('input', false)
    },
  },
}
</script>

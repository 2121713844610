import { configure, extend } from 'vee-validate'
import * as validation from './validation-types'
import { min, max } from 'vee-validate/dist/rules'

configure({
  classes: {
    invalid: 'is-invalid',
    dirty: 'is-dirty',
  },
})

extend(validation.REQUIRED, {
  validate(value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1,
    }
  },
  computesRequired: true,
  message: (field, _) => `${field} is a required field`,
})

extend(validation.MIN, {
  ...min,
  message: (field, { length }) =>
    `${field} requires atleast ${length} characters`,
})

extend(validation.MAX, {
  ...max,
  message: (field, { length }) =>
    `${field} supports up to ${length} characters`,
})

export default class Rate {
  loginOnly = null
  countryId = ''
  state = ''
  parentId = null
  propertyId = null
  roomTypeId = null
  variance = {
    variance: 'None',
    amount: 0,
  }
  rateTableId = ''
  discountDealType = 'None'
  loggedInRateTableId = ''
  depositType = 'None'
  depositAmount = null
  leadTimeType = 'None'
  leadTimeRelease = null
  sellDates = []
  excludeSellDates = []
  stayDates = []
  excludeStayDates = []
  stayNightsToBlock = []
  id = null
  dateCreated = null
  dateModified = null
  name = ''
  externalAccountId = null
  isDeal = false
  description = null
  loggedInDiscount = null
  status = null
  minStay = null
  maxStay = null
  groupCode = null
  stayPayStay = null
  stayPayPay = null
  minOccupants = null
  maxOccupants = null
  slug = null
  disclaimer = null
  image = null
  metaTitle = null
  metaDescription = null
  extras = []
  additionalRateTables = []
  loggedInAdditionalRateTables = []
  optInOnlyProperties = []
  optIn = false

  static from(json) {
    return Object.assign(new Rate(), json)
  }
}

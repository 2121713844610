<template>
  <div :class="wrapperClass">
    <label :for="id" v-if="label">{{ label }}</label>
    <template v-if="!deleted">
      <button
        class="btn btn-icon-split"
        :class="[
          value ? `btn-${checkedTheme}` : `btn-${uncheckedTheme}`,
          !inline ? 'btn-block' : '',
          large ? 'btn-lg' : '',
        ]"
        :disabled="disabled"
        @click="handleInput"
      >
        <span class="icon text-white-50">
          <font-awesome-icon
            v-if="value"
            fixed-width
            :icon="checkedIcon"
          ></font-awesome-icon>
          <font-awesome-icon
            v-else
            fixed-width
            :icon="uncheckedIcon"
          ></font-awesome-icon>
        </span>

        <span
          v-if="!noLabel"
          class="text"
          :style="{ minWidth: `${minTextWidth}px` }"
          >{{ value ? checked : unchecked }}</span
        >
      </button>
    </template>
    <template v-else>
      <button
        class="btn btn-icon-split btn-secondary"
        disabled
        :class="{ 'btn-block': !inline }"
      >
        <span class="icon text-white-50">
          <font-awesome-icon
            fixed-width
            :icon="['fas', 'exclamation']"
          ></font-awesome-icon>
        </span>
        <span
          v-if="!noLabel"
          class="text"
          :style="{ minWidth: `${minTextWidth}px` }"
          >{{ nooption }}</span
        >
      </button>
    </template>
    <small v-if="help" :id="id + 'Help'" class="form-text text-muted">{{
      help
    }}</small>
  </div>
</template>

<script>
import form from './form'

export default {
  name: 'quest-toggle',
  extends: form,
  props: {
    checked: String,
    unchecked: String,
    nooption: { default: 'Deleted' },
    deleted: { default: false },
    checkedIcon: { default: () => ['fas', 'plus'] },
    uncheckedIcon: { default: () => ['fas', 'times'] },
    checkedTheme: { default: 'primary' },
    uncheckedTheme: { default: 'secondary' },
    large: { default: false },
    inline: { default: false },
    minTextWidth: { default: '0' },
    wrapperClass: { default: 'form-group' },
    noLabel: { default: false },
    disabled: { default: false },
  },
  methods: {
    handleInput() {
      this.$emit('input', !this.value)
    },
  },
}
</script>

export default {
  setCountries(state, value) {
    state.countries = value
  },
  setStates(state, value) {
    state.states = value
  },
  setStayNights(state, value) {
    state.stayNights = value
  },
  setDynamicTokens(state, value) {
    state.engineDynamicTokens = value
  },
  setIdentifiers(state, value) {
    state.engineIdentifiers = value
  },
  setPromotionTypes(state, value) {
    state.enginePromotionTypes = value
  },
  setSites(state, value) {
    state.sites = value
  },
  setRoomFeatures(state, value) {
    state.roomFeatures = value
  },
  setFacilities(state, value) {
    state.facilities = value
  },
  setStories(state, value) {
    state.stores = value
  },
  setRestaurants(state, value) {
    state.restaurants = value
  },
  setFactsheets(state, value) {
    state.factsheets = value
  },
  setLoading(state, value) {
    state.loading = value
  },
  disconnect(state) {
    state.noconnect = true
  },
  authenticated(state, value) {
    if (value) {
      localStorage.setItem('nexus:identity', JSON.stringify(value))
      state.authenticated = true
      state.authentication = value
    } else {
      localStorage.removeItem('nexus:identity')
      localStorage.removeItem('nexus:account')
      state.authenticated = false
      state.authentication = {}
    }
  },
  setExternalAccounts(state, value) {
    state.accounts = value
  },
  account(state, value) {
    if (value == null) {
      localStorage.removeItem('nexus:account')
    } else {
      localStorage.setItem('nexus:account', value)
    }
    state.account = value
  },
}

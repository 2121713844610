<template>
  <div class="card mb-1 card-slim">
    <a
      class="card-header py-3 card-header-flex pointer"
      @click="collapse = !collapse"
    >
      <h6 class="m-0 font-weight-bold text-primary">
        {{ room.name
        }}<small v-if="room.externalId">&nbsp;({{ room.externalId }})</small>
      </h6>
      <div class="d-flex align-items-center">
        <span class="ml-2 order-2">
          <font-awesome-icon
            v-if="!collapsable"
            :icon="['fal', 'bars']"
            size="sm"
            class="text-gray-400"
            fixed-width
          ></font-awesome-icon>
          <font-awesome-icon
            v-if="collapsable && collapse"
            :icon="['fas', 'angle-right']"
            class="text-gray-400"
            fixed-width
          ></font-awesome-icon>
          <font-awesome-icon
            v-if="collapsable && !collapse"
            :icon="['fas', 'angle-down']"
            class="text-gray-400"
            fixed-width
          ></font-awesome-icon>
        </span>
        <small
          class="order-1"
          :class="[
            room.isEnabled
              ? 'text-primary'
              : !room.isInactive
              ? 'text-danger'
              : 'text-secondary',
          ]"
          >{{
            room.isEnabled
              ? 'Enabled'
              : room.isInactive
              ? 'Deleted'
              : 'Disabled'
          }}</small
        >
      </div>
    </a>
    <div v-if="this.collapsable" class="collapse" :class="{ show: !collapse }">
      <div class="card-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'room-type-card',
  props: {
    room: { required: true },
    collapsable: Boolean,
  },
  data() {
    return {
      collapse: true,
    }
  },
}
</script>

let auth = JSON.parse(localStorage.getItem('nexus:identity') || '{}')

export default {
  authenticated: !!auth && !!auth.token,
  authentication: auth,
  accounts: [],
  account: localStorage.getItem('nexus:account') || '',
  countries: [],
  states: [],
  stayNights: [],
  sites: [],
  roomFeatures: [],
  facilities: [],
  stories: [],
  restaurants: [],
  factsheets: [],
  noconnect: false,
  loading: true,
  engineDynamicTokens: [],
  engineIdentifiers: [],
  enginePromotionTypes: [],
}

<template>
  <quest-card title="FAQs" :collapsable="true" :collapsed="true" id="faqsForm">
    <div class="row">
      <div class="col-md-6 offset-md-6">
        <div class="form-group text-right">
          <a
            @click="addFAQ()"
            class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
            ><font-awesome-icon
              icon="plus"
              size="sm"
              class="text-white-50"
            ></font-awesome-icon>
            Add
          </a>
        </div>
      </div>
    </div>
    <template v-if="faqs.length > 0">
      <div class="row" v-for="(faq, index) in faqs" :key="index">
        <div class="col-12">
          <hr />
          <quest-input
            :id="'question-' + index"
            label="Question"
            name="FAQ question"
            v-model="faq.question"
            inputGroupTextAppendStyle="margin: 0; padding: 0; border: 0"
            :rules="{ [REQUIRED]: true, [MAX]: { length: 60 } }"
          >
            <template v-slot:append>
              <a
                @click="removeFAQ(faq)"
                class="btn btn-sm btn-primary"
                style="
                  height: 100%;
                  line-height: 2;
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0;
                "
                ><font-awesome-icon
                  icon="times"
                  size="sm"
                  class="text-white-50"
                ></font-awesome-icon>
                Remove
              </a>
            </template></quest-input
          >
        </div>
        <div class="col-12">
          <quest-text-area
            name="FAQ answer"
            label="Answer"
            v-model="faq.answer"
            :rules="{ [REQUIRED]: true, [MAX]: { length: 350 } }"
          ></quest-text-area>
        </div>
      </div>
    </template>
    <template v-else>
      <small class="text-center d-block">No FAQs configured</small>
    </template>
  </quest-card>
</template>

<script>
import { FAQService } from '../../services'
import questTextArea from '../form/quest-text-area.vue'
import Card from './property-card'

export default {
  components: { questTextArea },
  extends: Card,
  data() {
    return {
      faqs: [],
    }
  },
  mounted() {
    new FAQService().get(this.property.id).then((data) => (this.faqs = data))
  },
  methods: {
    getValue() {
      return {
        property: this.property.id,
        payload: this.faqs,
      }
    },
    addFAQ() {
      this.faqs.push({ question: '', answer: '' })
    },
    removeFAQ(faq) {
      this.faqs = this.faqs.filter((x) => x !== faq)
    },
  },
}
</script>

<template>
  <footer class="sticky-footer bg-white">
    <div class="container my-auto">
      <div class="copyright text-center my-auto">
        <span>Quest Serviced Apartments - Administration</span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'admin-footer',
}
</script>

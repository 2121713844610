<template>
  <quest-main title="Rate Management" :loading="loading">
    <template v-slot:head>
      <div>
        <button
          v-if="!exploreMode && property"
          class="d-sm-inline-block btn btn-sm btn-primary shadow-sm mr-1"
          @click="downloadPrivateRates"
          :disabled="privateRatesDownloading"
        >
          <font-awesome-icon
            icon="download"
            size="sm"
            class="text-white-50"
          ></font-awesome-icon>
          {{ privateRatesDownloading ? 'Downloading...' : 'Download Private Rates', }}
        </button>
        <button
          v-if="!exploreMode"
          @click="$refs.selection.open()"
          class="d-sm-inline-block btn btn-sm btn-primary shadow-sm mr-1"
        >
          <font-awesome-icon
            icon="plus"
            size="sm"
            class="text-white-50"
          ></font-awesome-icon>
          Add
        </button>
        <button
          v-if="!exploreMode"
          @click="explorer()"
          class="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
        >
          <font-awesome-icon
            icon="flask"
            size="sm"
            class="text-white-50"
          ></font-awesome-icon>
          Test
        </button>
        <button
          v-if="exploreMode"
          @click="manage()"
          class="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
        >
          <font-awesome-icon
            icon="edit"
            size="sm"
            class="text-white-50"
          ></font-awesome-icon>
          Manage
        </button>
      </div>
    </template>
    <template v-slot:body>
      <template v-if="!exploreMode">
        <quest-card :panel="true">
          <div class="row">
            <div class="col-12 col-md-4">
              <quest-toggle
                :checked-icon="['fas', 'globe-asia']"
                :unchecked-icon="['fas', 'hotel']"
                checked="Global"
                unchecked="Property"
                label="Scope"
                v-model="scope"
                unchecked-theme="dark"
                @input="clearAndSearch()"
              >
              </quest-toggle>
            </div>

            <template v-if="scope">
              <div class="col-12 col-md-4">
                <quest-single-select
                  v-model="country"
                  :options="countries"
                  label="Country"
                  id="country"
                  placeholder="Any"
                  :keepPlaceholder="true"
                  @update="updateCountry"
                ></quest-single-select>
              </div>

              <div class="col-12 col-md-4">
                <quest-single-select
                  v-model="state"
                  :options="calculatedStates"
                  label="State"
                  id="state"
                  placeholder="Any"
                  :keepPlaceholder="true"
                  @update="updateState"
                ></quest-single-select>
              </div>
            </template>

            <template v-if="!scope">
              <div class="col-12 col-md-4">
                <quest-single-select
                  v-model="property"
                  :options="properties"
                  label="Property"
                  id="property"
                  placeholder="None"
                  okey="id"
                  otext="name"
                  :keepPlaceholder="true"
                  @update="updateProperty"
                ></quest-single-select>
              </div>

              <div class="col-12 col-md-4">
                <quest-single-select
                  v-model="room"
                  :options="rooms"
                  label="Room"
                  id="room"
                  :placeholder="property ? 'Any' : 'None'"
                  okey="id"
                  otext="name"
                  :keepPlaceholder="true"
                  @update="updateRoom"
                ></quest-single-select>
              </div>
            </template>
          </div>
        </quest-card>

        <rate-listing-view
          ref="view"
          @init="() => search()"
          :countries="countries"
          :properties="properties"
        ></rate-listing-view>

        <create-rate-selection
          ref="selection"
          :properties="properties"
          @confirm="add"
        ></create-rate-selection>
      </template>
      <template v-if="exploreMode">
        <quest-card :panel="true">
          <div class="row">
            <div class="col-12 col-md-4">
              <quest-single-select
                v-model="property"
                :options="properties"
                id="property"
                placeholder="No property selected"
                okey="id"
                otext="name"
                :keepPlaceholder="true"
                @update="updateProperty"
                wrapperClass="form-group m-0"
              ></quest-single-select>
            </div>
          </div>
        </quest-card>
        <rate-explorer v-if="property" :property="property"></rate-explorer>
      </template>
    </template>
  </quest-main>
</template>

<script>
import { PropertyManagementService } from '@/services'
import { QuestMain, QuestCard } from '../structure'
import { QuestInput, QuestSingleSelect, QuestToggle } from '../form'

import RateListingBase from './rate-listing-base'
import RateListingView from './rate-listing-view.vue'
import RateExplorer from './rate-explorer.vue'
import CreateRateSelection from './create-rate-selection.vue'

export default {
  name: 'rate-listing',
  extends: RateListingBase,
  data() {
    return {
      /* SEARCH VALUES */
      scope: true,
      country: '',
      state: '',
      property: '',
      room: '',
      exploreMode: false,
      privateRatesDownloading: false,
    }
  },
  components: {
    CreateRateSelection,
    QuestCard,
    RateListingView,
    QuestMain,
    QuestSingleSelect,
    QuestInput,
    QuestToggle,
    RateExplorer,
  },
  mounted() {
    new PropertyManagementService().extract(this.account).then((data) => {
      this.properties = data
      this.loading = false
    })
  },
  methods: {
    explorer() {
      this.exploreMode = true
    },
    manage() {
      this.exploreMode = false
    },
    add(e) {
      this.$refs.view.add(e)
    },
    downloadPrivateRates() {
      this.privateRatesDownloading = true
      let self = this
      let token = JSON.parse(
        localStorage?.getItem('nexus:identity') || '{}'
      ).token
      let uri = `${$config.nexus.api}/admin/rates/view/download-promos/${this.account}/${this.property}?x-api-token=${token}`
      var xhr = new XMLHttpRequest()
      xhr.open('GET', uri, true)
      xhr.responseType = 'blob'
      xhr.onerror = function (e) {
        self.privateRatesDownloading = false
      }
      xhr.onload = function (e) {
        self.privateRatesDownloading = false
        if (this.status == 200) {
          let a = document.createElement('a')
          a.href = window.URL.createObjectURL(this.response)
          a.download = 'rate-export.csv'
          a.dispatchEvent(new MouseEvent('click'))
        }
      }
      xhr.send()
    },
    clearAndSearch() {
      if (this.scope) {
        this.property = ''
        this.room = ''
      } else {
        this.country = ''
        this.state = ''
      }
      this.search()
    },
    async search() {
      this.$refs['view'].search(
        this.scope,
        this.country,
        this.state,
        this.property,
        this.room
      )
    },
  },
}
</script>

<template>
  <ValidationObserver ref="vobserver" v-slot="{ errors }">
    <quest-validation-summary :errors="errors"></quest-validation-summary>
    <quest-main
      id="engine-form"
      :title="
        engine && engine.id ? engine.name || '(untitled)' : 'Create Engine'
      "
      :loading="loading"
      :saving="saving"
    >
      <template v-slot:head>
        <a
          @click="save()"
          class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
          ><font-awesome-icon
            icon="save"
            size="sm"
            class="text-white-50"
          ></font-awesome-icon>
          Save
        </a>
      </template>
      <template v-slot:body>
        <quest-card title="Basic Information">
          <quest-input
            id="name"
            label="Name"
            name="Name"
            v-model="engine.name"
            :rules="{ [REQUIRED]: true }"
            help=""
          ></quest-input>

          <quest-input
            id="url"
            label="Url"
            v-model="engine.url"
            help=""
          ></quest-input>

          <quest-input
            id="dateFormat"
            label="Date format"
            v-model="engine.dateFormat"
            help=""
          ></quest-input>

          <quest-input
            id="logo"
            label="Logo"
            v-model="engine.logo"
            help=""
          ></quest-input>

          <quest-single-select
            v-model="engine.propertyIdentifier"
            :options="engineIdentifiers"
            id="propertyIdentifier"
            placeholder="Select a property identifier"
            help="The property field identifying the hotel"
            @update="updatePropertyIdentifier"
          ></quest-single-select>
        </quest-card>

        <quest-card
          title="Static Tokens"
          id="static-tokens-card"
          :collapsable="true"
          :collapsed="true"
        >
          <div class="row">
            <div class="col-md-6 offset-md-6">
              <div class="form-group text-right">
                <a
                  @click="addStatic()"
                  class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                  ><font-awesome-icon
                    icon="plus"
                    size="sm"
                    class="text-white-50"
                  ></font-awesome-icon>
                  Add
                </a>
              </div>
            </div>
          </div>
          <template v-if="engine.staticTokens.length > 0">
            <div
              class="row"
              v-for="(token, index) in engine.staticTokens"
              :key="index"
            >
              <div class="col-md-6">
                <quest-input v-model="token.key"></quest-input>
              </div>
              <div class="col-md-6">
                <quest-input v-model="token.value"></quest-input>
              </div>
            </div>
          </template>
          <template v-else>
            <small class="text-center d-block"
              >No static tokens configured</small
            >
          </template>
        </quest-card>

        <quest-card
          title="Dynamic Tokens"
          id="dynamic-tokens-card"
          :collapsable="true"
          :collapsed="true"
        >
          <div class="row">
            <div class="col-md-6 offset-md-6">
              <div class="form-group text-right">
                <a
                  @click="addDynamic()"
                  class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                  ><font-awesome-icon
                    icon="plus"
                    size="sm"
                    class="text-white-50"
                  ></font-awesome-icon>
                  Add
                </a>
              </div>
            </div>
          </div>
          <template v-if="engine.dynamicTokens.length > 0">
            <div
              class="row"
              v-for="(token, index) in engine.dynamicTokens"
              :key="index"
            >
              <div class="col-md-6">
                <quest-input v-model="token.key"></quest-input>
              </div>
              <div class="col-md-6">
                <quest-single-select
                  v-model="token.value"
                  :options="engineDynamicTokens"
                  @update="(value) => updateDynamicToken(value, index)"
                ></quest-single-select>
              </div>
            </div>
          </template>
          <template v-else>
            <small class="text-center d-block"
              >No dynamic tokens configured</small
            >
          </template>
        </quest-card>

        <quest-card
          title="Promotion Type Mapping"
          id="promotion-type-card"
          :collapsable="true"
          :collapsed="true"
        >
          <div class="row">
            <div class="col-md-6 offset-md-6">
              <div class="form-group text-right">
                <a
                  @click="addPromotionType()"
                  class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                  ><font-awesome-icon
                    icon="plus"
                    size="sm"
                    class="text-white-50"
                  ></font-awesome-icon>
                  Add
                </a>
              </div>
            </div>
          </div>
          <template v-if="engine.promotionTypeTokens.length > 0">
            <div
              class="row"
              v-for="(token, index) in engine.promotionTypeTokens"
              :key="index"
            >
              <div class="col-md-6">
                <quest-single-select
                  v-model="token.key"
                  :options="enginePromotionTypes"
                ></quest-single-select>
              </div>
              <div class="col-md-6">
                <quest-input v-model="token.key"></quest-input>
              </div>
            </div>
          </template>
          <template v-else>
            <small class="text-center d-block"
              >No promotion type tokens configured</small
            >
          </template>
        </quest-card>

        <quest-card
          title="Presentation"
          id="presentation-card"
          :collapsable="true"
          :collapsed="true"
        >
          <div class="row">
            <div class="col-md-6">
              <quest-input
                id="primaryColour"
                label="Primary colour"
                v-model="engine.primaryColour"
                help="The primary color used for identifying the special rates"
              >
                <template v-slot:prepend>
                  <span>#</span>
                </template>
              </quest-input>
            </div>
            <div class="col-md-6">
              <quest-input
                id="secondaryColour"
                label="Secondary colour"
                v-model="engine.secondaryColour"
                help="The secondary color used for identifying the special rates"
              >
                <template v-slot:prepend>
                  <span>#</span>
                </template>
              </quest-input>
            </div>
          </div>
        </quest-card>
      </template>
    </quest-main>
  </ValidationObserver>
</template>

<script>
import { REQUIRED } from '@/validation-types'
import { success, error } from '../helpers'
import { mapState } from 'vuex'
import { EngineService } from '../services'
import {
  QuestCard,
  QuestMain,
  QuestStatCard,
  QuestValidationSummary,
} from './structure'
import {
  QuestInput,
  QuestTextArea,
  QuestEditor,
  QuestDatePicker,
  QuestToggle,
  QuestSingleSelect,
  QuestMultiSelect,
} from './form'

export default {
  name: 'engine',
  components: {
    QuestCard,
    QuestMain,
    QuestInput,
    QuestTextArea,
    QuestEditor,
    QuestDatePicker,
    QuestToggle,
    QuestSingleSelect,
    QuestMultiSelect,
    QuestStatCard,
    QuestValidationSummary,
  },
  data() {
    return {
      REQUIRED,
      engine: null,
      loading: true,
      saving: false,
    }
  },
  computed: {
    ...mapState([
      'engineDynamicTokens',
      'engineIdentifiers',
      'enginePromotionTypes',
    ]),
  },
  mounted() {
    this.load()
  },
  methods: {
    addDynamic() {
      this.engine.dynamicTokens.push({ key: '', value: null })
    },
    addStatic() {
      this.engine.staticTokens.push({ key: '', value: null })
    },
    addPromotionType() {
      this.engine.promotionTypeTokens.push({ key: '', value: null })
    },
    updatePropertyIdentifier(update) {
      this.engine.propertyIdentifier = update
    },
    async save() {
      if (!this.$refs.vobserver) return
      let validated = await this.$refs.vobserver.validate()
      if (!validated) {
        error('Please ensure all errors are resolved')
        return
      }
      this.saving = true
      new EngineService()
        .update(this.engine)
        .then((data) => {
          if (data) {
            this.load()
            success(`Updated engine #`, this.engine.name)
          } else {
            error(`Failed to update engine #`, this.engine.name)
          }
        })
        .finally((_) => {
          this.saving = false
        })
    },
    updateDynamicToken(value, index) {
      this.engine.dynamicTokens[index].value = value
    },
    load() {
      if (this.$route.params.id) {
        new EngineService()
          .get(this.$route.params.id)
          .then((data) => (this.engine = data))
          .catch((_) => (this.engine = null))
          .finally((_) => {
            this.loading = false
            this.$nextTick(() => {
              if (this.$refs && this.$refs.vobserver) {
                this.$refs.vobserver.validate()
              }
            })
          })
      } else {
        this.loading = false
      }
    },
  },
}
</script>

<template>
  <quest-card title="SEO" :collapsable="true" :collapsed="true" id="seoInfo">
    <div class="row">
      <div class="col-md-6">
        <quest-input
          id="metaTitle"
          label="Meta Title"
          v-model="deal.metaTitle"
        ></quest-input>
      </div>
      <div class="col-md-6">
        <quest-input
          id="slug"
          label="Url Slug"
          v-model="deal.slug"
        ></quest-input>
      </div>
    </div>

    <quest-editor
      id="metaDescription"
      label="Meta Description"
      v-model="deal.metaDescription"
    ></quest-editor>
  </quest-card>
</template>

<script>
import Card from './deal-card'
export default {
  name: 'seo-card',
  extends: Card,
}
</script>

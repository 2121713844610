import { AmenityService, ProofPointService, SitesManagementService, ThemeService } from '.'

export default class GeneratePreviewService {
  
  async generate(property, configuration, locality, roomTypes, amenities, csr) {
    let themeId = locality && locality.locality ? locality.locality.themeId : ''

    return await new SitesManagementService().getByCountry(property.country.id)
      .then(async host => {
        return await new ProofPointService().mandatory(host.id).then(async mandatory => {
          return await Promise.all([
            new ProofPointService().getSelected([...new Set([...(mandatory || []), ...property.proofPoints])]),
            new SitesManagementService().getCSR(host.id),
            new ThemeService().get(themeId),
            new AmenityService().getSelected(amenities.map(x => x.key)),
          ]).then(async ([ points, _csr, theme, _amenities ]) => {
            return { data:  
              JSON.stringify({
                property: { 
                  ...property,
                  amenities,
                  postcode: property.postcode.override || property.postcode.raw,
                  street: property.street.override || property.street.raw,
                  state: property.state.override || property.state.raw,
                  suburb: property.suburb.override || property.suburb.raw
                },
                configuration,
                roomTypes,
                locality,
                features: [],
                points,
                theme: theme || { pattern: host.pattern },
                deals: [],
                amenities: _amenities,
                csr: !!csr?.title ? csr : _csr
              })
            }
          })
        })
      })
  }
}

<template>
  <quest-card
    title="Amenities"
    :collapsable="true"
    :collapsed="true"
    id="amenitiesForm"
  >
    <div class="row" v-for="idx in [0, 1, 2]" :key="idx">
      <div class="col-md-6">
        <quest-single-select
          v-model="values[idx].amenity"
          :keepPlaceholder="true"
          placeholder="No amenity selected"
          :options="amenities"
          label="Amenity"
          :id="'amenity_' + idx"
          help=""
          @update="(val) => updateAmenity(val, idx)"
        ></quest-single-select>
      </div>
      <div class="col-md-6">
        <quest-input
          v-model="values[idx].distance"
          type="number"
          label="Distance"
          :id="'distance_' + idx"
        >
          <template v-slot:append>
            <span>Km</span>
          </template>
        </quest-input>
      </div>
    </div>
  </quest-card>
</template>

<script>
import { AmenityService } from '../../services'
import Card from './property-card'

export default {
  extends: Card,
  data() {
    return {
      values: [
        { amenity: '', distance: 0 },
        { amenity: '', distance: 0 },
        { amenity: '', distance: 0 },
      ],
      amenities: [],
    }
  },
  mounted() {
    this.property.amenities.forEach((e, i) => {
      this.values[i].amenity = e.key
      this.values[i].distance = e.value
    })
    new AmenityService().all().then((data) => (this.amenities = data))
  },
  methods: {
    updateAmenity(val, idx) {
      this.values[idx].amenity = val
    },
    getValue() {
      return this.values
        .filter((x) => x.amenity)
        .map((x) => ({ key: x.amenity, value: x.distance }))
    },
  },
}
</script>

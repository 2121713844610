export const FONT_CLASSES = [
  {
    key: 'far fa-abacus',
    value: 'abacus',
  },
  {
    key: 'far fa-acorn',
    value: 'acorn',
  },
  {
    key: 'far fa-ad',
    value: 'ad',
  },
  {
    key: 'far fa-address-book',
    value: 'address-book',
  },
  {
    key: 'far fa-address-card',
    value: 'address-card',
  },
  {
    key: 'far fa-adjust',
    value: 'adjust',
  },
  {
    key: 'far fa-air-conditioner',
    value: 'air-conditioner',
  },
  {
    key: 'far fa-air-freshener',
    value: 'air-freshener',
  },
  {
    key: 'far fa-alarm-clock',
    value: 'alarm-clock',
  },
  {
    key: 'far fa-alarm-exclamation',
    value: 'alarm-exclamation',
  },
  {
    key: 'far fa-alarm-plus',
    value: 'alarm-plus',
  },
  {
    key: 'far fa-alarm-snooze',
    value: 'alarm-snooze',
  },
  {
    key: 'far fa-album',
    value: 'album',
  },
  {
    key: 'far fa-album-collection',
    value: 'album-collection',
  },
  {
    key: 'far fa-alicorn',
    value: 'alicorn',
  },
  {
    key: 'far fa-alien',
    value: 'alien',
  },
  {
    key: 'far fa-alien-monster',
    value: 'alien-monster',
  },
  {
    key: 'far fa-align-center',
    value: 'align-center',
  },
  {
    key: 'far fa-align-justify',
    value: 'align-justify',
  },
  {
    key: 'far fa-align-left',
    value: 'align-left',
  },
  {
    key: 'far fa-align-right',
    value: 'align-right',
  },
  {
    key: 'far fa-align-slash',
    value: 'align-slash',
  },
  {
    key: 'far fa-allergies',
    value: 'allergies',
  },
  {
    key: 'far fa-ambulance',
    value: 'ambulance',
  },
  {
    key: 'far fa-american-sign-language-interpreting',
    value: 'american-sign-language-interpreting',
  },
  {
    key: 'far fa-amp-guitar',
    value: 'amp-guitar',
  },
  {
    key: 'far fa-analytics',
    value: 'analytics',
  },
  {
    key: 'far fa-anchor',
    value: 'anchor',
  },
  {
    key: 'far fa-angel',
    value: 'angel',
  },
  {
    key: 'far fa-angle-double-down',
    value: 'angle-double-down',
  },
  {
    key: 'far fa-angle-double-left',
    value: 'angle-double-left',
  },
  {
    key: 'far fa-angle-double-right',
    value: 'angle-double-right',
  },
  {
    key: 'far fa-angle-double-up',
    value: 'angle-double-up',
  },
  {
    key: 'far fa-angle-down',
    value: 'angle-down',
  },
  {
    key: 'far fa-angle-left',
    value: 'angle-left',
  },
  {
    key: 'far fa-angle-right',
    value: 'angle-right',
  },
  {
    key: 'far fa-angle-up',
    value: 'angle-up',
  },
  {
    key: 'far fa-angry',
    value: 'angry',
  },
  {
    key: 'far fa-ankh',
    value: 'ankh',
  },
  {
    key: 'far fa-apple-alt',
    value: 'apple-alt',
  },
  {
    key: 'far fa-apple-crate',
    value: 'apple-crate',
  },
  {
    key: 'far fa-archive',
    value: 'archive',
  },
  {
    key: 'far fa-archway',
    value: 'archway',
  },
  {
    key: 'far fa-arrow-alt-circle-down',
    value: 'arrow-alt-circle-down',
  },
  {
    key: 'far fa-arrow-alt-circle-left',
    value: 'arrow-alt-circle-left',
  },
  {
    key: 'far fa-arrow-alt-circle-right',
    value: 'arrow-alt-circle-right',
  },
  {
    key: 'far fa-arrow-alt-circle-up',
    value: 'arrow-alt-circle-up',
  },
  {
    key: 'far fa-arrow-alt-down',
    value: 'arrow-alt-down',
  },
  {
    key: 'far fa-arrow-alt-from-bottom',
    value: 'arrow-alt-from-bottom',
  },
  {
    key: 'far fa-arrow-alt-from-left',
    value: 'arrow-alt-from-left',
  },
  {
    key: 'far fa-arrow-alt-from-right',
    value: 'arrow-alt-from-right',
  },
  {
    key: 'far fa-arrow-alt-from-top',
    value: 'arrow-alt-from-top',
  },
  {
    key: 'far fa-arrow-alt-left',
    value: 'arrow-alt-left',
  },
  {
    key: 'far fa-arrow-alt-right',
    value: 'arrow-alt-right',
  },
  {
    key: 'far fa-arrow-alt-square-down',
    value: 'arrow-alt-square-down',
  },
  {
    key: 'far fa-arrow-alt-square-left',
    value: 'arrow-alt-square-left',
  },
  {
    key: 'far fa-arrow-alt-square-right',
    value: 'arrow-alt-square-right',
  },
  {
    key: 'far fa-arrow-alt-square-up',
    value: 'arrow-alt-square-up',
  },
  {
    key: 'far fa-arrow-alt-to-bottom',
    value: 'arrow-alt-to-bottom',
  },
  {
    key: 'far fa-arrow-alt-to-left',
    value: 'arrow-alt-to-left',
  },
  {
    key: 'far fa-arrow-alt-to-right',
    value: 'arrow-alt-to-right',
  },
  {
    key: 'far fa-arrow-alt-to-top',
    value: 'arrow-alt-to-top',
  },
  {
    key: 'far fa-arrow-alt-up',
    value: 'arrow-alt-up',
  },
  {
    key: 'far fa-arrow-circle-down',
    value: 'arrow-circle-down',
  },
  {
    key: 'far fa-arrow-circle-left',
    value: 'arrow-circle-left',
  },
  {
    key: 'far fa-arrow-circle-right',
    value: 'arrow-circle-right',
  },
  {
    key: 'far fa-arrow-circle-up',
    value: 'arrow-circle-up',
  },
  {
    key: 'far fa-arrow-down',
    value: 'arrow-down',
  },
  {
    key: 'far fa-arrow-from-bottom',
    value: 'arrow-from-bottom',
  },
  {
    key: 'far fa-arrow-from-left',
    value: 'arrow-from-left',
  },
  {
    key: 'far fa-arrow-from-right',
    value: 'arrow-from-right',
  },
  {
    key: 'far fa-arrow-from-top',
    value: 'arrow-from-top',
  },
  {
    key: 'far fa-arrow-left',
    value: 'arrow-left',
  },
  {
    key: 'far fa-arrow-right',
    value: 'arrow-right',
  },
  {
    key: 'far fa-arrow-square-down',
    value: 'arrow-square-down',
  },
  {
    key: 'far fa-arrow-square-left',
    value: 'arrow-square-left',
  },
  {
    key: 'far fa-arrow-square-right',
    value: 'arrow-square-right',
  },
  {
    key: 'far fa-arrow-square-up',
    value: 'arrow-square-up',
  },
  {
    key: 'far fa-arrow-to-bottom',
    value: 'arrow-to-bottom',
  },
  {
    key: 'far fa-arrow-to-left',
    value: 'arrow-to-left',
  },
  {
    key: 'far fa-arrow-to-right',
    value: 'arrow-to-right',
  },
  {
    key: 'far fa-arrow-to-top',
    value: 'arrow-to-top',
  },
  {
    key: 'far fa-arrow-up',
    value: 'arrow-up',
  },
  {
    key: 'far fa-arrows',
    value: 'arrows',
  },
  {
    key: 'far fa-arrows-alt',
    value: 'arrows-alt',
  },
  {
    key: 'far fa-arrows-alt-h',
    value: 'arrows-alt-h',
  },
  {
    key: 'far fa-arrows-alt-v',
    value: 'arrows-alt-v',
  },
  {
    key: 'far fa-arrows-h',
    value: 'arrows-h',
  },
  {
    key: 'far fa-arrows-v',
    value: 'arrows-v',
  },
  {
    key: 'far fa-assistive-listening-systems',
    value: 'assistive-listening-systems',
  },
  {
    key: 'far fa-asterisk',
    value: 'asterisk',
  },
  {
    key: 'far fa-at',
    value: 'at',
  },
  {
    key: 'far fa-atlas',
    value: 'atlas',
  },
  {
    key: 'far fa-atom',
    value: 'atom',
  },
  {
    key: 'far fa-atom-alt',
    value: 'atom-alt',
  },
  {
    key: 'far fa-audio-description',
    value: 'audio-description',
  },
  {
    key: 'far fa-award',
    value: 'award',
  },
  {
    key: 'far fa-axe',
    value: 'axe',
  },
  {
    key: 'far fa-axe-battle',
    value: 'axe-battle',
  },
  {
    key: 'far fa-baby',
    value: 'baby',
  },
  {
    key: 'far fa-baby-carriage',
    value: 'baby-carriage',
  },
  {
    key: 'far fa-backpack',
    value: 'backpack',
  },
  {
    key: 'far fa-backspace',
    value: 'backspace',
  },
  {
    key: 'far fa-backward',
    value: 'backward',
  },
  {
    key: 'far fa-bacon',
    value: 'bacon',
  },
  {
    key: 'far fa-bacteria',
    value: 'bacteria',
  },
  {
    key: 'far fa-bacterium',
    value: 'bacterium',
  },
  {
    key: 'far fa-badge',
    value: 'badge',
  },
  {
    key: 'far fa-badge-check',
    value: 'badge-check',
  },
  {
    key: 'far fa-badge-dollar',
    value: 'badge-dollar',
  },
  {
    key: 'far fa-badge-percent',
    value: 'badge-percent',
  },
  {
    key: 'far fa-badge-sheriff',
    value: 'badge-sheriff',
  },
  {
    key: 'far fa-badger-honey',
    value: 'badger-honey',
  },
  {
    key: 'far fa-bags-shopping',
    value: 'bags-shopping',
  },
  {
    key: 'far fa-bahai',
    value: 'bahai',
  },
  {
    key: 'far fa-balance-scale',
    value: 'balance-scale',
  },
  {
    key: 'far fa-balance-scale-left',
    value: 'balance-scale-left',
  },
  {
    key: 'far fa-balance-scale-right',
    value: 'balance-scale-right',
  },
  {
    key: 'far fa-ball-pile',
    value: 'ball-pile',
  },
  {
    key: 'far fa-ballot',
    value: 'ballot',
  },
  {
    key: 'far fa-ballot-check',
    value: 'ballot-check',
  },
  {
    key: 'far fa-ban',
    value: 'ban',
  },
  {
    key: 'far fa-band-aid',
    value: 'band-aid',
  },
  {
    key: 'far fa-banjo',
    value: 'banjo',
  },
  {
    key: 'far fa-barcode',
    value: 'barcode',
  },
  {
    key: 'far fa-barcode-alt',
    value: 'barcode-alt',
  },
  {
    key: 'far fa-barcode-read',
    value: 'barcode-read',
  },
  {
    key: 'far fa-barcode-scan',
    value: 'barcode-scan',
  },
  {
    key: 'far fa-bars',
    value: 'bars',
  },
  {
    key: 'far fa-baseball',
    value: 'baseball',
  },
  {
    key: 'far fa-baseball-ball',
    value: 'baseball-ball',
  },
  {
    key: 'far fa-basketball-ball',
    value: 'basketball-ball',
  },
  {
    key: 'far fa-basketball-hoop',
    value: 'basketball-hoop',
  },
  {
    key: 'far fa-bat',
    value: 'bat',
  },
  {
    key: 'far fa-bath',
    value: 'bath',
  },
  {
    key: 'far fa-battery-bolt',
    value: 'battery-bolt',
  },
  {
    key: 'far fa-battery-empty',
    value: 'battery-empty',
  },
  {
    key: 'far fa-battery-full',
    value: 'battery-full',
  },
  {
    key: 'far fa-battery-half',
    value: 'battery-half',
  },
  {
    key: 'far fa-battery-quarter',
    value: 'battery-quarter',
  },
  {
    key: 'far fa-battery-slash',
    value: 'battery-slash',
  },
  {
    key: 'far fa-battery-three-quarters',
    value: 'battery-three-quarters',
  },
  {
    key: 'far fa-bed',
    value: 'bed',
  },
  {
    key: 'far fa-bed-alt',
    value: 'bed-alt',
  },
  {
    key: 'far fa-bed-bunk',
    value: 'bed-bunk',
  },
  {
    key: 'far fa-bed-empty',
    value: 'bed-empty',
  },
  {
    key: 'far fa-beer',
    value: 'beer',
  },
  {
    key: 'far fa-bell',
    value: 'bell',
  },
  {
    key: 'far fa-bell-exclamation',
    value: 'bell-exclamation',
  },
  {
    key: 'far fa-bell-on',
    value: 'bell-on',
  },
  {
    key: 'far fa-bell-plus',
    value: 'bell-plus',
  },
  {
    key: 'far fa-bell-school',
    value: 'bell-school',
  },
  {
    key: 'far fa-bell-school-slash',
    value: 'bell-school-slash',
  },
  {
    key: 'far fa-bell-slash',
    value: 'bell-slash',
  },
  {
    key: 'far fa-bells',
    value: 'bells',
  },
  {
    key: 'far fa-betamax',
    value: 'betamax',
  },
  {
    key: 'far fa-bezier-curve',
    value: 'bezier-curve',
  },
  {
    key: 'far fa-bible',
    value: 'bible',
  },
  {
    key: 'far fa-bicycle',
    value: 'bicycle',
  },
  {
    key: 'far fa-biking',
    value: 'biking',
  },
  {
    key: 'far fa-biking-mountain',
    value: 'biking-mountain',
  },
  {
    key: 'far fa-binoculars',
    value: 'binoculars',
  },
  {
    key: 'far fa-biohazard',
    value: 'biohazard',
  },
  {
    key: 'far fa-birthday-cake',
    value: 'birthday-cake',
  },
  {
    key: 'far fa-blanket',
    value: 'blanket',
  },
  {
    key: 'far fa-blender',
    value: 'blender',
  },
  {
    key: 'far fa-blender-phone',
    value: 'blender-phone',
  },
  {
    key: 'far fa-blind',
    value: 'blind',
  },
  {
    key: 'far fa-blinds',
    value: 'blinds',
  },
  {
    key: 'far fa-blinds-open',
    value: 'blinds-open',
  },
  {
    key: 'far fa-blinds-raised',
    value: 'blinds-raised',
  },
  {
    key: 'far fa-blog',
    value: 'blog',
  },
  {
    key: 'far fa-bold',
    value: 'bold',
  },
  {
    key: 'far fa-bolt',
    value: 'bolt',
  },
  {
    key: 'far fa-bomb',
    value: 'bomb',
  },
  {
    key: 'far fa-bone',
    value: 'bone',
  },
  {
    key: 'far fa-bone-break',
    value: 'bone-break',
  },
  {
    key: 'far fa-bong',
    value: 'bong',
  },
  {
    key: 'far fa-book',
    value: 'book',
  },
  {
    key: 'far fa-book-alt',
    value: 'book-alt',
  },
  {
    key: 'far fa-book-dead',
    value: 'book-dead',
  },
  {
    key: 'far fa-book-heart',
    value: 'book-heart',
  },
  {
    key: 'far fa-book-medical',
    value: 'book-medical',
  },
  {
    key: 'far fa-book-open',
    value: 'book-open',
  },
  {
    key: 'far fa-book-reader',
    value: 'book-reader',
  },
  {
    key: 'far fa-book-spells',
    value: 'book-spells',
  },
  {
    key: 'far fa-book-user',
    value: 'book-user',
  },
  {
    key: 'far fa-bookmark',
    value: 'bookmark',
  },
  {
    key: 'far fa-books',
    value: 'books',
  },
  {
    key: 'far fa-books-medical',
    value: 'books-medical',
  },
  {
    key: 'far fa-boombox',
    value: 'boombox',
  },
  {
    key: 'far fa-boot',
    value: 'boot',
  },
  {
    key: 'far fa-booth-curtain',
    value: 'booth-curtain',
  },
  {
    key: 'far fa-border-all',
    value: 'border-all',
  },
  {
    key: 'far fa-border-bottom',
    value: 'border-bottom',
  },
  {
    key: 'far fa-border-center-h',
    value: 'border-center-h',
  },
  {
    key: 'far fa-border-center-v',
    value: 'border-center-v',
  },
  {
    key: 'far fa-border-inner',
    value: 'border-inner',
  },
  {
    key: 'far fa-border-left',
    value: 'border-left',
  },
  {
    key: 'far fa-border-none',
    value: 'border-none',
  },
  {
    key: 'far fa-border-outer',
    value: 'border-outer',
  },
  {
    key: 'far fa-border-right',
    value: 'border-right',
  },
  {
    key: 'far fa-border-style',
    value: 'border-style',
  },
  {
    key: 'far fa-border-style-alt',
    value: 'border-style-alt',
  },
  {
    key: 'far fa-border-top',
    value: 'border-top',
  },
  {
    key: 'far fa-bow-arrow',
    value: 'bow-arrow',
  },
  {
    key: 'far fa-bowling-ball',
    value: 'bowling-ball',
  },
  {
    key: 'far fa-bowling-pins',
    value: 'bowling-pins',
  },
  {
    key: 'far fa-box',
    value: 'box',
  },
  {
    key: 'far fa-box-alt',
    value: 'box-alt',
  },
  {
    key: 'far fa-box-ballot',
    value: 'box-ballot',
  },
  {
    key: 'far fa-box-check',
    value: 'box-check',
  },
  {
    key: 'far fa-box-fragile',
    value: 'box-fragile',
  },
  {
    key: 'far fa-box-full',
    value: 'box-full',
  },
  {
    key: 'far fa-box-heart',
    value: 'box-heart',
  },
  {
    key: 'far fa-box-open',
    value: 'box-open',
  },
  {
    key: 'far fa-box-tissue',
    value: 'box-tissue',
  },
  {
    key: 'far fa-box-up',
    value: 'box-up',
  },
  {
    key: 'far fa-box-usd',
    value: 'box-usd',
  },
  {
    key: 'far fa-boxes',
    value: 'boxes',
  },
  {
    key: 'far fa-boxes-alt',
    value: 'boxes-alt',
  },
  {
    key: 'far fa-boxing-glove',
    value: 'boxing-glove',
  },
  {
    key: 'far fa-brackets',
    value: 'brackets',
  },
  {
    key: 'far fa-brackets-curly',
    value: 'brackets-curly',
  },
  {
    key: 'far fa-braille',
    value: 'braille',
  },
  {
    key: 'far fa-brain',
    value: 'brain',
  },
  {
    key: 'far fa-bread-loaf',
    value: 'bread-loaf',
  },
  {
    key: 'far fa-bread-slice',
    value: 'bread-slice',
  },
  {
    key: 'far fa-briefcase',
    value: 'briefcase',
  },
  {
    key: 'far fa-briefcase-medical',
    value: 'briefcase-medical',
  },
  {
    key: 'far fa-bring-forward',
    value: 'bring-forward',
  },
  {
    key: 'far fa-bring-front',
    value: 'bring-front',
  },
  {
    key: 'far fa-broadcast-tower',
    value: 'broadcast-tower',
  },
  {
    key: 'far fa-broom',
    value: 'broom',
  },
  {
    key: 'far fa-browser',
    value: 'browser',
  },
  {
    key: 'far fa-brush',
    value: 'brush',
  },
  {
    key: 'far fa-bug',
    value: 'bug',
  },
  {
    key: 'far fa-building',
    value: 'building',
  },
  {
    key: 'far fa-bullhorn',
    value: 'bullhorn',
  },
  {
    key: 'far fa-bullseye',
    value: 'bullseye',
  },
  {
    key: 'far fa-bullseye-arrow',
    value: 'bullseye-arrow',
  },
  {
    key: 'far fa-bullseye-pointer',
    value: 'bullseye-pointer',
  },
  {
    key: 'far fa-burger-soda',
    value: 'burger-soda',
  },
  {
    key: 'far fa-burn',
    value: 'burn',
  },
  {
    key: 'far fa-burrito',
    value: 'burrito',
  },
  {
    key: 'far fa-bus',
    value: 'bus',
  },
  {
    key: 'far fa-bus-alt',
    value: 'bus-alt',
  },
  {
    key: 'far fa-bus-school',
    value: 'bus-school',
  },
  {
    key: 'far fa-business-time',
    value: 'business-time',
  },
  {
    key: 'far fa-cabinet-filing',
    value: 'cabinet-filing',
  },
  {
    key: 'far fa-cactus',
    value: 'cactus',
  },
  {
    key: 'far fa-calculator',
    value: 'calculator',
  },
  {
    key: 'far fa-calculator-alt',
    value: 'calculator-alt',
  },
  {
    key: 'far fa-calendar',
    value: 'calendar',
  },
  {
    key: 'far fa-calendar-alt',
    value: 'calendar-alt',
  },
  {
    key: 'far fa-calendar-check',
    value: 'calendar-check',
  },
  {
    key: 'far fa-calendar-day',
    value: 'calendar-day',
  },
  {
    key: 'far fa-calendar-edit',
    value: 'calendar-edit',
  },
  {
    key: 'far fa-calendar-exclamation',
    value: 'calendar-exclamation',
  },
  {
    key: 'far fa-calendar-minus',
    value: 'calendar-minus',
  },
  {
    key: 'far fa-calendar-plus',
    value: 'calendar-plus',
  },
  {
    key: 'far fa-calendar-star',
    value: 'calendar-star',
  },
  {
    key: 'far fa-calendar-times',
    value: 'calendar-times',
  },
  {
    key: 'far fa-calendar-week',
    value: 'calendar-week',
  },
  {
    key: 'far fa-camcorder',
    value: 'camcorder',
  },
  {
    key: 'far fa-camera',
    value: 'camera',
  },
  {
    key: 'far fa-camera-alt',
    value: 'camera-alt',
  },
  {
    key: 'far fa-camera-home',
    value: 'camera-home',
  },
  {
    key: 'far fa-camera-movie',
    value: 'camera-movie',
  },
  {
    key: 'far fa-camera-polaroid',
    value: 'camera-polaroid',
  },
  {
    key: 'far fa-camera-retro',
    value: 'camera-retro',
  },
  {
    key: 'far fa-campfire',
    value: 'campfire',
  },
  {
    key: 'far fa-campground',
    value: 'campground',
  },
  {
    key: 'far fa-candle-holder',
    value: 'candle-holder',
  },
  {
    key: 'far fa-candy-cane',
    value: 'candy-cane',
  },
  {
    key: 'far fa-candy-corn',
    value: 'candy-corn',
  },
  {
    key: 'far fa-cannabis',
    value: 'cannabis',
  },
  {
    key: 'far fa-capsules',
    value: 'capsules',
  },
  {
    key: 'far fa-car',
    value: 'car',
  },
  {
    key: 'far fa-car-alt',
    value: 'car-alt',
  },
  {
    key: 'far fa-car-battery',
    value: 'car-battery',
  },
  {
    key: 'far fa-car-building',
    value: 'car-building',
  },
  {
    key: 'far fa-car-bump',
    value: 'car-bump',
  },
  {
    key: 'far fa-car-bus',
    value: 'car-bus',
  },
  {
    key: 'far fa-car-crash',
    value: 'car-crash',
  },
  {
    key: 'far fa-car-garage',
    value: 'car-garage',
  },
  {
    key: 'far fa-car-mechanic',
    value: 'car-mechanic',
  },
  {
    key: 'far fa-car-side',
    value: 'car-side',
  },
  {
    key: 'far fa-car-tilt',
    value: 'car-tilt',
  },
  {
    key: 'far fa-car-wash',
    value: 'car-wash',
  },
  {
    key: 'far fa-caravan',
    value: 'caravan',
  },
  {
    key: 'far fa-caravan-alt',
    value: 'caravan-alt',
  },
  {
    key: 'far fa-caret-circle-down',
    value: 'caret-circle-down',
  },
  {
    key: 'far fa-caret-circle-left',
    value: 'caret-circle-left',
  },
  {
    key: 'far fa-caret-circle-right',
    value: 'caret-circle-right',
  },
  {
    key: 'far fa-caret-circle-up',
    value: 'caret-circle-up',
  },
  {
    key: 'far fa-caret-down',
    value: 'caret-down',
  },
  {
    key: 'far fa-caret-left',
    value: 'caret-left',
  },
  {
    key: 'far fa-caret-right',
    value: 'caret-right',
  },
  {
    key: 'far fa-caret-square-down',
    value: 'caret-square-down',
  },
  {
    key: 'far fa-caret-square-left',
    value: 'caret-square-left',
  },
  {
    key: 'far fa-caret-square-right',
    value: 'caret-square-right',
  },
  {
    key: 'far fa-caret-square-up',
    value: 'caret-square-up',
  },
  {
    key: 'far fa-caret-up',
    value: 'caret-up',
  },
  {
    key: 'far fa-carrot',
    value: 'carrot',
  },
  {
    key: 'far fa-cars',
    value: 'cars',
  },
  {
    key: 'far fa-cart-arrow-down',
    value: 'cart-arrow-down',
  },
  {
    key: 'far fa-cart-plus',
    value: 'cart-plus',
  },
  {
    key: 'far fa-cash-register',
    value: 'cash-register',
  },
  {
    key: 'far fa-cassette-tape',
    value: 'cassette-tape',
  },
  {
    key: 'far fa-cat',
    value: 'cat',
  },
  {
    key: 'far fa-cat-space',
    value: 'cat-space',
  },
  {
    key: 'far fa-cauldron',
    value: 'cauldron',
  },
  {
    key: 'far fa-cctv',
    value: 'cctv',
  },
  {
    key: 'far fa-certificate',
    value: 'certificate',
  },
  {
    key: 'far fa-chair',
    value: 'chair',
  },
  {
    key: 'far fa-chair-office',
    value: 'chair-office',
  },
  {
    key: 'far fa-chalkboard',
    value: 'chalkboard',
  },
  {
    key: 'far fa-chalkboard-teacher',
    value: 'chalkboard-teacher',
  },
  {
    key: 'far fa-charging-station',
    value: 'charging-station',
  },
  {
    key: 'far fa-chart-area',
    value: 'chart-area',
  },
  {
    key: 'far fa-chart-bar',
    value: 'chart-bar',
  },
  {
    key: 'far fa-chart-line',
    value: 'chart-line',
  },
  {
    key: 'far fa-chart-line-down',
    value: 'chart-line-down',
  },
  {
    key: 'far fa-chart-network',
    value: 'chart-network',
  },
  {
    key: 'far fa-chart-pie',
    value: 'chart-pie',
  },
  {
    key: 'far fa-chart-pie-alt',
    value: 'chart-pie-alt',
  },
  {
    key: 'far fa-chart-scatter',
    value: 'chart-scatter',
  },
  {
    key: 'far fa-check',
    value: 'check',
  },
  {
    key: 'far fa-check-circle',
    value: 'check-circle',
  },
  {
    key: 'far fa-check-double',
    value: 'check-double',
  },
  {
    key: 'far fa-check-square',
    value: 'check-square',
  },
  {
    key: 'far fa-cheese',
    value: 'cheese',
  },
  {
    key: 'far fa-cheese-swiss',
    value: 'cheese-swiss',
  },
  {
    key: 'far fa-cheeseburger',
    value: 'cheeseburger',
  },
  {
    key: 'far fa-chess',
    value: 'chess',
  },
  {
    key: 'far fa-chess-bishop',
    value: 'chess-bishop',
  },
  {
    key: 'far fa-chess-bishop-alt',
    value: 'chess-bishop-alt',
  },
  {
    key: 'far fa-chess-board',
    value: 'chess-board',
  },
  {
    key: 'far fa-chess-clock',
    value: 'chess-clock',
  },
  {
    key: 'far fa-chess-clock-alt',
    value: 'chess-clock-alt',
  },
  {
    key: 'far fa-chess-king',
    value: 'chess-king',
  },
  {
    key: 'far fa-chess-king-alt',
    value: 'chess-king-alt',
  },
  {
    key: 'far fa-chess-knight',
    value: 'chess-knight',
  },
  {
    key: 'far fa-chess-knight-alt',
    value: 'chess-knight-alt',
  },
  {
    key: 'far fa-chess-pawn',
    value: 'chess-pawn',
  },
  {
    key: 'far fa-chess-pawn-alt',
    value: 'chess-pawn-alt',
  },
  {
    key: 'far fa-chess-queen',
    value: 'chess-queen',
  },
  {
    key: 'far fa-chess-queen-alt',
    value: 'chess-queen-alt',
  },
  {
    key: 'far fa-chess-rook',
    value: 'chess-rook',
  },
  {
    key: 'far fa-chess-rook-alt',
    value: 'chess-rook-alt',
  },
  {
    key: 'far fa-chevron-circle-down',
    value: 'chevron-circle-down',
  },
  {
    key: 'far fa-chevron-circle-left',
    value: 'chevron-circle-left',
  },
  {
    key: 'far fa-chevron-circle-right',
    value: 'chevron-circle-right',
  },
  {
    key: 'far fa-chevron-circle-up',
    value: 'chevron-circle-up',
  },
  {
    key: 'far fa-chevron-double-down',
    value: 'chevron-double-down',
  },
  {
    key: 'far fa-chevron-double-left',
    value: 'chevron-double-left',
  },
  {
    key: 'far fa-chevron-double-right',
    value: 'chevron-double-right',
  },
  {
    key: 'far fa-chevron-double-up',
    value: 'chevron-double-up',
  },
  {
    key: 'far fa-chevron-down',
    value: 'chevron-down',
  },
  {
    key: 'far fa-chevron-left',
    value: 'chevron-left',
  },
  {
    key: 'far fa-chevron-right',
    value: 'chevron-right',
  },
  {
    key: 'far fa-chevron-square-down',
    value: 'chevron-square-down',
  },
  {
    key: 'far fa-chevron-square-left',
    value: 'chevron-square-left',
  },
  {
    key: 'far fa-chevron-square-right',
    value: 'chevron-square-right',
  },
  {
    key: 'far fa-chevron-square-up',
    value: 'chevron-square-up',
  },
  {
    key: 'far fa-chevron-up',
    value: 'chevron-up',
  },
  {
    key: 'far fa-child',
    value: 'child',
  },
  {
    key: 'far fa-chimney',
    value: 'chimney',
  },
  {
    key: 'far fa-church',
    value: 'church',
  },
  {
    key: 'far fa-circle',
    value: 'circle',
  },
  {
    key: 'far fa-circle-notch',
    value: 'circle-notch',
  },
  {
    key: 'far fa-city',
    value: 'city',
  },
  {
    key: 'far fa-clarinet',
    value: 'clarinet',
  },
  {
    key: 'far fa-claw-marks',
    value: 'claw-marks',
  },
  {
    key: 'far fa-clinic-medical',
    value: 'clinic-medical',
  },
  {
    key: 'far fa-clipboard',
    value: 'clipboard',
  },
  {
    key: 'far fa-clipboard-check',
    value: 'clipboard-check',
  },
  {
    key: 'far fa-clipboard-list',
    value: 'clipboard-list',
  },
  {
    key: 'far fa-clipboard-list-check',
    value: 'clipboard-list-check',
  },
  {
    key: 'far fa-clipboard-prescription',
    value: 'clipboard-prescription',
  },
  {
    key: 'far fa-clipboard-user',
    value: 'clipboard-user',
  },
  {
    key: 'far fa-clock',
    value: 'clock',
  },
  {
    key: 'far fa-clone',
    value: 'clone',
  },
  {
    key: 'far fa-closed-captioning',
    value: 'closed-captioning',
  },
  {
    key: 'far fa-cloud',
    value: 'cloud',
  },
  {
    key: 'far fa-cloud-download',
    value: 'cloud-download',
  },
  {
    key: 'far fa-cloud-download-alt',
    value: 'cloud-download-alt',
  },
  {
    key: 'far fa-cloud-drizzle',
    value: 'cloud-drizzle',
  },
  {
    key: 'far fa-cloud-hail',
    value: 'cloud-hail',
  },
  {
    key: 'far fa-cloud-hail-mixed',
    value: 'cloud-hail-mixed',
  },
  {
    key: 'far fa-cloud-meatball',
    value: 'cloud-meatball',
  },
  {
    key: 'far fa-cloud-moon',
    value: 'cloud-moon',
  },
  {
    key: 'far fa-cloud-moon-rain',
    value: 'cloud-moon-rain',
  },
  {
    key: 'far fa-cloud-music',
    value: 'cloud-music',
  },
  {
    key: 'far fa-cloud-rain',
    value: 'cloud-rain',
  },
  {
    key: 'far fa-cloud-rainbow',
    value: 'cloud-rainbow',
  },
  {
    key: 'far fa-cloud-showers',
    value: 'cloud-showers',
  },
  {
    key: 'far fa-cloud-showers-heavy',
    value: 'cloud-showers-heavy',
  },
  {
    key: 'far fa-cloud-sleet',
    value: 'cloud-sleet',
  },
  {
    key: 'far fa-cloud-snow',
    value: 'cloud-snow',
  },
  {
    key: 'far fa-cloud-sun',
    value: 'cloud-sun',
  },
  {
    key: 'far fa-cloud-sun-rain',
    value: 'cloud-sun-rain',
  },
  {
    key: 'far fa-cloud-upload',
    value: 'cloud-upload',
  },
  {
    key: 'far fa-cloud-upload-alt',
    value: 'cloud-upload-alt',
  },
  {
    key: 'far fa-clouds',
    value: 'clouds',
  },
  {
    key: 'far fa-clouds-moon',
    value: 'clouds-moon',
  },
  {
    key: 'far fa-clouds-sun',
    value: 'clouds-sun',
  },
  {
    key: 'far fa-club',
    value: 'club',
  },
  {
    key: 'far fa-cocktail',
    value: 'cocktail',
  },
  {
    key: 'far fa-code',
    value: 'code',
  },
  {
    key: 'far fa-code-branch',
    value: 'code-branch',
  },
  {
    key: 'far fa-code-commit',
    value: 'code-commit',
  },
  {
    key: 'far fa-code-merge',
    value: 'code-merge',
  },
  {
    key: 'far fa-coffee',
    value: 'coffee',
  },
  {
    key: 'far fa-coffee-pot',
    value: 'coffee-pot',
  },
  {
    key: 'far fa-coffee-togo',
    value: 'coffee-togo',
  },
  {
    key: 'far fa-coffin',
    value: 'coffin',
  },
  {
    key: 'far fa-coffin-cross',
    value: 'coffin-cross',
  },
  {
    key: 'far fa-cog',
    value: 'cog',
  },
  {
    key: 'far fa-cogs',
    value: 'cogs',
  },
  {
    key: 'far fa-coin',
    value: 'coin',
  },
  {
    key: 'far fa-coins',
    value: 'coins',
  },
  {
    key: 'far fa-columns',
    value: 'columns',
  },
  {
    key: 'far fa-comet',
    value: 'comet',
  },
  {
    key: 'far fa-comment',
    value: 'comment',
  },
  {
    key: 'far fa-comment-alt',
    value: 'comment-alt',
  },
  {
    key: 'far fa-comment-alt-check',
    value: 'comment-alt-check',
  },
  {
    key: 'far fa-comment-alt-dollar',
    value: 'comment-alt-dollar',
  },
  {
    key: 'far fa-comment-alt-dots',
    value: 'comment-alt-dots',
  },
  {
    key: 'far fa-comment-alt-edit',
    value: 'comment-alt-edit',
  },
  {
    key: 'far fa-comment-alt-exclamation',
    value: 'comment-alt-exclamation',
  },
  {
    key: 'far fa-comment-alt-lines',
    value: 'comment-alt-lines',
  },
  {
    key: 'far fa-comment-alt-medical',
    value: 'comment-alt-medical',
  },
  {
    key: 'far fa-comment-alt-minus',
    value: 'comment-alt-minus',
  },
  {
    key: 'far fa-comment-alt-music',
    value: 'comment-alt-music',
  },
  {
    key: 'far fa-comment-alt-plus',
    value: 'comment-alt-plus',
  },
  {
    key: 'far fa-comment-alt-slash',
    value: 'comment-alt-slash',
  },
  {
    key: 'far fa-comment-alt-smile',
    value: 'comment-alt-smile',
  },
  {
    key: 'far fa-comment-alt-times',
    value: 'comment-alt-times',
  },
  {
    key: 'far fa-comment-check',
    value: 'comment-check',
  },
  {
    key: 'far fa-comment-dollar',
    value: 'comment-dollar',
  },
  {
    key: 'far fa-comment-dots',
    value: 'comment-dots',
  },
  {
    key: 'far fa-comment-edit',
    value: 'comment-edit',
  },
  {
    key: 'far fa-comment-exclamation',
    value: 'comment-exclamation',
  },
  {
    key: 'far fa-comment-lines',
    value: 'comment-lines',
  },
  {
    key: 'far fa-comment-medical',
    value: 'comment-medical',
  },
  {
    key: 'far fa-comment-minus',
    value: 'comment-minus',
  },
  {
    key: 'far fa-comment-music',
    value: 'comment-music',
  },
  {
    key: 'far fa-comment-plus',
    value: 'comment-plus',
  },
  {
    key: 'far fa-comment-slash',
    value: 'comment-slash',
  },
  {
    key: 'far fa-comment-smile',
    value: 'comment-smile',
  },
  {
    key: 'far fa-comment-times',
    value: 'comment-times',
  },
  {
    key: 'far fa-comments',
    value: 'comments',
  },
  {
    key: 'far fa-comments-alt',
    value: 'comments-alt',
  },
  {
    key: 'far fa-comments-alt-dollar',
    value: 'comments-alt-dollar',
  },
  {
    key: 'far fa-comments-dollar',
    value: 'comments-dollar',
  },
  {
    key: 'far fa-compact-disc',
    value: 'compact-disc',
  },
  {
    key: 'far fa-compass',
    value: 'compass',
  },
  {
    key: 'far fa-compass-slash',
    value: 'compass-slash',
  },
  {
    key: 'far fa-compress',
    value: 'compress',
  },
  {
    key: 'far fa-compress-alt',
    value: 'compress-alt',
  },
  {
    key: 'far fa-compress-arrows-alt',
    value: 'compress-arrows-alt',
  },
  {
    key: 'far fa-compress-wide',
    value: 'compress-wide',
  },
  {
    key: 'far fa-computer-classic',
    value: 'computer-classic',
  },
  {
    key: 'far fa-computer-speaker',
    value: 'computer-speaker',
  },
  {
    key: 'far fa-concierge-bell',
    value: 'concierge-bell',
  },
  {
    key: 'far fa-construction',
    value: 'construction',
  },
  {
    key: 'far fa-container-storage',
    value: 'container-storage',
  },
  {
    key: 'far fa-conveyor-belt',
    value: 'conveyor-belt',
  },
  {
    key: 'far fa-conveyor-belt-alt',
    value: 'conveyor-belt-alt',
  },
  {
    key: 'far fa-cookie',
    value: 'cookie',
  },
  {
    key: 'far fa-cookie-bite',
    value: 'cookie-bite',
  },
  {
    key: 'far fa-copy',
    value: 'copy',
  },
  {
    key: 'far fa-copyright',
    value: 'copyright',
  },
  {
    key: 'far fa-corn',
    value: 'corn',
  },
  {
    key: 'far fa-couch',
    value: 'couch',
  },
  {
    key: 'far fa-cow',
    value: 'cow',
  },
  {
    key: 'far fa-cowbell',
    value: 'cowbell',
  },
  {
    key: 'far fa-cowbell-more',
    value: 'cowbell-more',
  },
  {
    key: 'far fa-credit-card',
    value: 'credit-card',
  },
  {
    key: 'far fa-credit-card-blank',
    value: 'credit-card-blank',
  },
  {
    key: 'far fa-credit-card-front',
    value: 'credit-card-front',
  },
  {
    key: 'far fa-cricket',
    value: 'cricket',
  },
  {
    key: 'far fa-croissant',
    value: 'croissant',
  },
  {
    key: 'far fa-crop',
    value: 'crop',
  },
  {
    key: 'far fa-crop-alt',
    value: 'crop-alt',
  },
  {
    key: 'far fa-cross',
    value: 'cross',
  },
  {
    key: 'far fa-crosshairs',
    value: 'crosshairs',
  },
  {
    key: 'far fa-crow',
    value: 'crow',
  },
  {
    key: 'far fa-crown',
    value: 'crown',
  },
  {
    key: 'far fa-crutch',
    value: 'crutch',
  },
  {
    key: 'far fa-crutches',
    value: 'crutches',
  },
  {
    key: 'far fa-cube',
    value: 'cube',
  },
  {
    key: 'far fa-cubes',
    value: 'cubes',
  },
  {
    key: 'far fa-curling',
    value: 'curling',
  },
  {
    key: 'far fa-cut',
    value: 'cut',
  },
  {
    key: 'far fa-dagger',
    value: 'dagger',
  },
  {
    key: 'far fa-database',
    value: 'database',
  },
  {
    key: 'far fa-deaf',
    value: 'deaf',
  },
  {
    key: 'far fa-debug',
    value: 'debug',
  },
  {
    key: 'far fa-deer',
    value: 'deer',
  },
  {
    key: 'far fa-deer-rudolph',
    value: 'deer-rudolph',
  },
  {
    key: 'far fa-democrat',
    value: 'democrat',
  },
  {
    key: 'far fa-desktop',
    value: 'desktop',
  },
  {
    key: 'far fa-desktop-alt',
    value: 'desktop-alt',
  },
  {
    key: 'far fa-dewpoint',
    value: 'dewpoint',
  },
  {
    key: 'far fa-dharmachakra',
    value: 'dharmachakra',
  },
  {
    key: 'far fa-diagnoses',
    value: 'diagnoses',
  },
  {
    key: 'far fa-diamond',
    value: 'diamond',
  },
  {
    key: 'far fa-dice',
    value: 'dice',
  },
  {
    key: 'far fa-dice-d10',
    value: 'dice-d10',
  },
  {
    key: 'far fa-dice-d12',
    value: 'dice-d12',
  },
  {
    key: 'far fa-dice-d20',
    value: 'dice-d20',
  },
  {
    key: 'far fa-dice-d4',
    value: 'dice-d4',
  },
  {
    key: 'far fa-dice-d6',
    value: 'dice-d6',
  },
  {
    key: 'far fa-dice-d8',
    value: 'dice-d8',
  },
  {
    key: 'far fa-dice-five',
    value: 'dice-five',
  },
  {
    key: 'far fa-dice-four',
    value: 'dice-four',
  },
  {
    key: 'far fa-dice-one',
    value: 'dice-one',
  },
  {
    key: 'far fa-dice-six',
    value: 'dice-six',
  },
  {
    key: 'far fa-dice-three',
    value: 'dice-three',
  },
  {
    key: 'far fa-dice-two',
    value: 'dice-two',
  },
  {
    key: 'far fa-digging',
    value: 'digging',
  },
  {
    key: 'far fa-digital-tachograph',
    value: 'digital-tachograph',
  },
  {
    key: 'far fa-diploma',
    value: 'diploma',
  },
  {
    key: 'far fa-directions',
    value: 'directions',
  },
  {
    key: 'far fa-disc-drive',
    value: 'disc-drive',
  },
  {
    key: 'far fa-disease',
    value: 'disease',
  },
  {
    key: 'far fa-divide',
    value: 'divide',
  },
  {
    key: 'far fa-dizzy',
    value: 'dizzy',
  },
  {
    key: 'far fa-dna',
    value: 'dna',
  },
  {
    key: 'far fa-do-not-enter',
    value: 'do-not-enter',
  },
  {
    key: 'far fa-dog',
    value: 'dog',
  },
  {
    key: 'far fa-dog-leashed',
    value: 'dog-leashed',
  },
  {
    key: 'far fa-dollar-sign',
    value: 'dollar-sign',
  },
  {
    key: 'far fa-dolly',
    value: 'dolly',
  },
  {
    key: 'far fa-dolly-empty',
    value: 'dolly-empty',
  },
  {
    key: 'far fa-dolly-flatbed',
    value: 'dolly-flatbed',
  },
  {
    key: 'far fa-dolly-flatbed-alt',
    value: 'dolly-flatbed-alt',
  },
  {
    key: 'far fa-dolly-flatbed-empty',
    value: 'dolly-flatbed-empty',
  },
  {
    key: 'far fa-donate',
    value: 'donate',
  },
  {
    key: 'far fa-door-closed',
    value: 'door-closed',
  },
  {
    key: 'far fa-door-open',
    value: 'door-open',
  },
  {
    key: 'far fa-dot-circle',
    value: 'dot-circle',
  },
  {
    key: 'far fa-dove',
    value: 'dove',
  },
  {
    key: 'far fa-download',
    value: 'download',
  },
  {
    key: 'far fa-drafting-compass',
    value: 'drafting-compass',
  },
  {
    key: 'far fa-dragon',
    value: 'dragon',
  },
  {
    key: 'far fa-draw-circle',
    value: 'draw-circle',
  },
  {
    key: 'far fa-draw-polygon',
    value: 'draw-polygon',
  },
  {
    key: 'far fa-draw-square',
    value: 'draw-square',
  },
  {
    key: 'far fa-dreidel',
    value: 'dreidel',
  },
  {
    key: 'far fa-drone',
    value: 'drone',
  },
  {
    key: 'far fa-drone-alt',
    value: 'drone-alt',
  },
  {
    key: 'far fa-drum',
    value: 'drum',
  },
  {
    key: 'far fa-drum-steelpan',
    value: 'drum-steelpan',
  },
  {
    key: 'far fa-drumstick',
    value: 'drumstick',
  },
  {
    key: 'far fa-drumstick-bite',
    value: 'drumstick-bite',
  },
  {
    key: 'far fa-dryer',
    value: 'dryer',
  },
  {
    key: 'far fa-dryer-alt',
    value: 'dryer-alt',
  },
  {
    key: 'far fa-duck',
    value: 'duck',
  },
  {
    key: 'far fa-dumbbell',
    value: 'dumbbell',
  },
  {
    key: 'far fa-dumpster',
    value: 'dumpster',
  },
  {
    key: 'far fa-dumpster-fire',
    value: 'dumpster-fire',
  },
  {
    key: 'far fa-dungeon',
    value: 'dungeon',
  },
  {
    key: 'far fa-ear',
    value: 'ear',
  },
  {
    key: 'far fa-ear-muffs',
    value: 'ear-muffs',
  },
  {
    key: 'far fa-eclipse',
    value: 'eclipse',
  },
  {
    key: 'far fa-eclipse-alt',
    value: 'eclipse-alt',
  },
  {
    key: 'far fa-edit',
    value: 'edit',
  },
  {
    key: 'far fa-egg',
    value: 'egg',
  },
  {
    key: 'far fa-egg-fried',
    value: 'egg-fried',
  },
  {
    key: 'far fa-eject',
    value: 'eject',
  },
  {
    key: 'far fa-elephant',
    value: 'elephant',
  },
  {
    key: 'far fa-ellipsis-h',
    value: 'ellipsis-h',
  },
  {
    key: 'far fa-ellipsis-h-alt',
    value: 'ellipsis-h-alt',
  },
  {
    key: 'far fa-ellipsis-v',
    value: 'ellipsis-v',
  },
  {
    key: 'far fa-ellipsis-v-alt',
    value: 'ellipsis-v-alt',
  },
  {
    key: 'far fa-empty-set',
    value: 'empty-set',
  },
  {
    key: 'far fa-engine-warning',
    value: 'engine-warning',
  },
  {
    key: 'far fa-envelope',
    value: 'envelope',
  },
  {
    key: 'far fa-envelope-open',
    value: 'envelope-open',
  },
  {
    key: 'far fa-envelope-open-dollar',
    value: 'envelope-open-dollar',
  },
  {
    key: 'far fa-envelope-open-text',
    value: 'envelope-open-text',
  },
  {
    key: 'far fa-envelope-square',
    value: 'envelope-square',
  },
  {
    key: 'far fa-equals',
    value: 'equals',
  },
  {
    key: 'far fa-eraser',
    value: 'eraser',
  },
  {
    key: 'far fa-ethernet',
    value: 'ethernet',
  },
  {
    key: 'far fa-euro-sign',
    value: 'euro-sign',
  },
  {
    key: 'far fa-exchange',
    value: 'exchange',
  },
  {
    key: 'far fa-exchange-alt',
    value: 'exchange-alt',
  },
  {
    key: 'far fa-exclamation',
    value: 'exclamation',
  },
  {
    key: 'far fa-exclamation-circle',
    value: 'exclamation-circle',
  },
  {
    key: 'far fa-exclamation-square',
    value: 'exclamation-square',
  },
  {
    key: 'far fa-exclamation-triangle',
    value: 'exclamation-triangle',
  },
  {
    key: 'far fa-expand',
    value: 'expand',
  },
  {
    key: 'far fa-expand-alt',
    value: 'expand-alt',
  },
  {
    key: 'far fa-expand-arrows',
    value: 'expand-arrows',
  },
  {
    key: 'far fa-expand-arrows-alt',
    value: 'expand-arrows-alt',
  },
  {
    key: 'far fa-expand-wide',
    value: 'expand-wide',
  },
  {
    key: 'far fa-external-link',
    value: 'external-link',
  },
  {
    key: 'far fa-external-link-alt',
    value: 'external-link-alt',
  },
  {
    key: 'far fa-external-link-square',
    value: 'external-link-square',
  },
  {
    key: 'far fa-external-link-square-alt',
    value: 'external-link-square-alt',
  },
  {
    key: 'far fa-eye',
    value: 'eye',
  },
  {
    key: 'far fa-eye-dropper',
    value: 'eye-dropper',
  },
  {
    key: 'far fa-eye-evil',
    value: 'eye-evil',
  },
  {
    key: 'far fa-eye-slash',
    value: 'eye-slash',
  },
  {
    key: 'far fa-fan',
    value: 'fan',
  },
  {
    key: 'far fa-fan-table',
    value: 'fan-table',
  },
  {
    key: 'far fa-farm',
    value: 'farm',
  },
  {
    key: 'far fa-fast-backward',
    value: 'fast-backward',
  },
  {
    key: 'far fa-fast-forward',
    value: 'fast-forward',
  },
  {
    key: 'far fa-faucet',
    value: 'faucet',
  },
  {
    key: 'far fa-faucet-drip',
    value: 'faucet-drip',
  },
  {
    key: 'far fa-fax',
    value: 'fax',
  },
  {
    key: 'far fa-feather',
    value: 'feather',
  },
  {
    key: 'far fa-feather-alt',
    value: 'feather-alt',
  },
  {
    key: 'far fa-female',
    value: 'female',
  },
  {
    key: 'far fa-field-hockey',
    value: 'field-hockey',
  },
  {
    key: 'far fa-fighter-jet',
    value: 'fighter-jet',
  },
  {
    key: 'far fa-file',
    value: 'file',
  },
  {
    key: 'far fa-file-alt',
    value: 'file-alt',
  },
  {
    key: 'far fa-file-archive',
    value: 'file-archive',
  },
  {
    key: 'far fa-file-audio',
    value: 'file-audio',
  },
  {
    key: 'far fa-file-certificate',
    value: 'file-certificate',
  },
  {
    key: 'far fa-file-chart-line',
    value: 'file-chart-line',
  },
  {
    key: 'far fa-file-chart-pie',
    value: 'file-chart-pie',
  },
  {
    key: 'far fa-file-check',
    value: 'file-check',
  },
  {
    key: 'far fa-file-code',
    value: 'file-code',
  },
  {
    key: 'far fa-file-contract',
    value: 'file-contract',
  },
  {
    key: 'far fa-file-csv',
    value: 'file-csv',
  },
  {
    key: 'far fa-file-download',
    value: 'file-download',
  },
  {
    key: 'far fa-file-edit',
    value: 'file-edit',
  },
  {
    key: 'far fa-file-excel',
    value: 'file-excel',
  },
  {
    key: 'far fa-file-exclamation',
    value: 'file-exclamation',
  },
  {
    key: 'far fa-file-export',
    value: 'file-export',
  },
  {
    key: 'far fa-file-image',
    value: 'file-image',
  },
  {
    key: 'far fa-file-import',
    value: 'file-import',
  },
  {
    key: 'far fa-file-invoice',
    value: 'file-invoice',
  },
  {
    key: 'far fa-file-invoice-dollar',
    value: 'file-invoice-dollar',
  },
  {
    key: 'far fa-file-medical',
    value: 'file-medical',
  },
  {
    key: 'far fa-file-medical-alt',
    value: 'file-medical-alt',
  },
  {
    key: 'far fa-file-minus',
    value: 'file-minus',
  },
  {
    key: 'far fa-file-music',
    value: 'file-music',
  },
  {
    key: 'far fa-file-pdf',
    value: 'file-pdf',
  },
  {
    key: 'far fa-file-plus',
    value: 'file-plus',
  },
  {
    key: 'far fa-file-powerpoint',
    value: 'file-powerpoint',
  },
  {
    key: 'far fa-file-prescription',
    value: 'file-prescription',
  },
  {
    key: 'far fa-file-search',
    value: 'file-search',
  },
  {
    key: 'far fa-file-signature',
    value: 'file-signature',
  },
  {
    key: 'far fa-file-spreadsheet',
    value: 'file-spreadsheet',
  },
  {
    key: 'far fa-file-times',
    value: 'file-times',
  },
  {
    key: 'far fa-file-upload',
    value: 'file-upload',
  },
  {
    key: 'far fa-file-user',
    value: 'file-user',
  },
  {
    key: 'far fa-file-video',
    value: 'file-video',
  },
  {
    key: 'far fa-file-word',
    value: 'file-word',
  },
  {
    key: 'far fa-files-medical',
    value: 'files-medical',
  },
  {
    key: 'far fa-fill',
    value: 'fill',
  },
  {
    key: 'far fa-fill-drip',
    value: 'fill-drip',
  },
  {
    key: 'far fa-film',
    value: 'film',
  },
  {
    key: 'far fa-film-alt',
    value: 'film-alt',
  },
  {
    key: 'far fa-film-canister',
    value: 'film-canister',
  },
  {
    key: 'far fa-filter',
    value: 'filter',
  },
  {
    key: 'far fa-fingerprint',
    value: 'fingerprint',
  },
  {
    key: 'far fa-fire',
    value: 'fire',
  },
  {
    key: 'far fa-fire-alt',
    value: 'fire-alt',
  },
  {
    key: 'far fa-fire-extinguisher',
    value: 'fire-extinguisher',
  },
  {
    key: 'far fa-fire-smoke',
    value: 'fire-smoke',
  },
  {
    key: 'far fa-fireplace',
    value: 'fireplace',
  },
  {
    key: 'far fa-first-aid',
    value: 'first-aid',
  },
  {
    key: 'far fa-fish',
    value: 'fish',
  },
  {
    key: 'far fa-fish-cooked',
    value: 'fish-cooked',
  },
  {
    key: 'far fa-fist-raised',
    value: 'fist-raised',
  },
  {
    key: 'far fa-flag',
    value: 'flag',
  },
  {
    key: 'far fa-flag-alt',
    value: 'flag-alt',
  },
  {
    key: 'far fa-flag-checkered',
    value: 'flag-checkered',
  },
  {
    key: 'far fa-flag-usa',
    value: 'flag-usa',
  },
  {
    key: 'far fa-flame',
    value: 'flame',
  },
  {
    key: 'far fa-flashlight',
    value: 'flashlight',
  },
  {
    key: 'far fa-flask',
    value: 'flask',
  },
  {
    key: 'far fa-flask-poison',
    value: 'flask-poison',
  },
  {
    key: 'far fa-flask-potion',
    value: 'flask-potion',
  },
  {
    key: 'far fa-flower',
    value: 'flower',
  },
  {
    key: 'far fa-flower-daffodil',
    value: 'flower-daffodil',
  },
  {
    key: 'far fa-flower-tulip',
    value: 'flower-tulip',
  },
  {
    key: 'far fa-flushed',
    value: 'flushed',
  },
  {
    key: 'far fa-flute',
    value: 'flute',
  },
  {
    key: 'far fa-flux-capacitor',
    value: 'flux-capacitor',
  },
  {
    key: 'far fa-fog',
    value: 'fog',
  },
  {
    key: 'far fa-folder',
    value: 'folder',
  },
  {
    key: 'far fa-folder-download',
    value: 'folder-download',
  },
  {
    key: 'far fa-folder-minus',
    value: 'folder-minus',
  },
  {
    key: 'far fa-folder-open',
    value: 'folder-open',
  },
  {
    key: 'far fa-folder-plus',
    value: 'folder-plus',
  },
  {
    key: 'far fa-folder-times',
    value: 'folder-times',
  },
  {
    key: 'far fa-folder-tree',
    value: 'folder-tree',
  },
  {
    key: 'far fa-folder-upload',
    value: 'folder-upload',
  },
  {
    key: 'far fa-folders',
    value: 'folders',
  },
  {
    key: 'far fa-font',
    value: 'font',
  },
  {
    key: 'far fa-font-case',
    value: 'font-case',
  },
  {
    key: 'far fa-football-ball',
    value: 'football-ball',
  },
  {
    key: 'far fa-football-helmet',
    value: 'football-helmet',
  },
  {
    key: 'far fa-forklift',
    value: 'forklift',
  },
  {
    key: 'far fa-forward',
    value: 'forward',
  },
  {
    key: 'far fa-fragile',
    value: 'fragile',
  },
  {
    key: 'far fa-french-fries',
    value: 'french-fries',
  },
  {
    key: 'far fa-frog',
    value: 'frog',
  },
  {
    key: 'far fa-frosty-head',
    value: 'frosty-head',
  },
  {
    key: 'far fa-frown',
    value: 'frown',
  },
  {
    key: 'far fa-frown-open',
    value: 'frown-open',
  },
  {
    key: 'far fa-function',
    value: 'function',
  },
  {
    key: 'far fa-funnel-dollar',
    value: 'funnel-dollar',
  },
  {
    key: 'far fa-futbol',
    value: 'futbol',
  },
  {
    key: 'far fa-galaxy',
    value: 'galaxy',
  },
  {
    key: 'far fa-game-board',
    value: 'game-board',
  },
  {
    key: 'far fa-game-board-alt',
    value: 'game-board-alt',
  },
  {
    key: 'far fa-game-console-handheld',
    value: 'game-console-handheld',
  },
  {
    key: 'far fa-gamepad',
    value: 'gamepad',
  },
  {
    key: 'far fa-gamepad-alt',
    value: 'gamepad-alt',
  },
  {
    key: 'far fa-garage',
    value: 'garage',
  },
  {
    key: 'far fa-garage-car',
    value: 'garage-car',
  },
  {
    key: 'far fa-garage-open',
    value: 'garage-open',
  },
  {
    key: 'far fa-gas-pump',
    value: 'gas-pump',
  },
  {
    key: 'far fa-gas-pump-slash',
    value: 'gas-pump-slash',
  },
  {
    key: 'far fa-gavel',
    value: 'gavel',
  },
  {
    key: 'far fa-gem',
    value: 'gem',
  },
  {
    key: 'far fa-genderless',
    value: 'genderless',
  },
  {
    key: 'far fa-ghost',
    value: 'ghost',
  },
  {
    key: 'far fa-gift',
    value: 'gift',
  },
  {
    key: 'far fa-gift-card',
    value: 'gift-card',
  },
  {
    key: 'far fa-gifts',
    value: 'gifts',
  },
  {
    key: 'far fa-gingerbread-man',
    value: 'gingerbread-man',
  },
  {
    key: 'far fa-glass',
    value: 'glass',
  },
  {
    key: 'far fa-glass-champagne',
    value: 'glass-champagne',
  },
  {
    key: 'far fa-glass-cheers',
    value: 'glass-cheers',
  },
  {
    key: 'far fa-glass-citrus',
    value: 'glass-citrus',
  },
  {
    key: 'far fa-glass-martini',
    value: 'glass-martini',
  },
  {
    key: 'far fa-glass-martini-alt',
    value: 'glass-martini-alt',
  },
  {
    key: 'far fa-glass-whiskey',
    value: 'glass-whiskey',
  },
  {
    key: 'far fa-glass-whiskey-rocks',
    value: 'glass-whiskey-rocks',
  },
  {
    key: 'far fa-glasses',
    value: 'glasses',
  },
  {
    key: 'far fa-glasses-alt',
    value: 'glasses-alt',
  },
  {
    key: 'far fa-globe',
    value: 'globe',
  },
  {
    key: 'far fa-globe-africa',
    value: 'globe-africa',
  },
  {
    key: 'far fa-globe-americas',
    value: 'globe-americas',
  },
  {
    key: 'far fa-globe-asia',
    value: 'globe-asia',
  },
  {
    key: 'far fa-globe-europe',
    value: 'globe-europe',
  },
  {
    key: 'far fa-globe-snow',
    value: 'globe-snow',
  },
  {
    key: 'far fa-globe-stand',
    value: 'globe-stand',
  },
  {
    key: 'far fa-golf-ball',
    value: 'golf-ball',
  },
  {
    key: 'far fa-golf-club',
    value: 'golf-club',
  },
  {
    key: 'far fa-gopuram',
    value: 'gopuram',
  },
  {
    key: 'far fa-graduation-cap',
    value: 'graduation-cap',
  },
  {
    key: 'far fa-gramophone',
    value: 'gramophone',
  },
  {
    key: 'far fa-greater-than',
    value: 'greater-than',
  },
  {
    key: 'far fa-greater-than-equal',
    value: 'greater-than-equal',
  },
  {
    key: 'far fa-grimace',
    value: 'grimace',
  },
  {
    key: 'far fa-grin',
    value: 'grin',
  },
  {
    key: 'far fa-grin-alt',
    value: 'grin-alt',
  },
  {
    key: 'far fa-grin-beam',
    value: 'grin-beam',
  },
  {
    key: 'far fa-grin-beam-sweat',
    value: 'grin-beam-sweat',
  },
  {
    key: 'far fa-grin-hearts',
    value: 'grin-hearts',
  },
  {
    key: 'far fa-grin-squint',
    value: 'grin-squint',
  },
  {
    key: 'far fa-grin-squint-tears',
    value: 'grin-squint-tears',
  },
  {
    key: 'far fa-grin-stars',
    value: 'grin-stars',
  },
  {
    key: 'far fa-grin-tears',
    value: 'grin-tears',
  },
  {
    key: 'far fa-grin-tongue',
    value: 'grin-tongue',
  },
  {
    key: 'far fa-grin-tongue-squint',
    value: 'grin-tongue-squint',
  },
  {
    key: 'far fa-grin-tongue-wink',
    value: 'grin-tongue-wink',
  },
  {
    key: 'far fa-grin-wink',
    value: 'grin-wink',
  },
  {
    key: 'far fa-grip-horizontal',
    value: 'grip-horizontal',
  },
  {
    key: 'far fa-grip-lines',
    value: 'grip-lines',
  },
  {
    key: 'far fa-grip-lines-vertical',
    value: 'grip-lines-vertical',
  },
  {
    key: 'far fa-grip-vertical',
    value: 'grip-vertical',
  },
  {
    key: 'far fa-guitar',
    value: 'guitar',
  },
  {
    key: 'far fa-guitar-electric',
    value: 'guitar-electric',
  },
  {
    key: 'far fa-guitars',
    value: 'guitars',
  },
  {
    key: 'far fa-h-square',
    value: 'h-square',
  },
  {
    key: 'far fa-h1',
    value: 'h1',
  },
  {
    key: 'far fa-h2',
    value: 'h2',
  },
  {
    key: 'far fa-h3',
    value: 'h3',
  },
  {
    key: 'far fa-h4',
    value: 'h4',
  },
  {
    key: 'far fa-hamburger',
    value: 'hamburger',
  },
  {
    key: 'far fa-hammer',
    value: 'hammer',
  },
  {
    key: 'far fa-hammer-war',
    value: 'hammer-war',
  },
  {
    key: 'far fa-hamsa',
    value: 'hamsa',
  },
  {
    key: 'far fa-hand-heart',
    value: 'hand-heart',
  },
  {
    key: 'far fa-hand-holding',
    value: 'hand-holding',
  },
  {
    key: 'far fa-hand-holding-box',
    value: 'hand-holding-box',
  },
  {
    key: 'far fa-hand-holding-heart',
    value: 'hand-holding-heart',
  },
  {
    key: 'far fa-hand-holding-magic',
    value: 'hand-holding-magic',
  },
  {
    key: 'far fa-hand-holding-medical',
    value: 'hand-holding-medical',
  },
  {
    key: 'far fa-hand-holding-seedling',
    value: 'hand-holding-seedling',
  },
  {
    key: 'far fa-hand-holding-usd',
    value: 'hand-holding-usd',
  },
  {
    key: 'far fa-hand-holding-water',
    value: 'hand-holding-water',
  },
  {
    key: 'far fa-hand-lizard',
    value: 'hand-lizard',
  },
  {
    key: 'far fa-hand-middle-finger',
    value: 'hand-middle-finger',
  },
  {
    key: 'far fa-hand-paper',
    value: 'hand-paper',
  },
  {
    key: 'far fa-hand-peace',
    value: 'hand-peace',
  },
  {
    key: 'far fa-hand-point-down',
    value: 'hand-point-down',
  },
  {
    key: 'far fa-hand-point-left',
    value: 'hand-point-left',
  },
  {
    key: 'far fa-hand-point-right',
    value: 'hand-point-right',
  },
  {
    key: 'far fa-hand-point-up',
    value: 'hand-point-up',
  },
  {
    key: 'far fa-hand-pointer',
    value: 'hand-pointer',
  },
  {
    key: 'far fa-hand-receiving',
    value: 'hand-receiving',
  },
  {
    key: 'far fa-hand-rock',
    value: 'hand-rock',
  },
  {
    key: 'far fa-hand-scissors',
    value: 'hand-scissors',
  },
  {
    key: 'far fa-hand-sparkles',
    value: 'hand-sparkles',
  },
  {
    key: 'far fa-hand-spock',
    value: 'hand-spock',
  },
  {
    key: 'far fa-hands',
    value: 'hands',
  },
  {
    key: 'far fa-hands-heart',
    value: 'hands-heart',
  },
  {
    key: 'far fa-hands-helping',
    value: 'hands-helping',
  },
  {
    key: 'far fa-hands-usd',
    value: 'hands-usd',
  },
  {
    key: 'far fa-hands-wash',
    value: 'hands-wash',
  },
  {
    key: 'far fa-handshake',
    value: 'handshake',
  },
  {
    key: 'far fa-handshake-alt',
    value: 'handshake-alt',
  },
  {
    key: 'far fa-handshake-alt-slash',
    value: 'handshake-alt-slash',
  },
  {
    key: 'far fa-handshake-slash',
    value: 'handshake-slash',
  },
  {
    key: 'far fa-hanukiah',
    value: 'hanukiah',
  },
  {
    key: 'far fa-hard-hat',
    value: 'hard-hat',
  },
  {
    key: 'far fa-hashtag',
    value: 'hashtag',
  },
  {
    key: 'far fa-hat-chef',
    value: 'hat-chef',
  },
  {
    key: 'far fa-hat-cowboy',
    value: 'hat-cowboy',
  },
  {
    key: 'far fa-hat-cowboy-side',
    value: 'hat-cowboy-side',
  },
  {
    key: 'far fa-hat-santa',
    value: 'hat-santa',
  },
  {
    key: 'far fa-hat-winter',
    value: 'hat-winter',
  },
  {
    key: 'far fa-hat-witch',
    value: 'hat-witch',
  },
  {
    key: 'far fa-hat-wizard',
    value: 'hat-wizard',
  },
  {
    key: 'far fa-hdd',
    value: 'hdd',
  },
  {
    key: 'far fa-head-side',
    value: 'head-side',
  },
  {
    key: 'far fa-head-side-brain',
    value: 'head-side-brain',
  },
  {
    key: 'far fa-head-side-cough',
    value: 'head-side-cough',
  },
  {
    key: 'far fa-head-side-cough-slash',
    value: 'head-side-cough-slash',
  },
  {
    key: 'far fa-head-side-headphones',
    value: 'head-side-headphones',
  },
  {
    key: 'far fa-head-side-mask',
    value: 'head-side-mask',
  },
  {
    key: 'far fa-head-side-medical',
    value: 'head-side-medical',
  },
  {
    key: 'far fa-head-side-virus',
    value: 'head-side-virus',
  },
  {
    key: 'far fa-head-vr',
    value: 'head-vr',
  },
  {
    key: 'far fa-heading',
    value: 'heading',
  },
  {
    key: 'far fa-headphones',
    value: 'headphones',
  },
  {
    key: 'far fa-headphones-alt',
    value: 'headphones-alt',
  },
  {
    key: 'far fa-headset',
    value: 'headset',
  },
  {
    key: 'far fa-heart',
    value: 'heart',
  },
  {
    key: 'far fa-heart-broken',
    value: 'heart-broken',
  },
  {
    key: 'far fa-heart-circle',
    value: 'heart-circle',
  },
  {
    key: 'far fa-heart-rate',
    value: 'heart-rate',
  },
  {
    key: 'far fa-heart-square',
    value: 'heart-square',
  },
  {
    key: 'far fa-heartbeat',
    value: 'heartbeat',
  },
  {
    key: 'far fa-heat',
    value: 'heat',
  },
  {
    key: 'far fa-helicopter',
    value: 'helicopter',
  },
  {
    key: 'far fa-helmet-battle',
    value: 'helmet-battle',
  },
  {
    key: 'far fa-hexagon',
    value: 'hexagon',
  },
  {
    key: 'far fa-highlighter',
    value: 'highlighter',
  },
  {
    key: 'far fa-hiking',
    value: 'hiking',
  },
  {
    key: 'far fa-hippo',
    value: 'hippo',
  },
  {
    key: 'far fa-history',
    value: 'history',
  },
  {
    key: 'far fa-hockey-mask',
    value: 'hockey-mask',
  },
  {
    key: 'far fa-hockey-puck',
    value: 'hockey-puck',
  },
  {
    key: 'far fa-hockey-sticks',
    value: 'hockey-sticks',
  },
  {
    key: 'far fa-holly-berry',
    value: 'holly-berry',
  },
  {
    key: 'far fa-home',
    value: 'home',
  },
  {
    key: 'far fa-home-alt',
    value: 'home-alt',
  },
  {
    key: 'far fa-home-heart',
    value: 'home-heart',
  },
  {
    key: 'far fa-home-lg',
    value: 'home-lg',
  },
  {
    key: 'far fa-home-lg-alt',
    value: 'home-lg-alt',
  },
  {
    key: 'far fa-hood-cloak',
    value: 'hood-cloak',
  },
  {
    key: 'far fa-horizontal-rule',
    value: 'horizontal-rule',
  },
  {
    key: 'far fa-horse',
    value: 'horse',
  },
  {
    key: 'far fa-horse-head',
    value: 'horse-head',
  },
  {
    key: 'far fa-horse-saddle',
    value: 'horse-saddle',
  },
  {
    key: 'far fa-hospital',
    value: 'hospital',
  },
  {
    key: 'far fa-hospital-alt',
    value: 'hospital-alt',
  },
  {
    key: 'far fa-hospital-symbol',
    value: 'hospital-symbol',
  },
  {
    key: 'far fa-hospital-user',
    value: 'hospital-user',
  },
  {
    key: 'far fa-hospitals',
    value: 'hospitals',
  },
  {
    key: 'far fa-hot-tub',
    value: 'hot-tub',
  },
  {
    key: 'far fa-hotdog',
    value: 'hotdog',
  },
  {
    key: 'far fa-hotel',
    value: 'hotel',
  },
  {
    key: 'far fa-hourglass',
    value: 'hourglass',
  },
  {
    key: 'far fa-hourglass-end',
    value: 'hourglass-end',
  },
  {
    key: 'far fa-hourglass-half',
    value: 'hourglass-half',
  },
  {
    key: 'far fa-hourglass-start',
    value: 'hourglass-start',
  },
  {
    key: 'far fa-house',
    value: 'house',
  },
  {
    key: 'far fa-house-damage',
    value: 'house-damage',
  },
  {
    key: 'far fa-house-day',
    value: 'house-day',
  },
  {
    key: 'far fa-house-flood',
    value: 'house-flood',
  },
  {
    key: 'far fa-house-leave',
    value: 'house-leave',
  },
  {
    key: 'far fa-house-night',
    value: 'house-night',
  },
  {
    key: 'far fa-house-return',
    value: 'house-return',
  },
  {
    key: 'far fa-house-signal',
    value: 'house-signal',
  },
  {
    key: 'far fa-house-user',
    value: 'house-user',
  },
  {
    key: 'far fa-hryvnia',
    value: 'hryvnia',
  },
  {
    key: 'far fa-humidity',
    value: 'humidity',
  },
  {
    key: 'far fa-hurricane',
    value: 'hurricane',
  },
  {
    key: 'far fa-i-cursor',
    value: 'i-cursor',
  },
  {
    key: 'far fa-ice-cream',
    value: 'ice-cream',
  },
  {
    key: 'far fa-ice-skate',
    value: 'ice-skate',
  },
  {
    key: 'far fa-icicles',
    value: 'icicles',
  },
  {
    key: 'far fa-icons',
    value: 'icons',
  },
  {
    key: 'far fa-icons-alt',
    value: 'icons-alt',
  },
  {
    key: 'far fa-id-badge',
    value: 'id-badge',
  },
  {
    key: 'far fa-id-card',
    value: 'id-card',
  },
  {
    key: 'far fa-id-card-alt',
    value: 'id-card-alt',
  },
  {
    key: 'far fa-igloo',
    value: 'igloo',
  },
  {
    key: 'far fa-image',
    value: 'image',
  },
  {
    key: 'far fa-image-polaroid',
    value: 'image-polaroid',
  },
  {
    key: 'far fa-images',
    value: 'images',
  },
  {
    key: 'far fa-inbox',
    value: 'inbox',
  },
  {
    key: 'far fa-inbox-in',
    value: 'inbox-in',
  },
  {
    key: 'far fa-inbox-out',
    value: 'inbox-out',
  },
  {
    key: 'far fa-indent',
    value: 'indent',
  },
  {
    key: 'far fa-industry',
    value: 'industry',
  },
  {
    key: 'far fa-industry-alt',
    value: 'industry-alt',
  },
  {
    key: 'far fa-infinity',
    value: 'infinity',
  },
  {
    key: 'far fa-info',
    value: 'info',
  },
  {
    key: 'far fa-info-circle',
    value: 'info-circle',
  },
  {
    key: 'far fa-info-square',
    value: 'info-square',
  },
  {
    key: 'far fa-inhaler',
    value: 'inhaler',
  },
  {
    key: 'far fa-integral',
    value: 'integral',
  },
  {
    key: 'far fa-intersection',
    value: 'intersection',
  },
  {
    key: 'far fa-inventory',
    value: 'inventory',
  },
  {
    key: 'far fa-island-tropical',
    value: 'island-tropical',
  },
  {
    key: 'far fa-italic',
    value: 'italic',
  },
  {
    key: 'far fa-jack-o-lantern',
    value: 'jack-o-lantern',
  },
  {
    key: 'far fa-jedi',
    value: 'jedi',
  },
  {
    key: 'far fa-joint',
    value: 'joint',
  },
  {
    key: 'far fa-journal-whills',
    value: 'journal-whills',
  },
  {
    key: 'far fa-joystick',
    value: 'joystick',
  },
  {
    key: 'far fa-jug',
    value: 'jug',
  },
  {
    key: 'far fa-kaaba',
    value: 'kaaba',
  },
  {
    key: 'far fa-kazoo',
    value: 'kazoo',
  },
  {
    key: 'far fa-kerning',
    value: 'kerning',
  },
  {
    key: 'far fa-key',
    value: 'key',
  },
  {
    key: 'far fa-key-skeleton',
    value: 'key-skeleton',
  },
  {
    key: 'far fa-keyboard',
    value: 'keyboard',
  },
  {
    key: 'far fa-keynote',
    value: 'keynote',
  },
  {
    key: 'far fa-khanda',
    value: 'khanda',
  },
  {
    key: 'far fa-kidneys',
    value: 'kidneys',
  },
  {
    key: 'far fa-kiss',
    value: 'kiss',
  },
  {
    key: 'far fa-kiss-beam',
    value: 'kiss-beam',
  },
  {
    key: 'far fa-kiss-wink-heart',
    value: 'kiss-wink-heart',
  },
  {
    key: 'far fa-kite',
    value: 'kite',
  },
  {
    key: 'far fa-kiwi-bird',
    value: 'kiwi-bird',
  },
  {
    key: 'far fa-knife-kitchen',
    value: 'knife-kitchen',
  },
  {
    key: 'far fa-lambda',
    value: 'lambda',
  },
  {
    key: 'far fa-lamp',
    value: 'lamp',
  },
  {
    key: 'far fa-lamp-desk',
    value: 'lamp-desk',
  },
  {
    key: 'far fa-lamp-floor',
    value: 'lamp-floor',
  },
  {
    key: 'far fa-landmark',
    value: 'landmark',
  },
  {
    key: 'far fa-landmark-alt',
    value: 'landmark-alt',
  },
  {
    key: 'far fa-language',
    value: 'language',
  },
  {
    key: 'far fa-laptop',
    value: 'laptop',
  },
  {
    key: 'far fa-laptop-code',
    value: 'laptop-code',
  },
  {
    key: 'far fa-laptop-house',
    value: 'laptop-house',
  },
  {
    key: 'far fa-laptop-medical',
    value: 'laptop-medical',
  },
  {
    key: 'far fa-lasso',
    value: 'lasso',
  },
  {
    key: 'far fa-laugh',
    value: 'laugh',
  },
  {
    key: 'far fa-laugh-beam',
    value: 'laugh-beam',
  },
  {
    key: 'far fa-laugh-squint',
    value: 'laugh-squint',
  },
  {
    key: 'far fa-laugh-wink',
    value: 'laugh-wink',
  },
  {
    key: 'far fa-layer-group',
    value: 'layer-group',
  },
  {
    key: 'far fa-layer-minus',
    value: 'layer-minus',
  },
  {
    key: 'far fa-layer-plus',
    value: 'layer-plus',
  },
  {
    key: 'far fa-leaf',
    value: 'leaf',
  },
  {
    key: 'far fa-leaf-heart',
    value: 'leaf-heart',
  },
  {
    key: 'far fa-leaf-maple',
    value: 'leaf-maple',
  },
  {
    key: 'far fa-leaf-oak',
    value: 'leaf-oak',
  },
  {
    key: 'far fa-lemon',
    value: 'lemon',
  },
  {
    key: 'far fa-less-than',
    value: 'less-than',
  },
  {
    key: 'far fa-less-than-equal',
    value: 'less-than-equal',
  },
  {
    key: 'far fa-level-down',
    value: 'level-down',
  },
  {
    key: 'far fa-level-down-alt',
    value: 'level-down-alt',
  },
  {
    key: 'far fa-level-up',
    value: 'level-up',
  },
  {
    key: 'far fa-level-up-alt',
    value: 'level-up-alt',
  },
  {
    key: 'far fa-life-ring',
    value: 'life-ring',
  },
  {
    key: 'far fa-light-ceiling',
    value: 'light-ceiling',
  },
  {
    key: 'far fa-light-switch',
    value: 'light-switch',
  },
  {
    key: 'far fa-light-switch-off',
    value: 'light-switch-off',
  },
  {
    key: 'far fa-light-switch-on',
    value: 'light-switch-on',
  },
  {
    key: 'far fa-lightbulb',
    value: 'lightbulb',
  },
  {
    key: 'far fa-lightbulb-dollar',
    value: 'lightbulb-dollar',
  },
  {
    key: 'far fa-lightbulb-exclamation',
    value: 'lightbulb-exclamation',
  },
  {
    key: 'far fa-lightbulb-on',
    value: 'lightbulb-on',
  },
  {
    key: 'far fa-lightbulb-slash',
    value: 'lightbulb-slash',
  },
  {
    key: 'far fa-lights-holiday',
    value: 'lights-holiday',
  },
  {
    key: 'far fa-line-columns',
    value: 'line-columns',
  },
  {
    key: 'far fa-line-height',
    value: 'line-height',
  },
  {
    key: 'far fa-link',
    value: 'link',
  },
  {
    key: 'far fa-lips',
    value: 'lips',
  },
  {
    key: 'far fa-lira-sign',
    value: 'lira-sign',
  },
  {
    key: 'far fa-list',
    value: 'list',
  },
  {
    key: 'far fa-list-alt',
    value: 'list-alt',
  },
  {
    key: 'far fa-list-music',
    value: 'list-music',
  },
  {
    key: 'far fa-list-ol',
    value: 'list-ol',
  },
  {
    key: 'far fa-list-ul',
    value: 'list-ul',
  },
  {
    key: 'far fa-location',
    value: 'location',
  },
  {
    key: 'far fa-location-arrow',
    value: 'location-arrow',
  },
  {
    key: 'far fa-location-circle',
    value: 'location-circle',
  },
  {
    key: 'far fa-location-slash',
    value: 'location-slash',
  },
  {
    key: 'far fa-lock',
    value: 'lock',
  },
  {
    key: 'far fa-lock-alt',
    value: 'lock-alt',
  },
  {
    key: 'far fa-lock-open',
    value: 'lock-open',
  },
  {
    key: 'far fa-lock-open-alt',
    value: 'lock-open-alt',
  },
  {
    key: 'far fa-long-arrow-alt-down',
    value: 'long-arrow-alt-down',
  },
  {
    key: 'far fa-long-arrow-alt-left',
    value: 'long-arrow-alt-left',
  },
  {
    key: 'far fa-long-arrow-alt-right',
    value: 'long-arrow-alt-right',
  },
  {
    key: 'far fa-long-arrow-alt-up',
    value: 'long-arrow-alt-up',
  },
  {
    key: 'far fa-long-arrow-down',
    value: 'long-arrow-down',
  },
  {
    key: 'far fa-long-arrow-left',
    value: 'long-arrow-left',
  },
  {
    key: 'far fa-long-arrow-right',
    value: 'long-arrow-right',
  },
  {
    key: 'far fa-long-arrow-up',
    value: 'long-arrow-up',
  },
  {
    key: 'far fa-loveseat',
    value: 'loveseat',
  },
  {
    key: 'far fa-low-vision',
    value: 'low-vision',
  },
  {
    key: 'far fa-luchador',
    value: 'luchador',
  },
  {
    key: 'far fa-luggage-cart',
    value: 'luggage-cart',
  },
  {
    key: 'far fa-lungs',
    value: 'lungs',
  },
  {
    key: 'far fa-lungs-virus',
    value: 'lungs-virus',
  },
  {
    key: 'far fa-mace',
    value: 'mace',
  },
  {
    key: 'far fa-magic',
    value: 'magic',
  },
  {
    key: 'far fa-magnet',
    value: 'magnet',
  },
  {
    key: 'far fa-mail-bulk',
    value: 'mail-bulk',
  },
  {
    key: 'far fa-mailbox',
    value: 'mailbox',
  },
  {
    key: 'far fa-male',
    value: 'male',
  },
  {
    key: 'far fa-mandolin',
    value: 'mandolin',
  },
  {
    key: 'far fa-map',
    value: 'map',
  },
  {
    key: 'far fa-map-marked',
    value: 'map-marked',
  },
  {
    key: 'far fa-map-marked-alt',
    value: 'map-marked-alt',
  },
  {
    key: 'far fa-map-marker',
    value: 'map-marker',
  },
  {
    key: 'far fa-map-marker-alt',
    value: 'map-marker-alt',
  },
  {
    key: 'far fa-map-marker-alt-slash',
    value: 'map-marker-alt-slash',
  },
  {
    key: 'far fa-map-marker-check',
    value: 'map-marker-check',
  },
  {
    key: 'far fa-map-marker-edit',
    value: 'map-marker-edit',
  },
  {
    key: 'far fa-map-marker-exclamation',
    value: 'map-marker-exclamation',
  },
  {
    key: 'far fa-map-marker-minus',
    value: 'map-marker-minus',
  },
  {
    key: 'far fa-map-marker-plus',
    value: 'map-marker-plus',
  },
  {
    key: 'far fa-map-marker-question',
    value: 'map-marker-question',
  },
  {
    key: 'far fa-map-marker-slash',
    value: 'map-marker-slash',
  },
  {
    key: 'far fa-map-marker-smile',
    value: 'map-marker-smile',
  },
  {
    key: 'far fa-map-marker-times',
    value: 'map-marker-times',
  },
  {
    key: 'far fa-map-pin',
    value: 'map-pin',
  },
  {
    key: 'far fa-map-signs',
    value: 'map-signs',
  },
  {
    key: 'far fa-marker',
    value: 'marker',
  },
  {
    key: 'far fa-mars',
    value: 'mars',
  },
  {
    key: 'far fa-mars-double',
    value: 'mars-double',
  },
  {
    key: 'far fa-mars-stroke',
    value: 'mars-stroke',
  },
  {
    key: 'far fa-mars-stroke-h',
    value: 'mars-stroke-h',
  },
  {
    key: 'far fa-mars-stroke-v',
    value: 'mars-stroke-v',
  },
  {
    key: 'far fa-mask',
    value: 'mask',
  },
  {
    key: 'far fa-meat',
    value: 'meat',
  },
  {
    key: 'far fa-medal',
    value: 'medal',
  },
  {
    key: 'far fa-medkit',
    value: 'medkit',
  },
  {
    key: 'far fa-megaphone',
    value: 'megaphone',
  },
  {
    key: 'far fa-meh',
    value: 'meh',
  },
  {
    key: 'far fa-meh-blank',
    value: 'meh-blank',
  },
  {
    key: 'far fa-meh-rolling-eyes',
    value: 'meh-rolling-eyes',
  },
  {
    key: 'far fa-memory',
    value: 'memory',
  },
  {
    key: 'far fa-menorah',
    value: 'menorah',
  },
  {
    key: 'far fa-mercury',
    value: 'mercury',
  },
  {
    key: 'far fa-meteor',
    value: 'meteor',
  },
  {
    key: 'far fa-microchip',
    value: 'microchip',
  },
  {
    key: 'far fa-microphone',
    value: 'microphone',
  },
  {
    key: 'far fa-microphone-alt',
    value: 'microphone-alt',
  },
  {
    key: 'far fa-microphone-alt-slash',
    value: 'microphone-alt-slash',
  },
  {
    key: 'far fa-microphone-slash',
    value: 'microphone-slash',
  },
  {
    key: 'far fa-microphone-stand',
    value: 'microphone-stand',
  },
  {
    key: 'far fa-microscope',
    value: 'microscope',
  },
  {
    key: 'far fa-microwave',
    value: 'microwave',
  },
  {
    key: 'far fa-mind-share',
    value: 'mind-share',
  },
  {
    key: 'far fa-minus',
    value: 'minus',
  },
  {
    key: 'far fa-minus-circle',
    value: 'minus-circle',
  },
  {
    key: 'far fa-minus-hexagon',
    value: 'minus-hexagon',
  },
  {
    key: 'far fa-minus-octagon',
    value: 'minus-octagon',
  },
  {
    key: 'far fa-minus-square',
    value: 'minus-square',
  },
  {
    key: 'far fa-mistletoe',
    value: 'mistletoe',
  },
  {
    key: 'far fa-mitten',
    value: 'mitten',
  },
  {
    key: 'far fa-mobile',
    value: 'mobile',
  },
  {
    key: 'far fa-mobile-alt',
    value: 'mobile-alt',
  },
  {
    key: 'far fa-mobile-android',
    value: 'mobile-android',
  },
  {
    key: 'far fa-mobile-android-alt',
    value: 'mobile-android-alt',
  },
  {
    key: 'far fa-money-bill',
    value: 'money-bill',
  },
  {
    key: 'far fa-money-bill-alt',
    value: 'money-bill-alt',
  },
  {
    key: 'far fa-money-bill-wave',
    value: 'money-bill-wave',
  },
  {
    key: 'far fa-money-bill-wave-alt',
    value: 'money-bill-wave-alt',
  },
  {
    key: 'far fa-money-check',
    value: 'money-check',
  },
  {
    key: 'far fa-money-check-alt',
    value: 'money-check-alt',
  },
  {
    key: 'far fa-money-check-edit',
    value: 'money-check-edit',
  },
  {
    key: 'far fa-money-check-edit-alt',
    value: 'money-check-edit-alt',
  },
  {
    key: 'far fa-monitor-heart-rate',
    value: 'monitor-heart-rate',
  },
  {
    key: 'far fa-monkey',
    value: 'monkey',
  },
  {
    key: 'far fa-monument',
    value: 'monument',
  },
  {
    key: 'far fa-moon',
    value: 'moon',
  },
  {
    key: 'far fa-moon-cloud',
    value: 'moon-cloud',
  },
  {
    key: 'far fa-moon-stars',
    value: 'moon-stars',
  },
  {
    key: 'far fa-mortar-pestle',
    value: 'mortar-pestle',
  },
  {
    key: 'far fa-mosque',
    value: 'mosque',
  },
  {
    key: 'far fa-motorcycle',
    value: 'motorcycle',
  },
  {
    key: 'far fa-mountain',
    value: 'mountain',
  },
  {
    key: 'far fa-mountains',
    value: 'mountains',
  },
  {
    key: 'far fa-mouse',
    value: 'mouse',
  },
  {
    key: 'far fa-mouse-alt',
    value: 'mouse-alt',
  },
  {
    key: 'far fa-mouse-pointer',
    value: 'mouse-pointer',
  },
  {
    key: 'far fa-mp3-player',
    value: 'mp3-player',
  },
  {
    key: 'far fa-mug',
    value: 'mug',
  },
  {
    key: 'far fa-mug-hot',
    value: 'mug-hot',
  },
  {
    key: 'far fa-mug-marshmallows',
    value: 'mug-marshmallows',
  },
  {
    key: 'far fa-mug-tea',
    value: 'mug-tea',
  },
  {
    key: 'far fa-music',
    value: 'music',
  },
  {
    key: 'far fa-music-alt',
    value: 'music-alt',
  },
  {
    key: 'far fa-music-alt-slash',
    value: 'music-alt-slash',
  },
  {
    key: 'far fa-music-slash',
    value: 'music-slash',
  },
  {
    key: 'far fa-narwhal',
    value: 'narwhal',
  },
  {
    key: 'far fa-network-wired',
    value: 'network-wired',
  },
  {
    key: 'far fa-neuter',
    value: 'neuter',
  },
  {
    key: 'far fa-newspaper',
    value: 'newspaper',
  },
  {
    key: 'far fa-not-equal',
    value: 'not-equal',
  },
  {
    key: 'far fa-notes-medical',
    value: 'notes-medical',
  },
  {
    key: 'far fa-object-group',
    value: 'object-group',
  },
  {
    key: 'far fa-object-ungroup',
    value: 'object-ungroup',
  },
  {
    key: 'far fa-octagon',
    value: 'octagon',
  },
  {
    key: 'far fa-oil-can',
    value: 'oil-can',
  },
  {
    key: 'far fa-oil-temp',
    value: 'oil-temp',
  },
  {
    key: 'far fa-om',
    value: 'om',
  },
  {
    key: 'far fa-omega',
    value: 'omega',
  },
  {
    key: 'far fa-ornament',
    value: 'ornament',
  },
  {
    key: 'far fa-otter',
    value: 'otter',
  },
  {
    key: 'far fa-outdent',
    value: 'outdent',
  },
  {
    key: 'far fa-outlet',
    value: 'outlet',
  },
  {
    key: 'far fa-oven',
    value: 'oven',
  },
  {
    key: 'far fa-overline',
    value: 'overline',
  },
  {
    key: 'far fa-page-break',
    value: 'page-break',
  },
  {
    key: 'far fa-pager',
    value: 'pager',
  },
  {
    key: 'far fa-paint-brush',
    value: 'paint-brush',
  },
  {
    key: 'far fa-paint-brush-alt',
    value: 'paint-brush-alt',
  },
  {
    key: 'far fa-paint-roller',
    value: 'paint-roller',
  },
  {
    key: 'far fa-palette',
    value: 'palette',
  },
  {
    key: 'far fa-pallet',
    value: 'pallet',
  },
  {
    key: 'far fa-pallet-alt',
    value: 'pallet-alt',
  },
  {
    key: 'far fa-paper-plane',
    value: 'paper-plane',
  },
  {
    key: 'far fa-paperclip',
    value: 'paperclip',
  },
  {
    key: 'far fa-parachute-box',
    value: 'parachute-box',
  },
  {
    key: 'far fa-paragraph',
    value: 'paragraph',
  },
  {
    key: 'far fa-paragraph-rtl',
    value: 'paragraph-rtl',
  },
  {
    key: 'far fa-parking',
    value: 'parking',
  },
  {
    key: 'far fa-parking-circle',
    value: 'parking-circle',
  },
  {
    key: 'far fa-parking-circle-slash',
    value: 'parking-circle-slash',
  },
  {
    key: 'far fa-parking-slash',
    value: 'parking-slash',
  },
  {
    key: 'far fa-passport',
    value: 'passport',
  },
  {
    key: 'far fa-pastafarianism',
    value: 'pastafarianism',
  },
  {
    key: 'far fa-paste',
    value: 'paste',
  },
  {
    key: 'far fa-pause',
    value: 'pause',
  },
  {
    key: 'far fa-pause-circle',
    value: 'pause-circle',
  },
  {
    key: 'far fa-paw',
    value: 'paw',
  },
  {
    key: 'far fa-paw-alt',
    value: 'paw-alt',
  },
  {
    key: 'far fa-paw-claws',
    value: 'paw-claws',
  },
  {
    key: 'far fa-peace',
    value: 'peace',
  },
  {
    key: 'far fa-pegasus',
    value: 'pegasus',
  },
  {
    key: 'far fa-pen',
    value: 'pen',
  },
  {
    key: 'far fa-pen-alt',
    value: 'pen-alt',
  },
  {
    key: 'far fa-pen-fancy',
    value: 'pen-fancy',
  },
  {
    key: 'far fa-pen-nib',
    value: 'pen-nib',
  },
  {
    key: 'far fa-pen-square',
    value: 'pen-square',
  },
  {
    key: 'far fa-pencil',
    value: 'pencil',
  },
  {
    key: 'far fa-pencil-alt',
    value: 'pencil-alt',
  },
  {
    key: 'far fa-pencil-paintbrush',
    value: 'pencil-paintbrush',
  },
  {
    key: 'far fa-pencil-ruler',
    value: 'pencil-ruler',
  },
  {
    key: 'far fa-pennant',
    value: 'pennant',
  },
  {
    key: 'far fa-people-arrows',
    value: 'people-arrows',
  },
  {
    key: 'far fa-people-carry',
    value: 'people-carry',
  },
  {
    key: 'far fa-pepper-hot',
    value: 'pepper-hot',
  },
  {
    key: 'far fa-percent',
    value: 'percent',
  },
  {
    key: 'far fa-percentage',
    value: 'percentage',
  },
  {
    key: 'far fa-person-booth',
    value: 'person-booth',
  },
  {
    key: 'far fa-person-carry',
    value: 'person-carry',
  },
  {
    key: 'far fa-person-dolly',
    value: 'person-dolly',
  },
  {
    key: 'far fa-person-dolly-empty',
    value: 'person-dolly-empty',
  },
  {
    key: 'far fa-person-sign',
    value: 'person-sign',
  },
  {
    key: 'far fa-phone',
    value: 'phone',
  },
  {
    key: 'far fa-phone-alt',
    value: 'phone-alt',
  },
  {
    key: 'far fa-phone-laptop',
    value: 'phone-laptop',
  },
  {
    key: 'far fa-phone-office',
    value: 'phone-office',
  },
  {
    key: 'far fa-phone-plus',
    value: 'phone-plus',
  },
  {
    key: 'far fa-phone-rotary',
    value: 'phone-rotary',
  },
  {
    key: 'far fa-phone-slash',
    value: 'phone-slash',
  },
  {
    key: 'far fa-phone-square',
    value: 'phone-square',
  },
  {
    key: 'far fa-phone-square-alt',
    value: 'phone-square-alt',
  },
  {
    key: 'far fa-phone-volume',
    value: 'phone-volume',
  },
  {
    key: 'far fa-photo-video',
    value: 'photo-video',
  },
  {
    key: 'far fa-pi',
    value: 'pi',
  },
  {
    key: 'far fa-piano',
    value: 'piano',
  },
  {
    key: 'far fa-piano-keyboard',
    value: 'piano-keyboard',
  },
  {
    key: 'far fa-pie',
    value: 'pie',
  },
  {
    key: 'far fa-pig',
    value: 'pig',
  },
  {
    key: 'far fa-piggy-bank',
    value: 'piggy-bank',
  },
  {
    key: 'far fa-pills',
    value: 'pills',
  },
  {
    key: 'far fa-pizza',
    value: 'pizza',
  },
  {
    key: 'far fa-pizza-slice',
    value: 'pizza-slice',
  },
  {
    key: 'far fa-place-of-worship',
    value: 'place-of-worship',
  },
  {
    key: 'far fa-plane',
    value: 'plane',
  },
  {
    key: 'far fa-plane-alt',
    value: 'plane-alt',
  },
  {
    key: 'far fa-plane-arrival',
    value: 'plane-arrival',
  },
  {
    key: 'far fa-plane-departure',
    value: 'plane-departure',
  },
  {
    key: 'far fa-plane-slash',
    value: 'plane-slash',
  },
  {
    key: 'far fa-planet-moon',
    value: 'planet-moon',
  },
  {
    key: 'far fa-planet-ringed',
    value: 'planet-ringed',
  },
  {
    key: 'far fa-play',
    value: 'play',
  },
  {
    key: 'far fa-play-circle',
    value: 'play-circle',
  },
  {
    key: 'far fa-plug',
    value: 'plug',
  },
  {
    key: 'far fa-plus',
    value: 'plus',
  },
  {
    key: 'far fa-plus-circle',
    value: 'plus-circle',
  },
  {
    key: 'far fa-plus-hexagon',
    value: 'plus-hexagon',
  },
  {
    key: 'far fa-plus-octagon',
    value: 'plus-octagon',
  },
  {
    key: 'far fa-plus-square',
    value: 'plus-square',
  },
  {
    key: 'far fa-podcast',
    value: 'podcast',
  },
  {
    key: 'far fa-podium',
    value: 'podium',
  },
  {
    key: 'far fa-podium-star',
    value: 'podium-star',
  },
  {
    key: 'far fa-police-box',
    value: 'police-box',
  },
  {
    key: 'far fa-poll',
    value: 'poll',
  },
  {
    key: 'far fa-poll-h',
    value: 'poll-h',
  },
  {
    key: 'far fa-poll-people',
    value: 'poll-people',
  },
  {
    key: 'far fa-poo',
    value: 'poo',
  },
  {
    key: 'far fa-poo-storm',
    value: 'poo-storm',
  },
  {
    key: 'far fa-poop',
    value: 'poop',
  },
  {
    key: 'far fa-popcorn',
    value: 'popcorn',
  },
  {
    key: 'far fa-portal-enter',
    value: 'portal-enter',
  },
  {
    key: 'far fa-portal-exit',
    value: 'portal-exit',
  },
  {
    key: 'far fa-portrait',
    value: 'portrait',
  },
  {
    key: 'far fa-pound-sign',
    value: 'pound-sign',
  },
  {
    key: 'far fa-power-off',
    value: 'power-off',
  },
  {
    key: 'far fa-pray',
    value: 'pray',
  },
  {
    key: 'far fa-praying-hands',
    value: 'praying-hands',
  },
  {
    key: 'far fa-prescription',
    value: 'prescription',
  },
  {
    key: 'far fa-prescription-bottle',
    value: 'prescription-bottle',
  },
  {
    key: 'far fa-prescription-bottle-alt',
    value: 'prescription-bottle-alt',
  },
  {
    key: 'far fa-presentation',
    value: 'presentation',
  },
  {
    key: 'far fa-print',
    value: 'print',
  },
  {
    key: 'far fa-print-search',
    value: 'print-search',
  },
  {
    key: 'far fa-print-slash',
    value: 'print-slash',
  },
  {
    key: 'far fa-procedures',
    value: 'procedures',
  },
  {
    key: 'far fa-project-diagram',
    value: 'project-diagram',
  },
  {
    key: 'far fa-projector',
    value: 'projector',
  },
  {
    key: 'far fa-pump-medical',
    value: 'pump-medical',
  },
  {
    key: 'far fa-pump-soap',
    value: 'pump-soap',
  },
  {
    key: 'far fa-pumpkin',
    value: 'pumpkin',
  },
  {
    key: 'far fa-puzzle-piece',
    value: 'puzzle-piece',
  },
  {
    key: 'far fa-qrcode',
    value: 'qrcode',
  },
  {
    key: 'far fa-question',
    value: 'question',
  },
  {
    key: 'far fa-question-circle',
    value: 'question-circle',
  },
  {
    key: 'far fa-question-square',
    value: 'question-square',
  },
  {
    key: 'far fa-quidditch',
    value: 'quidditch',
  },
  {
    key: 'far fa-quote-left',
    value: 'quote-left',
  },
  {
    key: 'far fa-quote-right',
    value: 'quote-right',
  },
  {
    key: 'far fa-quran',
    value: 'quran',
  },
  {
    key: 'far fa-rabbit',
    value: 'rabbit',
  },
  {
    key: 'far fa-rabbit-fast',
    value: 'rabbit-fast',
  },
  {
    key: 'far fa-racquet',
    value: 'racquet',
  },
  {
    key: 'far fa-radar',
    value: 'radar',
  },
  {
    key: 'far fa-radiation',
    value: 'radiation',
  },
  {
    key: 'far fa-radiation-alt',
    value: 'radiation-alt',
  },
  {
    key: 'far fa-radio',
    value: 'radio',
  },
  {
    key: 'far fa-radio-alt',
    value: 'radio-alt',
  },
  {
    key: 'far fa-rainbow',
    value: 'rainbow',
  },
  {
    key: 'far fa-raindrops',
    value: 'raindrops',
  },
  {
    key: 'far fa-ram',
    value: 'ram',
  },
  {
    key: 'far fa-ramp-loading',
    value: 'ramp-loading',
  },
  {
    key: 'far fa-random',
    value: 'random',
  },
  {
    key: 'far fa-raygun',
    value: 'raygun',
  },
  {
    key: 'far fa-receipt',
    value: 'receipt',
  },
  {
    key: 'far fa-record-vinyl',
    value: 'record-vinyl',
  },
  {
    key: 'far fa-rectangle-landscape',
    value: 'rectangle-landscape',
  },
  {
    key: 'far fa-rectangle-portrait',
    value: 'rectangle-portrait',
  },
  {
    key: 'far fa-rectangle-wide',
    value: 'rectangle-wide',
  },
  {
    key: 'far fa-recycle',
    value: 'recycle',
  },
  {
    key: 'far fa-redo',
    value: 'redo',
  },
  {
    key: 'far fa-redo-alt',
    value: 'redo-alt',
  },
  {
    key: 'far fa-refrigerator',
    value: 'refrigerator',
  },
  {
    key: 'far fa-registered',
    value: 'registered',
  },
  {
    key: 'far fa-remove-format',
    value: 'remove-format',
  },
  {
    key: 'far fa-repeat',
    value: 'repeat',
  },
  {
    key: 'far fa-repeat-1',
    value: 'repeat-1',
  },
  {
    key: 'far fa-repeat-1-alt',
    value: 'repeat-1-alt',
  },
  {
    key: 'far fa-repeat-alt',
    value: 'repeat-alt',
  },
  {
    key: 'far fa-reply',
    value: 'reply',
  },
  {
    key: 'far fa-reply-all',
    value: 'reply-all',
  },
  {
    key: 'far fa-republican',
    value: 'republican',
  },
  {
    key: 'far fa-restroom',
    value: 'restroom',
  },
  {
    key: 'far fa-retweet',
    value: 'retweet',
  },
  {
    key: 'far fa-retweet-alt',
    value: 'retweet-alt',
  },
  {
    key: 'far fa-ribbon',
    value: 'ribbon',
  },
  {
    key: 'far fa-ring',
    value: 'ring',
  },
  {
    key: 'far fa-rings-wedding',
    value: 'rings-wedding',
  },
  {
    key: 'far fa-road',
    value: 'road',
  },
  {
    key: 'far fa-robot',
    value: 'robot',
  },
  {
    key: 'far fa-rocket',
    value: 'rocket',
  },
  {
    key: 'far fa-rocket-launch',
    value: 'rocket-launch',
  },
  {
    key: 'far fa-route',
    value: 'route',
  },
  {
    key: 'far fa-route-highway',
    value: 'route-highway',
  },
  {
    key: 'far fa-route-interstate',
    value: 'route-interstate',
  },
  {
    key: 'far fa-router',
    value: 'router',
  },
  {
    key: 'far fa-rss',
    value: 'rss',
  },
  {
    key: 'far fa-rss-square',
    value: 'rss-square',
  },
  {
    key: 'far fa-ruble-sign',
    value: 'ruble-sign',
  },
  {
    key: 'far fa-ruler',
    value: 'ruler',
  },
  {
    key: 'far fa-ruler-combined',
    value: 'ruler-combined',
  },
  {
    key: 'far fa-ruler-horizontal',
    value: 'ruler-horizontal',
  },
  {
    key: 'far fa-ruler-triangle',
    value: 'ruler-triangle',
  },
  {
    key: 'far fa-ruler-vertical',
    value: 'ruler-vertical',
  },
  {
    key: 'far fa-running',
    value: 'running',
  },
  {
    key: 'far fa-rupee-sign',
    value: 'rupee-sign',
  },
  {
    key: 'far fa-rv',
    value: 'rv',
  },
  {
    key: 'far fa-sack',
    value: 'sack',
  },
  {
    key: 'far fa-sack-dollar',
    value: 'sack-dollar',
  },
  {
    key: 'far fa-sad-cry',
    value: 'sad-cry',
  },
  {
    key: 'far fa-sad-tear',
    value: 'sad-tear',
  },
  {
    key: 'far fa-salad',
    value: 'salad',
  },
  {
    key: 'far fa-sandwich',
    value: 'sandwich',
  },
  {
    key: 'far fa-satellite',
    value: 'satellite',
  },
  {
    key: 'far fa-satellite-dish',
    value: 'satellite-dish',
  },
  {
    key: 'far fa-sausage',
    value: 'sausage',
  },
  {
    key: 'far fa-save',
    value: 'save',
  },
  {
    key: 'far fa-sax-hot',
    value: 'sax-hot',
  },
  {
    key: 'far fa-saxophone',
    value: 'saxophone',
  },
  {
    key: 'far fa-scalpel',
    value: 'scalpel',
  },
  {
    key: 'far fa-scalpel-path',
    value: 'scalpel-path',
  },
  {
    key: 'far fa-scanner',
    value: 'scanner',
  },
  {
    key: 'far fa-scanner-image',
    value: 'scanner-image',
  },
  {
    key: 'far fa-scanner-keyboard',
    value: 'scanner-keyboard',
  },
  {
    key: 'far fa-scanner-touchscreen',
    value: 'scanner-touchscreen',
  },
  {
    key: 'far fa-scarecrow',
    value: 'scarecrow',
  },
  {
    key: 'far fa-scarf',
    value: 'scarf',
  },
  {
    key: 'far fa-school',
    value: 'school',
  },
  {
    key: 'far fa-screwdriver',
    value: 'screwdriver',
  },
  {
    key: 'far fa-scroll',
    value: 'scroll',
  },
  {
    key: 'far fa-scroll-old',
    value: 'scroll-old',
  },
  {
    key: 'far fa-scrubber',
    value: 'scrubber',
  },
  {
    key: 'far fa-scythe',
    value: 'scythe',
  },
  {
    key: 'far fa-sd-card',
    value: 'sd-card',
  },
  {
    key: 'far fa-search',
    value: 'search',
  },
  {
    key: 'far fa-search-dollar',
    value: 'search-dollar',
  },
  {
    key: 'far fa-search-location',
    value: 'search-location',
  },
  {
    key: 'far fa-search-minus',
    value: 'search-minus',
  },
  {
    key: 'far fa-search-plus',
    value: 'search-plus',
  },
  {
    key: 'far fa-seedling',
    value: 'seedling',
  },
  {
    key: 'far fa-send-back',
    value: 'send-back',
  },
  {
    key: 'far fa-send-backward',
    value: 'send-backward',
  },
  {
    key: 'far fa-sensor',
    value: 'sensor',
  },
  {
    key: 'far fa-sensor-alert',
    value: 'sensor-alert',
  },
  {
    key: 'far fa-sensor-fire',
    value: 'sensor-fire',
  },
  {
    key: 'far fa-sensor-on',
    value: 'sensor-on',
  },
  {
    key: 'far fa-sensor-smoke',
    value: 'sensor-smoke',
  },
  {
    key: 'far fa-server',
    value: 'server',
  },
  {
    key: 'far fa-shapes',
    value: 'shapes',
  },
  {
    key: 'far fa-share',
    value: 'share',
  },
  {
    key: 'far fa-share-all',
    value: 'share-all',
  },
  {
    key: 'far fa-share-alt',
    value: 'share-alt',
  },
  {
    key: 'far fa-share-alt-square',
    value: 'share-alt-square',
  },
  {
    key: 'far fa-share-square',
    value: 'share-square',
  },
  {
    key: 'far fa-sheep',
    value: 'sheep',
  },
  {
    key: 'far fa-shekel-sign',
    value: 'shekel-sign',
  },
  {
    key: 'far fa-shield',
    value: 'shield',
  },
  {
    key: 'far fa-shield-alt',
    value: 'shield-alt',
  },
  {
    key: 'far fa-shield-check',
    value: 'shield-check',
  },
  {
    key: 'far fa-shield-cross',
    value: 'shield-cross',
  },
  {
    key: 'far fa-shield-virus',
    value: 'shield-virus',
  },
  {
    key: 'far fa-ship',
    value: 'ship',
  },
  {
    key: 'far fa-shipping-fast',
    value: 'shipping-fast',
  },
  {
    key: 'far fa-shipping-timed',
    value: 'shipping-timed',
  },
  {
    key: 'far fa-shish-kebab',
    value: 'shish-kebab',
  },
  {
    key: 'far fa-shoe-prints',
    value: 'shoe-prints',
  },
  {
    key: 'far fa-shopping-bag',
    value: 'shopping-bag',
  },
  {
    key: 'far fa-shopping-basket',
    value: 'shopping-basket',
  },
  {
    key: 'far fa-shopping-cart',
    value: 'shopping-cart',
  },
  {
    key: 'far fa-shovel',
    value: 'shovel',
  },
  {
    key: 'far fa-shovel-snow',
    value: 'shovel-snow',
  },
  {
    key: 'far fa-shower',
    value: 'shower',
  },
  {
    key: 'far fa-shredder',
    value: 'shredder',
  },
  {
    key: 'far fa-shuttle-van',
    value: 'shuttle-van',
  },
  {
    key: 'far fa-shuttlecock',
    value: 'shuttlecock',
  },
  {
    key: 'far fa-sickle',
    value: 'sickle',
  },
  {
    key: 'far fa-sigma',
    value: 'sigma',
  },
  {
    key: 'far fa-sign',
    value: 'sign',
  },
  {
    key: 'far fa-sign-in',
    value: 'sign-in',
  },
  {
    key: 'far fa-sign-in-alt',
    value: 'sign-in-alt',
  },
  {
    key: 'far fa-sign-language',
    value: 'sign-language',
  },
  {
    key: 'far fa-sign-out',
    value: 'sign-out',
  },
  {
    key: 'far fa-sign-out-alt',
    value: 'sign-out-alt',
  },
  {
    key: 'far fa-signal',
    value: 'signal',
  },
  {
    key: 'far fa-signal-1',
    value: 'signal-1',
  },
  {
    key: 'far fa-signal-2',
    value: 'signal-2',
  },
  {
    key: 'far fa-signal-3',
    value: 'signal-3',
  },
  {
    key: 'far fa-signal-4',
    value: 'signal-4',
  },
  {
    key: 'far fa-signal-alt',
    value: 'signal-alt',
  },
  {
    key: 'far fa-signal-alt-1',
    value: 'signal-alt-1',
  },
  {
    key: 'far fa-signal-alt-2',
    value: 'signal-alt-2',
  },
  {
    key: 'far fa-signal-alt-3',
    value: 'signal-alt-3',
  },
  {
    key: 'far fa-signal-alt-slash',
    value: 'signal-alt-slash',
  },
  {
    key: 'far fa-signal-slash',
    value: 'signal-slash',
  },
  {
    key: 'far fa-signal-stream',
    value: 'signal-stream',
  },
  {
    key: 'far fa-signature',
    value: 'signature',
  },
  {
    key: 'far fa-sim-card',
    value: 'sim-card',
  },
  {
    key: 'far fa-sink',
    value: 'sink',
  },
  {
    key: 'far fa-siren',
    value: 'siren',
  },
  {
    key: 'far fa-siren-on',
    value: 'siren-on',
  },
  {
    key: 'far fa-sitemap',
    value: 'sitemap',
  },
  {
    key: 'far fa-skating',
    value: 'skating',
  },
  {
    key: 'far fa-skeleton',
    value: 'skeleton',
  },
  {
    key: 'far fa-ski-jump',
    value: 'ski-jump',
  },
  {
    key: 'far fa-ski-lift',
    value: 'ski-lift',
  },
  {
    key: 'far fa-skiing',
    value: 'skiing',
  },
  {
    key: 'far fa-skiing-nordic',
    value: 'skiing-nordic',
  },
  {
    key: 'far fa-skull',
    value: 'skull',
  },
  {
    key: 'far fa-skull-cow',
    value: 'skull-cow',
  },
  {
    key: 'far fa-skull-crossbones',
    value: 'skull-crossbones',
  },
  {
    key: 'far fa-slash',
    value: 'slash',
  },
  {
    key: 'far fa-sledding',
    value: 'sledding',
  },
  {
    key: 'far fa-sleigh',
    value: 'sleigh',
  },
  {
    key: 'far fa-sliders-h',
    value: 'sliders-h',
  },
  {
    key: 'far fa-sliders-h-square',
    value: 'sliders-h-square',
  },
  {
    key: 'far fa-sliders-v',
    value: 'sliders-v',
  },
  {
    key: 'far fa-sliders-v-square',
    value: 'sliders-v-square',
  },
  {
    key: 'far fa-smile',
    value: 'smile',
  },
  {
    key: 'far fa-smile-beam',
    value: 'smile-beam',
  },
  {
    key: 'far fa-smile-plus',
    value: 'smile-plus',
  },
  {
    key: 'far fa-smile-wink',
    value: 'smile-wink',
  },
  {
    key: 'far fa-smog',
    value: 'smog',
  },
  {
    key: 'far fa-smoke',
    value: 'smoke',
  },
  {
    key: 'far fa-smoking',
    value: 'smoking',
  },
  {
    key: 'far fa-smoking-ban',
    value: 'smoking-ban',
  },
  {
    key: 'far fa-sms',
    value: 'sms',
  },
  {
    key: 'far fa-snake',
    value: 'snake',
  },
  {
    key: 'far fa-snooze',
    value: 'snooze',
  },
  {
    key: 'far fa-snow-blowing',
    value: 'snow-blowing',
  },
  {
    key: 'far fa-snowboarding',
    value: 'snowboarding',
  },
  {
    key: 'far fa-snowflake',
    value: 'snowflake',
  },
  {
    key: 'far fa-snowflakes',
    value: 'snowflakes',
  },
  {
    key: 'far fa-snowman',
    value: 'snowman',
  },
  {
    key: 'far fa-snowmobile',
    value: 'snowmobile',
  },
  {
    key: 'far fa-snowplow',
    value: 'snowplow',
  },
  {
    key: 'far fa-soap',
    value: 'soap',
  },
  {
    key: 'far fa-socks',
    value: 'socks',
  },
  {
    key: 'far fa-solar-panel',
    value: 'solar-panel',
  },
  {
    key: 'far fa-solar-system',
    value: 'solar-system',
  },
  {
    key: 'far fa-sort',
    value: 'sort',
  },
  {
    key: 'far fa-sort-alpha-down',
    value: 'sort-alpha-down',
  },
  {
    key: 'far fa-sort-alpha-down-alt',
    value: 'sort-alpha-down-alt',
  },
  {
    key: 'far fa-sort-alpha-up',
    value: 'sort-alpha-up',
  },
  {
    key: 'far fa-sort-alpha-up-alt',
    value: 'sort-alpha-up-alt',
  },
  {
    key: 'far fa-sort-alt',
    value: 'sort-alt',
  },
  {
    key: 'far fa-sort-amount-down',
    value: 'sort-amount-down',
  },
  {
    key: 'far fa-sort-amount-down-alt',
    value: 'sort-amount-down-alt',
  },
  {
    key: 'far fa-sort-amount-up',
    value: 'sort-amount-up',
  },
  {
    key: 'far fa-sort-amount-up-alt',
    value: 'sort-amount-up-alt',
  },
  {
    key: 'far fa-sort-circle',
    value: 'sort-circle',
  },
  {
    key: 'far fa-sort-circle-down',
    value: 'sort-circle-down',
  },
  {
    key: 'far fa-sort-circle-up',
    value: 'sort-circle-up',
  },
  {
    key: 'far fa-sort-down',
    value: 'sort-down',
  },
  {
    key: 'far fa-sort-numeric-down',
    value: 'sort-numeric-down',
  },
  {
    key: 'far fa-sort-numeric-down-alt',
    value: 'sort-numeric-down-alt',
  },
  {
    key: 'far fa-sort-numeric-up',
    value: 'sort-numeric-up',
  },
  {
    key: 'far fa-sort-numeric-up-alt',
    value: 'sort-numeric-up-alt',
  },
  {
    key: 'far fa-sort-shapes-down',
    value: 'sort-shapes-down',
  },
  {
    key: 'far fa-sort-shapes-down-alt',
    value: 'sort-shapes-down-alt',
  },
  {
    key: 'far fa-sort-shapes-up',
    value: 'sort-shapes-up',
  },
  {
    key: 'far fa-sort-shapes-up-alt',
    value: 'sort-shapes-up-alt',
  },
  {
    key: 'far fa-sort-size-down',
    value: 'sort-size-down',
  },
  {
    key: 'far fa-sort-size-down-alt',
    value: 'sort-size-down-alt',
  },
  {
    key: 'far fa-sort-size-up',
    value: 'sort-size-up',
  },
  {
    key: 'far fa-sort-size-up-alt',
    value: 'sort-size-up-alt',
  },
  {
    key: 'far fa-sort-up',
    value: 'sort-up',
  },
  {
    key: 'far fa-soup',
    value: 'soup',
  },
  {
    key: 'far fa-spa',
    value: 'spa',
  },
  {
    key: 'far fa-space-shuttle',
    value: 'space-shuttle',
  },
  {
    key: 'far fa-space-station-moon',
    value: 'space-station-moon',
  },
  {
    key: 'far fa-space-station-moon-alt',
    value: 'space-station-moon-alt',
  },
  {
    key: 'far fa-spade',
    value: 'spade',
  },
  {
    key: 'far fa-sparkles',
    value: 'sparkles',
  },
  {
    key: 'far fa-speaker',
    value: 'speaker',
  },
  {
    key: 'far fa-speakers',
    value: 'speakers',
  },
  {
    key: 'far fa-spell-check',
    value: 'spell-check',
  },
  {
    key: 'far fa-spider',
    value: 'spider',
  },
  {
    key: 'far fa-spider-black-widow',
    value: 'spider-black-widow',
  },
  {
    key: 'far fa-spider-web',
    value: 'spider-web',
  },
  {
    key: 'far fa-spinner',
    value: 'spinner',
  },
  {
    key: 'far fa-spinner-third',
    value: 'spinner-third',
  },
  {
    key: 'far fa-splotch',
    value: 'splotch',
  },
  {
    key: 'far fa-spray-can',
    value: 'spray-can',
  },
  {
    key: 'far fa-sprinkler',
    value: 'sprinkler',
  },
  {
    key: 'far fa-square',
    value: 'square',
  },
  {
    key: 'far fa-square-full',
    value: 'square-full',
  },
  {
    key: 'far fa-square-root',
    value: 'square-root',
  },
  {
    key: 'far fa-square-root-alt',
    value: 'square-root-alt',
  },
  {
    key: 'far fa-squirrel',
    value: 'squirrel',
  },
  {
    key: 'far fa-staff',
    value: 'staff',
  },
  {
    key: 'far fa-stamp',
    value: 'stamp',
  },
  {
    key: 'far fa-star',
    value: 'star',
  },
  {
    key: 'far fa-star-and-crescent',
    value: 'star-and-crescent',
  },
  {
    key: 'far fa-star-christmas',
    value: 'star-christmas',
  },
  {
    key: 'far fa-star-exclamation',
    value: 'star-exclamation',
  },
  {
    key: 'far fa-star-half',
    value: 'star-half',
  },
  {
    key: 'far fa-star-half-alt',
    value: 'star-half-alt',
  },
  {
    key: 'far fa-star-of-david',
    value: 'star-of-david',
  },
  {
    key: 'far fa-star-of-life',
    value: 'star-of-life',
  },
  {
    key: 'far fa-star-shooting',
    value: 'star-shooting',
  },
  {
    key: 'far fa-starfighter',
    value: 'starfighter',
  },
  {
    key: 'far fa-starfighter-alt',
    value: 'starfighter-alt',
  },
  {
    key: 'far fa-stars',
    value: 'stars',
  },
  {
    key: 'far fa-starship',
    value: 'starship',
  },
  {
    key: 'far fa-starship-freighter',
    value: 'starship-freighter',
  },
  {
    key: 'far fa-steak',
    value: 'steak',
  },
  {
    key: 'far fa-steering-wheel',
    value: 'steering-wheel',
  },
  {
    key: 'far fa-step-backward',
    value: 'step-backward',
  },
  {
    key: 'far fa-step-forward',
    value: 'step-forward',
  },
  {
    key: 'far fa-stethoscope',
    value: 'stethoscope',
  },
  {
    key: 'far fa-sticky-note',
    value: 'sticky-note',
  },
  {
    key: 'far fa-stocking',
    value: 'stocking',
  },
  {
    key: 'far fa-stomach',
    value: 'stomach',
  },
  {
    key: 'far fa-stop',
    value: 'stop',
  },
  {
    key: 'far fa-stop-circle',
    value: 'stop-circle',
  },
  {
    key: 'far fa-stopwatch',
    value: 'stopwatch',
  },
  {
    key: 'far fa-stopwatch-20',
    value: 'stopwatch-20',
  },
  {
    key: 'far fa-store',
    value: 'store',
  },
  {
    key: 'far fa-store-alt',
    value: 'store-alt',
  },
  {
    key: 'far fa-store-alt-slash',
    value: 'store-alt-slash',
  },
  {
    key: 'far fa-store-slash',
    value: 'store-slash',
  },
  {
    key: 'far fa-stream',
    value: 'stream',
  },
  {
    key: 'far fa-street-view',
    value: 'street-view',
  },
  {
    key: 'far fa-stretcher',
    value: 'stretcher',
  },
  {
    key: 'far fa-strikethrough',
    value: 'strikethrough',
  },
  {
    key: 'far fa-stroopwafel',
    value: 'stroopwafel',
  },
  {
    key: 'far fa-subscript',
    value: 'subscript',
  },
  {
    key: 'far fa-subway',
    value: 'subway',
  },
  {
    key: 'far fa-suitcase',
    value: 'suitcase',
  },
  {
    key: 'far fa-suitcase-rolling',
    value: 'suitcase-rolling',
  },
  {
    key: 'far fa-sun',
    value: 'sun',
  },
  {
    key: 'far fa-sun-cloud',
    value: 'sun-cloud',
  },
  {
    key: 'far fa-sun-dust',
    value: 'sun-dust',
  },
  {
    key: 'far fa-sun-haze',
    value: 'sun-haze',
  },
  {
    key: 'far fa-sunglasses',
    value: 'sunglasses',
  },
  {
    key: 'far fa-sunrise',
    value: 'sunrise',
  },
  {
    key: 'far fa-sunset',
    value: 'sunset',
  },
  {
    key: 'far fa-superscript',
    value: 'superscript',
  },
  {
    key: 'far fa-surprise',
    value: 'surprise',
  },
  {
    key: 'far fa-swatchbook',
    value: 'swatchbook',
  },
  {
    key: 'far fa-swimmer',
    value: 'swimmer',
  },
  {
    key: 'far fa-swimming-pool',
    value: 'swimming-pool',
  },
  {
    key: 'far fa-sword',
    value: 'sword',
  },
  {
    key: 'far fa-sword-laser',
    value: 'sword-laser',
  },
  {
    key: 'far fa-sword-laser-alt',
    value: 'sword-laser-alt',
  },
  {
    key: 'far fa-swords',
    value: 'swords',
  },
  {
    key: 'far fa-swords-laser',
    value: 'swords-laser',
  },
  {
    key: 'far fa-synagogue',
    value: 'synagogue',
  },
  {
    key: 'far fa-sync',
    value: 'sync',
  },
  {
    key: 'far fa-sync-alt',
    value: 'sync-alt',
  },
  {
    key: 'far fa-syringe',
    value: 'syringe',
  },
  {
    key: 'far fa-table',
    value: 'table',
  },
  {
    key: 'far fa-table-tennis',
    value: 'table-tennis',
  },
  {
    key: 'far fa-tablet',
    value: 'tablet',
  },
  {
    key: 'far fa-tablet-alt',
    value: 'tablet-alt',
  },
  {
    key: 'far fa-tablet-android',
    value: 'tablet-android',
  },
  {
    key: 'far fa-tablet-android-alt',
    value: 'tablet-android-alt',
  },
  {
    key: 'far fa-tablet-rugged',
    value: 'tablet-rugged',
  },
  {
    key: 'far fa-tablets',
    value: 'tablets',
  },
  {
    key: 'far fa-tachometer',
    value: 'tachometer',
  },
  {
    key: 'far fa-tachometer-alt',
    value: 'tachometer-alt',
  },
  {
    key: 'far fa-tachometer-alt-average',
    value: 'tachometer-alt-average',
  },
  {
    key: 'far fa-tachometer-alt-fast',
    value: 'tachometer-alt-fast',
  },
  {
    key: 'far fa-tachometer-alt-fastest',
    value: 'tachometer-alt-fastest',
  },
  {
    key: 'far fa-tachometer-alt-slow',
    value: 'tachometer-alt-slow',
  },
  {
    key: 'far fa-tachometer-alt-slowest',
    value: 'tachometer-alt-slowest',
  },
  {
    key: 'far fa-tachometer-average',
    value: 'tachometer-average',
  },
  {
    key: 'far fa-tachometer-fast',
    value: 'tachometer-fast',
  },
  {
    key: 'far fa-tachometer-fastest',
    value: 'tachometer-fastest',
  },
  {
    key: 'far fa-tachometer-slow',
    value: 'tachometer-slow',
  },
  {
    key: 'far fa-tachometer-slowest',
    value: 'tachometer-slowest',
  },
  {
    key: 'far fa-taco',
    value: 'taco',
  },
  {
    key: 'far fa-tag',
    value: 'tag',
  },
  {
    key: 'far fa-tags',
    value: 'tags',
  },
  {
    key: 'far fa-tally',
    value: 'tally',
  },
  {
    key: 'far fa-tanakh',
    value: 'tanakh',
  },
  {
    key: 'far fa-tape',
    value: 'tape',
  },
  {
    key: 'far fa-tasks',
    value: 'tasks',
  },
  {
    key: 'far fa-tasks-alt',
    value: 'tasks-alt',
  },
  {
    key: 'far fa-taxi',
    value: 'taxi',
  },
  {
    key: 'far fa-teeth',
    value: 'teeth',
  },
  {
    key: 'far fa-teeth-open',
    value: 'teeth-open',
  },
  {
    key: 'far fa-telescope',
    value: 'telescope',
  },
  {
    key: 'far fa-temperature-down',
    value: 'temperature-down',
  },
  {
    key: 'far fa-temperature-frigid',
    value: 'temperature-frigid',
  },
  {
    key: 'far fa-temperature-high',
    value: 'temperature-high',
  },
  {
    key: 'far fa-temperature-hot',
    value: 'temperature-hot',
  },
  {
    key: 'far fa-temperature-low',
    value: 'temperature-low',
  },
  {
    key: 'far fa-temperature-up',
    value: 'temperature-up',
  },
  {
    key: 'far fa-tenge',
    value: 'tenge',
  },
  {
    key: 'far fa-tennis-ball',
    value: 'tennis-ball',
  },
  {
    key: 'far fa-terminal',
    value: 'terminal',
  },
  {
    key: 'far fa-text',
    value: 'text',
  },
  {
    key: 'far fa-text-height',
    value: 'text-height',
  },
  {
    key: 'far fa-text-size',
    value: 'text-size',
  },
  {
    key: 'far fa-text-width',
    value: 'text-width',
  },
  {
    key: 'far fa-th',
    value: 'th',
  },
  {
    key: 'far fa-th-large',
    value: 'th-large',
  },
  {
    key: 'far fa-th-list',
    value: 'th-list',
  },
  {
    key: 'far fa-theater-masks',
    value: 'theater-masks',
  },
  {
    key: 'far fa-thermometer',
    value: 'thermometer',
  },
  {
    key: 'far fa-thermometer-empty',
    value: 'thermometer-empty',
  },
  {
    key: 'far fa-thermometer-full',
    value: 'thermometer-full',
  },
  {
    key: 'far fa-thermometer-half',
    value: 'thermometer-half',
  },
  {
    key: 'far fa-thermometer-quarter',
    value: 'thermometer-quarter',
  },
  {
    key: 'far fa-thermometer-three-quarters',
    value: 'thermometer-three-quarters',
  },
  {
    key: 'far fa-theta',
    value: 'theta',
  },
  {
    key: 'far fa-thumbs-down',
    value: 'thumbs-down',
  },
  {
    key: 'far fa-thumbs-up',
    value: 'thumbs-up',
  },
  {
    key: 'far fa-thumbtack',
    value: 'thumbtack',
  },
  {
    key: 'far fa-thunderstorm',
    value: 'thunderstorm',
  },
  {
    key: 'far fa-thunderstorm-moon',
    value: 'thunderstorm-moon',
  },
  {
    key: 'far fa-thunderstorm-sun',
    value: 'thunderstorm-sun',
  },
  {
    key: 'far fa-ticket',
    value: 'ticket',
  },
  {
    key: 'far fa-ticket-alt',
    value: 'ticket-alt',
  },
  {
    key: 'far fa-tilde',
    value: 'tilde',
  },
  {
    key: 'far fa-times',
    value: 'times',
  },
  {
    key: 'far fa-times-circle',
    value: 'times-circle',
  },
  {
    key: 'far fa-times-hexagon',
    value: 'times-hexagon',
  },
  {
    key: 'far fa-times-octagon',
    value: 'times-octagon',
  },
  {
    key: 'far fa-times-square',
    value: 'times-square',
  },
  {
    key: 'far fa-tint',
    value: 'tint',
  },
  {
    key: 'far fa-tint-slash',
    value: 'tint-slash',
  },
  {
    key: 'far fa-tire',
    value: 'tire',
  },
  {
    key: 'far fa-tire-flat',
    value: 'tire-flat',
  },
  {
    key: 'far fa-tire-pressure-warning',
    value: 'tire-pressure-warning',
  },
  {
    key: 'far fa-tire-rugged',
    value: 'tire-rugged',
  },
  {
    key: 'far fa-tired',
    value: 'tired',
  },
  {
    key: 'far fa-toggle-off',
    value: 'toggle-off',
  },
  {
    key: 'far fa-toggle-on',
    value: 'toggle-on',
  },
  {
    key: 'far fa-toilet',
    value: 'toilet',
  },
  {
    key: 'far fa-toilet-paper',
    value: 'toilet-paper',
  },
  {
    key: 'far fa-toilet-paper-alt',
    value: 'toilet-paper-alt',
  },
  {
    key: 'far fa-toilet-paper-slash',
    value: 'toilet-paper-slash',
  },
  {
    key: 'far fa-tombstone',
    value: 'tombstone',
  },
  {
    key: 'far fa-tombstone-alt',
    value: 'tombstone-alt',
  },
  {
    key: 'far fa-toolbox',
    value: 'toolbox',
  },
  {
    key: 'far fa-tools',
    value: 'tools',
  },
  {
    key: 'far fa-tooth',
    value: 'tooth',
  },
  {
    key: 'far fa-toothbrush',
    value: 'toothbrush',
  },
  {
    key: 'far fa-torah',
    value: 'torah',
  },
  {
    key: 'far fa-torii-gate',
    value: 'torii-gate',
  },
  {
    key: 'far fa-tornado',
    value: 'tornado',
  },
  {
    key: 'far fa-tractor',
    value: 'tractor',
  },
  {
    key: 'far fa-trademark',
    value: 'trademark',
  },
  {
    key: 'far fa-traffic-cone',
    value: 'traffic-cone',
  },
  {
    key: 'far fa-traffic-light',
    value: 'traffic-light',
  },
  {
    key: 'far fa-traffic-light-go',
    value: 'traffic-light-go',
  },
  {
    key: 'far fa-traffic-light-slow',
    value: 'traffic-light-slow',
  },
  {
    key: 'far fa-traffic-light-stop',
    value: 'traffic-light-stop',
  },
  {
    key: 'far fa-trailer',
    value: 'trailer',
  },
  {
    key: 'far fa-train',
    value: 'train',
  },
  {
    key: 'far fa-tram',
    value: 'tram',
  },
  {
    key: 'far fa-transgender',
    value: 'transgender',
  },
  {
    key: 'far fa-transgender-alt',
    value: 'transgender-alt',
  },
  {
    key: 'far fa-transporter',
    value: 'transporter',
  },
  {
    key: 'far fa-transporter-1',
    value: 'transporter-1',
  },
  {
    key: 'far fa-transporter-2',
    value: 'transporter-2',
  },
  {
    key: 'far fa-transporter-3',
    value: 'transporter-3',
  },
  {
    key: 'far fa-transporter-empty',
    value: 'transporter-empty',
  },
  {
    key: 'far fa-trash',
    value: 'trash',
  },
  {
    key: 'far fa-trash-alt',
    value: 'trash-alt',
  },
  {
    key: 'far fa-trash-restore',
    value: 'trash-restore',
  },
  {
    key: 'far fa-trash-restore-alt',
    value: 'trash-restore-alt',
  },
  {
    key: 'far fa-trash-undo',
    value: 'trash-undo',
  },
  {
    key: 'far fa-trash-undo-alt',
    value: 'trash-undo-alt',
  },
  {
    key: 'far fa-treasure-chest',
    value: 'treasure-chest',
  },
  {
    key: 'far fa-tree',
    value: 'tree',
  },
  {
    key: 'far fa-tree-alt',
    value: 'tree-alt',
  },
  {
    key: 'far fa-tree-christmas',
    value: 'tree-christmas',
  },
  {
    key: 'far fa-tree-decorated',
    value: 'tree-decorated',
  },
  {
    key: 'far fa-tree-large',
    value: 'tree-large',
  },
  {
    key: 'far fa-tree-palm',
    value: 'tree-palm',
  },
  {
    key: 'far fa-trees',
    value: 'trees',
  },
  {
    key: 'far fa-triangle',
    value: 'triangle',
  },
  {
    key: 'far fa-triangle-music',
    value: 'triangle-music',
  },
  {
    key: 'far fa-trophy',
    value: 'trophy',
  },
  {
    key: 'far fa-trophy-alt',
    value: 'trophy-alt',
  },
  {
    key: 'far fa-truck',
    value: 'truck',
  },
  {
    key: 'far fa-truck-container',
    value: 'truck-container',
  },
  {
    key: 'far fa-truck-couch',
    value: 'truck-couch',
  },
  {
    key: 'far fa-truck-loading',
    value: 'truck-loading',
  },
  {
    key: 'far fa-truck-monster',
    value: 'truck-monster',
  },
  {
    key: 'far fa-truck-moving',
    value: 'truck-moving',
  },
  {
    key: 'far fa-truck-pickup',
    value: 'truck-pickup',
  },
  {
    key: 'far fa-truck-plow',
    value: 'truck-plow',
  },
  {
    key: 'far fa-truck-ramp',
    value: 'truck-ramp',
  },
  {
    key: 'far fa-trumpet',
    value: 'trumpet',
  },
  {
    key: 'far fa-tshirt',
    value: 'tshirt',
  },
  {
    key: 'far fa-tty',
    value: 'tty',
  },
  {
    key: 'far fa-turkey',
    value: 'turkey',
  },
  {
    key: 'far fa-turntable',
    value: 'turntable',
  },
  {
    key: 'far fa-turtle',
    value: 'turtle',
  },
  {
    key: 'far fa-tv',
    value: 'tv',
  },
  {
    key: 'far fa-tv-alt',
    value: 'tv-alt',
  },
  {
    key: 'far fa-tv-music',
    value: 'tv-music',
  },
  {
    key: 'far fa-tv-retro',
    value: 'tv-retro',
  },
  {
    key: 'far fa-typewriter',
    value: 'typewriter',
  },
  {
    key: 'far fa-ufo',
    value: 'ufo',
  },
  {
    key: 'far fa-ufo-beam',
    value: 'ufo-beam',
  },
  {
    key: 'far fa-umbrella',
    value: 'umbrella',
  },
  {
    key: 'far fa-umbrella-beach',
    value: 'umbrella-beach',
  },
  {
    key: 'far fa-underline',
    value: 'underline',
  },
  {
    key: 'far fa-undo',
    value: 'undo',
  },
  {
    key: 'far fa-undo-alt',
    value: 'undo-alt',
  },
  {
    key: 'far fa-unicorn',
    value: 'unicorn',
  },
  {
    key: 'far fa-union',
    value: 'union',
  },
  {
    key: 'far fa-universal-access',
    value: 'universal-access',
  },
  {
    key: 'far fa-university',
    value: 'university',
  },
  {
    key: 'far fa-unlink',
    value: 'unlink',
  },
  {
    key: 'far fa-unlock',
    value: 'unlock',
  },
  {
    key: 'far fa-unlock-alt',
    value: 'unlock-alt',
  },
  {
    key: 'far fa-upload',
    value: 'upload',
  },
  {
    key: 'far fa-usb-drive',
    value: 'usb-drive',
  },
  {
    key: 'far fa-usd-circle',
    value: 'usd-circle',
  },
  {
    key: 'far fa-usd-square',
    value: 'usd-square',
  },
  {
    key: 'far fa-user',
    value: 'user',
  },
  {
    key: 'far fa-user-alien',
    value: 'user-alien',
  },
  {
    key: 'far fa-user-alt',
    value: 'user-alt',
  },
  {
    key: 'far fa-user-alt-slash',
    value: 'user-alt-slash',
  },
  {
    key: 'far fa-user-astronaut',
    value: 'user-astronaut',
  },
  {
    key: 'far fa-user-chart',
    value: 'user-chart',
  },
  {
    key: 'far fa-user-check',
    value: 'user-check',
  },
  {
    key: 'far fa-user-circle',
    value: 'user-circle',
  },
  {
    key: 'far fa-user-clock',
    value: 'user-clock',
  },
  {
    key: 'far fa-user-cog',
    value: 'user-cog',
  },
  {
    key: 'far fa-user-cowboy',
    value: 'user-cowboy',
  },
  {
    key: 'far fa-user-crown',
    value: 'user-crown',
  },
  {
    key: 'far fa-user-edit',
    value: 'user-edit',
  },
  {
    key: 'far fa-user-friends',
    value: 'user-friends',
  },
  {
    key: 'far fa-user-graduate',
    value: 'user-graduate',
  },
  {
    key: 'far fa-user-hard-hat',
    value: 'user-hard-hat',
  },
  {
    key: 'far fa-user-headset',
    value: 'user-headset',
  },
  {
    key: 'far fa-user-injured',
    value: 'user-injured',
  },
  {
    key: 'far fa-user-lock',
    value: 'user-lock',
  },
  {
    key: 'far fa-user-md',
    value: 'user-md',
  },
  {
    key: 'far fa-user-md-chat',
    value: 'user-md-chat',
  },
  {
    key: 'far fa-user-minus',
    value: 'user-minus',
  },
  {
    key: 'far fa-user-music',
    value: 'user-music',
  },
  {
    key: 'far fa-user-ninja',
    value: 'user-ninja',
  },
  {
    key: 'far fa-user-nurse',
    value: 'user-nurse',
  },
  {
    key: 'far fa-user-plus',
    value: 'user-plus',
  },
  {
    key: 'far fa-user-robot',
    value: 'user-robot',
  },
  {
    key: 'far fa-user-secret',
    value: 'user-secret',
  },
  {
    key: 'far fa-user-shield',
    value: 'user-shield',
  },
  {
    key: 'far fa-user-slash',
    value: 'user-slash',
  },
  {
    key: 'far fa-user-tag',
    value: 'user-tag',
  },
  {
    key: 'far fa-user-tie',
    value: 'user-tie',
  },
  {
    key: 'far fa-user-times',
    value: 'user-times',
  },
  {
    key: 'far fa-user-unlock',
    value: 'user-unlock',
  },
  {
    key: 'far fa-user-visor',
    value: 'user-visor',
  },
  {
    key: 'far fa-users',
    value: 'users',
  },
  {
    key: 'far fa-users-class',
    value: 'users-class',
  },
  {
    key: 'far fa-users-cog',
    value: 'users-cog',
  },
  {
    key: 'far fa-users-crown',
    value: 'users-crown',
  },
  {
    key: 'far fa-users-medical',
    value: 'users-medical',
  },
  {
    key: 'far fa-users-slash',
    value: 'users-slash',
  },
  {
    key: 'far fa-utensil-fork',
    value: 'utensil-fork',
  },
  {
    key: 'far fa-utensil-knife',
    value: 'utensil-knife',
  },
  {
    key: 'far fa-utensil-spoon',
    value: 'utensil-spoon',
  },
  {
    key: 'far fa-utensils',
    value: 'utensils',
  },
  {
    key: 'far fa-utensils-alt',
    value: 'utensils-alt',
  },
  {
    key: 'far fa-vacuum',
    value: 'vacuum',
  },
  {
    key: 'far fa-vacuum-robot',
    value: 'vacuum-robot',
  },
  {
    key: 'far fa-value-absolute',
    value: 'value-absolute',
  },
  {
    key: 'far fa-vector-square',
    value: 'vector-square',
  },
  {
    key: 'far fa-venus',
    value: 'venus',
  },
  {
    key: 'far fa-venus-double',
    value: 'venus-double',
  },
  {
    key: 'far fa-venus-mars',
    value: 'venus-mars',
  },
  {
    key: 'far fa-vest',
    value: 'vest',
  },
  {
    key: 'far fa-vest-patches',
    value: 'vest-patches',
  },
  {
    key: 'far fa-vhs',
    value: 'vhs',
  },
  {
    key: 'far fa-vial',
    value: 'vial',
  },
  {
    key: 'far fa-vials',
    value: 'vials',
  },
  {
    key: 'far fa-video',
    value: 'video',
  },
  {
    key: 'far fa-video-plus',
    value: 'video-plus',
  },
  {
    key: 'far fa-video-slash',
    value: 'video-slash',
  },
  {
    key: 'far fa-vihara',
    value: 'vihara',
  },
  {
    key: 'far fa-violin',
    value: 'violin',
  },
  {
    key: 'far fa-virus',
    value: 'virus',
  },
  {
    key: 'far fa-virus-slash',
    value: 'virus-slash',
  },
  {
    key: 'far fa-viruses',
    value: 'viruses',
  },
  {
    key: 'far fa-voicemail',
    value: 'voicemail',
  },
  {
    key: 'far fa-volcano',
    value: 'volcano',
  },
  {
    key: 'far fa-volleyball-ball',
    value: 'volleyball-ball',
  },
  {
    key: 'far fa-volume',
    value: 'volume',
  },
  {
    key: 'far fa-volume-down',
    value: 'volume-down',
  },
  {
    key: 'far fa-volume-mute',
    value: 'volume-mute',
  },
  {
    key: 'far fa-volume-off',
    value: 'volume-off',
  },
  {
    key: 'far fa-volume-slash',
    value: 'volume-slash',
  },
  {
    key: 'far fa-volume-up',
    value: 'volume-up',
  },
  {
    key: 'far fa-vote-nay',
    value: 'vote-nay',
  },
  {
    key: 'far fa-vote-yea',
    value: 'vote-yea',
  },
  {
    key: 'far fa-vr-cardboard',
    value: 'vr-cardboard',
  },
  {
    key: 'far fa-wagon-covered',
    value: 'wagon-covered',
  },
  {
    key: 'far fa-walker',
    value: 'walker',
  },
  {
    key: 'far fa-walkie-talkie',
    value: 'walkie-talkie',
  },
  {
    key: 'far fa-walking',
    value: 'walking',
  },
  {
    key: 'far fa-wallet',
    value: 'wallet',
  },
  {
    key: 'far fa-wand',
    value: 'wand',
  },
  {
    key: 'far fa-wand-magic',
    value: 'wand-magic',
  },
  {
    key: 'far fa-warehouse',
    value: 'warehouse',
  },
  {
    key: 'far fa-warehouse-alt',
    value: 'warehouse-alt',
  },
  {
    key: 'far fa-washer',
    value: 'washer',
  },
  {
    key: 'far fa-watch',
    value: 'watch',
  },
  {
    key: 'far fa-watch-calculator',
    value: 'watch-calculator',
  },
  {
    key: 'far fa-watch-fitness',
    value: 'watch-fitness',
  },
  {
    key: 'far fa-water',
    value: 'water',
  },
  {
    key: 'far fa-water-lower',
    value: 'water-lower',
  },
  {
    key: 'far fa-water-rise',
    value: 'water-rise',
  },
  {
    key: 'far fa-wave-sine',
    value: 'wave-sine',
  },
  {
    key: 'far fa-wave-square',
    value: 'wave-square',
  },
  {
    key: 'far fa-wave-triangle',
    value: 'wave-triangle',
  },
  {
    key: 'far fa-waveform',
    value: 'waveform',
  },
  {
    key: 'far fa-waveform-path',
    value: 'waveform-path',
  },
  {
    key: 'far fa-webcam',
    value: 'webcam',
  },
  {
    key: 'far fa-webcam-slash',
    value: 'webcam-slash',
  },
  {
    key: 'far fa-weight',
    value: 'weight',
  },
  {
    key: 'far fa-weight-hanging',
    value: 'weight-hanging',
  },
  {
    key: 'far fa-whale',
    value: 'whale',
  },
  {
    key: 'far fa-wheat',
    value: 'wheat',
  },
  {
    key: 'far fa-wheelchair',
    value: 'wheelchair',
  },
  {
    key: 'far fa-whistle',
    value: 'whistle',
  },
  {
    key: 'far fa-wifi',
    value: 'wifi',
  },
  {
    key: 'far fa-wifi-1',
    value: 'wifi-1',
  },
  {
    key: 'far fa-wifi-2',
    value: 'wifi-2',
  },
  {
    key: 'far fa-wifi-slash',
    value: 'wifi-slash',
  },
  {
    key: 'far fa-wind',
    value: 'wind',
  },
  {
    key: 'far fa-wind-turbine',
    value: 'wind-turbine',
  },
  {
    key: 'far fa-wind-warning',
    value: 'wind-warning',
  },
  {
    key: 'far fa-window',
    value: 'window',
  },
  {
    key: 'far fa-window-alt',
    value: 'window-alt',
  },
  {
    key: 'far fa-window-close',
    value: 'window-close',
  },
  {
    key: 'far fa-window-frame',
    value: 'window-frame',
  },
  {
    key: 'far fa-window-frame-open',
    value: 'window-frame-open',
  },
  {
    key: 'far fa-window-maximize',
    value: 'window-maximize',
  },
  {
    key: 'far fa-window-minimize',
    value: 'window-minimize',
  },
  {
    key: 'far fa-window-restore',
    value: 'window-restore',
  },
  {
    key: 'far fa-windsock',
    value: 'windsock',
  },
  {
    key: 'far fa-wine-bottle',
    value: 'wine-bottle',
  },
  {
    key: 'far fa-wine-glass',
    value: 'wine-glass',
  },
  {
    key: 'far fa-wine-glass-alt',
    value: 'wine-glass-alt',
  },
  {
    key: 'far fa-won-sign',
    value: 'won-sign',
  },
  {
    key: 'far fa-wreath',
    value: 'wreath',
  },
  {
    key: 'far fa-wrench',
    value: 'wrench',
  },
  {
    key: 'far fa-x-ray',
    value: 'x-ray',
  },
  {
    key: 'far fa-yen-sign',
    value: 'yen-sign',
  },
  {
    key: 'far fa-yin-yang',
    value: 'yin-yang',
  },
]

<template>
  <div class="rate-listing-view">
    <!-- Add column code -->

    <quest-card :panel="true">
      <div class="row">
        <div class="col-12 col-md-4">
          <quest-input
            id="search"
            label="Search"
            v-model="searchText"
            placeholder="Search by rate name / group code"
          ></quest-input>
        </div>
        <div class="col-12 col-md-4">
          <quest-single-select
            v-model="display"
            :options="displays"
            label="Display"
            id="display"
            @update="updateDisplay"
          ></quest-single-select>
        </div>
        <div class="col-12 col-md-4">
          <quest-toggle
            checked="Show"
            unchecked="Hide"
            label="Inherited"
            v-model="inherited"
            unchecked-theme="dark"
          ></quest-toggle>
        </div>
      </div>
    </quest-card>

    <template v-if="!loading">
      <rate-listing-table
        v-if="display == 'ALL' || display == 'PUB'"
        :fields="fields"
        :term="searchText"
        :items="
          inherited
            ? rates.public
            : rates.public.filter((x) => !x.isInherited || x.isOverride)
        "
        caption="Public rates"
        @read="($event) => $refs.editor.read($event)"
        @edit="($event) => $refs.editor.edit($event)"
        @revise="($event) => $refs.editor.revise($event)"
        @override="($event) => override($event)"
        @delete="($event) => remove($event)"
        @optIn="($event) => optIn($event)"
        @optOut="($event) => optOut($event)"
        @enable="($event) => enable($event)"
        @disable="($event) => disable($event)"
      ></rate-listing-table>

      <rate-listing-table
        v-if="display == 'ALL' || display == 'PRV'"
        :fields="fields"
        :term="searchText"
        :items="
          inherited
            ? rates.private
            : rates.private.filter((x) => !x.isInherited || x.isOverride)
        "
        caption="Private rates"
        @read="($event) => $refs.editor.read($event)"
        @edit="($event) => $refs.editor.edit($event)"
        @revise="($event) => $refs.editor.revise($event)"
        @override="($event) => override($event)"
        @delete="($event) => remove($event)"
        @optIn="($event) => optIn($event)"
        @optOut="($event) => optOut($event)"
        @enable="($event) => enable($event)"
        @disable="($event) => disable($event)"
      ></rate-listing-table>

      <rate-listing-table
        v-if="display == 'ALL' || display == 'SPC'"
        :fields="fields"
        :term="searchText"
        :items="
          inherited
            ? rates.special
            : rates.special.filter((x) => !x.isInherited || x.isOverride)
        "
        caption="Deals / Specials"
        @read="($event) => $refs.editor.read($event)"
        @edit="($event) => $refs.editor.edit($event)"
        @revise="($event) => $refs.editor.revise($event)"
        @override="($event) => override($event)"
        @delete="($event) => remove($event)"
        @optIn="($event) => optIn($event)"
        @optOut="($event) => optOut($event)"
        @enable="($event) => enable($event)"
        @disable="($event) => disable($event)"
      ></rate-listing-table>

      <rate-listing-table
        v-if="display == 'ALL' || display == 'MO'"
        :fields="fields"
        :term="searchText"
        :items="
          inherited
            ? rates.member
            : rates.member.filter((x) => !x.isInherited || x.isOverride)
        "
        caption="Member rates"
        @read="($event) => $refs.editor.read($event)"
        @edit="($event) => $refs.editor.edit($event)"
        @revise="($event) => $refs.editor.revise($event)"
        @override="($event) => override($event)"
        @delete="($event) => remove($event)"
        @optIn="($event) => optIn($event)"
        @optOut="($event) => optOut($event)"
        @enable="($event) => enable($event)"
        @disable="($event) => disable($event)"
      ></rate-listing-table>
      <div
        class="border rounded p-3 mt-3 bg-white"
        v-if="
          rates.public.length === 0 &&
          rates.private.length === 0 &&
          rates.special.length === 0 &&
          rates.member.length === 0
        "
      >
        <div class="small text-center">
          No rates were found for your search.
        </div>
      </div>
    </template>
    <template v-else>
      <div class="text-gray-400 text-center my-5">
        <font-awesome-icon
          icon="spinner"
          size="4x"
          fixed-width
          pulse
        ></font-awesome-icon>
        <p class="pt-3 pb-0">Loading...</p>
      </div>
    </template>
    <rate-editor
      ref="editor"
      @saved="() => saved()"
      @saving="$refs.saving.show()"
      @failed="$refs.saving.hide()"
      @check="(val) => check(val)"
    ></rate-editor>

    <b-modal
      ref="check"
      title="Duplicate group codes found"
      :ok-title="'Confirm'"
      centered
      header-bg-variant="danger"
      header-text-variant="white"
      button-size="sm"
      size="md"
      ok-variant="danger"
      @ok="() => confirm('check')"
      no-close-on-backdrop
      no-close-on-esc
    >
      <b class="text-center d-block">Are you sure you wish to proceed?</b
      ><br />The below records are in the scope of the specified rate and
      include the same group code.<br />
      <hr />
      <b-table-simple small responsive borderless>
        <b-tbody>
          <b-tr v-for="record in checkRecords" :key="record.id">
            <b-td>{{ record.name }}</b-td>
            <b-td>{{ record.type }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-modal>

    <b-modal
      ref="enable"
      title="Enable"
      :ok-title="'Enable'"
      centered
      button-size="sm"
      @ok="() => confirm('enable')"
      @cancel="() => cancel()"
    >
      Are you sure you wish to enable '{{ selected?.name || '(untitled)' }}'?
      All applicable rooms and any descendant opted-in rates will automatically
      activate.
    </b-modal>
    <b-modal
      ref="disable"
      title="Disable"
      :ok-title="'Disable'"
      ok-variant="danger"
      centered
      button-size="sm"
      @ok="() => confirm('disable')"
      @cancel="() => cancel()"
    >
      Are you sure you wish to disable '{{ selected?.name || '(untitled)' }}'?
      All applicable rooms and any descendant opted-in rates will automatically
      deactivate.
    </b-modal>
    <b-modal
      ref="optIn"
      title="Opt in"
      :ok-title="'Opt in'"
      centered
      button-size="sm"
      @ok="() => confirm('optIn')"
      @cancel="() => cancel()"
    >
      Are you sure you wish to opt in '{{ selected?.name || '(untitled)' }}'?
      All applicable rooms and any descendant opted-in rates will automatically
      activate.
    </b-modal>
    <b-modal
      ref="optOut"
      title="Opt out"
      :ok-title="'Opt out'"
      ok-variant="danger"
      centered
      button-size="sm"
      @ok="() => confirm('optOut')"
      @cancel="() => cancel()"
    >
      Are you sure you wish to opt out '{{ selected?.name || '(untitled)' }}'?
      All applicable rooms and any descendant opted-in rates will automatically
      deactivate.
    </b-modal>
    <b-modal
      ref="delete"
      title="Delete"
      :ok-title="'Delete'"
      ok-variant="danger"
      centered
      button-size="sm"
      header-bg-variant="danger"
      body-bg-variant="danger"
      footer-bg-variant="danger"
      header-text-variant="white"
      body-text-variant="white"
      @ok="() => confirm('delete')"
      @cancel="() => cancel()"
    >
      Are you sure you wish to delete '{{ selected?.name || '(untitled)' }}'?
      This action is <b>permanent</b> are will delete all derived rates as well.
    </b-modal>
    <b-modal
      ref="saving"
      centered
      size="sm"
      body-class="p-0"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="container-xl my-5">
        <div class="text-gray-400 text-center">
          <font-awesome-icon
            icon="save"
            size="4x"
            fixed-width
            pulse
            class="animated--heart-beat"
          ></font-awesome-icon>
          <p class="pt-3 pb-0">Saving</p>
          <small>This may take a moment</small>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { NexusRatesService } from '@/services'
import { RateView } from '@/models'
import { uuidv4 } from '@/helpers'
import RateListingViewBase from './rate-listing-view-base'

import RateListingTable from './rate-listing-table.vue'
import RateEditor from './rate-editor.vue'

import { QuestCard } from '../structure'

import { QuestInput, QuestSingleSelect, QuestToggle } from '../form'

export default {
  name: 'rate-listing-view',
  extends: RateListingViewBase,
  components: {
    RateListingTable,
    RateEditor,
    QuestCard,
    QuestInput,
    QuestSingleSelect,
    QuestToggle,
  },
  data() {
    return {
      inherited: true,
      searchText: '',
      display: 'ALL',
      displays: [
        {
          key: 'ALL',
          value: 'All',
        },
        {
          key: 'PUB',
          value: 'Public',
        },
        {
          key: 'PRV',
          value: 'Private',
        },
        {
          key: 'SPC',
          value: 'Specials/Deals',
        },
        {
          key: 'MO',
          value: 'Member Only',
        },
      ],
    }
  },
  props: {
    countries: { default: () => [] },
    properties: { default: () => [] },
  },
  methods: {
    refresh() {
      this.search(
        this.scope,
        this.country,
        this.state,
        this.property,
        this.room
      )
    },
    updateDisplay(e) {
      this.display = e
    },
    search(scope, country, state, property, room) {
      this.loading = true
      let hash = uuidv4()
      this.hash = hash
      new NexusRatesService()
        .view(
          this.account,
          scope,
          country || null,
          state || null,
          property || null,
          room || null
        )
        .then((data) => {
          if (hash == this.hash) {
            this.scope = scope
            this.country = country || null
            this.state = state
            this.property = property || null
            this.room = room || null

            this.rates = RateView.from(data, {
              scope,
              country: this.country,
              state: this.state,
              property: this.property,
              room: this.room,
              countries: this.countries || [],
              properties: this.properties || [],
            })
            this.loading = false
          }
        })
        .catch((_) => (this.loading = false))
    },
  },
}
</script>

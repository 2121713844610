<template>
  <div class="table-responsive">
    <table class="table table-bordered" :id="id" width="100%" cellspacing="0">
      <thead>
        <tr>
          <th v-for="(column, idx) in columns" :key="idx">{{ column }}</th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <th v-for="(column, idx) in columns" :key="idx">{{ column }}</th>
        </tr>
      </tfoot>
      <tbody>
        <slot></slot>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'quest-table',
  props: {
    id: String,
    columns: Array,
  },
}
</script>

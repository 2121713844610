<template>
  <div class="row">
    <div class="col-12 col-md-4">
      <div class="d-block text-md-right">
        <label :for="id" class="pr-2">{{ label }}</label>
        <a
          :id="id + '-popover-target'"
          class="pointer text-secondary"
          v-if="tip"
        >
          <font-awesome-icon
            :icon="['fas', 'info-circle']"
            fixed-width
          ></font-awesome-icon>
        </a>
        <b-popover
          v-if="tip"
          :target="id + '-popover-target'"
          triggers="hover"
          placement="top"
        >
          <template #title>{{ label }}</template>
          {{ tip }}
        </b-popover>
      </div>
    </div>
    <div class="d-flex col-12 col-md-8 gap-2">
      <div class="flex-grow-1"><slot></slot></div>
      <quest-toggle
        v-if="base"
        :checked-icon="['fas', 'unlock']"
        :unchecked-icon="['fas', 'lock']"
        :id="id + '-override-toggle'"
        :value="override"
        @input="(e) => $emit('toggle', e)"
        no-label="true"
        inline="true"
      />
    </div>
  </div>
</template>

<script>
import { QuestToggle } from '../form'

export default {
  name: 'editor-row',
  components: {
    QuestToggle,
  },
  props: {
    id: { required: true },
    label: { required: true },
    tip: { default: '' },
    base: { default: null },
    override: { default: false },
  },
}
</script>

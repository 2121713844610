import AdminNexusHttpClient from './admin-nexus-http-client'

export default class ExternalAccountsService {
  constructor() {
    this.httpClient = new AdminNexusHttpClient('/admin/external-accounts')
  }

  async list() {
    return await this.httpClient.get('')
  }

  async all() {
    return await this.httpClient.get('all')
  }

  async get(id) {
    return await this.httpClient.get(`${id}`)
  }

  async add(payload) {
    return await this.httpClient.put(``, payload)
  }

  async update(payload) {
    return await this.httpClient.post(``, payload)
  }

  async getRates(id) {
    return await this.httpClient.get(`${id}/rates`)
  }

  async setRates(id, exclusions) {
    return await this.httpClient.post(`${id}/rates`, { id, exclusions })
  }

  async toggleAccountService(id, enable) {
    return await this.httpClient.post(`${id}/autocreate`, { id, enable })
  }

  async setMinQuoteThreshold(id, threshold) {
    return await this.httpClient.post(`${id}/min-threshold`, { id, threshold })
  }
}

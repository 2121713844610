var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('div',{staticClass:"border bg-white p-3 my-3 rounded"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"d-flex col-12 gap-2 justify-content-between mb-3"},[_c('label',[_vm._v("Extras / Inclusions")]),_c('div',{staticClass:"d-flex gap-2"},[_c('quest-single-select',{attrs:{"placeholder":"Select an extra to configure","value":_vm.extra,"options":_vm.extras,"id":"extra","okey":"id","otext":"name","wrapperClass":"form-group m-0"},on:{"update":_vm.updateExtra}}),_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":() => _vm.add()}},[_c('span',{staticClass:"icon text-white-50"},[_c('font-awesome-icon',{attrs:{"fixed-width":"","icon":['fas', 'plus']}})],1),_c('span',{staticClass:"text"},[_vm._v("Add")])])],1)])]),(!_vm.extras.length)?_c('small',{staticClass:"text-center d-block"},[_vm._v("No extras are available for this property")]):_vm._e(),(_vm.items.length)?[_c('b-table',{attrs:{"items":_vm.items,"fields":[
        { key: 'name' },
        { key: 'relationship' },
        {
          key: 'options',
          label: '',
          thStyle: { width: '50px' },
          tdClass: 'text-center',
        },
      ],"small":"","hover":"","fixed":"","stacked":"md","borderless":"","tbody-tr-class":"align-middle"},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_vm._v(_vm._s(_vm.extras.find((x) => x.id === data.item.key).name))]}},{key:"cell(relationship)",fn:function(data){return [(data.item.isDefault)?_c('span',[_vm._v("Optional (Default)")]):_vm._e(),(!data.item.isDefault)?_c('quest-single-select',{attrs:{"value":data.item.value,"options":_vm.extras.find((x) => x.id === data.item.key).interval ===
            _vm.ExtraInterval.Booking
              ? _vm.bookingRelationships
              : _vm.relationships,"id":"extra","okey":"key","otext":"value","wrapperClass":"form-group m-0"},on:{"update":(e) => (data.item.value = e)}}):_vm._e()]}},{key:"cell(options)",fn:function(data){return [(!data.item.isDefault)?_c('a',{staticClass:"pointer",on:{"click":function($event){return _vm.remove(data.item)}}},[_c('font-awesome-icon',{attrs:{"icon":"times","size":"sm"}})],1):_vm._e()]}}],null,false,1483977211)})]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <quest-main
    title="Snippets"
    copy="Management of global property promotional materials / snippets / guarantees"
    :loading="loading"
  >
    <template v-slot:head>
      <router-link
        :to="{ name: 'snippets-add' }"
        class="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
      >
        <font-awesome-icon
          icon="plus"
          size="sm"
          class="text-white-50"
        ></font-awesome-icon>
        Add</router-link
      >
    </template>
    <template v-slot:body>
      <quest-card title="Snippets collection">
        <quest-table id="snippets-table" :columns="columns">
          <template v-if="snippets.items.length > 0">
            <tr
              class="small align-middle"
              v-for="snippet in snippets.items"
              :key="snippet.id"
            >
              <td>
                <router-link
                  :to="{ name: 'snippets-edit', params: { id: snippet.id } }"
                  >{{ snippet.name }}</router-link
                >
              </td>
              <td>{{ lastModified(snippet.modifiedUTC) }}</td>
              <td>
                <b-dropdown no-caret variant="link" class="b-dropdown-p-0">
                  <template #button-content>
                    <font-awesome-icon
                      icon="ellipsis-v"
                      size="sm"
                      fixed-width
                      class="text-gray-400"
                    ></font-awesome-icon>
                  </template>
                  <b-dropdown-header>Actions</b-dropdown-header>
                  <b-dropdown-item
                    :to="{
                      name: 'snippets-edit',
                      params: { id: snippet.id },
                    }"
                    >View</b-dropdown-item
                  >
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item-button
                    @click="promptDelete(snippet)"
                    text="danger"
                    variant="danger"
                    >Delete</b-dropdown-item-button
                  >
                </b-dropdown>
              </td>
            </tr>
          </template>
          <template v-if="snippets.items.length === 0">
            <tr class="align-middle">
              <td :colspan="columns.length">
                <div class="small text-center py-5">
                  There are no snippets in the system
                </div>
              </td>
            </tr>
          </template>
        </quest-table>
        <quest-pager
          v-if="snippets.total > pageSize"
          @changePage="changePage"
          :currentPage="page"
          :itemCount="snippets.total"
          :pageSize="pageSize"
        ></quest-pager>
      </quest-card>
      <b-modal v-model="showDeleteModal" centered title="Delete">
        <p class="my-4" v-if="toDelete">
          Are you sure you wish to delete snippet '{{ toDelete.name }}'?
        </p>
        <template v-slot:modal-footer>
          <button
            class="btn btn-secondary btn-sm"
            type="button"
            @click="cancelPromptDelete"
          >
            Cancel</button
          ><button
            class="btn btn-danger btn-sm"
            type="button"
            @click="confirmPromptDelete"
          >
            Delete
          </button>
        </template>
      </b-modal>
    </template>
  </quest-main>
</template>

<script>
import { success, error } from '../helpers'
import moment from 'moment'
import 'moment-timezone'
import {
  QuestMain,
  QuestTable,
  QuestCard,
  QuestModal,
  QuestPager,
} from './structure'
import { SnippetService } from '../services'
import PagedResult from '../models/paged-result'

export default {
  name: 'snippets',
  components: {
    QuestMain,
    QuestTable,
    QuestCard,
    QuestPager,
    QuestModal,
  },
  data() {
    return {
      pageSize: 10,
      page: 1,
      term: '',
      snippets: new PagedResult(),
      columns: ['Name', 'Modified', ''],
      loading: true,
      toDelete: null,
      showDeleteModal: false,
    }
  },
  mounted() {
    this.changePage(1)
  },
  methods: {
    changePage(page) {
      new SnippetService()
        .list(page, this.pageSize, this.term)
        .then((data) => {
          this.snippets = data
          this.page = page
        })
        .finally((_) => (this.loading = false))
    },
    lastModified(date) {
      return moment(`${date}Z`).tz('Australia/Melbourne').format('lll')
    },
    promptDelete(amenity) {
      this.toDelete = amenity
      this.showDeleteModal = true
    },
    cancelPromptDelete() {
      this.showDeleteModal = false
      this.toDelete = null
    },
    confirmPromptDelete() {
      new SnippetService()
        .delete(this.toDelete.id)
        .then((data) => {
          this.showDeleteModal = false

          if (data) {
            this.changePage(1)
            success(`Deleted snippet #`, this.toDelete.name)
          } else {
            error(`Failed to delete snippet #`, this.toDelete.name)
          }
        })
        .finally((_) => {
          this.toDelete = null
        })
    },
  },
}
</script>

import AdminNexusHttpClient from './admin-nexus-http-client'

export default class SitesManagementService {
  constructor() {
    this.httpClient = new AdminNexusHttpClient('/admin/sites')
  }

  async list() {
    return await this.httpClient.get('')
  }

  async get(id) {
    return await this.httpClient.get(`${id}`)
  }

  async getByCountry(id) {
    return await this.httpClient.get(`by-country/${id}`)
  }

  async getCSR(id) {
    return await this.httpClient.get(`${id}/csr`)
  }

  async update(payload) {
    return await this.httpClient.post('', payload)
  }
}

<template>
  <div class="form-group">
    <label :for="id">{{ label }}</label>
    <multiselect
      :id="id"
      v-model="values"
      tag-placeholder="Add this as new tag"
      placeholder="Add a tag"
      label="name"
      track-by="name"
      :options="values"
      :multiple="true"
      :close-on-select="false"
      :taggable="true"
      @tag="addTag"
      @remove="removeTag"
    ></multiselect>
    <small v-if="help" :id="id + 'Help'" class="form-text text-muted">{{
      help
    }}</small>
  </div>
</template>

<script>
import form from './form'

export default {
  name: 'quest-tag-input',
  extends: form,
  data() {
    return {
      values: [],
    }
  },
  async mounted() {
    await this.$nextTick()

    const selectInput = document.querySelector(`#${this.id}.multiselect__input`)

    if (selectInput) {
      selectInput.setAttribute('maxlength', '40')
    }
  },
  created() {
    this.values = this.value.map((x) => ({ name: x }))
  },
  watch: {
    value() {
      this.values = this.value.map((x) => ({ name: x }))
    },
  },
  methods: {
    addTag(newTag) {
      const tag = {
        name: newTag,
      }
      this.values.push(tag)
      this.$emit(
        'update',
        this.values.map((x) => x.name)
      )
    },
    removeTag(tag) {
      this.values = this.values.filter((x) => x.name !== tag.name)
      this.$emit(
        'update',
        this.values.map((x) => x.name)
      )
    },
  },
}
</script>

<template>
  <div>
    <quest-card panel v-if="!p_any">
      <h4 class="text-center h4 text-gray-400 mt-2 mb-2">
        You do not have access to modify any fields in this tab
      </h4>
    </quest-card>
    <template v-if="p_any">
      <quest-card
        panel
        v-if="
          [p_email, p_phoneNumber, p_facebookURL, p_instagramURL, p_tags].some(
            (x) => x
          )
        "
      >
        <div
          class="row"
          v-if="
            [p_email, p_phoneNumber, p_facebookURL, p_instagramURL].some(
              (x) => x
            )
          "
        >
          <div class="col-md-6" v-if="p_email">
            <quest-input
              id="email"
              label="Email Address"
              v-model="property.emailAddress"
              type="email"
              help="Public contact email for property"
            >
              <template v-slot:prepend>
                <font-awesome-icon
                  :icon="['fas', 'envelope']"
                ></font-awesome-icon>
              </template>
            </quest-input>
          </div>

          <div class="col-md-6" v-if="p_phoneNumber">
            <quest-input
              id="phoneNumber"
              label="Contact Number"
              v-model="property.phoneNumber"
              help="Public contact number for property"
            >
              <template v-slot:prepend>
                <font-awesome-icon :icon="['fas', 'phone']"></font-awesome-icon>
              </template>
            </quest-input>
          </div>
          <div class="col-md-6" v-if="p_facebookURL">
            <quest-input
              id="facebookURL"
              label="Facebook URL"
              v-model="property.facebookURL"
              help="Add the profile slug (e.g. 'questapartmenthotels' from 'www.facebook.com/questapartmenthotels')"
            >
              <template v-slot:prepend>
                <font-awesome-icon
                  :icon="['fab', 'facebook']"
                ></font-awesome-icon>
              </template>
            </quest-input>
          </div>
          <div class="col-md-6" v-if="p_instagramURL">
            <quest-input
              id="instagramURL"
              label="Instagram URL"
              v-model="property.instagramURL"
              help="Add the profile slug (e.g. 'questapartments' from 'www.instagram.com/questapartments')"
            >
              <template v-slot:prepend>
                <font-awesome-icon
                  :icon="['fab', 'instagram']"
                ></font-awesome-icon>
              </template>
            </quest-input>
          </div>
        </div>

        <div class="row" v-if="p_tags">
          <div class="col-12">
            <quest-tag-input
              id="tags"
              label="Tagging"
              v-model="property.tags"
              @update="updateTags"
              help="Add marketing tags for this property (e.g. 'Family friendly')"
            ></quest-tag-input>
          </div>
        </div>
      </quest-card>

      <quest-card
        title="Page &amp; SEO"
        id="pageAndSEOform"
        :collapsable="true"
        :collapsed="true"
        v-if="[p_pageTitle, p_tagline, p_summary, p_description].some((x) => x)"
      >
        <div class="row" v-if="[p_pageTitle, p_tagline].some((x) => x)">
          <div class="col-md-6" v-if="p_pageTitle">
            <quest-input
              id="pageTitle"
              label="Page Title"
              v-model="property.pageTitle"
              help="Page and social media panel title"
            ></quest-input>
          </div>

          <div class="col-md-6" v-if="p_tagline">
            <quest-input
              id="tagline"
              label="Tagline"
              v-model="property.tagline"
              help="A short, catchy sentence introducing the user to the property"
              :rules="{ [MAX]: { length: 42 } }"
            ></quest-input>
          </div>
        </div>
        <div class="row" v-if="[p_summary, p_description].some((x) => x)">
          <div class="col-12" v-if="p_summary">
            <quest-text-area
              id="summary"
              label="Summary"
              v-model="property.summary"
              help="Summary used for tiles and list views for the property. Also used as the descriptor in social media panels."
              :rules="{ [MAX]: { length: 120 } }"
            ></quest-text-area>
          </div>
          <div class="col-12" v-if="p_description">
            <quest-editor
              name="Description"
              label="Description"
              v-model="property.description"
              :rules="{ [MAX]: { length: 3000 } }"
            ></quest-editor>
          </div>
        </div>
      </quest-card>

      <announcement-card
        v-show="p_announcement"
        :property="property"
        ref="announcement"
        :announcement="announcement"
      ></announcement-card>
    </template>
    <template v-if="p_any">
      <imagery-card :property="property" v-if="p_imagery"></imagery-card>
      <amenity-card
        :property="property"
        ref="amenities"
        v-if="p_amenities"
      ></amenity-card>
      <relationships-card
        :property="property"
        v-if="p_relationships"
      ></relationships-card>
    </template>
    <csr-card
      :property="property"
      ref="csr"
      v-show="p_csr"
      :csr="csr"
    ></csr-card>
  </div>
</template>

<script>
import View from './view'
import { Properties } from '@/router/permission-types'
import { hasFieldAccess } from '@/helpers'

import {
  LocationCard,
  RelationshipsCard,
  ImageryCard,
  AmenityCard,
  CSRCard,
  AnnouncementCard,
} from '../property-cards'

export default {
  extends: View,
  name: 'property-content-view',
  components: {
    LocationCard,
    RelationshipsCard,
    ImageryCard,
    AmenityCard,
    'csr-card': CSRCard,
    AnnouncementCard,
  },
  computed: {
    p_any() {
      return Object.entries(this._computedWatchers)
        .filter((x) => x[0] != 'p_any' && x[0].startsWith('p_'))
        .some((x) => x[1].getter())
    },
    p_email() {
      return hasFieldAccess(Properties, 'email')
    },
    p_phoneNumber() {
      return hasFieldAccess(Properties, 'phoneNumber')
    },
    p_facebookURL() {
      return hasFieldAccess(Properties, 'facebookURL')
    },
    p_instagramURL() {
      return hasFieldAccess(Properties, 'instagramURL')
    },
    p_tags() {
      return hasFieldAccess(Properties, 'tags')
    },
    p_pageTitle() {
      return hasFieldAccess(Properties, 'pageTitle')
    },
    p_tagline() {
      return hasFieldAccess(Properties, 'tagline')
    },
    p_summary() {
      return hasFieldAccess(Properties, 'summary')
    },
    p_description() {
      return hasFieldAccess(Properties, 'description')
    },
    p_announcement() {
      return hasFieldAccess(Properties, 'announcement')
    },
    p_imagery() {
      return hasFieldAccess(Properties, 'imagery')
    },
    p_amenities() {
      return hasFieldAccess(Properties, 'amenities')
    },
    p_relationships() {
      return hasFieldAccess(Properties, 'relationships')
    },
    p_csr() {
      return hasFieldAccess(Properties, 'csr')
    },
  },
  mounted() {},
  methods: {
    save() {},
    updateTags(update) {
      this.property.tags = update
    },
    getAmenities() {
      return this.$refs.amenities
        ? this.$refs.amenities.getValue()
        : this.property.amenities
    },
    getCSR() {
      return this.$refs.csr ? this.$refs.csr.getValue() : this.property.csr
    },
    getAnnouncement() {
      return this.$refs.announcement
        ? this.$refs.announcement.getValue()
        : this.property.announcement
    },
  },
}
</script>

<template>
  <ValidationObserver ref="vobserver" v-slot="{}">
    <quest-main>
      <template v-slot:body>
        <div class="row justify-content-center">
          <div class="col-xl-10 col-lg-12 col-md-9">
            <div class="card o-hidden border-0 shadow-lg my-5">
              <div class="card-body p-0">
                <div class="row">
                  <div class="col">
                    <div class="p-5">
                      <div class="text-center">
                        <h1 class="h4 text-gray-900 mb-0">
                          Create a group deal
                        </h1>
                        <small
                          >Group deals find all rate plans with matching name
                          and code. Only rate plans from selected properties
                          will be matched. You may select properties after the
                          deal is built.</small
                        >
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <quest-input
                            id="name"
                            placeholder="Name"
                            v-model="name"
                            large
                            help="The name of the deals' rate plan"
                          ></quest-input>
                        </div>
                        <div class="col-md-6">
                          <quest-input
                            id="code"
                            placeholder="Code"
                            v-model="code"
                            large
                            help="The code of the deals' rate plan"
                          ></quest-input>
                        </div>
                      </div>

                      <div class="form-group">
                        <button
                          @click="create()"
                          v-bind="{ disabled: !ready || saving }"
                          class="btn btn-primary btn-lg btn-block"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'badge-dollar']"
                            size="lg"
                            fixed-width
                            :class="{ 'animated--heart-beat': saving }"
                          ></font-awesome-icon>
                          {{ saving ? 'Building' : 'Build' }} Group Deal
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </quest-main>
  </ValidationObserver>
</template>

<script>
import { QuestMain } from './structure'
import { QuestInput } from './form'
import { DealManagementService } from '../services'
import { slugify } from '../helpers'

export default {
  name: 'deal-create-group',
  components: {
    QuestMain,
    QuestInput,
  },
  data() {
    return {
      saving: false,
      name: '',
      code: '',
    }
  },
  computed: {
    ready() {
      return this.name && this.code
    },
  },
  methods: {
    create() {
      this.saving = true

      const deal = {
        name: this.name.trim(),
        properties: [],
        isGroup: true,
        slug: slugify(this.name.trim()),
        code: this.code.trim(),
      }

      new DealManagementService()
        .create(deal)
        .then((data) => {
          if (data) {
            this.$router.push({
              name: 'deals-edit',
              params: { id: data.value },
            })
          }
        })
        .finally((_) => (this.saving = false))
    },
  },
}
</script>

<template>
  <quest-card
    title="Modules"
    :collapsable="false"
    :collapsed="false"
    id="configurationsForm"
  >
    <div class="row">
      <div class="col-md-4">
        <quest-toggle
          id="enableConferences"
          label="Enable Conferences"
          v-model="configuration.enableConferences"
          checked="Enabled"
          unchecked="Disabled"
          help="Enables the meeting and conferences page and module for this property"
        />
      </div>
      <div class="col-md-4">
        <quest-toggle
          id="enableLongerStay"
          label="Enable Long Stays"
          v-model="configuration.enableLongerStay"
          checked="Enabled"
          unchecked="Disabled"
          help="Enables the 'long stay' page and module for this property"
        />
      </div>
      <div class="col-md-4">
        <quest-toggle
          id="enableLocalDiscovery"
          label="Enable Local Discovery"
          v-model="configuration.enableLocalDiscovery"
          checked="Enabled"
          unchecked="Disabled"
          help="Enables the 'as local as you like it' module for this property"
        />
      </div>
    </div>
  </quest-card>
</template>

<script>
import Card from './property-card'

export default {
  extends: Card,
  props: {
    configuration: { required: true },
  },
  methods: {
    getValue() {
      return {
        property: this.property.id,
        payload: this.configuration,
      }
    },
  },
}
</script>

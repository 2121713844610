<template>
  <div id="wrapper">
    <template v-if="!loading || $route.name == 'login'">
      <admin-sidebar
        v-if="
          this.authenticated &&
          this.$router.currentRoute.name !== 'account' &&
          hasSideBarView()
        "
        :toggled="toggled"
      ></admin-sidebar>
      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
          <admin-header
            :showDashboard="
              authenticated &&
              this.$router.currentRoute.name !== 'account' &&
              !(
                this.authenticated &&
                this.$router.currentRoute.name !== 'account' &&
                hasSideBarView()
              )
            "
            v-if="authenticated"
            @toggle="toggled = !toggled"
            @changeAccount="setAccount($event)"
          ></admin-header>
          <router-view @deleteAccount="deleteAccount()"></router-view>
        </div>
        <admin-footer v-if="authenticated"></admin-footer>
      </div>
      <scroll-to-top></scroll-to-top>
    </template>
    <quest-preloader
      v-if="loading && $route.name != 'login'"
      header="Loading"
    ></quest-preloader>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { hasSideBarView } from '@/helpers'
import { INIT, SET_ACCOUNT, SET_UNLOADED } from '../../store/action-types'
import AdminSidebar from './admin-sidebar.vue'
import AdminHeader from './admin-header.vue'
import AdminFooter from './admin-footer.vue'
import ScrollToTop from '../scroll-to-top.vue'
import { QuestPreloader } from '../structure'

export default {
  name: 'admin-layout',
  components: {
    AdminSidebar,
    AdminHeader,
    AdminFooter,
    ScrollToTop,
    QuestPreloader,
  },
  data() {
    return {
      toggled: false,
    }
  },
  methods: {
    hasSideBarView,
    ...mapActions([INIT, SET_ACCOUNT, SET_UNLOADED]),
    async setAccount(value) {
      this.SET_UNLOADED()
      this.SET_ACCOUNT(value)
      await this.INIT()
      this.$router.push({ name: 'dashboard' })
    },
    async deleteAccount() {
      this.SET_ACCOUNT(null)
    },
  },
  computed: {
    ...mapState(['authenticated', 'loading', 'account']),
  },
  async mounted() {
    if (this.authenticated) {
      await this.INIT()
    }
  },
}
</script>

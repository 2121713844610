<template>
  <ValidationObserver ref="vobserver" v-slot="{}">
    <quest-main :loading="loading">
      <template v-slot:body>
        <div class="row justify-content-center">
          <div class="col-xl-10 col-lg-12 col-md-9">
            <div class="card o-hidden border-0 shadow-lg my-5">
              <div class="card-body p-0">
                <div class="row">
                  <div class="col">
                    <div class="p-5">
                      <div class="text-center">
                        <h1 class="h4 text-gray-900 mb-0">
                          Choose a local deal
                        </h1>
                        <small
                          >New local deals must go through a linking process.
                          Please select the property and rate plan below to
                          link!</small
                        >
                      </div>
                      <quest-single-select
                        class="mt-3"
                        v-model="property"
                        :options="propertyOptions"
                        id="property"
                        placeholder="Select a property"
                        help="The property that applies the local deal"
                        @update="updateProperty"
                        large
                      ></quest-single-select>

                      <template v-if="plansLoaded && plans.length">
                        <quest-single-select
                          v-model="plan"
                          :options="planOptions"
                          id="rateplan"
                          placeholder="Select a rate plan"
                          help="The rate plan applying the deal"
                          @update="updatePlan"
                          large
                        ></quest-single-select>
                      </template>

                      <template v-if="plansLoaded && !plans.length">
                        <p>
                          No unlinked rate plans available. Do you need to sync
                          the property?
                        </p>
                      </template>

                      <div class="form-group">
                        <button
                          @click="create()"
                          v-bind="{ disabled: !linked || saving }"
                          class="btn btn-primary btn-lg btn-block"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'badge-dollar']"
                            size="lg"
                            fixed-width
                            :class="{ 'animated--heart-beat': saving }"
                          ></font-awesome-icon>
                          {{ saving ? 'Building' : 'Build' }} Local Deal
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </quest-main>
  </ValidationObserver>
</template>

<script>
import { QuestMain } from './structure'
import { QuestSingleSelect } from './form'
import { slugify } from '../helpers'
import {
  DealManagementService,
  PropertyManagementService,
  RateService,
} from '../services'

export default {
  name: 'deal-create-local',
  components: {
    QuestMain,
    QuestSingleSelect,
  },
  data() {
    return {
      linked: false,
      loading: true,
      saving: false,
      saveDisabled: true,
      properties: [],
      property: '',
      plan: '',
      plans: [],
      plansLoaded: false,
      saving: false,
    }
  },
  computed: {
    propertyOptions() {
      return this.properties.map((x) => {
        return { key: x.siteminderPropertyId, value: x.name }
      })
    },
    planOptions() {
      return this.plans.map((x) => {
        return { key: x.siteminderRatePlanId, value: x.name }
      })
    },
  },
  mounted() {
    this.loadProperties()
  },
  methods: {
    loadProperties() {
      new PropertyManagementService().filters().then((data) => {
        this.properties = data
        this.loading = false
      })
    },
    updateProperty(update) {
      this.plansLoaded = false
      this.linked = false
      this.property = update
      this.plan = ''
      if (this.property) {
        new RateService().unlinked(this.property).then((data) => {
          this.plans = data
          this.plansLoaded = true
        })
      }
    },
    updatePlan(update) {
      this.linked = false
      this.plan = update
      if (this.plan) {
        this.linked = true
      }
    },
    create() {
      this.saving = true

      const rateplan = this.plans.find(
        (x) => this.plan == x.siteminderRatePlanId
      )

      const deal = {
        name: rateplan.name,
        siteminderRatePlanId: rateplan.siteminderRatePlanId,
        properties: [this.property],
        isGroup: false,
        slug: slugify(rateplan.name),
        code: rateplan.code,
      }

      new DealManagementService()
        .create(deal)
        .then((data) => {
          if (data) {
            this.$router.push({
              name: 'deals-edit',
              params: { id: data.value },
            })
          }
        })
        .finally((_) => (this.saving = false))
    },
  },
}
</script>

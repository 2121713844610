import AdminNexusHttpClient from './admin-nexus-http-client'

export default class AuthService {
  constructor() {
    this.httpClient = new AdminNexusHttpClient('/auth', 5000, {
      'Content-Type': 'application/json',
    })
  }

  async login(username, password, otp) {
    return await this.httpClient.post('in', { username, password, otp })
  }
}

<template>
  <div>
    <div class="text-right mb-3">
      <button class="btn btn-primary btn-sm" @click="() => $refs.view.add()">
        <span class="icon text-white-50">
          <font-awesome-icon
            fixed-width
            :icon="['fas', 'plus']"
          ></font-awesome-icon>
        </span>
        <span class="text">Add Extra</span>
      </button>
    </div>

    <extra-listing-view ref="view" @init="() => init()"></extra-listing-view>
  </div>
</template>

<script>
import { QuestMain, QuestCard } from '../structure'
import { QuestInput, QuestSingleSelect, QuestToggle } from '../form'

import ExtraListingView from './extra-listing-view.vue'

export default {
  name: 'property-extra-listing',
  components: {
    QuestCard,
    ExtraListingView,
    QuestMain,
    QuestSingleSelect,
    QuestInput,
    QuestToggle,
  },
  props: {
    property: { required: true },
  },
  methods: {
    init() {
      this.$refs['view'].search(this.property)
    },
  },
}
</script>

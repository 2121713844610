<template>
  <quest-main
    title="Properties Management"
    copy="Manage Quest properties by selecting from the table below"
    :loading="loading"
  >
    <template v-slot:head>
      <router-link
        v-if="currentAccount?.service === ExternalServiceType.TBB"
        :to="{ name: 'properties-add' }"
        class="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
      >
        <font-awesome-icon
          icon="plus"
          size="sm"
          class="text-white-50"
        ></font-awesome-icon>
        Add</router-link
      >
    </template>
    <template v-slot:body>
      <quest-card
        title="Filters"
        id="filterCard"
        :collapsable="true"
        :collapsed="true"
      >
        <div class="row">
          <div class="col-md-6">
            <quest-input
              v-model="term"
              label="Search"
              id="search"
              help="Filter properties by their name"
              @enter="filter()"
            ></quest-input>
          </div>
          <div class="col-md-6">
            <quest-single-select
              v-model="country"
              :options="countries"
              label="Country"
              id="country"
              placeholder="All"
              help="Filter properties by country"
              :keepPlaceholder="true"
              @update="updateCountry"
            ></quest-single-select>
          </div>
        </div>
        <div class="justify-content-end d-flex gap-2">
          <button class="btn btn-secondary btn-sm" @click="clear()">
            <font-awesome-icon
              :icon="['fas', 'times']"
              fixed-width
            ></font-awesome-icon>
            Clear
          </button>
          <button class="btn btn-primary btn-sm" @click="filter()">
            <font-awesome-icon
              :icon="['fas', 'filter']"
              fixed-width
            ></font-awesome-icon>
            Filter
          </button>
        </div>
      </quest-card>
      <quest-card title="Properties">
        <quest-table id="properties-table" :columns="columns">
          <template v-if="properties.items.length > 0">
            <tr
              class="small align-middle"
              v-for="property in properties.items"
              :key="property.id"
            >
              <td>
                <router-link
                  :to="{
                    name: 'properties-edit',
                    params: { id: property.id },
                  }"
                  >{{ property.name }}</router-link
                ><br />
                <router-link
                  class="small"
                  :to="{
                    name: 'properties-edit',
                    hash: '#rooms',
                    params: { id: property.id },
                  }"
                  >(Rooms)</router-link
                >
              </td>
              <td>{{ property.channelCode }}</td>
              <td>{{ property.state }}</td>
              <td>{{ property.country }}</td>
              <td>
                <span class="text-success" v-if="property.isEnabled"
                  >Enabled</span
                >
                <span
                  class="text-warning"
                  v-if="!property.isEnabled && property.isGhost"
                  >Ghost</span
                >
                <span
                  class="text-danger"
                  v-if="!property.isEnabled && !property.isGhost"
                  >Disabled</span
                >
              </td>
              <td>{{ property.modified }}</td>
              <td>
                <b-dropdown no-caret variant="link">
                  <template #button-content>
                    <font-awesome-icon
                      icon="ellipsis-v"
                      size="sm"
                      fixed-width
                      class="text-gray-400"
                    ></font-awesome-icon>
                  </template>
                  <b-dropdown-header>Actions</b-dropdown-header>
                  <b-dropdown-item
                    :to="{
                      name: 'properties-edit',
                      params: { id: property.id },
                    }"
                    >View</b-dropdown-item
                  >
                  <template
                    v-if="currentAccount?.service === ExternalServiceType.TBB"
                  >
                    <b-dropdown-item-button @click="purgeQuotes(property)"
                      >Purge Quotes</b-dropdown-item-button
                    >

                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item-button
                      @click="promptDelete(property)"
                      text="danger"
                      variant="danger"
                      >Delete</b-dropdown-item-button
                    ></template
                  >
                </b-dropdown>
              </td>
            </tr>
          </template>
          <template v-if="properties.items.length === 0">
            <tr class="align-middle">
              <td :colspan="columns.length">
                <div class="small text-center py-5">
                  No properties were found
                </div>
              </td>
            </tr>
          </template>
        </quest-table>
        <quest-pager
          v-if="properties.total > 12"
          @changePage="changePage"
          :currentPage="page"
          :itemCount="properties.total"
          :pageSize="pageSize"
        ></quest-pager>
      </quest-card>
      <b-modal v-model="showModal" centered title="Delete">
        <p class="my-4" v-if="toDelete">
          Are you sure you wish to delete property '{{ toDelete.name }}'?
        </p>
        <template v-slot:modal-footer>
          <button
            class="btn btn-secondary btn-sm"
            type="button"
            @click="cancelPromptDelete"
          >
            Cancel</button
          ><button
            class="btn btn-danger btn-sm"
            type="button"
            @click="confirmPromptDelete"
          >
            Delete
          </button>
        </template>
      </b-modal>
    </template>
  </quest-main>
</template>

<script>
import { success, error } from '../helpers'
import { mapState, mapGetters } from 'vuex'
import { ExternalServiceType } from '@/models'
import PagedResult from '../models/paged-result'
import { PropertyManagementService, PurgeService } from '../services'
import { QuestInput, QuestSingleSelect } from './form'
import {
  QuestMain,
  QuestTable,
  QuestPager,
  QuestCard,
  QuestModal,
} from './structure'

export default {
  name: 'properties',
  components: {
    QuestMain,
    QuestTable,
    QuestPager,
    QuestCard,
    QuestModal,
    QuestSingleSelect,
    QuestInput,
  },
  data() {
    return {
      ExternalServiceType,
      page: 1,
      pageSize: 10,
      properties: new PagedResult(),
      toDelete: null,
      showModal: false,
      columns: ['Name', 'Code', 'State', 'Country', 'Status', 'Modified', ''],
      country: '',
      term: '',
      loading: true,
    }
  },
  computed: {
    ...mapState(['countries', 'account']),
    ...mapGetters(['currentAccount']),
  },
  mounted() {
    this.changePage(1)
  },
  methods: {
    updateCountry(v) {
      this.country = v
    },
    filter() {
      this.changePage(1)
    },
    clear() {
      this.term = ''
      this.country = ''
      this.changePage(1)
    },
    changePage(page) {
      new PropertyManagementService()
        .getProperties(
          this.account,
          page,
          this.pageSize,
          this.term,
          this.country
        )
        .then((data) => {
          this.properties = data
          this.page = page
        })
        .finally((_) => (this.loading = false))
    },
    promptDelete(property) {
      this.toDelete = property
      this.showModal = true
    },
    purgeQuotes(property) {
      new PurgeService().quotes(property.id).then((data) => {
        if (data) {
          success(`Purged all # quotes`, property.name)
        } else {
          error(`Failed to purge # quotes`, property.name)
        }
      })
    },
    cancelPromptDelete() {
      this.showModal = false
      this.toDelete = null
    },
    confirmPromptDelete() {
      new PropertyManagementService()
        .delete(this.toDelete.id)
        .then((data) => {
          this.showModal = false

          if (data) {
            this.preloads = this.changePage(1)
            success(`Deleted property #`, this.toDelete.name)
          } else {
            error(`Failed to delete property #`, this.toDelete.name)
          }
        })
        .finally((_) => {
          this.toDelete = null
        })
    },
  },
}
</script>

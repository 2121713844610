<template>
  <div>
    <quest-card panel v-if="!p_any">
      <h4 class="text-center h4 text-gray-400 mt-2 mb-2">
        You do not have access to modify any fields in this tab
      </h4>
    </quest-card>
    <configuration-card
      v-show="p_configuration"
      :property="property"
      :configuration="configuration"
      ref="configuration"
    ></configuration-card>
    <template v-if="p_any">
      <quest-card panel>
        <div
          class="row"
          v-if="
            [
              p_isEnabled,
              p_channelCode,
              p_sabreHotelId,
              p_slug,
              p_openDateUTC,
              p_maximumGroupsPerBooking,
              p_dEdgeHotelId,
              p_iBEIdentifier,
            ].some((x) => x)
          "
        >
          <template v-if="currentAccount?.service === ExternalServiceType.TBB">
            <div class="col-md-4" v-if="p_channelCode">
              <quest-single-select
                v-model="property.channelCode"
                :options="
                  channels.length > 0 ? channels : [property.channelCode]
                "
                label="Channel Code"
                id="channelCode"
                placeholder="No channel code set"
                help="Used for identifying the property within TBB"
                @update="updateChannelCode"
              ></quest-single-select>
            </div>
            <div class="col-md-4" v-if="p_sabreHotelId">
              <quest-input
                id="sabreHotelId"
                label="Sabre Hotel ID"
                v-model="property.sabreHotelId"
                help="The properties' unique identifier in the Sabre platform"
              ></quest-input>
            </div>
            <div class="col-md-4" v-if="p_iBEIdentifier">
              <quest-input
                id="ibeIdentifier"
                label="IBE Identifier"
                v-model="property.ibeIdentifier"
                help="The properties' custom unique identifier"
              ></quest-input>
            </div>
          </template>
          <template v-if="p_isEnabled">
            <div
              class="col-md-6"
              v-if="!property.isMirrored || property.isActivated"
            >
              <quest-toggle
                id="isEnabled"
                label="Status"
                v-model="property.isEnabled"
                checked="Enabled"
                unchecked="Disabled"
                nooption="Deleted"
                :deleted="property.isInactive"
                help="Enable property throughout Quest network"
                @input="($event) => checkStatusChange($event)"
              />
            </div>
            <div
              class="col-md-6"
              v-if="property.isMirrored && !property.isActivated"
            >
              <div class="form-group">
                <label>Status</label>
                <button @click="onActivate()" class="btn btn-danger btn-block">
                  <font-awesome-icon
                    icon="exclamation"
                    size="sm"
                    fixed-width
                    class="text-white-50"
                  ></font-awesome-icon>
                  Activate
                </button>
              </div>
            </div>
          </template>

          <div class="col-md-6" v-if="p_openDateUTC">
            <quest-date-picker
              id="openDateUTC"
              label="Open Date"
              v-model="property.openDateUTC"
              clearable="true"
              help="Set an open date for the property"
            ></quest-date-picker>
          </div>
          <div class="col-md-6" v-if="p_maximumGroupsPerBooking">
            <quest-input
              id="maximumGroupsPerBooking"
              label="Maximum groups"
              v-model="property.maximumGroupsPerBooking"
              help="Sets the maximum groups that may be added per booking. Set to zero for unlimited."
            ></quest-input>
          </div>
          <div class="col-md-6" v-if="p_slug">
            <quest-input
              id="slug"
              label="Slug"
              v-model="property.slug"
              :disabled="property.isMirrored && !property.isActivated"
              help="Update the property slug"
            ></quest-input>
          </div>
          <div class="col-md-6" v-if="p_dEdgeHotelId">
            <quest-input
              id="dEdgeHotelId"
              label="DEdge Hotel ID"
              v-model="property.dEdgeHotelId"
              help="The properties' unique identifier in the D-Edge tracking tag"
            ></quest-input>
          </div>
          <div class="col-md-6" v-if="p_additionalPaxPrice">
            <quest-input
              id="additionalPaxPrice"
              label="Additional PAX Price"
              v-model="property.additionalPaxPrice"
              help="If not using RMS additional cost per guest, apply a static additional amount for extra guests here"
            ></quest-input>
          </div>
        </div>

        <div
          class="row"
          v-if="
            [p_allowOverbooking, p_allowPets, p_isAccessible].some((x) => x)
          "
        >
          <div class="col-md-6 col-xl-4" v-if="p_allowOverbooking">
            <quest-toggle
              id="allowOverbooking"
              label="Overbooking"
              v-model="property.allowOverbooking"
              checked="Enabled"
              unchecked="Disabled"
              help="Enable to allow overbooking of rooms on property"
            />
          </div>
          <div class="col-md-6 col-xl-4" v-if="p_allowPets">
            <quest-toggle
              id="allowPets"
              label="Pet Friendly"
              v-model="property.allowPets"
              checked="Permitted"
              unchecked="Prohibited"
              help="Enable to permit pets on property"
            />
          </div>
          <div class="col-md-6 col-xl-4" v-if="p_isAccessible">
            <quest-toggle
              id="isAccessible"
              label="Accessible Property"
              v-model="property.isAccessible"
              checked="True"
              unchecked="False"
              help="Does property provide accessible options"
            />
          </div>
        </div>
      </quest-card>

      <location-card :property="property" v-if="p_location"></location-card>
    </template>
    <redirects-card
      v-if="!property.isMirrored || property.isActivated"
      :property="property"
      v-show="p_redirects"
      ref="redirects"
    ></redirects-card>
    <b-modal
      ref="confirmDisable"
      :title="`Disable ${property.name}`"
      :ok-title="'Disable'"
      ok-variant="danger"
      centered
      button-size="sm"
      header-bg-variant="danger"
      body-bg-variant="danger"
      footer-bg-variant="danger"
      header-text-variant="white"
      body-text-variant="white"
      @ok="
        () => {
          this.property.isEnabled = false
          this.$refs.confirmDisable.hide()
        }
      "
    >
      Are you sure you wish to disable '{{
        property.name || '(untitled)'
      }}'?<br />
      This property will no longer display to the public or receive bookings on
      the website.
    </b-modal>
    <b-modal
      ref="confirmActivate"
      :title="`Activate ${property.name}`"
      :ok-title="'Activate'"
      ok-variant="danger"
      centered
      button-size="sm"
      header-bg-variant="danger"
      body-bg-variant="danger"
      footer-bg-variant="danger"
      header-text-variant="white"
      body-text-variant="white"
      @ok="activate()"
    >
      Are you sure you wish to activate '{{
        property.name || '(untitled)'
      }}'?<br /><br />
      This will disable this properties currently active counterpart.
      <br /><br />This property will take on the counterparts' URL slug,
      redirects and status immediately - effectively replacing the counterpart
      record. <br /><br />The counterpart will become deactivated, or a 'Ghost'
      record. It will lose it's slug, and be disabled.
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ExternalServiceType } from '@/models'
import { PropertyManagementService } from '../../services'
import { hasFieldAccess } from '@/helpers'
import { Properties } from '@/router/permission-types'
import { QuestMain, QuestStatCard } from '../structure'
import View from './view'

import {
  ConfigurationCard,
  LocationCard,
  RedirectsCard,
} from '../property-cards'

export default {
  extends: View,
  name: 'property-config-view',
  props: {
    channels: { default: Array },
  },
  data() {
    return {
      ExternalServiceType,
      channelCodes: this.channels,
    }
  },
  components: {
    LocationCard,
    QuestMain,
    QuestStatCard,
    ConfigurationCard,
    RedirectsCard,
  },
  mounted() {
    if (this.channelCodes.length == 0) {
      if (this.currentAccount?.service === ExternalServiceType.TBB) {
        new PropertyManagementService()
          .channelCodes(this.property.siteminderPropertyId)
          .then((channels) => (this.channelCodes = channels))
      } else {
        this.channelCodes = []
      }
    }
  },
  computed: {
    ...mapGetters(['currentAccount']),
    p_any() {
      return Object.entries(this._computedWatchers)
        .filter((x) => x[0] != 'p_any' && x[0].startsWith('p_'))
        .some((x) => x[1].getter())
    },
    p_maximumGroupsPerBooking() {
      return hasFieldAccess(Properties, 'maximumGroupsPerBookin')
    },
    p_configuration() {
      return hasFieldAccess(Properties, 'configuration')
    },
    p_channelCode() {
      return hasFieldAccess(Properties, 'channelCode')
    },
    p_sabreHotelId() {
      return hasFieldAccess(Properties, 'sabreHotelId')
    },
    p_iBEIdentifier() {
      return hasFieldAccess(Properties, 'p_iBEIdentifier')
    },
    p_slug() {
      return hasFieldAccess(Properties, 'slug')
    },
    p_openDateUTC() {
      return hasFieldAccess(Properties, 'openDateUTC')
    },
    p_isEnabled() {
      return hasFieldAccess(Properties, 'isEnabled')
    },
    p_allowOverbooking() {
      return hasFieldAccess(Properties, 'allowOverbooking')
    },
    p_allowPets() {
      return hasFieldAccess(Properties, 'allowPets')
    },
    p_isAccessible() {
      return hasFieldAccess(Properties, 'isAccessible')
    },
    p_location() {
      return hasFieldAccess(Properties, 'location')
    },
    p_redirects() {
      return hasFieldAccess(Properties, 'redirects')
    },
    p_dEdgeHotelId() {
      return hasFieldAccess(Properties, 'dEdgeHotelId')
    },
    p_additionalPaxPrice() {
      return hasFieldAccess(Properties, 'additionalPaxPrice')
    },
  },
  methods: {
    onActivate() {
      if (this.property.isMirrored && !this.property.isActivated) {
        this.$refs['confirmActivate'].show()
      }
    },
    activate() {
      this.$emit('activate')
    },
    updateChannelCode(update) {
      this.property.channelCode = update
    },
    getConfig() {
      return this.$refs.configuration.getValue()
    },
    getRedirects() {
      return this.$refs.redirects.getValue()
    },
    checkStatusChange(value) {
      if (!value && this.property.id) {
        this.property.isEnabled = true
        this.$refs['confirmDisable'].show()
      }
    },
  },
}
</script>

import AdminNexusHttpClient from './admin-nexus-http-client'

export default class RequirementService {
  constructor() {
    this.httpClient = new AdminNexusHttpClient('/admin/requirements')
  }

  async list(property) {
    return await this.httpClient.get(`list/${property}`)
  }
}

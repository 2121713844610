<template>
  <quest-main title="Extras Management" :loading="loading">
    <template v-slot:head>
      <button
        v-if="!!property"
        @click="$refs.view.add()"
        class="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
      >
        <font-awesome-icon
          icon="plus"
          size="sm"
          class="text-white-50"
        ></font-awesome-icon>
        Add
      </button>
    </template>
    <template v-slot:body>
      <quest-card :panel="true">
        <div class="row">
          <div class="col-12">
            <quest-single-select
              v-model="property"
              :options="properties"
              label="Property"
              id="property"
              placeholder="None"
              okey="id"
              otext="name"
              :keepPlaceholder="true"
              @update="updateProperty"
            ></quest-single-select>
          </div>
        </div>
      </quest-card>

      <extra-listing-view
        ref="view"
        @init="() => search()"
      ></extra-listing-view>
    </template>
  </quest-main>
</template>

<script>
import { mapState } from 'vuex'
import { PropertyManagementService } from '@/services'
import { QuestMain, QuestCard } from '../structure'
import { QuestInput, QuestSingleSelect, QuestToggle } from '../form'
import ExtraListingView from './extra-listing-view.vue'

export default {
  name: 'extra-listing',
  data() {
    return {
      property: '',
      properties: [],
      loading: true,
    }
  },
  components: {
    QuestCard,
    QuestMain,
    QuestSingleSelect,
    QuestInput,
    QuestToggle,
    ExtraListingView,
  },
  mounted() {
    new PropertyManagementService().extract(this.account).then((data) => {
      this.properties = data
      this.loading = false
    })
  },
  computed: {
    ...mapState(['account']),
  },
  methods: {
    updateProperty(value) {
      this.property = value
      this.search()
    },
    search() {
      this.$refs['view'].search(this.property)
    },
  },
}
</script>

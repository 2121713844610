import AdminNexusHttpClient from './admin-nexus-http-client'

export default class CSRManagementService {
  constructor() {
    this.httpClient = new AdminNexusHttpClient('/admin/csr')
  }

  async setForProperty(payload) {
    return await this.httpClient.post('', payload)
  }

  async setForHost(payload) {
    return await this.httpClient.post('default', payload)
  }
}

<template>
  <transition-slide>
    <div class="container" v-if="toggle">
      <!-- <div class="pb-3"> -->
      <slot></slot>
      <!-- </div> -->
    </div>
  </transition-slide>
</template>

<script>
import TransitionSlide from './transition-slide.vue'

export default {
  components: {
    TransitionSlide,
  },
  name: 'rate-editor-slide',
  props: {
    toggle: { required: true },
  },
}
</script>

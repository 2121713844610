<template>
  <quest-main
    title="Deals"
    :loading="loading"
    copy="Manage Quest deals by selecting from the table below"
  >
    <template v-slot:head>
      <router-link
        :to="{ name: 'deals-add' }"
        class="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
      >
        <font-awesome-icon
          icon="plus"
          size="sm"
          class="text-white-50"
        ></font-awesome-icon>
        Add</router-link
      >
    </template>
    <template v-slot:body>
      <quest-card
        title="Filters"
        id="filterCard"
        :collapsable="true"
        :collapsed="true"
      >
        <div class="row">
          <div class="col-md-6">
            <quest-input
              v-model="term"
              label="Search"
              id="search"
              help="Filter deals by their name"
              @enter="filter()"
            ></quest-input>
          </div>
          <div class="col-md-6">
            <quest-single-select
              v-model="type"
              :options="types"
              label="Deal Type"
              id="type"
              placeholder="All"
              help="Filter deals by type"
              :keepPlaceholder="true"
              @update="updateType"
            ></quest-single-select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <quest-single-select
              v-model="property"
              :options="filters"
              label="Property"
              id="property"
              okey="id"
              otext="name"
              placeholder="All"
              help="Filter deals by property"
              :keepPlaceholder="true"
              @update="updateProperty"
            ></quest-single-select>
          </div>
        </div>
        <div class="text-right">
          <button class="btn btn-secondary btn-sm" @click="clear()">
            <font-awesome-icon
              :icon="['fas', 'times']"
              fixed-width
            ></font-awesome-icon>
            Clear
          </button>
          <button class="btn btn-primary btn-sm" @click="filter()">
            <font-awesome-icon
              :icon="['fas', 'filter']"
              fixed-width
            ></font-awesome-icon>
            Filter
          </button>
        </div>
      </quest-card>

      <quest-card title="Deals">
        <quest-table id="deals-table" :columns="columns">
          <template v-if="deals.items.length > 0">
            <tr
              class="small align-middle"
              v-for="(deal, idx) in deals.items"
              :key="idx"
            >
              <td>
                <router-link
                  :to="{
                    name: 'deals-edit',
                    params: { id: deal.id },
                  }"
                  >{{ deal.name || '(untitled)' }}</router-link
                >
              </td>
              <td>{{ deal.isGroup ? 'Group' : 'Local' }}</td>
              <td>{{ deal.isEnabled ? 'Enabled' : 'Disabled' }}</td>
              <td>{{ deal.property }}</td>
              <td>{{ lastModified(deal.modifiedUTC) }}</td>
              <td>
                <b-dropdown no-caret variant="link">
                  <template #button-content>
                    <font-awesome-icon
                      icon="ellipsis-v"
                      size="sm"
                      fixed-width
                      class="text-gray-400"
                    ></font-awesome-icon>
                  </template>
                  <b-dropdown-header>Actions</b-dropdown-header>
                  <b-dropdown-item
                    :to="{
                      name: 'deals-edit',
                      params: { id: deal.id },
                    }"
                    >View</b-dropdown-item
                  >
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item-button
                    @click="promptDelete(deal)"
                    text="danger"
                    variant="danger"
                    >Delete</b-dropdown-item-button
                  >
                </b-dropdown>
              </td>
            </tr>
          </template>
          <template v-if="deals.items.length === 0">
            <tr class="align-middle">
              <td :colspan="columns.length">
                <div class="small text-center py-5">No deals were found</div>
              </td>
            </tr>
          </template>
        </quest-table>
        <quest-pager
          v-if="deals.total > 12"
          @changePage="changePage"
          :currentPage="page"
          :itemCount="deals.total"
          :pageSize="pageSize"
        ></quest-pager>
      </quest-card>

      <b-modal v-model="showModal" centered title="Delete">
        <p class="my-4">
          Are you sure you wish to delete deal '{{
            toDelete ? toDelete.name || 'untitled' : 'untitled'
          }}'?
        </p>
        <template v-slot:modal-footer>
          <button
            class="btn btn-secondary btn-sm"
            type="button"
            @click="cancelPromptDelete"
          >
            Cancel</button
          ><button
            class="btn btn-danger btn-sm"
            type="button"
            @click="confirmPromptDelete"
          >
            Delete
          </button>
        </template>
      </b-modal>
    </template>
  </quest-main>
</template>

<script>
import { success, error } from '../helpers'
import moment from 'moment'
import 'moment-timezone'
import PagedResult from '../models/paged-result'
import {
  QuestMain,
  QuestTable,
  QuestPager,
  QuestCard,
  QuestModal,
} from './structure'
import { DealManagementService, PropertyManagementService } from '../services'
import { QuestInput, QuestSingleSelect } from './form'

export default {
  name: 'deals',
  components: {
    QuestMain,
    QuestTable,
    QuestPager,
    QuestCard,
    QuestSingleSelect,
    QuestInput,
    QuestModal,
  },
  data() {
    return {
      loading: true,
      page: 1,
      term: '',
      pageSize: 10,
      filters: [],
      deals: new PagedResult(),
      columns: ['Name', 'Type', 'Status', 'Property', 'Modified', ''],
      toDelete: null,
      showModal: false,
      type: '',
      property: '',
      types: [
        {
          key: 'True',
          value: 'Group',
        },
        {
          key: 'False',
          value: 'Local',
        },
      ],
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      new PropertyManagementService().filters().then((data) => {
        this.filters = data
        this.changePage(1)
      })
    },
    changePage(page) {
      new DealManagementService()
        .all(page, this.pageSize, this.term, this.type, this.property)
        .then((data) => {
          this.deals = data
          this.page = page
        })
        .finally((_) => (this.loading = false))
    },
    lastModified(date) {
      return moment(`${date}Z`).tz('Australia/Melbourne').format('lll')
    },
    clear() {
      this.term = ''
      this.type = ''
      this.property = ''
      this.changePage(1)
    },
    filter() {
      this.changePage(1)
    },
    promptDelete(deal) {
      this.toDelete = deal
      this.showModal = true
    },
    cancelPromptDelete() {
      this.showModal = false
      this.toDelete = null
    },
    updateType(v) {
      this.type = v
    },
    updateProperty(v) {
      this.property = v
    },
    confirmPromptDelete() {
      new DealManagementService()
        .delete(this.toDelete.id)
        .then((data) => {
          this.showModal = false

          if (data) {
            this.preloads = this.changePage(1)
            success(`Deleted deal #`, this.toDelete.name)
          } else {
            error(`Failed to delete deal #`, this.toDelete.name)
          }
        })
        .finally((_) => (this.toDelete = null))
    },
  },
}
</script>

<template>
  <b-modal v-model="showModal" centered :title="title" size="xl">
    <div class="row" v-if="!displayUpload">
      <div class="col-9">
        <div
          class="d-flex h-100 flex-wrap"
          style="max-height: 50vh; overflow: auto"
        >
          <template v-if="images && images.length">
            <a
              @click="toggle(image.Key)"
              v-for="(image, idx) in images"
              :key="idx"
              class="gallery-image border mr-2 mb-2 pointer"
              :style="style(image.Value)"
              style="background-size: cover"
              :class="{ selected: isSelected(image.Key) }"
            >
            </a>
          </template>
          <template v-else>
            <p>No images found</p>
          </template>
        </div>
      </div>
      <div class="col-3">
        <div v-if="currentFolder">
          <p class="text-center">
            You are inside the {{ currentFolder }} folder.
          </p>
          <a class="text-center d-block pointer" @click="updateFolder()"
            >Back to parent</a
          >
        </div>
        <div v-else-if="folders && folders.length > 0">
          <p class="font-weight-bold small mb-1">Folders</p>
          <a
            v-for="(f, idx) in folders"
            :key="idx"
            @click="updateFolder(f)"
            class="d-block p-2 list-group-item-action border-left-primary text-xs text-uppercase pointer"
            :class="{ 'mt-2': idx != 0 }"
            >{{ f.Value }}</a
          >
        </div>
      </div>
    </div>
    <quest-image-upload
      v-if="displayUpload"
      :propertyId="propertyId"
      :albumName="albumName"
      :folder="currentFolder"
    ></quest-image-upload>
    <template v-slot:modal-footer>
      <div class="w-100 m-0" v-if="displayUpload">
        <button
          class="btn btn-primary btn-sm"
          type="button"
          @click="manageMode(false)"
          style="margin: 0.25rem"
        >
          Return to select mode
        </button>
      </div>
      <div
        class="d-flex justify-content-between w-100 m-0"
        v-if="!displayUpload"
      >
        <div>
          <button
            v-if="manage"
            class="btn btn-primary btn-sm"
            type="button"
            @click="manageMode(true)"
            style="margin: 0.25rem"
          >
            Manage/Upload
          </button>
        </div>
        <div>
          <button
            class="btn btn-secondary btn-sm"
            type="button"
            @click="cancel"
            style="margin: 0.25rem"
          >
            Cancel
          </button>
          <button
            class="btn btn-secondary btn-sm"
            type="button"
            style="margin: 0.25rem"
            @click="selected = []"
          >
            Clear
          </button>
          <button
            class="btn btn-primary btn-sm"
            type="button"
            style="margin: 0.25rem"
            v-bind="{ disabled: required ? selected.length == 0 : false }"
            @click="update"
          >
            Update
          </button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import QuestImageUpload from './quest-image-upload.vue'
import { QuestModal } from '../structure'
import { ResourcesService } from '../../services'

export default {
  name: 'quest-image-picker',
  components: {
    QuestModal,
    QuestImageUpload,
  },
  props: {
    propertyId: String,
    albumName: String,
    folder: { default: '' },
    show: Boolean,
    current: Array,
    multiple: Boolean,
    required: Boolean,
    manage: Boolean,
    title: { default: 'Images' },
  },
  data() {
    return {
      images: [],
      selected: this.current.map((p) => p),
      showModal: this.show,
      folders: [],
      currentFolder: this.folder,
      displayUpload: false,
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    isSelected() {
      return (key) => this.selected.find((x) => x === key)
    },
    style() {
      return (url) => {
        return {
          'background-image': `url("${this.$config.cms.uri}${url}")`,
        }
      }
    },
  },
  watch: {
    current() {
      this.selected = this.current.map((p) => p)
    },
  },
  methods: {
    load() {
      new ResourcesService()
        .gallery(this.propertyId, this.albumName, this.currentFolder)
        .then((data) => {
          this.images = data.Images
          this.folders = data.Folders
        })
    },
    toggle(key) {
      if (this.selected.length == 0) {
        this.selected = [key]
      } else if (this.selected.find((x) => x === key)) {
        this.selected = this.selected.filter((x) => x !== key)
      } else {
        if (this.multiple) {
          this.selected.push(key)
        } else {
          this.selected = [key]
        }
      }
    },
    open() {
      this.showModal = true
    },
    close() {
      this.showModal = false
    },
    manageMode(value) {
      this.displayUpload = value
    },
    cancel() {
      this.close()
      this.selected = this.current.map((p) => p)
    },
    update() {
      this.$emit(
        'update',
        this.multiple
          ? this.selected
          : this.selected.length > 0
          ? this.selected[0]
          : null
      )
      this.close()
    },
    updateFolder(folder = '') {
      this.currentFolder = folder.Value
      this.load()
    },
  },
}
</script>

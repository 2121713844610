import store from '../store/store'
import { DISCONNECT } from '../store/action-types'
import NexusHttpClient from '../utilities/nexus-http-client'

export default class AdminNexusHttpClient extends NexusHttpClient {
  constructor(baseUrl, timeout, headers) {
    super(baseUrl, timeout, headers)
  }

  disconnect() {
    store.dispatch(DISCONNECT)
  }
}

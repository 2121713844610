<template>
  <div class="container-fluid" v-if="hasErrors">
    <div class="alert alert-danger border-left-danger" role="alert">
      <h5 class="alert-heading">Errors</h5>
      <ul class="m-0">
        <li v-for="(error, idx) in getErrors" :key="idx">
          {{ error }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'quest-validation-summary',
  props: {
    errors: { required: true },
  },
  computed: {
    hasErrors() {
      return !!this.getErrors.length
    },
    getErrors() {
      return this.errors
        ? Object.values(this.errors)
            .filter((x) => !!x && !!x.length)
            .map((x) => x[0])
        : []
    },
  },
}
</script>

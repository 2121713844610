var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-xl",attrs:{"id":_vm.id}},[(_vm.noconnect)?_c('quest-disconnected'):(_vm.loading)?_c('quest-preloader',{attrs:{"header":_vm.loadingHeader,"copy":_vm.loadingCopy}}):(_vm.saving)?_c('quest-preloader',{attrs:{"header":"Saving","copy":"This may take a moment","saving":""}}):(_vm.title)?[(!_vm.copy)?[_c('div',{staticClass:"d-flex align-items-center mb-4",class:{
          'justify-content-between': !_vm.center,
          'justify-content-center': _vm.center,
        }},[_c('h1',{staticClass:"h3 mb-0 text-gray-800"},[_vm._v(_vm._s(_vm.title))]),_vm._t("head")],2)]:[_c('div',{staticClass:"d-flex align-items-center",class:{
          'justify-content-between': !_vm.center,
          'justify-content-center': _vm.center,
        }},[_c('h1',{staticClass:"h3 mb-0 text-gray-800"},[_vm._v(_vm._s(_vm.title))]),_vm._t("head")],2),_c('p',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.copy))])],_vm._t("body")]:[_vm._t("body")]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { REQUIRED, MAX } from '@/validation-types'
import Card from '../card'

export default {
  data() {
    return {
      REQUIRED,
      MAX,
    }
  },
  props: {
    property: { required: true },
  },
  extends: Card,
}

<template>
  <transition name="slide">
    <ul
      class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
      id="accordionSidebar"
      :class="{ toggled: !toggled && isMobile }"
    >
      <router-link
        :to="{ path: '/' }"
        class="sidebar-brand d-flex align-items-center justify-content-center"
      >
        <div class="sidebar-brand-text mx-3">Quest</div>
      </router-link>

      <hr class="sidebar-divider my-0" />
      <li class="nav-item">
        <router-link :to="{ name: 'dashboard' }" class="nav-link">
          <font-awesome-icon
            icon="tachometer-alt"
            fixed-width
          ></font-awesome-icon>
          <span>Dashboard</span>
        </router-link>
      </li>

      <template v-if="links.collections.length">
        <hr class="sidebar-divider" />
        <div class="sidebar-heading">Collections</div>
        <li v-for="link in links.collections" class="nav-item" :key="link.name">
          <router-link :to="{ name: link.name }" class="nav-link">
            <font-awesome-icon
              :icon="link.icon"
              fixed-width
            ></font-awesome-icon>
            <span>{{ link.label }}</span>
          </router-link>
        </li>
      </template>

      <template v-if="links.system.length">
        <hr class="sidebar-divider" />
        <div class="sidebar-heading">System</div>
        <li v-for="link in links.system" class="nav-item" :key="link.name">
          <router-link :to="{ name: link.name }" class="nav-link">
            <font-awesome-icon
              :icon="link.icon"
              fixed-width
            ></font-awesome-icon>
            <span>{{ link.label }}</span>
          </router-link>
        </li>
      </template>
    </ul>
  </transition>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import * as PERMISSION from '@/router/permission-types'
import { applicableRoute, hasRouteAccess } from '@/helpers'
import { Links } from './sidebar-links'

export default {
  name: 'admin-sidebar',
  props: {
    toggled: Boolean,
  },
  data() {
    return {
      PERMISSION,
      hasRouteAccess,
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapState(['account', 'accounts']),
    isMobile() {
      return this.windowWidth <= 767
    },
    links() {
      let valid = Links.filter(
        (x) =>
          hasRouteAccess(x.permission) &&
          applicableRoute(
            this.$router.options.routes[0].children.find(
              (p) => p.name === x.name
            )?.meta.applicable || [],
            this.accounts,
            this.account,
            this.isAdmin
          )
      )
      return {
        collections: valid.filter((x) => x.group === 'collections'),
        system: valid.filter((x) => x.group === 'system'),
      }
    },
  },
  mounted() {
    window.addEventListener(
      'resize',
      () => (this.windowWidth = window.innerWidth)
    )
  },
}
</script>

<template>
  <div class="form-group">
    <label v-if="label" :for="id">{{ label }}</label>
    <multiselect
      v-model="completeValue"
      deselect-label="Remove"
      :track-by="uid"
      :label="text"
      :multiple="true"
      :close-on-select="false"
      placeholder=""
      :options="options"
      :searchable="true"
      :disabled="disabled"
      :allow-empty="true"
      :max="max"
    ></multiselect>
    <small v-if="help" :id="id + 'Help'" class="form-text text-muted">{{
      help
    }}</small>
  </div>
</template>

<script>
import form from './form'

export default {
  name: 'quest-multi-select',
  props: {
    options: Array,
    uid: { default: 'key' },
    text: { default: 'value' },
    max: { default: false },
    disabled: { default: false },
  },
  extends: form,
  computed: {
    completeValue: {
      get() {
        return this.input
          ? this.input
              .map((value) =>
                this.options.find((option) => option[this.uid] === value)
              )
              .filter((option) => option != null)
          : []
      },
      set(v) {
        this.input = v.map((value) => value[this.uid])
      },
    },
  },
}
</script>

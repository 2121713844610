export default {
  isAdmin(_, getters) {
    return getters.roles.includes('Administrators')
  },
  roles(state) {
    return state.authenticated ? state.authentication.roles || [] : []
  },
  properties(state) {
    return state.authenticated ? state.authentication.properties || [] : []
  },
  currentAccount(state) {
    return state.accounts.find((x) => x.id === state.account)
  },
}

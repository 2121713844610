<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div
        class="col-xl-6 col-lg-8 col-md-8 d-flex align-items-center"
        style="min-height: 100vh; max-width: 600px"
      >
        <div class="card o-hidden border-0 shadow-lg my-5 w-100">
          <div class="card-body p-0">
            <div class="row">
              <div class="col">
                <div class="p-5">
                  <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4">Quest Nexus</h1>
                  </div>
                  <quest-input
                    v-model="username"
                    label="Username / Email"
                    id="username"
                  ></quest-input>
                  <quest-input
                    v-model="password"
                    label="Password"
                    id="password"
                    type="password"
                  ></quest-input>
                  <quest-input
                    v-model="otp"
                    label="Code"
                    id="otp"
                  ></quest-input>
                  <div class="form-group">
                    <button
                      @click="login"
                      class="btn btn-primary btn-lg btn-block"
                    >
                      Sign in
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { error } from '@/helpers'
import { mapActions, mapState } from 'vuex'
import { QuestInput } from './form'
import { AuthService } from '../services'
import { SET_AUTHENTICATED, SET_ACCOUNT } from '@/store/action-types'

export default {
  name: 'login',
  components: {
    QuestInput,
  },
  data() {
    return {
      loading: false,
      username: '',
      password: '',
      otp: '',
    }
  },
  mounted() {
    if (this.authenticated) {
      this.$router.push({ name: 'dashboard' })
    }
  },
  computed: {
    ...mapState(['authenticated']),
  },
  methods: {
    ...mapActions([SET_AUTHENTICATED, SET_ACCOUNT]),
    login() {
      this.SET_ACCOUNT(null)
      new AuthService()
        .login(this.username, this.password, this.otp)
        .then((data) => {
          if (!data || (data.status && data.status != 200)) {
            error(`Failed to authenticate`)
          } else {
            this.SET_AUTHENTICATED(data)
          }
        })
        .catch((_) => {
          error(`There was an issue communicating with the server`)
        })
    },
  },
  watch: {
    authenticated() {
      this.$router.push({ name: 'dashboard' })
    },
  },
}
</script>

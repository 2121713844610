import AdminNexusHttpClient from './admin-nexus-http-client'
import Preload from '../models/preload'

export default class PreloadService {
  constructor() {
    this.httpClient = new AdminNexusHttpClient('/preload')
  }

  async list() {
    return await this.httpClient.get('')
  }

  async get(id) {
    return new Preload(await this.httpClient.get(`${id}`))
  }

  async add(preload) {
    return await this.httpClient.put(``, preload)
  }

  async update(preload) {
    return await this.httpClient.post(``, preload)
  }

  async delete(id) {
    return await this.httpClient.delete(`${id}`)
  }
}

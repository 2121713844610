<template>
  <div class="card" :class="[!slim ? 'shadow mb-4' : 'card-slim mb-1']">
    <template v-if="collapsable && id && !panel">
      <a
        @click="collapse = !collapse"
        class="card-header-flex"
        :class="initialClass"
        role="button"
        :aria-controls="id"
      >
        <h6 class="m-0 font-weight-bold text-primary">{{ title }}</h6>
        <font-awesome-icon
          v-if="collapse"
          :icon="['fas', 'angle-right']"
          class="text-gray-400"
        ></font-awesome-icon>
        <font-awesome-icon
          v-if="!collapse"
          :icon="['fas', 'angle-down']"
          class="text-gray-400"
        ></font-awesome-icon>
      </a>
      <div :id="id" class="collapse" :class="{ show: !collapse }">
        <div class="card-body">
          <slot></slot>
        </div>
      </div>
    </template>
    <template v-if="(!collapsable || !id) && !panel">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">{{ title }}</h6>
      </div>
      <div class="card-body">
        <slot></slot>
      </div>
    </template>
    <template v-if="panel">
      <div class="card-body">
        <slot></slot>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'quest-card-header',
  props: {
    title: { required: false },
    collapsable: { default: false },
    collapsed: { default: false },
    id: String,
    slim: { default: false },
    panel: Boolean,
  },
  data() {
    return {
      collapse: this.collapsed,
    }
  },
  computed: {
    initialClass() {
      return `card-header-flex card-header py-3 ${
        this.collapsable && this.collapsed ? 'collapsed' : ''
      }`.trim()
    },
  },
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card mb-4 card-slim"},[_c('div',{staticClass:"card-header py-3 card-header-flex"},[_c('h6',{staticClass:"m-0 font-weight-bold text-primary"},[_vm._v(_vm._s(_vm.room.name))]),_c('h6',{staticClass:"m-0 font-weight-bold text-primary",class:[
        _vm.rates.status === _vm.RoomQuoteStatus.Success
          ? 'text-success'
          : 'text-danger',
      ]},[_vm._v(" "+_vm._s(_vm.roomQuoteStatus)+" ")])]),(
      ![
        _vm.RoomQuoteStatus.OverCapacity,
        _vm.RoomQuoteStatus.NoRates,
        _vm.RoomQuoteStatus.NoInventory,
        _vm.RoomQuoteStatus.Error,
      ].includes(_vm.rates.status)
    )?_c('div',{staticClass:"card-body"},[(_vm.room.isEnabled)?_c('div',[_c('b-table',{attrs:{"items":_vm.rates.rates,"fields":_vm.fields,"small":"","hover":"","fixed":"","stacked":"md","borderless":""},scopedSlots:_vm._u([{key:"cell(rate)",fn:function(data){return [_vm._v(_vm._s(data.item.name))]}},{key:"cell(status)",fn:function(data){return [_c('rate-explorer-status',{attrs:{"rate":data.item,"roomId":_vm.room.id}})]}},{key:"cell(gross)",fn:function(data){return [(data.item.quote)?[_vm._v(" "+_vm._s(_vm.currency)+_vm._s(_vm.formatCurrency( _vm.isLoggedIn && data.item.quote?.promoted ? data.item.quote?.promoted?.total : data.item.quote?.standard?.total )))]:_vm._e(),(!data.item.quote)?[_vm._v("-")]:_vm._e()]}}],null,false,661380789)})],1):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
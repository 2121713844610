<template>
  <quest-card
    title="Configuration"
    :collapsable="true"
    :collapsed="true"
    id="configInfo"
  >
    <div class="row">
      <div class="col-md-6">
        <quest-input
          id="maxOccupancy"
          label="Max Occupancy"
          v-model="deal.maxOccupancy"
          help="The maximum guests applicable to the deal"
        ></quest-input>
      </div>
      <div class="col-md-6">
        <quest-input
          id="minimumLengthOfStay"
          label="Minimum Length Of Stay"
          v-model="deal.minimumLengthOfStay"
          help="The minimum nights stay for the deal to apply"
        ></quest-input>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <quest-date-picker
          id="bookStayDateUTC"
          label="Book Start Date"
          v-model="deal.bookStayDateUTC"
          clearable="true"
          help="The earliest a booking may be placed"
        ></quest-date-picker>
      </div>
      <div class="col-md-6">
        <quest-date-picker
          id="bookEndDateUTC"
          label="Book End Date"
          v-model="deal.bookEndDateUTC"
          clearable="true"
          help="The latest a booking may be placed"
        ></quest-date-picker>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <quest-date-picker
          id="stayStartDateUTC"
          label="Stay Start Date"
          v-model="deal.stayStartDateUTC"
          clearable="true"
          help="The earliest night a stay may commence"
        ></quest-date-picker>
      </div>
      <div class="col-md-6">
        <quest-date-picker
          id="stayEndDateUTC"
          label="Stay End Date"
          v-model="deal.stayEndDateUTC"
          clearable="true"
          help="The latest night a stay may finish"
        ></quest-date-picker>
      </div>
    </div>
  </quest-card>
</template>

<script>
import Card from './deal-card'
export default {
  name: 'deal-configuration-card',
  extends: Card,
}
</script>

<template>
  <a
    class="scroll-to-top rounded pointer"
    @click="top()"
    :class="{ 'd-block': windowTop > 100 }"
  >
    <font-awesome-icon
      :icon="['fas', 'angle-up']"
      fized-width
    ></font-awesome-icon>
  </a>
</template>

<script>
export default {
  name: 'scroll-to-top',
  data() {
    return {
      windowTop: window.top.scrollY,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll(_) {
      this.windowTop = window.top.scrollY
    },
    top() {
      this.$scrollTo(window, 100, {
        easing: 'ease-in',
        lazy: false,
        force: true,
        cancelable: true,
        x: false,
        y: true,
      })
    },
  },
}
</script>

<template>
  <div class="main-loading text-gray-400 text-center">
    <font-awesome-icon
      icon="wifi-slash"
      size="4x"
      fixed-width
    ></font-awesome-icon>
    <p class="pt-3 pb-0">Unable to connect with Quest Nexus service</p>
    <small>Please try again shortly</small>
  </div>
</template>

<script>
export default {
  name: 'quest-disconnected',
}
</script>

<template>
  <div>
    <quest-card
      title="Page Metadata"
      :collapsable="false"
      :collapsed="false"
      id="pageForm"
    >
      <div class="row">
        <div class="col-12">
          <label>Page Title</label>
          <p>{{ payload.property.pageTitle }}</p>
        </div>
        <div class="col-12">
          <label>Page Description</label>
          <p>{{ payload.property.summary }}</p>
        </div>
      </div>
    </quest-card>
    <quest-card
      title="OpenGraph Metadata"
      :collapsable="true"
      :collapsed="true"
      id="ogForm"
    >
      <div class="row">
        <div class="col-12">
          <label>Title</label>
          <p>{{ payload.property.pageTitle }}</p>
        </div>
        <div class="col-12">
          <label>Summary</label>
          <p>{{ payload.property.summary }}</p>
        </div>
        <div class="col-12">
          <label>Image</label>
          <br />
          <img
            style="max-width: 300px"
            :src="`${$config.cms.uri}/quest-api/nexusmedia/load/${payload.property.image}`"
          />
        </div>
      </div>
    </quest-card>

    <quest-card
      title="Google Structured Data Markup"
      :collapsable="true"
      :collapsed="true"
      id="schemaForm"
    >
      <div class="row">
        <div class="col-12">
          <pre style="word-wrap: break-word; white-space: pre-wrap">{{
            JSON.stringify(schema, null, 2)
          }}</pre>
        </div>
      </div>
    </quest-card>
  </div>
</template>

<script>
import Card from '../card'
import { FacilityService } from '../../services'

export default {
  extends: Card,
  name: 'property-web-preview-view',
  data() {
    return {
      facilties: [],
      schema: {},
    }
  },
  props: {
    payload: { required: true },
  },
  mounted() {
    new FacilityService()
      .all()
      .then((facilties) => (this.facilities = facilties))
      .finally(
        (_) =>
          (this.schema = this.compile(
            this.payload.property,
            this.payload.roomTypes,
            this.facilities
          ))
      )
  },
  methods: {
    capitalizeFirstLetter(value) {
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    compile(property, roomTypes, facilities) {
      return {
        '@context': 'https://schema.org',
        '@type': 'Hotel',
        name: property.name,
        description: property.summary,
        address: {
          '@type': 'PostalAddress',
          addressCountry: this.capitalizeFirstLetter(property.country.value),
          addressLocality: property.suburb.override || property.suburb.raw,
          addressRegion: property.state.override || property.state.raw,
          postalCode: property.postcode.override || property.postcode.raw,
          streetAddress: property.street.override || property.street.raw,
        },
        latitude: property.latitude,
        longitude: property.longitude,
        telephone: property.phoneNumber,
        photo: property.image
          ? `${$config.cms.uri}/quest-api/nexusmedia/load/${property.image}`
          : '',
        containsPlace: roomTypes.map((roomType) =>
          this.compileRoomTypeSchema(roomType, facilities, property.allowPets)
        ),
      }
    },
    bedDescription(size) {
      switch (size) {
        case 0:
          return 'Single'
        case 1:
          return 'Long single'
        case 2:
          return 'King single'
        case 3:
          return 'Double'
        case 4:
          return 'Queen'
        case 5:
          return 'King'
        case 6:
          return 'Super king'
        default:
          return ''
      }
    },
    compileRoomTypeSchema(roomType, facilities, allowPets) {
      let schema = {
        '@context': 'https://schema.org',
        '@type': 'HotelRoom',
        name: roomType.name,
        description: roomType.description,
        occupancy: {
          '@type': 'QuantitativeValue',
          maxValue: roomType.maxOccupancy,
        },
        photo: roomType.image
          ? `${$config.cms.uri}/quest-api/nexusmedia/load/${roomType.image}`
          : '',
        petsAllowed: allowPets,
        bed: roomType.beds.map((bed) => ({
          '@type': 'BedDetails',
          numberOfBeds: bed.value,
          typeOfBed: this.bedDescription(bed.key),
        })),
        amenityFeature: facilities
          .filter((facility) => roomType.facilities.includes(facility.id))
          .map((facility) => ({
            '@type': 'LocationFeatureSpecification',
            name: facility.name,
            value: 'True',
          })),
      }

      if (!!roomType.sqm) {
        schema.floorSize = {
          '@type': 'QuantitativeValue',
          value: roomType.sqm,
          unitCode: 'MTK',
        }
      }

      return schema
    },
  },
}
</script>

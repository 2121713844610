import * as PERMISSION from '@/router/permission-types'

export const Links = [
  {
    permission: PERMISSION.Properties,
    name: 'properties',
    label: 'Properties',
    icon: 'hotel',
    group: 'collections',
  },
  {
    permission: PERMISSION.Rates,
    name: 'rates',
    label: 'Rates',
    icon: 'badge-dollar',
    group: 'collections',
  },
  {
    permission: PERMISSION.Deals,
    name: 'deals',
    label: 'Deals',
    icon: 'badge-dollar',
    group: 'collections',
  },
  {
    permission: PERMISSION.Extras,
    name: 'extras',
    label: 'Extras',
    icon: 'search-dollar',
    group: 'collections',
  },
  {
    permission: PERMISSION.ProofPoints,
    name: 'proof-points',
    label: 'Proof Points',
    icon: 'newspaper',
    group: 'collections',
  },
  {
    permission: PERMISSION.Snippets,
    name: 'snippets',
    label: 'Snippets',
    icon: 'comment-alt-exclamation',
    group: 'collections',
  },
  {
    permission: PERMISSION.Announcements,
    name: 'announcements',
    label: 'Announcements',
    icon: 'bell',
    group: 'collections',
  },
  {
    permission: PERMISSION.Amenities,
    name: 'amenities',
    label: 'Amenities',
    icon: 'coffee',
    group: 'collections',
  },
  {
    permission: PERMISSION.Facilities,
    name: 'facilities',
    label: 'Facilities',
    icon: 'wifi',
    group: 'collections',
  },
  {
    permission: PERMISSION.Localities,
    name: 'localities',
    label: 'Localities',
    icon: 'icons',
    group: 'collections',
  },
  {
    permission: PERMISSION.Preloads,
    name: 'preload',
    label: 'Preload',
    icon: ['fad', 'sync'],
    group: 'system',
  },
  {
    permission: PERMISSION.Hosts,
    name: 'hosts',
    label: 'Hosts',
    icon: 'globe-asia',
    group: 'system',
  },
  {
    permission: PERMISSION.Engines,
    name: 'engines',
    label: 'Engines',
    icon: 'cogs',
    group: 'system',
  },
  {
    permission: PERMISSION.Themes,
    name: 'themes',
    label: 'Themes',
    icon: 'palette',
    group: 'system',
  },
  {
    permission: PERMISSION.ExternalAccounts,
    name: 'external-accounts',
    label: 'External Accounts',
    icon: 'link',
    group: 'system',
  },
]

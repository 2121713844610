<template>
  <div :class="card" class="mb-4">
    <div :class="`card border-left-${theme} shadow h-100 py-2`">
      <div class="card-body">
        <div class="row no-gutters align-items-center">
          <div class="col mr-2">
            <div
              :class="`text-xs font-weight-bold text-${theme} text-uppercase mb-1`"
            >
              {{ label }}
            </div>
            <div class="h5 mb-0 font-weight-bold text-gray-800 small">
              {{ value }}
            </div>
          </div>
          <div class="col-auto">
            <font-awesome-icon
              v-if="!hasSlotData && icon"
              :icon="icon"
              size="2x"
              class="text-gray-300"
            ></font-awesome-icon>
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'quest-stat-card',
  props: {
    label: { required: true },
    value: { required: true },
    icon: Array,
    card: { default: 'col-12' },
    theme: { default: 'primary' },
  },
  computed: {
    hasSlotData() {
      return this.$slots.default
    },
  },
}
</script>

import {
  QuestInput,
  QuestTextArea,
  QuestEditor,
  QuestDatePicker,
  QuestToggle,
  QuestSingleSelect,
  QuestMultiSelect,
  QuestImagePicker,
  QuestImage,
  QuestTagInput,
} from './form'

import { QuestCard } from './structure'

export default {
  components: {
    QuestInput,
    QuestTextArea,
    QuestEditor,
    QuestDatePicker,
    QuestToggle,
    QuestCard,
    QuestSingleSelect,
    QuestMultiSelect,
    QuestImagePicker,
    QuestImage,
    QuestTagInput,
  },
}

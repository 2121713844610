<template>
  <ValidationObserver ref="vobserver" v-slot="{}">
    <quest-main :loading="loading">
      <template v-slot:body>
        <div class="row justify-content-center">
          <div class="col-xl-10 col-lg-12 col-md-9">
            <div class="card o-hidden border-0 shadow-lg my-5">
              <div class="card-body p-0" v-if="sent">
                <div class="row">
                  <div class="col">
                    <div class="p-5">
                      <div class="text-center">
                        <h1 class="h4 text-gray-900 mb-4">
                          Thank you for contacting support
                        </h1>
                        <p class="text-gray-600">
                          Your message and details have been sent to support and
                          a ticket has been created for you. We will get back to
                          you as soon as we can.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0" v-if="!sent">
                <div class="row">
                  <div class="col">
                    <div class="pt-5 px-5">
                      <div class="text-center">
                        <h1 class="h4 text-gray-900 mb-4">Need support?</h1>
                        <p class="text-gray-600">
                          Please fill in the form below to contact the Quest
                          Nexus technical support team.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="px-5">
                      <quest-text-area
                        name="Message"
                        id="message"
                        help="Type your support request in the area above and send!"
                        v-model="message"
                        :rules="{ [REQUIRED]: true }"
                        rows="6"
                      ></quest-text-area>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="px-5 pb-5">
                      <div class="form-group">
                        <button
                          @click="submit"
                          class="btn btn-primary btn-lg btn-block"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'envelope']"
                            size="lg"
                          ></font-awesome-icon>
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </quest-main>
  </ValidationObserver>
</template>

<script>
import { QuestMain } from './structure'
import { QuestTextArea } from './form'
import { SupportService } from '@/services'
import { REQUIRED } from '@/validation-types'

export default {
  name: 'property-link',
  components: {
    QuestMain,
    QuestTextArea,
  },
  data() {
    return {
      REQUIRED,
      message: '',
      sent: false,
      loading: false,
    }
  },
  mounted() {},
  methods: {
    async submit() {
      if (!this.$refs.vobserver) return
      let validated = await this.$refs.vobserver.validate()
      if (!validated) {
        error('Please ensure all errors are resolved')
        return
      }
      this.loading = true
      new SupportService()
        .send(this.message)
        .then(async (data) => {
          if (data && data.status && data.status != 200) {
            error(`Failed to send support request`)
          } else {
            this.sent = true
            this.loading = true
          }
        })
        .finally((_) => (this.loading = false))
    },
  },
}
</script>

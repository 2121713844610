<template>
  <div class="form-group">
    <label v-if="label" :for="id">{{ label }}</label>
    <VueDatePicker
      :disabled="disabled"
      v-model="input"
      v-bind="attrs"
      format-header="dddd, DD MMMM"
      :color="'#a89968'"
      :placeholder="placeholder"
      ><template v-if="icon" #input-icon>
        <font-awesome-icon
          :icon="icon"
          size="sm"
          fixed-width
        ></font-awesome-icon> </template
    ></VueDatePicker>
    <small v-if="help" :id="id + 'Help'" class="form-text text-muted">{{
      help
    }}</small>
  </div>
</template>

<script>
import form from './form'

export default {
  extends: form,
  name: 'quest-date-picker',
  props: {
    clearable: { default: false },
    disabled: { default: false },
    icon: { default: false },
  },
  computed: {
    attrs() {
      if (this.clearable && !this.disabled) {
        return { clearable: '' }
      } else {
        return {}
      }
    },
  },
}
</script>

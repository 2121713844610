<template>
  <quest-card
    title="Marketing"
    :collapsable="true"
    :collapsed="true"
    id="marketingInfo"
  >
    <div class="row">
      <div class="col-md-6">
        <quest-input
          id="label"
          label="Label"
          v-model="deal.label"
          help="Custom label for Quest frontend"
        ></quest-input>
      </div>
      <div class="col-md-6">
        <quest-toggle
          id="isValueAdd"
          label="Value Add"
          v-model="deal.isValueAdd"
          checked="True"
          unchecked="False"
          help="Configure deal as 'Value Add'"
        />
      </div>
    </div>

    <quest-multi-select
      id="packageInclusions"
      label="Package Inclusions"
      v-model="deal.packageInclusions"
      :options="inclusions"
      :uid="'Key'"
      :text="'Value'"
    ></quest-multi-select>

    <quest-editor
      id="description"
      label="Description"
      v-model="deal.description"
    ></quest-editor>

    <quest-editor
      id="disclaimer"
      label="Disclaimer"
      v-model="deal.disclaimer"
    ></quest-editor>
  </quest-card>
</template>

<script>
import { ResourcesService } from '../../services'
import Card from './deal-card'

export default {
  name: 'marketing-card',
  extends: Card,
  data() {
    return {
      inclusions: [],
    }
  },
  mounted() {
    new ResourcesService().inclusions().then((data) => (this.inclusions = data))
  },
}
</script>

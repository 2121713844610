import AdminNexusHttpClient from './admin-nexus-http-client'

export default class StayNightService {
  constructor() {
    this.httpClient = new AdminNexusHttpClient('/stay-night')
  }

  async getStayNights() {
    return await this.httpClient.get('')
  }
}

<template>
  <quest-main title="Account selection" center :loading="loading">
    <template v-slot:body>
      <div class="row">
        <quest-stat-card
          v-for="acc in accounts"
          :key="acc.id"
          :label="acc.service"
          :value="`${acc.label} (${acc.externalId})`"
          card="col-xl-6 col-md-6"
          ><template>
            <button @click="setAccount(acc.id)" class="btn btn-primary btn-sm">
              <font-awesome-icon
                :icon="['fas', 'link']"
                size="sm"
                fixed-width
                class="text-white-50"
              ></font-awesome-icon>
              View
            </button>
          </template></quest-stat-card
        >
      </div>
    </template>
  </quest-main>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import {
  SET_ACCOUNT,
  SET_UNLOADED,
  INIT,
  GET_EXTERNAL_ACCOUNTS,
} from '@/store/action-types'
import { QuestMain, QuestStatCard } from './structure'
import { ExternalAccountsService } from '../services'
import { QuestSingleSelect } from './form'

export default {
  name: 'account',
  components: {
    QuestMain,
    QuestStatCard,
    QuestSingleSelect,
  },
  data() {
    return {
      // accounts: [],
      loading: true,
    }
  },
  computed: {
    ...mapState(['account', 'accounts']),
    ...mapGetters(['isAdmin']),
  },
  methods: {
    ...mapActions([SET_ACCOUNT, SET_UNLOADED, INIT, GET_EXTERNAL_ACCOUNTS]),
    async setAccount(value) {
      this.SET_UNLOADED()
      this.SET_ACCOUNT(value)
      await this.INIT()
      this.$router.push({ name: 'dashboard' })
    },
  },
  async mounted() {
    await this.GET_EXTERNAL_ACCOUNTS()
    this.loading = false
  },
}
</script>

<template>
  <quest-card
    title="Benefits"
    :collapsable="true"
    :collapsed="true"
    id="benefitsForm"
  >
    <div class="row">
      <div class="col-md-6 offset-md-6">
        <div class="form-group text-right">
          <a
            @click="addBenefit()"
            class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
            ><font-awesome-icon
              icon="plus"
              size="sm"
              class="text-white-50"
            ></font-awesome-icon>
            Add
          </a>
        </div>
      </div>
    </div>
    <template v-if="benefits.length > 0">
      <div class="row" v-for="(benefit, index) in benefits" :key="index">
        <div class="col-12">
          <hr />
          <quest-input
            :id="'heading-' + index"
            label="Heading"
            name="Benefit heading"
            v-model="benefit.heading"
            inputGroupTextAppendStyle="margin: 0; padding: 0; border: 0"
            :rules="{ [REQUIRED]: true, [MAX]: { length: 60 } }"
          >
            <template v-slot:append>
              <a
                @click="removeBenefit(benefit)"
                class="btn btn-sm btn-primary"
                style="
                  height: 100%;
                  line-height: 2;
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0;
                "
                ><font-awesome-icon
                  icon="times"
                  size="sm"
                  class="text-white-50"
                ></font-awesome-icon>
                Remove
              </a>
            </template></quest-input
          >
        </div>
        <div class="col-12">
          <quest-text-area
            label="Body"
            name="Benefit body"
            v-model="benefit.body"
            :rules="{ [REQUIRED]: true, [MAX]: { length: 350 } }"
          ></quest-text-area>
        </div>
      </div>
    </template>
    <template v-else>
      <small class="text-center d-block">No Benefits configured</small>
    </template>
  </quest-card>
</template>

<script>
import { BenefitsService } from '../../services'
import questTextArea from '../form/quest-text-area.vue'
import Card from './property-card'

export default {
  components: { questTextArea },
  extends: Card,
  data() {
    return {
      benefits: [],
    }
  },
  mounted() {
    new BenefitsService()
      .get(this.property.id)
      .then((data) => (this.benefits = data))
  },
  methods: {
    getValue() {
      return {
        property: this.property.id,
        payload: this.benefits,
      }
    },
    addBenefit() {
      this.benefits.push({ question: '', answer: '' })
    },
    removeBenefit(benefit) {
      this.benefits = this.benefits.filter((x) => x !== benefit)
    },
  },
}
</script>

<template>
  <quest-main
    title="Facilities"
    copy="Manage facilities and features for your properties such as Wifi and AV Equipment"
    :loading="loading"
  >
    <template v-slot:head>
      <button
        @click="add()"
        class="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
        v-bind="{ disabled: adding }"
      >
        <font-awesome-icon
          icon="plus"
          size="sm"
          fixed-width
          class="text-white-50"
          :class="{ 'animated--heart-beat': adding }"
        ></font-awesome-icon>
        {{ saving ? 'Adding...' : 'Add' }}
      </button>
    </template>
    <template v-slot:body>
      <quest-card title="Facility collection">
        <quest-table id="facility-table" :columns="columns">
          <template v-if="facilities.items.length > 0">
            <tr
              class="small align-middle"
              v-for="facility in facilities.items"
              :key="facility.id"
            >
              <td class="text-center">
                <i :class="facility.icon"></i>
              </td>
              <td>
                <a class="link pointer" @click="promptUpdate(facility)">{{
                  facility.name || '(untitled)'
                }}</a>
              </td>
              <td>{{ lastModified(facility.dateModified) }}</td>
              <td>
                <b-dropdown no-caret variant="link" class="b-dropdown-p-0">
                  <template #button-content>
                    <font-awesome-icon
                      icon="ellipsis-v"
                      size="sm"
                      fixed-width
                      class="text-gray-400"
                    ></font-awesome-icon>
                  </template>
                  <b-dropdown-header>Actions</b-dropdown-header>
                  <b-dropdown-item @click="promptUpdate(facility)"
                    >Update</b-dropdown-item
                  >
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item-button
                    @click="promptDelete(facility)"
                    text="danger"
                    variant="danger"
                    >Delete</b-dropdown-item-button
                  >
                </b-dropdown>
              </td>
            </tr>
          </template>
          <template v-if="facilities.items.length === 0">
            <tr class="align-middle">
              <td :colspan="columns.length">
                <div class="small text-center py-5">
                  There are no facilities in the system
                </div>
              </td>
            </tr>
          </template>
        </quest-table>
        <quest-pager
          v-if="facilities.total > pageSize"
          @changePage="changePage"
          :currentPage="page"
          :itemCount="facilities.total"
          :pageSize="pageSize"
        ></quest-pager>
      </quest-card>
      <ValidationObserver ref="vobserver" v-slot="{}">
        <b-modal
          v-model="showUpdateModal"
          centered
          :title="toUpdate && toUpdate.id ? 'Update' : 'Create'"
        >
          <div class="row" v-if="toUpdate">
            <div class="col-md-6">
              <quest-input
                id="name"
                label="Name"
                name="Name"
                :rules="{ [REQUIRED]: true }"
                v-model="toUpdate.name"
                help="The public label for the facility"
              ></quest-input>
            </div>
            <div class="col-md-6">
              <quest-single-select
                v-model="toUpdate.icon"
                :options="classes"
                id="icon"
                label="Icon"
                placeholder="Select an icon"
                help=""
                @update="updateIcon"
              >
                <template v-slot:prepend>
                  <i :class="toUpdate.icon"></i>
                </template>
              </quest-single-select>
            </div>
          </div>
          <template v-slot:modal-footer>
            <button
              class="btn btn-secondary btn-sm"
              type="button"
              @click="cancelPromptUpdate"
            >
              Cancel</button
            ><button
              class="btn btn-primary btn-sm"
              type="button"
              @click="confirmPromptUpdate"
            >
              {{ toUpdate && toUpdate.id ? 'Update' : 'Create' }}
            </button>
          </template>
        </b-modal>
      </ValidationObserver>
      <b-modal v-model="showDeleteModal" centered title="Delete">
        <p class="my-4" v-if="toDelete">
          Are you sure you wish to delete facility '{{ toDelete.name }}'?
        </p>
        <template v-slot:modal-footer>
          <button
            class="btn btn-secondary btn-sm"
            type="button"
            @click="cancelPromptDelete"
          >
            Cancel</button
          ><button
            class="btn btn-danger btn-sm"
            type="button"
            @click="confirmPromptDelete"
          >
            Delete
          </button>
        </template>
      </b-modal>
    </template>
  </quest-main>
</template>

<script>
import { REQUIRED } from '@/validation-types'
import { success, error } from '../helpers'
import { QuestInput, QuestSingleSelect } from './form'
import { FONT_CLASSES } from '../data'
import moment from 'moment'
import 'moment-timezone'
import {
  QuestMain,
  QuestTable,
  QuestCard,
  QuestModal,
  QuestPager,
} from './structure'
import { FacilityService } from '../services'
import PagedResult from '../models/paged-result'

export default {
  name: 'facilities',
  components: {
    QuestMain,
    QuestTable,
    QuestCard,
    QuestPager,
    QuestSingleSelect,
    QuestModal,
    QuestInput,
  },
  data() {
    return {
      REQUIRED,
      pageSize: 10,
      page: 1,
      term: '',
      facilities: new PagedResult(),
      columns: ['', 'Name', 'Modified', ''],
      loading: true,
      adding: false,
      saving: false,
      toUpdate: null,
      toDelete: null,
      showUpdateModal: false,
      showDeleteModal: false,
      classes: FONT_CLASSES.map((x) => ({
        key: x.key,
        value: `${x.value[0].toUpperCase()}${x.value.slice(1)}`.replace(
          /-/g,
          ' '
        ),
      })),
    }
  },
  mounted() {
    this.changePage(1)
  },
  methods: {
    new() {
      return {
        id: null,
        name: '',
        icon: '',
      }
    },
    add() {
      this.promptUpdate(this.new())
    },
    lastModified(date) {
      return moment(`${date}Z`).tz('Australia/Melbourne').format('lll')
    },
    updateIcon(val) {
      if (this.toUpdate) {
        this.toUpdate.icon = val
      }
    },
    changePage(page) {
      new FacilityService()
        .list(page, this.pageSize, this.term)
        .then((data) => {
          this.facilities = data
          this.page = page
        })
        .finally((_) => (this.loading = false))
    },
    update() {
      new FacilityService()
        .update(this.toUpdate)
        .then((data) => {
          if (data) {
            this.changePage(1)
            success(`Updated facility #`, this.toUpdate.name)
          } else {
            error(`Failed to update facility #`, this.toUpdate.name)
          }
        })
        .finally((_) => {
          this.showUpdateModal = false
          this.toUpdate = {}
        })
    },
    create() {
      new FacilityService()
        .add(this.toUpdate)
        .then((data) => {
          if (data) {
            this.changePage(1)
            success(`Added facility #`, this.toUpdate.name)
          } else {
            error(`Failed to add facility #'`, this.toUpdate.name)
          }
        })
        .finally((_) => {
          this.showUpdateModal = false
          this.toUpdate = {}
        })
    },
    promptUpdate(facility) {
      this.toUpdate = Object.assign({}, facility)
      this.showUpdateModal = true
    },
    promptDelete(facility) {
      this.toDelete = facility
      this.showDeleteModal = true
    },
    cancelPromptUpdate() {
      this.showUpdateModal = false
      this.toUpdate = {}
    },
    cancelPromptDelete() {
      this.showDeleteModal = false
      this.toDelete = null
    },
    async confirmPromptUpdate() {
      if (!this.$refs.vobserver) return
      let validated = await this.$refs.vobserver.validate()
      if (!validated) {
        error('Please ensure all errors are resolved')
        return
      }
      if (this.toUpdate.id) {
        this.update()
      } else {
        this.create()
      }
    },
    confirmPromptDelete() {
      new FacilityService()
        .delete(this.toDelete.id)
        .then((data) => {
          this.showDeleteModal = false

          if (data) {
            this.changePage(1)
            success(`Deleted facility #`, this.toDelete.name)
          } else {
            error(`Failed to delete facility #`, this.toDelete.name)
          }
        })
        .finally((_) => {
          this.toDelete = null
        })
    },
  },
}
</script>

<template>
  <div class="form-group">
    <label v-if="label" :for="id">{{ label }}</label>
    <ValidationProvider
      :name="visibleName"
      :rules="rules"
      v-slot="{ errors, classes }"
    >
      <template v-if="hasSlotData">
        <div class="input-group">
          <div class="input-group-prepend" v-if="hasPrependData">
            <span class="input-group-text" :id="`${id}-addon`">
              <slot name="prepend"></slot>
            </span>
          </div>
          <input
            :type="type"
            class="form-control"
            :class="{ 'form-control-lg': large, ...classes }"
            :id="id"
            :value="value"
            :aria-describedby="`${id}-addon`"
            @input="handleInput"
            :name="id"
            :disabled="disabled"
            :placeholder="placeholder"
            v-on:keydown="countdown"
            v-on:keyup="countdown"
            v-on:keyup.enter="$emit('enter')"
          />
          <div
            class="input-group-append"
            v-if="hasAppendData"
            :style="inputGroupAppendStyle"
          >
            <span
              class="input-group-text"
              :id="`${id}-append-addon`"
              :style="inputGroupTextAppendStyle"
            >
              <slot name="append"></slot>
            </span>
          </div>
        </div>
      </template>
      <template v-else>
        <input
          :type="type"
          class="form-control"
          :class="{ 'form-control-lg': large, ...classes }"
          :id="id"
          :value="value"
          :aria-describedby="id + 'Help'"
          @input="handleInput"
          :name="id"
          :disabled="disabled"
          :placeholder="placeholder"
          v-on:keydown="countdown"
          v-on:keyup="countdown"
          v-on:keyup.enter="$emit('enter')"
        />
      </template>
      <small v-if="help" :id="id + 'Help'" class="form-text text-muted">{{
        help
      }}</small>
      <small class="text-danger">{{ errors[0] }}</small>
    </ValidationProvider>
  </div>
</template>

<script>
import form from './form'

export default {
  name: 'quest-input',
  extends: form,
  props: {
    large: Boolean,
    multiple: Boolean,
    inputGroupAppendStyle: { type: String, default: '' },
    inputGroupTextAppendStyle: { type: String, default: '' },
  },
  methods: {
    countdown() {},
  },
  computed: {
    hasSlotData() {
      return this.hasPrependData || this.hasAppendData
    },
    hasPrependData() {
      return this.$slots.prepend
    },
    hasAppendData() {
      return this.$slots.append
    },
  },
}
</script>

<template>
  <div class="card mb-4 card-slim">
    <div class="card-header py-3 card-header-flex">
      <h6 class="m-0 font-weight-bold text-primary">{{ room.name }}</h6>
      <h6
        class="m-0 font-weight-bold text-primary"
        :class="[
          rates.status === RoomQuoteStatus.Success
            ? 'text-success'
            : 'text-danger',
        ]"
      >
        {{ roomQuoteStatus }}
      </h6>
    </div>
    <div
      class="card-body"
      v-if="
        ![
          RoomQuoteStatus.OverCapacity,
          RoomQuoteStatus.NoRates,
          RoomQuoteStatus.NoInventory,
          RoomQuoteStatus.Error,
        ].includes(rates.status)
      "
    >
      <div v-if="room.isEnabled">
        <b-table
          :items="rates.rates"
          :fields="fields"
          small
          hover
          fixed
          stacked="md"
          borderless
        >
          <template #cell(rate)="data">{{ data.item.name }}</template>
          <template #cell(status)="data">
            <rate-explorer-status
              :rate="data.item"
              :roomId="room.id"
            ></rate-explorer-status>
          </template>
          <template #cell(gross)="data">
            <template v-if="data.item.quote">
              {{ currency
              }}{{
                formatCurrency(
                  isLoggedIn && data.item.quote?.promoted
                    ? data.item.quote?.promoted?.total
                    : data.item.quote?.standard?.total
                )
              }}</template
            ><template v-if="!data.item.quote">-</template></template
          >
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import RateExplorerStatus from './rate-explorer-status.vue'
import { formatCurrency } from '@/helpers'
import { QuoteStatus, RoomQuoteStatus, RateQuoteStatus } from '../../models'
export default {
  name: 'rate-explorer-card',
  props: {
    card: { required: true },
    currency: { default: '$' },
    isLoggedIn: { default: false },
  },
  components: {
    RateExplorerStatus,
  },
  data() {
    return {
      QuoteStatus,
      RoomQuoteStatus,
      RateQuoteStatus,
      fields: ['rate', 'status', 'gross'],
    }
  },
  methods: {
    formatCurrency,
  },
  computed: {
    room() {
      return this.card.room || {}
    },
    rateQuoteStatus() {
      return (status) => {
        switch (status) {
          case RateQuoteStatus.MissingContext:
            return 'Missing pricing'
          case RateQuoteStatus.NoContext:
            return 'No pricing'
          case RateQuoteStatus.Success:
            return 'Successful'
          case RateQuoteStatus.Blocked:
            return 'Blocked'
          case RateQuoteStatus.NoTable:
            return 'No valid rate table'
          case RateQuoteStatus.Check:
          case RateQuoteStatus.Error:
          default:
            return 'System Error'
        }
      }
    },
    quoteStatus() {
      return (status) => {
        switch (status) {
          case QuoteStatus.MissingContext:
            return 'Missing pricing'
          case QuoteStatus.NoContext:
            return 'No pricing'
          case QuoteStatus.Success:
            return 'Successful'
          case QuoteStatus.Blocked:
            return 'Blocked'
          case QuoteStatus.NoCancellationPolicy:
            return 'No valid cancellation policy'
          case QuoteStatus.BelowMinimumThreshold:
            return 'Below minimum threshold'
          case QuoteStatus.NoTable:
            return 'No valid rate table'
          case QuoteStatus.Check:
          case QuoteStatus.Error:
          default:
            return 'System Error'
        }
      }
    },
    roomQuoteStatus() {
      switch (this.rates.status) {
        case RoomQuoteStatus.NoInventory:
          return 'No Inventory'
        case RoomQuoteStatus.NoQuotes:
          return 'No Quotes'
        case RoomQuoteStatus.OverCapacity:
          return 'Overcapacity'
        case RoomQuoteStatus.NoRates:
          return 'No Nexus Rates'
        case RoomQuoteStatus.Success:
          return 'Successful'
        case RoomQuoteStatus.Check:
        case RoomQuoteStatus.Error:
        default:
          return 'System Error'
      }
    },
    quoteStatusDescription() {
      return (status) => {
        switch (status) {
          case QuoteStatus.MissingContext:
            return 'There is limited pricing available given the selected rate table. This may indicate an issue with the RMS sync, requiring a resync to realign Nexus data'
          case QuoteStatus.NoContext:
            return 'There is no pricing available given the selected rate table. This may indicate an issue with the RMS sync, requiring a resync to realign Nexus data.'
          case QuoteStatus.Success:
            return 'A quote has been successfully provided'
          case QuoteStatus.Blocked:
            return 'Rate table is not associated with property or RMS booking rules have blocked this rate from producing a quote. This is due to a stop sell on arrival, departure or within the date range.'
          case QuoteStatus.NoCancellationPolicy:
            return "The rates' linked RMS rate table is in turn linked to a valid cancellation policy. Please ensure that it is mapped in RMS and/or not deleted. You may change the RMS rate table connected to this Nexus rate at any time."
          case QuoteStatus.BelowMinimumThreshold:
            return 'Any quote supplied that has a daily gross below the minimum threshold, after discounts, is automatically locked out and unable to be reserved.'
          case QuoteStatus.NoTable:
            return "The rates' linked RMS rate table is missing. Please ensure that it is mapped in RMS and/or not deleted. You may change the RMS rate table connected to this Nexus rate at any time."
          case QuoteStatus.Check:
          case QuoteStatus.Error:
          default:
            return 'There was an unexpected error encountered when processing this rate. Please lodge a ticket with support detailing your search and issue.'
        }
      }
    },
    rates() {
      return this.card.rates || {}
    },
    roomTypeStatus() {
      return this.room.isEnabled
        ? 'Enabled'
        : this.card.room.isInactive
        ? 'Inactive'
        : 'Disabled'
    },
  },
}
</script>

<template>
  <div>
    <quest-card panel v-if="!p_locality">
      <h4 class="text-center h4 text-gray-400 mt-2 mb-2">
        You do not have access to modify any fields in this tab
      </h4>
    </quest-card>
    <template v-if="p_locality">
      <div class="row" v-if="!configuration.enableLocalDiscovery">
        <quest-stat-card
          label="Disabled"
          value="The locality module is currently disabled on this property. You may edit the details in this section but they will not be available to end users."
          theme="dark"
          card="col-12"
        >
        </quest-stat-card>
      </div>

      <quest-card panel>
        <div class="row">
          <div class="col-md-4">
            <quest-single-select
              id="locality"
              label="Locality"
              v-model="locality.localityId"
              help="Select a branded local colour theme"
              placeholder="No locality selected"
              :options="localities"
              @update="updateLocality"
              keepPlaceholder
            ></quest-single-select>
          </div>
          <div class="col-md-4">
            <quest-input
              id="localityName"
              label="Name"
              v-model="locality.name"
              help="Public/local figures' name"
            >
            </quest-input>
          </div>
          <div class="col-md-4">
            <quest-input
              id="localityRole"
              label="Role/Relationship"
              v-model="locality.role"
              help="The relationship to the property (CEO, partner, manager etc.)"
            >
            </quest-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label>Profile Image</label>
              <div class="d-flex h-100">
                <quest-image
                  :image="locality.profile"
                  single
                  @change="$refs.profile.open()"
                ></quest-image>
              </div>
            </div>
          </div>
        </div>
        <quest-image-picker
          ref="profile"
          title="Select profile image"
          :propertyId="
            property.siteminderPropertyId !=
            '00000000-0000-0000-0000-000000000000'
              ? property.siteminderPropertyId
              : property.id
          "
          :albumName="property.name"
          :current="[locality.profile]"
          @update="updateProfile"
          required
          manage
        ></quest-image-picker>
      </quest-card>
    </template>
  </div>
</template>

<script>
import View from './view'
import { LocalityService, PropertyLocalityService } from '../../services'
import { QuestStatCard } from '../structure'
import { Properties } from '@/router/permission-types'
import { hasFieldAccess } from '@/helpers'

export default {
  extends: View,
  name: 'property-locality-view',
  components: {
    QuestStatCard,
  },
  data() {
    return {
      locality: this.newLocality(),
      localities: [],
    }
  },
  computed: {
    p_locality() {
      return hasFieldAccess(Properties, 'locality')
    },
  },
  mounted() {
    new LocalityService().all().then((data) => (this.localities = data))
    new PropertyLocalityService().get(this.property.id).then((locality) => {
      this.locality = locality || this.locality
      if (!this.locality.localityId) {
        this.locality.localityId = ''
      }
    })
  },
  methods: {
    getValue() {
      return {
        property: this.property.id,
        payload: this.locality,
      }
    },
    updateProfile(selected) {
      this.locality.profile = selected
    },
    updateLocality(update) {
      this.locality.localityId = update
    },
    newLocality() {
      return {
        profile: '',
        role: '',
        name: '',
        localityId: '',
      }
    },
  },
}
</script>

<template>
  <div class="rate-listing-view">
    <template v-if="!loading">
      <!-- TABLE START -->
      <template v-if="data.total">
        <div class="border rounded p-3 mb-3 bg-white">
          <b-table
            :items="data.items"
            :fields="fields"
            small
            hover
            fixed
            stacked="md"
            borderless
          >
            <template #cell(name)="data">
              <a class="pointer" @click="edit(data.item)">{{
                data.item.name || '(untitled)',
              }}</a>
            </template>
            <template #cell(status)="data">
              <span
                :class="[data.item.available ? 'text-success' : 'text-danger']"
                >{{ data.item.available ? 'Available' : 'Unavailable' }}</span
              >
            </template>
            <template #cell(options)="data">
              <b-dropdown no-caret variant="link" class="b-dropdown-p-0">
                <template #button-content>
                  <font-awesome-icon
                    icon="ellipsis-v"
                    size="sm"
                    fixed-width
                    class="text-gray-400"
                  ></font-awesome-icon>
                </template>
                <b-dropdown-header>Actions</b-dropdown-header>
                <b-dropdown-item-button @click="edit(data.item)"
                  >Update</b-dropdown-item-button
                >
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item-button
                  @click="remove(data.item)"
                  text="danger"
                  variant="danger"
                  >Delete</b-dropdown-item-button
                >
              </b-dropdown>
            </template></b-table
          >
        </div>
        <quest-pager
          v-if="data.total > pageSize"
          @changePage="changePage"
          :currentPage="page"
          :itemCount="data.total"
          :pageSize="pageSize"
        ></quest-pager>
      </template>
      <!-- TABLE END -->

      <div class="border rounded p-3 mt-3 bg-white" v-if="!data.total">
        <div class="small text-center">
          {{
            property
              ? 'No extras were found for this property.'
              : 'Please select a property'
          }}
        </div>
      </div>
    </template>
    <template v-else>
      <div class="text-gray-400 text-center my-5">
        <font-awesome-icon
          icon="spinner"
          size="4x"
          fixed-width
          pulse
        ></font-awesome-icon>
        <p class="pt-3 pb-0">Loading...</p>
      </div>
    </template>

    <extra-editor
      ref="editor"
      @saved="() => saved()"
      @saving="$refs.saving.show()"
      @failed="$refs.saving.hide()"
    ></extra-editor>

    <b-modal
      ref="saving"
      centered
      size="sm"
      body-class="p-0"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="container-xl my-5">
        <div class="text-gray-400 text-center">
          <font-awesome-icon
            icon="save"
            size="4x"
            fixed-width
            pulse
            class="animated--heart-beat"
          ></font-awesome-icon>
          <p class="pt-3 pb-0">Saving</p>
          <small>This may take a moment</small>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="delete"
      title="Delete"
      :ok-title="'Delete'"
      ok-variant="danger"
      centered
      button-size="sm"
      header-bg-variant="danger"
      body-bg-variant="danger"
      footer-bg-variant="danger"
      header-text-variant="white"
      body-text-variant="white"
      @ok="() => confirm('delete')"
      @cancel="() => cancel()"
      @hidden="() => cancel()"
    >
      Are you sure you wish to delete '{{ selected?.name || '(untitled)' }}'?
      This action is <b>permanent</b> are will be removed from checkout.
    </b-modal>
  </div>
</template>

<script>
import { ExtrasService } from '@/services'
import { uuidv4 } from '@/helpers'
import { PagedResult, ExtraIntervalListing } from '@/models'
import { QuestPager } from '@/components/structure'

import ExtraEditor from './extra-editor.vue'

export default {
  name: 'extra-listing-view',
  components: {
    ExtraEditor,
    QuestPager,
  },
  data() {
    return {
      selected: null,
      property: null,
      hash: null,
      loading: true,
      page: 1,
      term: '',
      pageSize: 10,
      data: new PagedResult(),
      fields: [
        {
          key: 'name',
        },
        {
          key: 'pricing',
          formatter: (_a, _b, item) =>
            item.gross === 0
              ? 'Free'
              : `$${item.gross} ${ExtraIntervalListing.find(
                  (x) => x.key === item.interval
                ).label.toLowerCase()}`,
        },
        {
          key: 'status',
        },
        {
          key: 'options',
          label: '',
          thStyle: { width: '50px' },
        },
      ],
    }
  },
  mounted() {
    this.$emit('init')
  },
  methods: {
    changePage(page) {
      this.search(this.property, page)
    },
    saved() {
      this.$refs['saving'].hide()
      this.refresh()
    },
    refresh() {
      this.search(this.property, 1)
    },
    add() {
      this.$refs.editor.new(this.property)
    },
    search(property, page = 1) {
      this.loading = true
      let hash = uuidv4()
      this.hash = hash
      if (property) {
        new ExtrasService()
          .list(property, page, this.pageSize, this.term)
          .then((data) => {
            if (hash == this.hash) {
              this.property = property
              this.data = data
              this.loading = false
              this.page = page
            }
          })
          .catch((_) => (this.loading = false))
      } else {
        this.data = new PagedResult()
        this.property = null
        this.loading = false
      }
    },
    edit(item) {
      this.$refs['editor'].edit(item.id)
    },
    remove(value) {
      this.selected = value
      this.$refs['delete'].show()
    },
    cancel() {
      this.selected = null
    },
    async confirm(value) {
      if (value === 'delete') {
        await new ExtrasService().delete(this.selected?.id).then().catch()
      }

      this.selected = null
      this.refresh()
      return true
    },
  },
}
</script>

export const INIT = 'INIT'
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
export const INIT_PROPERTY = 'INIT_PROPERTY'
export const GET_COUNTRIES = 'GET_COUNTRIES'
export const GET_STATES = 'GET_STATES'
export const GET_STAYNIGHTS = 'GET_STAYNIGHTS'
export const GET_SITES = 'GET_SITES'
export const GET_STORIES = 'GET_STORIES'
export const GET_RESTAURANTS = 'GET_RESTAURANTS'
export const GET_FACTSHEETS = 'GET_FACTSHEETS'
export const GET_ROOM_FEATURES = 'GET_ROOM_FEATURES'
export const DISCONNECT = 'DISCONNECT'
export const SET_LOADED = 'SET_LOADED'
export const SET_UNLOADED = 'SET_UNLOADED'
export const GET_ENGINE_DYNAMIC_TOKENS = 'GET_ENGINE_DYNAMIC_TOKENS'
export const GET_ENGINE_PROMOTIONS = 'GET_ENGINE_PROMOTIONS'
export const GET_ENGINE_IDENTIFIERS = 'GET_ENGINE_IDENTIFIERS'
export const GET_FACILITIES = 'GET_FACILITIES'
export const GET_EXTERNAL_ACCOUNTS = 'GET_EXTERNAL_ACCOUNTS'
export const SET_ACCOUNT = 'SET_ACCOUNT'

<template>
  <div class="container-fluid">
    <div class="main-loading text-gray-400 text-center">
      <font-awesome-icon
        icon="exclamation"
        size="4x"
        fixed-width
      ></font-awesome-icon>
      <p class="pt-3 pb-0">Page not found</p>
    </div>
  </div>
</template>

<script>
import { QuestMain } from './structure'

export default {
  name: 'not-found',
  components: {
    QuestMain,
  },
}
</script>

import { RateRecord, RateStatus } from '@/models'

export default class RateRecordGrouping {
  /* Base Records */
  group = null
  property = null
  room = null

  /* Priority level identifier */
  id = null

  /* Hierarchy level identifier */
  master = null

  /* Name and base via hierarchy */
  name = null
  base = null

  /* Is active is status via hierarchy (inherited is passed through to next level) */
  status = RateStatus.Disabled

  /* Question: Does priority level have a parent? */
  isOverride = false

  /* Based on scope of search, is the priority level via inheritance, or layer applicable */
  isInherited = false

  /* Priority level status identifier */
  on = RateStatus.Disabled

  /* Describes where exactly this rate is being added by, citing the current scope */
  context = { label: 'ERROR', type: 'ERROR' }

  getContext(context) {
    let country =
      this.group && this.group.countryId
        ? context.countries?.find((x) => x.key === this.group.countryId)
            ?.value || 'Regional'
        : null

    let state = this.group ? this.group.state?.trim() : null

    let pre = country
      ? { label: country, type: 'country' }
      : { label: 'Global', type: 'global' }
    let preWithState = state
      ? { label: `${state}, ${country}`, type: 'state' }
      : pre

    if (context.scope) {
      return preWithState
    }

    let property = this.property
      ? context.properties?.find((x) => x.id === this.property.propertyId)
      : null

    let room = this.room
      ? property?.rooms?.find((x) => x.id === this.room.roomTypeId)?.item2
      : null

    let preWithProperty =
      this.property &&
      (!this.property.parentId ||
        [RateStatus.OverrideInherit, RateStatus.OverrideOptOut].includes(
          this.property.status
        ))
        ? { label: property?.name || 'Property', type: 'property' }
        : preWithState

    let preWithRoom =
      room &&
      ![RateStatus.Inherit, RateStatus.OptOut].includes(this.room.status)
        ? { label: room || 'Room', type: 'room' }
        : preWithProperty

    return !context.room ? preWithProperty : preWithRoom
  }

  static from(json, context) {
    try {
      let grouping = new RateRecordGrouping()
      grouping.group = json.group ? RateRecord.from(json.group) : null
      grouping.property = json.property ? RateRecord.from(json.property) : null
      grouping.room = json.room ? RateRecord.from(json.room) : null

      grouping.id =
        grouping.room?.id || grouping.property?.id || grouping.group.id
      grouping.name =
        grouping.room?.name || grouping.property?.name || grouping.group.name
      grouping.base =
        grouping.room?.base || grouping.property?.base || grouping.group.base
      grouping.on = !!grouping.room
        ? grouping.room.status
        : !!grouping.property
        ? grouping.property.status
        : grouping.group.status

      grouping.isOverride =
        ((grouping.room?.parentId ?? false) &&
          [RateStatus.OverrideInherit, RateStatus.OverrideOptOut].includes(
            grouping.room.status
          )) ||
        ((grouping.property?.parentId ?? false) &&
          [RateStatus.OverrideInherit, RateStatus.OverrideOptOut].includes(
            grouping.property.status
          )) ||
        false

      grouping.status =
        grouping.room != null &&
        ![RateStatus.Inherit, RateStatus.OverrideInherit].includes(
          grouping.room.status
        )
          ? grouping.room.status
          : grouping.property != null &&
            ![RateStatus.Inherit, RateStatus.OverrideInherit].includes(
              grouping.property.status
            )
          ? grouping.property.status
          : grouping.group.status

      grouping.isInherited = context.room
        ? !grouping.room ||
          [RateStatus.Inherit, RateStatus.OptOut].includes(grouping.room.status)
        : context.property
        ? !grouping.property ||
          [RateStatus.Inherit, RateStatus.OptOut].includes(
            grouping.property.status
          )
        : false

      grouping.context = grouping.getContext(context)

      grouping.master =
        grouping.group?.id ?? grouping.property?.id ?? grouping.room.id

      return grouping
    } catch {}
    return null
  }
}

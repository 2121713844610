/* Imports */
import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'
import VueRouter from 'vue-router'
import VueDatePicker from '@mathieustan/vue-datepicker'
import ToggleButton from 'vue-js-toggle-button'
import Multiselect from 'vue-multiselect'
import VueToast from 'vue-toast-notification'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BootstrapVue } from 'bootstrap-vue'
import router from './router'
import store from './store'
import './library'
import './validation'

import App from './app.vue'

const PERMISSIONS_PATH = '/permissions.json'
const CONFIG_PATH = '/config.json'
const CONFIG_HEADERS = {
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
}

Vue.config.productionTip = false
Vue.config.ignoredElements = [/^property-preview:/];

/* Styles */
import './styles/index.scss'

/* Components */
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('multiselect', Multiselect)

/* Modules */
Vue.use(BootstrapVue)
Vue.use(VueToast)
Vue.use(VueDatePicker)
Vue.use(ToggleButton)
Vue.use(VueScrollTo)
Vue.use(VueRouter)

/* Init */

Promise.all([
  fetch(CONFIG_PATH, {
    headers: CONFIG_HEADERS,
  }).then((response) => response.json()),
  fetch(PERMISSIONS_PATH, {
    headers: CONFIG_HEADERS,
  }).then((response) => response.json()),
]).then((response) => {
  window.$config = response[0]
  Vue.prototype.$config = response[0]
  window.$permissions = response[1]
  Vue.prototype.$permissions = response[1]
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('admin-dashboard')
})

import { mapState } from 'vuex'

export default {
  data() {
    return {
      /* SEARCH TAXONOMIES */
      properties: [],
      rooms: [],

      /* STATE PROPERTIES */
      loading: true,
    }
  },
  computed: {
    ...mapState(['account', 'countries', 'states']),
    calculatedStates() {
      const states = this.states.find((x) => x.key == this.country)
      return states ? states.value : []
    },
  },
  methods: {
    /* SEARCH VALUE MUTATORS */
    updateCountry(value) {
      this.property = ''
      this.room = ''
      this.state = ''
      this.country = value
      this.search()
    },
    updateState(value) {
      this.state = value
      this.search()
    },
    updateProperty(value) {
      this.country = ''
      this.state = ''
      this.room = ''
      this.rooms = value
        ? this.properties
            .find((x) => x.id === value)
            ?.rooms?.filter((x) => x.isActive) || []
        : []
      this.property = value
      this.search()
    },
    updateRoom(value) {
      this.room = value
      this.search()
    },
  },
}

<template>
  <quest-card
    title="Linked Properties"
    :collapsable="true"
    :collapsed="true"
    id="propertiesInfo"
  >
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <quest-single-select
            v-model="country"
            :options="countries"
            label="Filter Country"
            id="filterCountry"
            placeholder="None"
            help="Filter selection by country"
            :keepPlaceholder="true"
            @update="updateCountry"
          ></quest-single-select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <quest-single-select
            v-model="state"
            :options="calculatedStates"
            label="Filter State"
            id="filterState"
            :placeholder="country != '' ? 'All' : 'None'"
            help="Filter selection by state"
            :keepPlaceholder="true"
            @update="updateState"
          ></quest-single-select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>&nbsp;</label>
          <button
            class="btn btn-primary btn-block"
            @click="addFilteredProperties()"
            v-bind="{ disabled: country == '' }"
          >
            Select Filter
          </button>
          <small>Add all properties given the selected filters</small>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <button
            class="btn btn-secondary btn-block"
            @click="clearProperties()"
          >
            Clear All
          </button>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <button class="btn btn-primary btn-block" @click="allProperties()">
            Select All
          </button>
        </div>
      </div>
    </div>
    <quest-multi-select
      id="properties"
      label="Properties"
      v-model="deal.properties"
      :options="filters"
      :uid="'id'"
      :text="'name'"
      help="A selection of linked properties to the deal"
    ></quest-multi-select>
  </quest-card>
</template>

<script>
import { mapState } from 'vuex'
import Card from './deal-card'

export default {
  name: 'linked-properties',
  extends: Card,
  props: {
    filters: Array,
  },
  data() {
    return {
      country: '',
      state: '',
    }
  },
  computed: {
    ...mapState(['countries', 'states']),
    calculatedStates() {
      const states = this.states.find((x) => x.key == this.country)
      return states ? states.value : []
    },
  },
  methods: {
    updateCountry(v) {
      this.state = ''
      this.country = v
    },
    updateState(v) {
      this.state = v
    },
    addFilteredProperties() {
      const c = this.countries.find((x) => x.key == this.country)
      if (c) {
        const properties = this.filters
          .filter(
            (x) =>
              x.country == c.value &&
              (this.state == '' || this.state == x.state)
          )
          .map((x) => x.id)

        this.deal.properties = [
          ...new Set([...properties, ...this.deal.properties]),
        ]
        this.state = ''
        this.country = ''
      }
    },
    clearProperties() {
      this.deal.properties = []
    },
    allProperties() {
      this.deal.properties = this.filters.map((x) => x.id)
    },
  },
}
</script>

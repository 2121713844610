<template>
  <quest-card
    title="Announcement"
    :collapsable="true"
    :collapsed="true"
    id="announcementForm"
  >
    <div class="row">
      <div class="col-md-4">
        <quest-toggle
          id="enableAnnouncement"
          label="Status"
          v-model="enableAnnouncement"
          checked="Enabled"
          unchecked="Disabled"
          help="Enable a unique announcement at a property specific level"
        />
      </div>
    </div>
    <div class="row" v-if="enableAnnouncement">
      <div class="col-12">
        <quest-editor
          id="announcementBody"
          label="Description"
          v-model="announcement.body"
          help="The body of the announcement provided to users"
        ></quest-editor>
      </div>
    </div>
  </quest-card>
</template>

<script>
import Card from './property-card'

export default {
  extends: Card,
  props: {
    announcement: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      enableAnnouncement: !!this.announcement.body,
    }
  },
  methods: {
    getValue() {
      return {
        property: this.property.id,
        payload: this.enableAnnouncement ? this.announcement : null,
      }
    },
  },
  watch: {
    enableAnnouncement(value) {
      if (!value) {
        this.announcement.body = null
      }
    },
  },
}
</script>

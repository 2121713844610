export const COLOURS = [
  { label: 'Gold', name: 'gold', value: '#9a8c5e' },
  { label: 'Grey', name: 'grey', value: '#51534a' },
  { label: 'Light grey', name: 'light-grey', value: '#a8a99e' },
  { label: 'Beige', name: 'beige', value: '#eeebe1' },
  { label: 'Light red', name: 'light-red', value: '#BF6440' },
  { label: 'Dark red', name: 'dark-red', value: '#6C242A' },
  { label: 'Light yellow', name: 'light-yellow', value: '#E6C09C' },
  { label: 'Dark yellow', name: 'dark-yellow', value: '#BB8C1E' },
  { label: 'Light blue', name: 'light-blue', value: '#BDD2E7' },
  { label: 'Dark blue', name: 'dark-blue', value: '#373E51' },
  { label: 'Light green', name: 'light-green', value: '#94BB86' },
  { label: 'Dark green', name: 'dark-green', value: '#4C5D57' },
  { label: 'Light purple', name: 'light-purple', value: '#E4BEBC' },
  { label: 'Dark purple', name: 'dark-purple', value: '#6E415A' },
]

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('quest-main',{attrs:{"title":"Themes","copy":"Various site themes can be managed here","loading":_vm.loading},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('router-link',{staticClass:"d-sm-inline-block btn btn-sm btn-primary shadow-sm",attrs:{"to":{ name: 'themes-add' }}},[_c('font-awesome-icon',{staticClass:"text-white-50",attrs:{"icon":"plus","size":"sm"}}),_vm._v(" Add")],1)]},proxy:true},{key:"body",fn:function(){return [_c('quest-card',{attrs:{"title":"Theme collection"}},[_c('quest-table',{attrs:{"id":"theme-table","columns":_vm.columns}},[(_vm.themes.items.length > 0)?_vm._l((_vm.themes.items),function(theme){return _c('tr',{key:theme.id,staticClass:"small align-middle"},[_c('td',[_c('router-link',{attrs:{"to":{
                  name: 'themes-edit',
                  params: { id: theme.id },
                }}},[_vm._v(_vm._s(theme.name || '(untitled)'))])],1),_c('td',[_vm._v(_vm._s(_vm.lastModified(theme.modifiedUTC)))]),_c('td',[_c('b-dropdown',{attrs:{"no-caret":"","variant":"link"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('font-awesome-icon',{staticClass:"text-gray-400",attrs:{"icon":"ellipsis-v","size":"sm","fixed-width":""}})]},proxy:true}],null,true)},[_c('b-dropdown-header',[_vm._v("Actions")]),_c('b-dropdown-item',{attrs:{"to":{
                    name: 'themes-edit',
                    params: { id: theme.id },
                  }}},[_vm._v("View")]),_c('b-dropdown-divider'),_c('b-dropdown-item-button',{attrs:{"text":"danger","variant":"danger"},on:{"click":function($event){return _vm.promptDelete(theme)}}},[_vm._v("Delete")])],1)],1)])}):_vm._e(),(_vm.themes.items.length === 0)?[_c('tr',{staticClass:"align-middle"},[_c('td',{attrs:{"colspan":_vm.columns.length}},[_c('div',{staticClass:"small text-center py-5"},[_vm._v(" There are no themes in the system ")])])])]:_vm._e()],2),(_vm.themes.total > _vm.pageSize)?_c('quest-pager',{attrs:{"currentPage":_vm.page,"itemCount":_vm.themes.total,"pageSize":_vm.pageSize},on:{"changePage":_vm.changePage}}):_vm._e()],1),_c('b-modal',{attrs:{"centered":"","title":"Delete"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('button',{staticClass:"btn btn-secondary btn-sm",attrs:{"type":"button"},on:{"click":_vm.cancelPromptDelete}},[_vm._v(" Cancel")]),_c('button',{staticClass:"btn btn-danger btn-sm",attrs:{"type":"button"},on:{"click":_vm.confirmPromptDelete}},[_vm._v(" Delete ")])]},proxy:true}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('p',{staticClass:"my-4"},[_vm._v(" Are you sure you wish to delete theme '"+_vm._s(_vm.toDelete ? _vm.toDelete.name || 'untitled' : 'untitled')+"'? ")])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
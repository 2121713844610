<template>
  <quest-card
    title="Additional redirects"
    :collapsable="true"
    :collapsed="true"
    id="redirectsForm"
  >
    <div class="row">
      <div class="col-md-10">
        <div class="form-group">
          <small
            >Property page URLs work off of a three directory path. This means a
            full slug has a region or territory, followed by the city or suburb
            and finally a name of the location. An example of this would be
            'vic/abbotsford/quest-abbotsford'</small
          >
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group text-right">
          <a
            @click="addRedirect()"
            class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
            ><font-awesome-icon
              icon="plus"
              size="sm"
              class="text-white-50"
            ></font-awesome-icon>
            Add
          </a>
        </div>
      </div>
    </div>
    <template v-if="redirects.length > 0">
      <div class="row" v-for="(redirect, index) in redirects" :key="index">
        <div class="col-4">
          <quest-input
            v-model="redirect.region"
            placeholder="region"
          ></quest-input>
        </div>
        <div class="col-4">
          <quest-input v-model="redirect.city" placeholder="city"></quest-input>
        </div>
        <div class="col-4">
          <quest-input v-model="redirect.name" placeholder="name"></quest-input>
        </div>
      </div>
    </template>
    <template v-else>
      <small class="text-center d-block">No redirects configured</small>
    </template>
  </quest-card>
</template>

<script>
import questTextArea from '../form/quest-text-area.vue'
import Card from './property-card'

export default {
  components: { questTextArea },
  extends: Card,
  data() {
    return {
      redirects: [],
    }
  },
  mounted() {
    this.redirects = this.property.redirects
      .map((slug) => {
        let parts = slug.split('/')
        if (parts && parts.length === 3) {
          return { region: parts[0], city: parts[1], name: parts[2] }
        }
      })
      .filter((map) => !!map)
  },
  methods: {
    getValue() {
      return this.redirects
        .filter((map) => map.region && map.city && map.name)
        .map((map) => `${map.region}/${map.city}/${map.name}`)
    },
    addRedirect() {
      this.redirects.push({ region: '', city: '', name: '' })
    },
  },
}
</script>

import AdminNexusHttpClient from './admin-nexus-http-client'

export default class ModuleConfigurationService {
  constructor() {
    this.httpClient = new AdminNexusHttpClient('/admin/modules')
  }

  async set(payload) {
    return await this.httpClient.post('', payload)
  }
}

import { mapState } from 'vuex'
import { success, error } from '@/helpers'
import { Rate, RateOverride, RateStatus } from '@/models'
import { REQUIRED, MAX } from '@/validation-types'
import { NexusRatesService } from '@/services'

import {
  QuestMain,
  QuestTable,
  QuestPager,
  QuestCard,
  QuestModal,
  QuestPreloader,
} from '../structure'

import DateRangeList from './date-range-list.vue'
import RateEditorSlide from './rate-editor-slide.vue'
import RateExtrasList from './rate-extras-list.vue'

import {
  QuestInput,
  QuestSingleSelect,
  QuestToggle,
  QuestTextArea,
  QuestMultiSelect,
  QuestDatePicker,
  QuestImage,
  QuestImagePicker,
  Row,
} from '../form'

export default {
  data() {
    return {
      /* IMPORTS */
      REQUIRED,
      MAX,
      RateStatus,

      /* STATUS */
      saving: false,
      readonly: false,

      /* STATIC TAXONOMIES */
      depositTypes: ['None', 'Full', 'Fixed', 'Percentage'],
      leadTimeTypes: [
        { key: 'None', value: 'None' },
        { key: 'Advance', value: 'Advance' },
        { key: 'LastMinute', value: 'Last Minute' },
      ],
      varianceTypes: ['None', 'Percentage', 'Flat'],
      discountDealTypes: [
        { key: 'None', value: 'None' },
        { key: 'StayPay', value: 'Stay X Pay Y' },
      ],

      /* DYNAMIC TAXONOMIES */
      context: [],
      properties: [],
      optInOnlyProperties: [],

      /* SCOPE */
      parent: null,
      country: null,
      property: null,
      room: null,

      /* PROPERTIES */
      rate: new Rate(),
      overrides: new RateOverride(),
    }
  },
  components: {
    QuestMain,
    QuestTable,
    QuestPager,
    QuestCard,
    QuestModal,
    QuestSingleSelect,
    QuestInput,
    QuestToggle,
    QuestTextArea,
    QuestMultiSelect,
    QuestDatePicker,
    DateRangeList,
    QuestImage,
    QuestImagePicker,
    QuestPreloader,
    RateExtrasList,
    Row,
    slide: RateEditorSlide,
  },
  computed: {
    ...mapState(['accounts', 'account', 'stayNights', 'countries']),
    discountAmountLabel() {
      return `Discount amount (${
        this.rate.variance.variance == 'Percentage' ? '%' : '$'
      })`
    },
    discountAmountTip() {
      return this.rate.variance.variance == 'Percentage'
        ? 'A percentage discount applied to the base rate price. E.g. $200 - 10% (discount) will result in a base quote of $160 before any other calculations'
        : 'A flat discount applied to the base rate price. E.g. $200 - $10 (discount) will result in a base quote of $190 before any other calculations'
    },
    depositAmountLabel() {
      return `Deposit amount (${
        this.rate.depositType == 'Percentage' ? '%' : '$'
      })`
    },
    depositAmountTip() {
      return `The ${
        this.rate.depositType == 'Percentage' ? 'percentage' : 'flat'
      } amount displayed to the user as the initial deposit`
    },
    leadTimeReleaseTip() {
      return `The ${
        this.rate.leadTimeType == 'Advance' ? 'minimum' : 'maximum'
      } number of nights from sell date the booking can take place`
    },
    accountLabel() {
      return `<b>${this.accounts?.find((x) => x.id == this.account)?.label}</b>`
    },
    countryLabel() {
      return this.country ? `<b>${this.country.value}</b>` : ''
    },
    propertyLabel() {
      return this.property ? `<b>${this.property.name}</b>` : ''
    },
    roomLabel() {
      return this.room ? `<b>${this.room.item2}</b>` : ''
    },
    countryAndStateLabel() {
      if (this.state?.trim()) {
        return `<b>${this.state}</b> in ${this.countryLabel}`
      } else {
        return `<b>any state</b> in ${this.countryLabel}`
      }
    },
    workspaceTitle() {
      if (this.readonly) return 'Readonly view'
      return (
        (!this.property
          ? !this.country
            ? 'Global'
            : 'Regional'
          : !this.room
          ? 'Property'
          : 'Room') +
        ' rate workspace' +
        (this.parent ? ' (Override)' : '')
      )
    },
    workspaceSummary() {
      if (this.readonly)
        return 'This rate is outside the editing scope. This is a read only version for display purposes.'
      if (!this.property) {
        if (!this.rate.id) {
          if (!this.country) {
            /* New rate - Global */
            return `This is a new group rate with no regional restrictions. All
                properties attached to account
                ${this.accountLabel} will be
                automatically opted in to this rate as soon as it is activated.`
          } else {
            /* New rate - Regional */
            return `This is a new group rate restricted to ${this.countryAndStateLabel}.
                All properties attached to account ${this.accountLabel} matching the
                regional criteria will be automatically opted in to this rate as
                soon as it is activated.`
          }
        } else {
          if (!this.country) {
            /* Update rate - Global */
            return `This will update a group rate with no regional restrictions. All
                properties attached to account ${this.accountLabel} are automatically
                opted in to this rate while it is activated. Note that any
                changes will be reflected across all properties that have not
                overridden or opted out of this rate. Changes to this rate will
                only affect future bookings.`
          } else {
            /* Update rate - Regional */
            return `This will update a group rate restricted to ${this.countryAndStateLabel}.
                All properties attached to account ${this.accountLabel} matching the
                regional criteria will be automatically opted in to this rate
                while it is activated. Note that any changes will be reflected
                across all properties that have not overrideen or opted out of
                this rate. Changes to this rate will only affect future
                bookings.`
          }
        }
      } else {
        if (!this.parent) {
          if (!this.rate.id) {
            /* New rate - Property | Self contained */
            return `This new rate will be applied to ${this.propertyLabel}. All enabled room
                types will be automatically opted in to this rate as soon as it
                is activated.`
          } else {
            /* Update rate - Property | Self contained */
            return `This will update a rate applied to ${this.propertyLabel}. All enabled
                room types are automatically opted in to this rate as soon as it
                is while is is activated. Note that any changes will be
                reflected across all rooms that have not overridden or opted out
                of this rate. Changes to this rate will only affect future
                bookings.`
          }
        } else {
          if (!this.room) {
            /* Override rate - Property */
            return `This rate will override defaults from <b>${this.parent.name}</b>. This override
                rate will be applied to ${this.propertyLabel}. All enabled room types
                will be automatically opted in to this override as soon as it is
                activated unless opted out. Changes to this rate will only
                affect future bookings. You must enable any field overrides by
                selecting the override toggle beside it. You may clear overrides
                by disabling the toggle at any time.`
          } else {
            /* Override rate - Room */
            return `This rate will override defaults from <b>${this.parent.name}</b> in ${this.propertyLabel}.
                This override rate will be applied to the room type ${this.roomLabel}.
                Changes to this rate will only affect future bookings. You must
                enable any field overrides by selecting the override toggle
                beside it. You may clear overrides by disabling the toggle at
                any time.`
          }
        }
      }
    },
  },
  methods: {
    /* SAVE (EVENT ACTION) */
    async save() {
      if (this.readonly) return
      if (!this.$refs.vobserver) return
      let validated = await this.$refs.vobserver.validate()
      if (!validated) {
        error('Please ensure all errors are resolved')
        return
      }
      if (this.rate.groupCode) {
        await this.checkCode().then((data) => this.$emit('check', data))
        return false
      }
      this.commit()
      return false
    },
    commit() {
      this.$emit('saving')
      if (this.rate.id) {
        this.update()
      } else {
        this.create()
      }
    },
    /* Event driven property mutators */
    updateSlug(v) {
      this.rate.slug = slugify(v)
    },
    updateSplash(selected) {
      this.rate.image = selected
    },
    updateVarianceType(value) {
      this.rate.variance.variance = value
      this.rate.variance.amount = 0
    },
    updateBaseRate(value) {
      this.rate.rateTableId = value
      if (!this.rate.rateTableId) {
        this.rate.additionalRateTables = []
      }
    },
    updateMemberRate(value) {
      this.rate.loggedInRateTableId = value
      if (!this.rate.loggedInRateTableId) {
        this.rate.loggedInAdditionalRateTables = []
      }
    },
    updateDeposit(value) {
      this.rate.depositAmount = null
      this.rate.depositType = value
    },
    updateLeadTimeType(value) {
      this.rate.leadTimeRelease = null
      this.rate.leadTimeType = value
    },
    updateDiscountDealType(value) {
      this.rate.stayPayPay = null
      this.rate.stayPayStay = null
      this.rate.discountDealType = value
    },

    /* Set defaults */
    reset() {
      this.readonly = false

      this.rate = new Rate()
      this.overrides = new RateOverride()

      this.parent = null
      this.property = null
      this.room = null
      this.country = null
      this.state = null
    },
    /* Manage modal */
    hidden() {
      this.reset()
    },
    close() {
      this.$refs['modal'].hide()
    },
    /* Set up assignments */
    baseSetup() {
      this.rate.status = RateStatus.Disabled
      this.rate.loginOnly = false
      this.rate.isDeal = false
    },
    workspaceSetup() {
      let country = this.rate.countryId || this.parent?.countryId
      if (country) {
        this.country = this.countries?.find((x) => x.key == country)
      }

      this.state = this.rate.state

      let property = this.rate.propertyId || this.parent?.propertyId
      if (property) {
        this.property = this.properties?.find((x) => x.id == property)

        let room = this.rate.roomId || this.parent?.roomId
        if (room) {
          this.room = this.property?.rooms.find((x) => x.id === room)
        }
      }
    },
    mapRateFromScope() {
      this.rate.externalAccountId = this.account
      this.rate.countryId = this.country?.key || null
      this.rate.state = this.state
      this.rate.propertyId = this.property?.id || null
      this.rate.roomTypeId = this.room?.id || null
    },
    newScopeSetup(scope, country, state, property, room) {
      if (scope == undefined || scope == null) {
        throw 'Scope is not defined correctly'
      }

      if (scope) {
        if (country) {
          this.country = this.countries?.find((x) => x.key == country)
          if (!this.country) {
            throw 'Country is not defined correctly'
          }
        }
        this.state = state
      } else {
        if (property == undefined || property == null) {
          throw 'Scope is not defined correctly'
        }

        this.property = this.properties?.find((x) => x.id == property)
        if (!this.property) {
          throw 'Property is not defined correctly'
        }

        if (room) {
          this.room = this.property.rooms.find((x) => x.id === room)
          if (!this.room) {
            throw 'Room is not defined correctly'
          }
        }
      }
    },
    /* SAVE (NEW) */
    async create() {
      await new NexusRatesService()
        .add(this.extractRate())
        .then((data) => {
          if (typeof data === 'object' && !!data.value) {
            this.$emit('saved')
            this.close()
            success(`Created rate #`, this.rate.name || this.parent?.name)
          } else {
            error(
              `Failed to create rate #`,
              this.rate.name || this.parent?.name
            )
          }
        })
        .then((_) => {
          this.$emit('failed')
        })
    },
    /* SAVE (UPDATE) */
    async update() {
      await new NexusRatesService()
        .update(this.extractRate())
        .then((data) => {
          if (data === true) {
            this.$emit('saved')
            this.close()
            success(`Updated rate #`, this.rate.name || this.parent?.name)
          } else {
            error(
              `Failed to update rate #`,
              this.rate.name || this.parent?.name
            )
          }
        })
        .then((_) => {
          this.$emit('failed')
        })
    },
    /* CHECK GROUP CODE */
    async checkCode() {
      return new NexusRatesService().check({
        id: this.rate.id,
        parentId: this.parent?.id,
        accountId: this.account,
        code: this.rate.groupCode,
        propertyId: this.rate.propertyId,
        roomTypeId: this.rate.roomTypeId,
      })
    },
    /* GET RATE READY FOR SAVE */
    extractRate() {
      if (!this.parent) {
        return this.rate
      } else {
        if (!this.overrides.variance) {
          this.rate.variance = { variance: null }
        }

        if (!this.overrides.rateTables) {
          this.rate.rateTableId = null
          this.rate.additionalRateTables = []
          this.rate.loggedInRateTableId = null
          this.rate.loggedInDiscount = null
          this.rate.loggedInAdditionalRateTables = []
        }

        if (!this.overrides.discountDealType) {
          this.rate.discountDealType = null
          this.rate.stayPayPay = null
          this.rate.stayPayStay = null
        }

        if (!this.overrides.depositType) {
          this.rate.depositType = null
          this.rate.depositAmount = null
        }

        if (!this.overrides.leadTimeType) {
          this.rate.leadTimeType = null
          this.rate.leadTimeRelease = null
        }

        if (!this.overrides.metaTitle) {
          this.rate.metaTitle = null
        }

        if (!this.overrides.metaDescription) {
          this.rate.metaDescription = null
        }

        if (!this.overrides.description) {
          this.rate.description = null
        }

        if (!this.overrides.disclaimer) {
          this.rate.disclaimer = null
        }

        if (!this.overrides.image) {
          this.rate.image = null
        }

        if (!this.overrides.stayXPayY) {
          this.rate.stayPayPay = null
          this.rate.stayPayStay = null
        }

        if (!this.overrides.groupCode) {
          this.rate.groupCode = null
        }

        if (!this.overrides.name) {
          this.rate.name = null
        }

        if (!this.overrides.occupants) {
          this.rate.minOccupants = null
          this.rate.maxOccupants = null
        }

        if (!this.overrides.stay) {
          this.rate.minStay = null
          this.rate.maxStay = null
        }

        if (!this.overrides.slug) {
          this.rate.slug = null
        }

        if (!this.overrides.stayNightsToBlock) {
          this.rate.stayNightsToBlock = null
        }

        if (!this.overrides.sellDates) {
          this.rate.sellDates = null
        }

        if (!this.overrides.excludeSellDates) {
          this.rate.excludeSellDates = null
        }

        if (!this.overrides.stayDates) {
          this.rate.stayDates = null
        }

        if (!this.overrides.excludeStayDates) {
          this.rate.excludeStayDates = null
        }

        return this.rate
      }
    },
    extractOverrides() {
      if (!!this.rate.variance.variance) this.overrides.variance = true

      if (!!this.rate.rateTableId) this.overrides.rateTables = true

      if (!!this.rate.discountDealType) this.overrides.discountDealType = true

      if (!!this.rate.depositType) this.overrides.depositType = true

      if (!!this.rate.leadTimeType) this.overrides.leadTimeType = true

      if (!!this.rate.metaTitle) this.overrides.metaTitle = true

      if (!!this.rate.metaDescription) this.overrides.metaDescription = true

      if (!!this.rate.description) this.overrides.description = true

      if (!!this.rate.disclaimer) this.overrides.disclaimer = true

      if (!!this.rate.image) this.overrides.image = true

      if (!!this.rate.stayPayPay || !!this.rate.stayPayStay)
        this.overrides.stayXPayY = true

      if (!!this.rate.groupCode) this.overrides.groupCode = true

      if (!!this.rate.name) this.overrides.name = true

      if (!!this.rate.minOccupants || !!this.rate.maxOccupants)
        this.overrides.occupants = true

      if (!!this.rate.minStay || !!this.rate.maxStay) this.overrides.stay = true

      if (!!this.rate.slug) this.overrides.slug = true

      if (!!this.rate.stayNightsToBlock) this.overrides.stayNightsToBlock = true

      if (!!this.rate.sellDates) this.overrides.sellDates = true

      if (!!this.rate.excludeSellDates) this.overrides.excludeSellDates = true

      if (!!this.rate.stayDates) this.overrides.stayDates = true

      if (!!this.rate.excludeStayDates) this.overrides.excludeStayDates = true
    },
  },
}

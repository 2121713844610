<template>
  <div>
    <quest-card panel v-if="!p_any">
      <h4 class="text-center h4 text-gray-400 mt-2 mb-2">
        You do not have access to modify any fields in this tab
      </h4>
    </quest-card>
    <template v-if="p_any">
      <div class="row" v-if="!configuration.enableConferences">
        <quest-stat-card
          label="Disabled"
          value="The meeting and conferences module is currently disabled on this property. You may edit the details in this section but they will not be available to end users."
          theme="dark"
          card="col-12"
        >
        </quest-stat-card>
      </div>
      <quest-card
        panel
        v-if="[p_meetingsConferencesCopy, p_imagery].some((x) => x)"
      >
        <div class="row" v-if="p_meetingsConferencesCopy">
          <div class="col-12">
            <quest-editor
              label="Meeting and conference copy"
              v-model="property.meetingsConferencesCopy"
            ></quest-editor>
          </div>
        </div>

        <div class="row" v-if="p_imagery">
          <div class="col-12">
            <div class="form-group rounded border p-2">
              <div class="d-flex justify-content-between">
                <label>Carousel</label>
                <button
                  @click="$refs.gallery.open()"
                  class="btn btn-primary btn-sm"
                >
                  Update Carousel
                </button>
              </div>
              <template v-if="property.conferenceGallery.length > 0">
                <div class="d-flex h-100 flex-wrap mt-2">
                  <div
                    class="col-12 col-md-4 col-lg-3 mb-3 text-center"
                    v-for="item in property.conferenceGallery"
                    :key="item.mediaId"
                  >
                    <quest-image :image="item.mediaId"> </quest-image>

                    <ValidationProvider
                      name="Conference image tag"
                      :rules="{ [MAX]: { length: 18 } }"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="text"
                        class="form-control"
                        :class="classes"
                        placeholder="Image Tag"
                        v-model="item.label"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                </div>
              </template>
              <template v-else>
                <small>No images added to carousel</small>
              </template>
            </div>
          </div>
        </div>

        <quest-image-picker
          title="Select carousel images"
          ref="gallery"
          :propertyId="
            property.siteminderPropertyId !=
            '00000000-0000-0000-0000-000000000000'
              ? property.siteminderPropertyId
              : property.id
          "
          :albumName="property.name"
          :current="property.conferenceGallery.map((s) => s.mediaId)"
          folder="Meeting and Conferences"
          @update="updateGallery"
          multiple
          manage
        ></quest-image-picker>
      </quest-card>
    </template>
    <faqs-card v-show="p_faqs" :property="property" ref="faqs"></faqs-card>
    <benefits-card
      v-show="p_benefits"
      :property="property"
      ref="benefits"
    ></benefits-card>
  </div>
</template>

<script>
import View from './view'
import { BenefitsCard, FAQsCard } from '../property-cards'
import { QuestStatCard } from '../structure'
import { Properties } from '@/router/permission-types'
import { hasFieldAccess } from '@/helpers'

export default {
  extends: View,
  name: 'property-conference-view',
  components: {
    BenefitsCard,
    'faqs-card': FAQsCard,
    QuestStatCard,
  },
  mounted() {},
  computed: {
    p_any() {
      return Object.entries(this._computedWatchers)
        .filter((x) => x[0] != 'p_any' && x[0].startsWith('p_'))
        .some((x) => x[1].getter())
    },
    p_meetingsConferencesCopy() {
      return hasFieldAccess(Properties, 'meetingsConferencesCopy')
    },
    p_faqs() {
      return hasFieldAccess(Properties, 'faqs')
    },
    p_benefits() {
      return hasFieldAccess(Properties, 'benefits')
    },
    p_imagery() {
      return hasFieldAccess(Properties, 'meetingsConferencesImagery')
    },
  },
  methods: {
    updateGallery(selected) {
      this.property.conferenceGallery = selected.map((x) => ({
        mediaId: x,
        label: ((x) => {
          const g = this.property.conferenceGallery.find((p) => p.mediaId === x)
          return g ? g.label : ''
        })(x),
      }))
    },
    getFAQs() {
      return this.$refs.faqs.getValue()
    },
    getBenefits() {
      return this.$refs.benefits.getValue()
    },
  },
}
</script>

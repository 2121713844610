import AdminNexusHttpClient from './admin-nexus-http-client'
import PropertyOverviewItem from '../models/property-overview-item'
import PagedResult from '../models/paged-result'

export default class PropertyManagementService {
  constructor() {
    this.httpClient = new AdminNexusHttpClient('/admin/properties')
  }

  async list(ids) {
    return await this.httpClient.get('list', { ids })
  }

  async getProperty(id) {
    return await this.httpClient.get(`${id}`)
  }

  async getConfiguration(id) {
    return await this.httpClient.get(`${id}/modules`)
  }

  async getCSR(id) {
    return await this.httpClient.get(`${id}/csr`)
  }

  async getAnnouncement(id) {
    return await this.httpClient.get(`${id}/announcement`)
  }

  async getPropertyRateTables(id) {
    return await this.httpClient.get(`${id}/rate-tables`)
  }

  async setPropertyRateTables(id, linked) {
    return await this.httpClient.post(`${id}/rate-tables`, { id, linked })
  }

  async channelCodes(id) {
    return await this.httpClient.get(`${id}/channel-codes`)
  }

  async new() {
    return await this.httpClient.get(`new`)
  }

  async prepare(id) {
    return await this.httpClient.get(`new/${id}`)
  }

  async filters() {
    return await this.httpClient.get(`filters`)
  }

  async extract(id) {
    return await this.httpClient.get(`by-account/${id}`)
  }

  async clone(propertyId, accountId, clientId, externalId, roomTypeCodes) {
    return await this.httpClient.post('clone', {
      propertyId,
      accountId,
      clientId,
      externalId,
      roomTypeCodes,
    })
  }

  async activate(id) {
    return await this.httpClient.get(`activate/${id}`)
  }

  async getProperties(
    account,
    page = 1,
    pageSize = 10,
    term = '',
    country = ''
  ) {
    const data = {
      account,
      page,
      pageSize,
      term,
      country: country !== '' ? country : null,
    }
    const response = await this.httpClient.get('', data)
    let result = new PagedResult()
    if (response && response.items) {
      result.total = response.total
      result.items = response.items.map((r) => new PropertyOverviewItem(r))
    }
    return result
  }

  async update(payload) {
    return await this.httpClient.post(``, payload)
  }

  async delete(id) {
    return await this.httpClient.delete(`${id}`)
  }

  async getRegional(account, country, state) {
    return await this.httpClient.get('regional', { account, country, state })
  }
}

<template>
  <quest-main title="Quest Nexus Dashboard" center :loading="loading">
    <template v-slot:body>
      <div class="row" v-if="isAdmin">
        <template v-if="service === 'TBB'">
          <quest-stat-card
            label="Live Properties"
            :value="stats.enabledProperties"
            :icon="['fas', 'globe-asia']"
            card="col-xl-4 col-md-4"
          ></quest-stat-card>
          <quest-stat-card
            label="Live Rates"
            :value="stats.liveRates"
            :icon="['fas', 'stars']"
            card="col-xl-4 col-md-4"
          ></quest-stat-card>

          <quest-stat-card
            label="Deals Enabled"
            :value="stats.enabledDeals"
            :icon="['fas', 'badge-dollar']"
            card="col-xl-4 col-md-4"
          ></quest-stat-card>
          <quest-stat-card
            label="Group Promotions"
            :value="stats.groupPromotions"
            :icon="['fas', 'fire']"
            card="col-xl-4 col-md-4"
          ></quest-stat-card>

          <quest-stat-card
            label="Minutes per full sync cycle"
            :value="stats.minsPerFullSyncCycle"
            :icon="['far', 'clock']"
            card="col-xl-4 col-md-4"
          ></quest-stat-card>

          <quest-stat-card
            label="Unique preload requests"
            :value="stats.preloadedCriteria"
            :icon="['fad', 'sync']"
            card="col-xl-4 col-md-4"
          ></quest-stat-card>
        </template>
        <template v-if="service === 'RMS'">
          <quest-stat-card
            label="Live Properties"
            :value="stats.enabledProperties"
            :icon="['fas', 'globe-asia']"
            card="col-xl-4 col-md-4"
          ></quest-stat-card>
          <quest-stat-card
            label="Total Nexus Reservations"
            :value="stats.totalReservations"
            :icon="['fas', 'badge-dollar']"
            card="col-xl-4 col-md-4"
          ></quest-stat-card>
        </template>
      </div>
      <div class="row justify-content-center" v-if="propertyList.length">
        <div class="col-xl-10 col-lg-12 col-md-9">
          <div class="card o-hidden border-0 shadow-lg my-5">
            <div class="card-body p-0">
              <div class="row">
                <div class="col">
                  <div class="p-5">
                    <h4 class="text-center h4 text-gray-900 mb-4">
                      You have access to the properties below
                    </h4>
                    <quest-single-select
                      v-model="propertyId"
                      :options="propertyList"
                      id="newProperty"
                      placeholder="Select a property"
                      @update="updatePropertyId"
                      okey="id"
                      otext="name"
                      large
                    ></quest-single-select>
                    <div class="form-group">
                      <button
                        @click="manageProperty"
                        v-bind="{ disabled: !propertyId }"
                        class="btn btn-primary btn-lg btn-block"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'edit']"
                          size="lg"
                        ></font-awesome-icon>
                        Manage property
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </quest-main>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { QuestMain, QuestStatCard } from './structure'
import { ConnectStatsService, PropertyManagementService } from '../services'
import { QuestSingleSelect } from './form'

export default {
  name: 'dashboard',
  components: {
    QuestMain,
    QuestStatCard,
    QuestSingleSelect,
  },
  data() {
    return {
      stats: null,
      loading: true,
      propertyList: [],
      propertyId: '',
    }
  },
  computed: {
    ...mapState(['account', 'accounts']),
    ...mapGetters(['isAdmin', 'properties']),
    service() {
      return this.accounts.find((x) => x.id === this.account)?.service
    },
  },
  methods: {
    updatePropertyId(value) {
      this.propertyId = value
    },
    manageProperty() {
      this.$router.push({
        name: 'properties-edit',
        params: { id: this.propertyId },
      })
    },
  },
  mounted() {
    new ConnectStatsService().get(this.account).then((data) => {
      this.stats = data
      this.loading = false
    })

    if (this.properties.length > 0) {
      new PropertyManagementService()
        .list(this.properties.join(','))
        .then((data) => {
          this.propertyList = data
        })
    }
  },
}
</script>

import AdminNexusHttpClient from './admin-nexus-http-client'

export default class PreviewService {
  constructor() {
    this.httpClient = new AdminNexusHttpClient('/preview')
  }

  async add(payload) {
    return await this.httpClient.put(``, payload)
  }
}

<template>
  <quest-card
    title="Location"
    id="locationForm"
    :collapsable="true"
    :collapsed="true"
  >
    <quest-input
      id="street"
      label="Street"
      :placeholder="property.street.raw"
      v-model="property.street.override"
      help="Input overrides the TBB street address"
    ></quest-input>

    <div class="row">
      <div class="col-md-6">
        <quest-input
          id="suburb"
          label="Suburb"
          :placeholder="property.suburb.raw"
          v-model="property.suburb.override"
          help="Input overrides the TBB suburb for property"
        ></quest-input>
      </div>
      <div class="col-md-6">
        <quest-input
          id="state"
          label="State"
          :placeholder="property.state.raw"
          v-model="property.state.override"
          help="Input overrides the TBB state for property"
        ></quest-input>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <quest-input
          id="postcode"
          label="Postcode"
          :placeholder="property.postcode.raw"
          v-model="property.postcode.override"
          help="Input overrides the TBB postcode for property"
        ></quest-input>
      </div>
      <div class="col-md-6">
        <quest-single-select
          v-model="property.country.id"
          :options="countries"
          label="Country"
          id="country"
          placeholder="No country selected"
          help="Set the residing country for the property"
          @update="updateCountry"
        ></quest-single-select>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-md-6">
        <quest-input
          id="latitude"
          label="Latitude"
          v-model="property.latitude"
          help="Manually enter the latitide or automate with the button below"
        ></quest-input>
      </div>
      <div class="col-md-6">
        <quest-input
          id="longitude"
          label="Longitude"
          v-model="property.longitude"
          help="Manually enter the longitude or automate with the button below"
        ></quest-input>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <quest-input
          :disabled="true"
          id="placeId"
          label="Place ID"
          v-model="property.placeId"
        ></quest-input>
      </div>
    </div>

    <div class="form-group">
      <button class="btn btn-primary btn-block" @click="syncCoordinates()">
        Resync with Address
      </button>
      <small class="form-text text-muted"
        >Update the coordinates by making an address lookup with the currently
        entered address</small
      >
    </div>
  </quest-card>
</template>

<script>
import { mapState } from 'vuex'
import Card from './property-card'
import { GeoService } from '../../services'

export default {
  extends: Card,
  data() {
    return {
      coordinateSyncLoading: false,
    }
  },
  computed: {
    ...mapState(['countries']),
  },
  methods: {
    syncCoordinates() {
      this.coordinateSyncLoading = true

      let country = ''
      if (this.property.country.id) {
        const c = this.countries.find((e) => e.key == this.property.country.id)
        country = c ? c.value : country
      }

      let address = this.addToAddress('', this.property.street)
      address = this.addToAddress(address, this.property.suburb)
      address = this.addToAddress(address, this.property.state)
      address = this.addToAddress(address, this.property.postcode)
      address = `${address.trim()} ${country}`

      new GeoService()
        .coordinate(address.trim())
        .then((data) => {
          this.property.latitude = data.lat
          this.property.longitude = data.lng
          this.property.placeId = data.placeId
        })
        .finally((_) => {
          this.coordinateSyncLoading = false
        })
    },
    updateCountry(update) {
      this.property.country.id = update
    },
    addToAddress(address, overridable) {
      return `${address.trim()} ${
        overridable.override ? overridable.override : overridable.raw
      }`
    },
  },
}
</script>

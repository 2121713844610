<template>
  <ValidationObserver ref="vobserver" v-slot="{ errors }">
    <quest-validation-summary :errors="errors"></quest-validation-summary>
    <quest-main
      id="deal-form"
      :title="deal && deal.id ? deal.name || '(untitled)' : '(Error)'"
      :loading="loading"
      :saving="saving"
    >
      <template v-slot:head>
        <button
          @click="save()"
          class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
          v-bind="{ disabled: saving }"
        >
          <font-awesome-icon
            icon="save"
            size="sm"
            fixed-width
            class="text-white-50"
            :class="{ 'animated--heart-beat': saving }"
          ></font-awesome-icon>
          {{ saving ? 'Saving...' : 'Save' }}
        </button>
      </template>
      <template v-slot:body>
        <template v-if="deal.id">
          <div class="row">
            <quest-stat-card
              label="Created"
              :value="dateCreated"
              :icon="['fas', 'calendar-plus']"
              card="col-xl-4 col-md-4"
            ></quest-stat-card>

            <quest-stat-card
              label="Last Modified"
              :value="dateModified"
              :icon="['fas', 'calendar-edit']"
              card="col-xl-4 col-md-4"
            ></quest-stat-card>

            <quest-stat-card
              label="Deal Type"
              :value="deal.isGroup ? 'Group' : 'Local'"
              :icon="['fas', 'map-marker-alt']"
              card="col-xl-4 col-md-4"
            ></quest-stat-card>
          </div>
          <div class="row" v-if="!deal.isGroup">
            <quest-stat-card
              label="Property"
              :value="property(deal.properties[0])"
              card="col-12"
              :icon="['fas', 'hotel']"
            >
            </quest-stat-card>
          </div>
        </template>

        <basic-card :deal="deal"></basic-card>
        <marketing-card :deal="deal"></marketing-card>
        <deal-configuration-card :deal="deal"></deal-configuration-card>
        <seo-card :deal="deal"></seo-card>

        <template v-if="deal.isGroup">
          <linked-properties-card
            :deal="deal"
            :filters="filters"
          ></linked-properties-card>
        </template>
      </template>
    </quest-main>
  </ValidationObserver>
</template>

<script>
import { REQUIRED } from '@/validation-types'
import moment from 'moment'
import 'moment-timezone'
import { success, error } from '../helpers'
import { DealManagementService, PropertyManagementService } from '../services'
import {
  QuestCard,
  QuestMain,
  QuestStatCard,
  QuestValidationSummary,
} from './structure'
import {
  QuestInput,
  QuestTextArea,
  QuestEditor,
  QuestDatePicker,
  QuestToggle,
  QuestSingleSelect,
  QuestMultiSelect,
} from './form'
import {
  LinkedPropertiesCard,
  SeoCard,
  DealConfigurationCard,
  MarketingCard,
  BasicCard,
} from './deal-cards'

export default {
  name: 'deal',
  components: {
    QuestCard,
    QuestMain,
    QuestInput,
    QuestTextArea,
    QuestEditor,
    QuestDatePicker,
    QuestToggle,
    QuestSingleSelect,
    QuestMultiSelect,
    QuestStatCard,
    LinkedPropertiesCard,
    SeoCard,
    DealConfigurationCard,
    MarketingCard,
    BasicCard,
    QuestValidationSummary,
  },
  data() {
    return {
      REQUIRED,
      deal: null,
      saving: false,
      loading: true,
      filters: [],
    }
  },
  computed: {
    dateCreated() {
      return moment(`${this.deal.dateCreated}Z`)
        .tz('Australia/Melbourne')
        .format('lll')
    },
    dateModified() {
      return moment(`${this.deal.dateModified}Z`)
        .tz('Australia/Melbourne')
        .format('lll')
    },
  },
  methods: {
    async save() {
      if (!this.$refs.vobserver) return
      let validated = await this.$refs.vobserver.validate()
      if (!validated) {
        error('Please ensure all errors are resolved')
        return
      }
      this.saving = true

      if (this.deal.id) {
        if (this.deal.disclaimer) {
          this.deal.disclaimer = this.deal.disclaimer
            .replace('<p>', '')
            .replace('</p>', '')
        }
        new DealManagementService()
          .update(this.deal)
          .then((data) => {
            if (data) {
              this.load()
              success(`Updated deal #`, this.deal.name)
            } else {
              error(`Failed to update deal #`, this.deal.name)
            }
          })
          .finally((_) => {
            this.saving = false
          })
      }
    },
    load() {
      if (this.$route.params.id) {
        new PropertyManagementService()
          .filters()
          .then((data) => (this.filters = data))

        new DealManagementService()
          .get(this.$route.params.id)
          .then((data) => {
            this.deal = data
          })
          .catch((_) => (this.deal = null))
          .finally((_) => {
            this.loading = false
            this.$nextTick(() => {
              if (this.$refs && this.$refs.vobserver) {
                this.$refs.vobserver.validate()
              }
            })
          })
      } else {
        this.loading = false
      }
    },
    property(id) {
      const p = this.filters.filter((x) => x.id === id)
      return p.length > 0 ? p[0].name : ''
    },
  },
  mounted() {
    this.load()
  },
}
</script>

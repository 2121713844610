<template>
  <div class="form-group">
    <label>{{ label }}</label>

    <ValidationProvider
      ref="vprovider"
      :name="name"
      :rules="rules"
      v-slot="{ errors, classes }"
    >
      <input type="hidden" v-bind:value="value" />
      <div
        class="form-control editor-wrapper"
        :class="classes"
        style="background: none !important"
      >
        <editor-content :editor="editor" class="editor__content" />
      </div>
      <small class="text-danger">{{ errors[0] }}</small>
    </ValidationProvider>

    <editor-menu-bar
      @hide="hideLinkMenu"
      :editor="editor"
      v-slot="{ commands, isActive, getMarkAttrs }"
    >
      <div class="menubar is-hidden border-left-primary mt-3 is-focused">
        <form
          class="menububble__form"
          v-if="linkMenuIsActive"
          @submit.prevent="setLinkUrl(commands.link, linkUrl)"
        >
          <div class="input-group">
            <input
              class="menububble__input form-control"
              style="border-radius: 0"
              type="text"
              v-model="linkUrl"
              v-on:keydown="countdown"
              v-on:keyup="countdown"
              placeholder="https://"
              ref="linkInput"
              @keydown.esc="hideLinkMenu"
            />
            <div class="input-group-append">
              <button
                class="menububble__button btn"
                @click="setLinkUrl(commands.link, null)"
                type="button"
              >
                <font-awesome-icon
                  :icon="['fas', 'times']"
                  fixed-width
                ></font-awesome-icon>
              </button>
            </div>
          </div>
        </form>
        <template v-else>
          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.bold() }"
            @click="commands.bold"
          >
            <font-awesome-icon
              :icon="['far', 'bold']"
              fixed-width
            ></font-awesome-icon>
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.italic() }"
            @click="commands.italic"
          >
            <font-awesome-icon
              :icon="['far', 'italic']"
              fixed-width
            ></font-awesome-icon>
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.underline() }"
            @click="commands.underline"
          >
            <font-awesome-icon
              :icon="['far', 'underline']"
              fixed-width
            ></font-awesome-icon>
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.paragraph() }"
            @click="commands.paragraph"
          >
            <font-awesome-icon
              :icon="['far', 'paragraph']"
              fixed-width
            ></font-awesome-icon>
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 3 }) }"
            @click="commands.heading({ level: 3 })"
          >
            <font-awesome-icon
              :icon="['far', 'heading']"
              fixed-width
            ></font-awesome-icon>
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.bullet_list() }"
            @click="commands.bullet_list"
          >
            <font-awesome-icon
              :icon="['far', 'list-ul']"
              fixed-width
            ></font-awesome-icon>
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.link() }"
            @click="showLinkMenu(getMarkAttrs('link'))"
          >
            <font-awesome-icon
              :icon="['fas', 'link']"
              fixed-width
            ></font-awesome-icon>
          </button>
        </template>
      </div>
    </editor-menu-bar>
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'

import {
  Blockquote,
  BulletList,
  CodeBlock,
  HardBreak,
  Heading,
  ListItem,
  OrderedList,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from 'tiptap-extensions'

export default {
  name: 'quest-editor',
  components: {
    'editor-content': EditorContent,
    'editor-menu-bar': EditorMenuBar,
  },
  props: {
    label: String,
    id: String,
    name: { default: '' },
    placeholder: { default: '' },
    rules: { default: () => ({}) },
    value: { required: true },
  },
  methods: {
    countdown() {},
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href
      this.linkMenuIsActive = true
      this.$nextTick(() => {
        if (this.$refs && this.$refs.linkInput) {
          this.$refs.linkInput.focus()
        }
      })
    },
    hideLinkMenu() {
      this.linkUrl = null
      this.linkMenuIsActive = false
    },
    setLinkUrl(command, url) {
      command({ href: url })
      this.hideLinkMenu()
    },
  },
  data() {
    return {
      property: null,
      keepInBounds: true,
      linkUrl: null,
      linkMenuIsActive: false,
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        content: this.value,
        onUpdate: ({ getHTML }) => {
          const provider = this.$refs['vprovider']
          provider.validate()
          this.$emit('input', getHTML())
        },
      }),
    }
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  mounted() {
    this.editor.setContent(this.value)
  },
  watch: {
    value(val) {
      if (this.editor && val !== this.value) {
        this.editor.setContent(val, true)
      }
    },
  },
}
</script>

<template>
  <quest-main
    title="Announcements"
    copy="State/region wide announcements can be managed in this section"
    :loading="loading"
  >
    <template v-slot:head>
      <router-link
        :to="{ name: 'announcements-add' }"
        class="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
      >
        <font-awesome-icon
          icon="plus"
          size="sm"
          class="text-white-50"
        ></font-awesome-icon>
        Add</router-link
      >
    </template>
    <template v-slot:body>
      <quest-card title="Announcement collection">
        <quest-table id="announcement-table" :columns="columns">
          <template v-if="announcements.items.length > 0">
            <tr
              class="small align-middle"
              v-for="announcement in announcements.items"
              :key="announcement.id"
            >
              <td>
                <router-link
                  :to="{
                    name: 'announcements-edit',
                    params: { id: announcement.id },
                  }"
                  >{{
                    stripHtml(announcement.body) || '(untitled)'
                  }}</router-link
                >
              </td>
              <td>{{ lastModified(announcement.dateModified) }}</td>
              <td>
                <b-dropdown no-caret variant="link">
                  <template #button-content>
                    <font-awesome-icon
                      icon="ellipsis-v"
                      size="sm"
                      fixed-width
                      class="text-gray-400"
                    ></font-awesome-icon>
                  </template>
                  <b-dropdown-header>Actions</b-dropdown-header>
                  <b-dropdown-item
                    :to="{
                      name: 'announcements-edit',
                      params: { id: announcement.id },
                    }"
                    >View</b-dropdown-item
                  >
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item-button
                    @click="promptDelete(announcement)"
                    text="danger"
                    variant="danger"
                    >Delete</b-dropdown-item-button
                  >
                </b-dropdown>
              </td>
            </tr>
          </template>
          <template v-if="announcements.items.length === 0">
            <tr class="align-middle">
              <td :colspan="columns.length">
                <div class="small text-center py-5">
                  There are no announcements in the system
                </div>
              </td>
            </tr>
          </template>
        </quest-table>
        <quest-pager
          v-if="announcements.total > pageSize"
          @changePage="changePage"
          :currentPage="page"
          :itemCount="announcements.total"
          :pageSize="pageSize"
        ></quest-pager>
      </quest-card>
      <b-modal v-model="showModal" centered title="Delete">
        <p class="my-4">
          Are you sure you wish to delete announcement '{{
            toDelete ? toDelete.name || 'untitled' : 'untitled'
          }}'?
        </p>
        <template v-slot:modal-footer>
          <button
            class="btn btn-secondary btn-sm"
            type="button"
            @click="cancelPromptDelete"
          >
            Cancel</button
          ><button
            class="btn btn-danger btn-sm"
            type="button"
            @click="confirmPromptDelete"
          >
            Delete
          </button>
        </template>
      </b-modal>
    </template>
  </quest-main>
</template>

<script>
import { success, error, stripHtml } from '../helpers'
import moment from 'moment'
import 'moment-timezone'
import {
  QuestMain,
  QuestTable,
  QuestCard,
  QuestModal,
  QuestPager,
} from './structure'
import { AnnouncementManagementService } from '../services'
import PagedResult from '../models/paged-result'

export default {
  name: 'announcements',
  components: {
    QuestMain,
    QuestTable,
    QuestCard,
    QuestPager,
    QuestModal,
  },
  data() {
    return {
      pageSize: 10,
      page: 1,
      term: '',
      announcements: new PagedResult(),
      columns: ['Body', 'Modified', ''],
      toDelete: null,
      showModal: false,
      loading: true,
    }
  },
  mounted() {
    this.changePage(1)
  },
  methods: {
    stripHtml,
    changePage(page) {
      new AnnouncementManagementService()
        .list(page, this.pageSize, this.term)
        .then((data) => {
          this.announcements = data
          this.page = page
        })
        .finally((_) => (this.loading = false))
    },
    lastModified(date) {
      return moment(`${date}Z`).tz('Australia/Melbourne').format('lll')
    },
    promptDelete(announcement) {
      this.toDelete = announcement
      this.showModal = true
    },
    cancelPromptDelete() {
      this.showModal = false
      this.toDelete = null
    },
    confirmPromptDelete() {
      new AnnouncementManagementService()
        .delete(this.toDelete.id)
        .then((data) => {
          this.showModal = false

          if (data) {
            this.changePage(1)
            success(`Deleted announcement #`, this.toDelete.name)
          } else {
            error(`Failed to delete announcement #`, this.toDelete.name)
          }
        })
        .finally((_) => (this.toDelete = null))
    },
  },
}
</script>

<template>
  <div>
    <template v-if="!loading">
      <quest-card :panel="true">
        <div class="row">
          <div class="col-6 col-md-3">
            <quest-date-picker
              id="startDate"
              icon="sign-in"
              v-model="start"
            ></quest-date-picker>
          </div>
          <div class="col-6 col-md-3">
            <quest-date-picker
              id="endDate"
              icon="sign-out"
              v-model="end"
            ></quest-date-picker>
          </div>
          <div class="col-6 col-md-3">
            <quest-single-select
              v-model="adults"
              :options="['1', '2', '3', '4', '5', '6', '7']"
              id="adults"
              @update="($event) => (adults = $event)"
            >
              <template v-slot:prepend>
                <font-awesome-icon
                  fixed-width
                  :icon="['fas', 'male']"
                ></font-awesome-icon></template
            ></quest-single-select>
          </div>
          <div class="col-6 col-md-3">
            <quest-single-select
              v-model="children"
              :options="['0', '1', '2', '3', '4', '5', '6']"
              id="children"
              @update="($event) => (children = $event)"
              :keepPlaceholder="false"
              ><template v-slot:prepend>
                <font-awesome-icon
                  fixed-width
                  :icon="['fas', 'child']"
                ></font-awesome-icon></template
            ></quest-single-select>
          </div>
          <div class="col-6 col-md-3">
            <quest-toggle
              id="loggedIn"
              v-model="loggedIn"
              checked="Signed in"
              unchecked="Signed out"
            />
          </div>
          <div class="col-6 col-md-3">
            <quest-input
              id="code"
              placeholder="Group / Promo code"
              v-model="code"
            ></quest-input>
          </div>
          <div class="col-6 col-md-3 offset-6 offset-md-3">
            <button class="btn btn-primary btn-block" @click="() => explore()">
              <span class="icon text-white-50">
                <font-awesome-icon
                  fixed-width
                  :icon="['fas', 'search']"
                ></font-awesome-icon>
              </span>
              <span class="text">Explore</span>
            </button>
          </div>
        </div>
      </quest-card>
      <template v-if="!loadingQuotes">
        <rate-explorer-card
          v-for="card in cards.filter((x) => x.room.isEnabled)"
          :key="card.room.id"
          :card="card"
          :is-logged-in="lastSearchLoggedIn"
        ></rate-explorer-card>
      </template>
      <template v-else>
        <div class="text-gray-400 text-center my-5">
          <font-awesome-icon
            icon="spinner"
            size="4x"
            fixed-width
            pulse
          ></font-awesome-icon>
          <p class="pt-3 pb-0">Loading Quotes...</p>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="text-gray-400 text-center my-5">
        <font-awesome-icon
          icon="spinner"
          size="4x"
          fixed-width
          pulse
        ></font-awesome-icon>
        <p class="pt-3 pb-0">Loading Rate Explorer...</p>
      </div>
    </template>
  </div>
</template>

<script>
import { QuoteService, RoomTypesManagementService } from '@/services'
import * as moment from 'moment'
import { QuestMain, QuestCard } from '../structure'
import {
  QuestDatePicker,
  QuestSingleSelect,
  QuestToggle,
  QuestInput,
} from '../form'

import RateExplorerCard from './rate-explorer-card.vue'

export default {
  name: 'rate-explorer',
  components: {
    QuestMain,
    QuestCard,
    QuestDatePicker,
    QuestSingleSelect,
    QuestToggle,
    QuestInput,
    RateExplorerCard,
  },
  props: {
    property: { required: true },
  },
  data() {
    return {
      loading: true,
      loadingQuotes: false,
      start: moment().format('yyyy-MM-DD'),
      end: moment().add(1, 'd').format('yyyy-MM-DD'),
      adults: '1',
      children: '0',
      loggedIn: false,
      code: '',
      roomTypes: [],
      records: [],
      cards: [],
      lastSearchLoggedIn: false,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      new RoomTypesManagementService().list(this.property).then((rooms) => {
        this.roomTypes = rooms
        this.loading = false
      })
    },
    explore() {
      this.loadingQuotes = true
      new QuoteService()
        .explore(
          this.property,
          this.start,
          this.end,
          this.adults,
          this.children,
          this.loggedIn,
          this.code
        )
        .then((data) => {
          this.lastSearchLoggedIn = this.loggedIn
          this.records = data
          this.cards = this.roomTypes.map((room) => ({
            room: room,
            rates: this.records.find((x) => x.roomTypeId == room.id),
          }))
          this.loadingQuotes = false
        })
    },
  },
}
</script>

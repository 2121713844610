import AdminNexusHttpClient from './admin-nexus-http-client'
import PagedResult from '../models/paged-result'

export default class DealManagementService {
  constructor() {
    this.httpClient = new AdminNexusHttpClient('/admin/deals')
  }

  async all(page = 1, pageSize = 10, term = '', type = '', property = '') {
    const data = {
      page,
      pageSize,
      term,
      property,
      type: type === 'True' ? true : type === 'False' ? false : null,
    }
    const response = await this.httpClient.get('', data)
    let result = new PagedResult()
    if (response && response.items) {
      result.total = response.total
      result.items = response.items
    }
    return result
  }

  async create(payload) {
    return await this.httpClient.put(``, payload)
  }

  async get(id) {
    return await this.httpClient.get(`${id}`)
  }

  async update(payload) {
    return await this.httpClient.post(``, payload)
  }

  async delete(id) {
    return await this.httpClient.delete(`${id}`)
  }
}

<template>
  <div>
    <div class="row" v-if="p_any">
      <div class="mb-4 col-12">
        <div :class="`card shadow h-100 py-2`">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col-auto">
                <ul class="nav nav-pills">
                  <li class="nav-item" v-if="p_config">
                    <a
                      class="nav-link"
                      :class="{ active: displayed === PropertyView.Config }"
                      href="#config"
                      @click="(_) => (displayed = PropertyView.Config)"
                      >Configuration</a
                    >
                  </li>
                  <li class="nav-item" v-if="p_content">
                    <a
                      class="nav-link"
                      :class="{ active: displayed === PropertyView.Content }"
                      href="#content"
                      @click="(_) => (displayed = PropertyView.Content)"
                      >Content</a
                    >
                  </li>
                  <li class="nav-item" v-if="p_conference">
                    <a
                      class="nav-link"
                      :class="{ active: displayed === PropertyView.Conference }"
                      href="#conferencing"
                      @click="(_) => (displayed = PropertyView.Conference)"
                      >Conferencing</a
                    >
                  </li>
                  <li class="nav-item" v-if="p_locality">
                    <a
                      class="nav-link"
                      :class="{ active: displayed === PropertyView.Locality }"
                      href="#locality"
                      @click="(_) => (displayed = PropertyView.Locality)"
                      >Locality</a
                    >
                  </li>
                  <li class="nav-item" v-if="p_rooms">
                    <a
                      class="nav-link"
                      :class="{ active: displayed === PropertyView.Rooms }"
                      href="#rooms"
                      @click="(_) => (displayed = PropertyView.Rooms)"
                      >Rooms</a
                    >
                  </li>
                  <li
                    class="nav-item"
                    v-if="
                      currentAccount?.service === ExternalServiceType.RMS &&
                      isAdmin
                    "
                  >
                    <a
                      class="nav-link"
                      :class="{ active: displayed === PropertyView.Rates }"
                      href="#rates"
                      @click="(_) => (displayed = PropertyView.Rates)"
                      >Rates</a
                    >
                  </li>
                  <li
                    class="nav-item"
                    v-if="
                      currentAccount?.service === ExternalServiceType.RMS &&
                      isAdmin
                    "
                  >
                    <a
                      class="nav-link"
                      :class="{ active: displayed === PropertyView.Explorer }"
                      href="#explorer"
                      @click="(_) => (displayed = PropertyView.Explorer)"
                      >Explorer</a
                    >
                  </li>
                  <li
                    class="nav-item"
                    v-if="currentAccount?.service === ExternalServiceType.RMS"
                  >
                    <a
                      class="nav-link"
                      :class="{ active: displayed === PropertyView.Extras }"
                      href="#extras"
                      @click="(_) => (displayed = PropertyView.Extras)"
                      >Extras</a
                    >
                  </li>
                  <b-dropdown
                    variant="link"
                    class="nav-item"
                    toggle-class="text-decoration-none shadow-none"
                  >
                    <template #button-content>
                      <span>Preview</span>
                    </template>
                    <b-dropdown-item
                      href="#preview-web"
                      @click="(_) => preview()"
                      >Web</b-dropdown-item
                    >
                    <b-dropdown-item
                      href="#preview-metadata"
                      @click="(_) => (displayed = PropertyView.MetaPreview)"
                      >Metadata</b-dropdown-item
                    >
                  </b-dropdown>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <property-config-view
      :property="property"
      :configuration="configuration"
      v-show="displayed === PropertyView.Config"
      :ref="PropertyView.Config"
      @activate="$emit('activate')"
    ></property-config-view>
    <property-content-view
      :property="property"
      :configuration="configuration"
      :csr="csr"
      :announcement="announcement"
      v-show="displayed === PropertyView.Content"
      :ref="PropertyView.Content"
    ></property-content-view>
    <property-conference-view
      :property="property"
      :configuration="configuration"
      v-show="displayed === PropertyView.Conference"
      :ref="PropertyView.Conference"
    ></property-conference-view>
    <property-locality-view
      :property="property"
      :configuration="configuration"
      v-show="displayed === PropertyView.Locality"
      :ref="PropertyView.Locality"
    ></property-locality-view>
    <property-rooms-view
      :property="property"
      :configuration="configuration"
      v-show="displayed === PropertyView.Rooms"
      :ref="PropertyView.Rooms"
    ></property-rooms-view>
    <property-web-preview-view
      :payload="propertyData"
      v-if="displayed === PropertyView.WebPreview"
      :ref="PropertyView.WebPreview"
    ></property-web-preview-view>
    <property-meta-preview-view
      :payload="metaPayload"
      v-if="displayed === PropertyView.MetaPreview"
      :ref="PropertyView.MetaPreview"
    ></property-meta-preview-view>
    <property-rate-listing
      v-if="
        displayed === PropertyView.Rates &&
        currentAccount?.service === ExternalServiceType.RMS
      "
      :property="property.id"
    ></property-rate-listing>
    <rate-explorer
      v-if="
        displayed === PropertyView.Explorer &&
        currentAccount?.service === ExternalServiceType.RMS
      "
      :property="property.id"
    ></rate-explorer>
    <property-extra-listing
      v-if="
        displayed === PropertyView.Extras &&
        currentAccount?.service === ExternalServiceType.RMS
      "
      :property="property.id"
    ></property-extra-listing>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { PropertyView } from '../helpers'
import { PropertyRateListing, RateExplorer } from '../components/rates'
import { PropertyExtraListing } from '../components/extras'

import {
  PropertyConfigView,
  PropertyContentView,
  PropertyConferenceView,
  PropertyLocalityView,
  PropertyRoomsView,
  PropertyWebPreviewView,
  PropertyMetaPreviewView,
} from './property-views'

import { GeneratePreviewService } from '../services'

import { ExternalServiceType } from '@/models'

export default {
  name: 'property-crud',
  props: {
    property: { required: true },
    configuration: { required: true },
    csr: { type: Object, default: () => ({}) },
    announcement: { type: Object, default: () => ({}) },
    view: { default: PropertyView.Config },
  },
  components: {
    PropertyConfigView,
    PropertyContentView,
    PropertyConferenceView,
    PropertyLocalityView,
    PropertyRoomsView,
    PropertyWebPreviewView,
    PropertyMetaPreviewView,
    PropertyRateListing,
    PropertyExtraListing,
    RateExplorer,
  },
  data() {
    return {
      ExternalServiceType,
      loaded: false,
      displayed: this.view,
      PropertyView,
      propertyData: {},
    }
  },
  computed: {
    ...mapGetters(['currentAccount', 'isAdmin']),
    metaPayload() {
      return {
        property: this.property,
        configuration: this.configuration,
        roomTypes: this.$refs[PropertyView.Rooms].getRoomTypes(),
      }
    },
    p_any() {
      return [
        this.p_config,
        this.p_content,
        this.p_conference,
        this.p_locality,
        this.p_rooms,
      ].some((x) => x)
    },
    p_config() {
      return (
        this.loaded &&
        this.$refs[PropertyView.Config] &&
        this.$refs[PropertyView.Config].p_any
      )
    },
    p_content() {
      return (
        this.loaded &&
        this.$refs[PropertyView.Content] &&
        this.$refs[PropertyView.Content].p_any
      )
    },
    p_conference() {
      return (
        this.loaded &&
        this.$refs[PropertyView.Conference] &&
        this.$refs[PropertyView.Conference].p_any
      )
    },
    p_locality() {
      return (
        this.loaded &&
        this.$refs[PropertyView.Locality] &&
        this.$refs[PropertyView.Locality].p_locality
      )
    },
    p_rooms() {
      return (
        this.loaded &&
        this.$refs[PropertyView.Rooms] &&
        this.$refs[PropertyView.Rooms].p_any
      )
    },
  },
  mounted() {
    this.$nextTick((_) => {
      this.loaded = true
      this.$emit('loaded')
    })
  },
  watch: {
    view(value) {
      this.displayed = value
    },
  },
  methods: {
    preview() {
      let roomTypes = this.$refs[PropertyView.Rooms].getRoomTypes()
      let locality = this.$refs[PropertyView.Locality].getValue().payload
      let a_amenities = this.$refs[PropertyView.Content].getAmenities()
      let csr = this.$refs[PropertyView.Content].getCSR()

      new GeneratePreviewService()
        .generate(
          this.property,
          this.configuration,
          locality,
          roomTypes,
          a_amenities,
          csr
        )
        .then((value) => {
          this.propertyData = value
          this.displayed = PropertyView.WebPreview
        })
    },
    getValues() {
      let faqs = this.$refs[PropertyView.Conference].getFAQs()
      let benefits = this.$refs[PropertyView.Conference].getBenefits()
      let config = this.$refs[PropertyView.Config].getConfig()
      let csr = this.$refs[PropertyView.Content].getCSR()
      let announcement = this.$refs[PropertyView.Content].getAnnouncement()
      let locality = this.$refs[PropertyView.Locality].getValue()
      let roomTypes = this.$refs[PropertyView.Rooms].getRoomTypes()

      this.property.redirects =
        (!this.property.isMirrored || this.property.isActivated) &&
        this.$refs[PropertyView.Config]
          ? this.$refs[PropertyView.Config].getRedirects()
          : this.property.redirects
      this.property.amenities = this.$refs[PropertyView.Content]
        ? this.$refs[PropertyView.Content].getAmenities()
        : this.property.amenities

      return {
        faqs,
        benefits,
        config,
        csr,
        announcement,
        locality,
        roomTypes,
      }
    },
  },
}
</script>

<template>
  <div class="card shadow">
    <template v-if="!loading">
      <div style="height:80vh">
        <iframe width="100%" height="100%" style="border: 0" :src="src"></iframe>
      </div>
    </template>
    <template v-if="loading">
      <quest-preloader></quest-preloader>
    </template>
  </div>
</template>

<script>
import Card from '../card'
import { PreviewService } from '../../services'
import { QuestPreloader } from '../structure'

export default {
  extends: Card,
  name: 'property-web-preview-view',
  props: {
    payload: { required: true },
  },
  components: {
    QuestPreloader
  },
  mounted() {
    new PreviewService().add(this.payload).then(token => { 
      this.token = token
      this.loading = false 
    })
  },
  data() {
    return {
      token: '',
      loading: true
    }
  },
  computed: {
    src() {
      return `${this.$config.cms.uri}/preview?token=${this.token}`
    }
  }
}
</script>

<template>
  <div :class="wrapperClass">
    <label v-if="label" :for="id">{{ label }}</label>
    <ValidationProvider
      :name="visibleName"
      :rules="rules"
      v-slot="{ errors, classes }"
    >
      <div :class="{ 'input-group': hasSlotData }">
        <span class="input-group-prepend" v-if="hasSlotData">
          <span class="input-group-text">
            <slot name="prepend"></slot>
          </span>
        </span>
        <select
          id="id"
          class="form-control"
          :class="{ 'form-control-lg': large, ...classes }"
          v-model="selectedOption"
          @change="notify_selection"
          :disabled="disabled"
        >
          <option
            v-bind="{ disabled: !keepPlaceholder }"
            value=""
            v-if="keepPlaceholder || !hasSelected"
          >
            {{ placeholder }}
          </option>
          <option
            v-for="(option, idx) in options"
            :key="idx"
            :value="isObject(option) ? option[okey] : option"
          >
            {{ isObject(option) ? option[otext] : option }}
          </option>
        </select>
      </div>
      <small v-if="help" :id="id + 'Help'" class="form-text text-muted">{{
        help
      }}</small>
      <small class="text-danger">{{ errors[0] }}</small>
    </ValidationProvider>
  </div>
</template>

<script>
import form from './form'

export default {
  name: 'quest-single-select',
  props: {
    options: Array,
    keepPlaceholder: { default: false },
    large: Boolean,
    okey: { default: 'key' },
    otext: { default: 'value' },
    disabled: { default: false },
    wrapperClass: { default: 'form-group' },
  },
  extends: form,
  data() {
    return {
      selectedOption: '',
    }
  },
  computed: {
    hasSelected() {
      return this.options.find((e) =>
        this.isObject(e) ? e[this.okey] === this.value : e === this.value
      )
    },
    hasSlotData() {
      return this.$slots.prepend
    },
  },
  methods: {
    notify_selection() {
      this.$emit('update', this.selectedOption)
    },
    isObject(v) {
      return typeof v === 'object' && v !== null
    },
  },
  created() {
    this.selectedOption = this.value
  },
  watch: {
    value() {
      this.selectedOption = this.value
    },
  },
}
</script>

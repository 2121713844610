<template>
  <div
    class="border bg-white p-3 my-3 rounded"
    :class="{ 'border-primary': records?.length }"
  >
    <div class="row" v-if="records?.length > 0 || base">
      <div class="d-flex col-12 gap-2">
        <div class="flex-grow-1">
          <label>{{
            records?.length > 0 ? title : base && !override ? emptyText : ''
          }}</label>
        </div>
        <quest-toggle
          :wrapperClass="records?.length > 0 ? 'form-group' : ''"
          v-if="base"
          :checked-icon="['fas', 'unlock']"
          :unchecked-icon="['fas', 'lock']"
          :id="id + '-override-toggle'"
          :value="override"
          @input="(e) => $emit('toggle', e)"
          no-label="true"
          inline="true"
        />
      </div>
    </div>
    <template v-if="records?.length > 0">
      <div class="row" v-for="(record, index) in records" :key="index">
        <div :class="disabled ? 'col-6' : 'col-5'">
          <quest-date-picker
            :disabled="disabled"
            id=""
            label=""
            v-model="record.start"
            clearable="true"
            help=""
          ></quest-date-picker>
        </div>
        <div :class="disabled ? 'col-6' : 'col-5'">
          <quest-date-picker
            :disabled="disabled"
            id=""
            label=""
            v-model="record.end"
            clearable="true"
            help=""
          ></quest-date-picker>
        </div>
        <div
          class="col-2 form-group d-flex align-items-center"
          v-if="!disabled"
        >
          <a @click="remove(record)" class="pointer"
            ><font-awesome-icon icon="times" size="sm"></font-awesome-icon>
          </a>
        </div>
      </div>
      <a @click="add()" class="pointer" v-if="!disabled"
        ><small class="text-center d-block">{{ addText }}</small></a
      >
    </template>
    <template v-else-if="!disabled">
      <a @click="add()" class="pointer"
        ><small class="text-center d-block">{{ emptyText }}</small></a
      >
    </template>
    <template v-else-if="disabled && !base">
      <small class="text-center d-block">{{ emptyText }}</small>
    </template>
  </div>
</template>

<script>
import { QuestDatePicker, QuestToggle } from '../form'

export default {
  name: 'date-range-list',
  components: {
    QuestDatePicker,
    QuestToggle,
  },
  props: {
    title: String,
    emptyText: String,
    addText: String,
    records: { default: () => [] },
    base: { default: null },
    override: { default: false },
    disabled: { default: false },
    id: { required: true },
  },
  methods: {
    add() {
      this.records?.push({ start: null, end: null })
      this.$emit('replace', this.records || [{ start: null, end: null }])
    },
    remove(record) {
      this.$emit(
        'replace',
        this.records.filter((x) => x !== record)
      )
    },
  },
}
</script>

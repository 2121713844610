import { RateRecordGrouping } from '@/models'

export default class RateView {
  public = []
  private = []
  special = []
  member = []

  static from(json, context) {
    let view = new RateView()

    view.public = json.public
      .map((x) => RateRecordGrouping.from(x, context))
      .filter((x) => x != null)
    view.private = json.private
      .map((x) => RateRecordGrouping.from(x, context))
      .filter((x) => x != null)
    view.special = json.special
      .map((x) => RateRecordGrouping.from(x, context))
      .filter((x) => x != null)
    view.member = json.member
      .map((x) => RateRecordGrouping.from(x, context))
      .filter((x) => x != null)

    return view
  }
}

<template>
  <ValidationObserver ref="vobserver" v-slot="{}">
    <b-modal
      ref="modal"
      centered
      size="lg"
      :title="rate.id ? 'Manage rate' : 'Create rate'"
      body-class="p-0"
      @hidden="hidden"
    >
      <div class="container">
        <b-alert
          show
          :variant="readonly ? 'secondary' : 'primary'"
          class="mt-3"
        >
          <h4 class="alert-heading pt-2">{{ workspaceTitle }}</h4>
          <p v-html="workspaceSummary"></p>
        </b-alert>
      </div>
      <div class="container">
        <row
          id="name"
          label="Name"
          tip="Rate name displayed to users. Maximum character limit of 60."
          :base="parent"
          :override="overrides.name"
          @toggle="(e) => (overrides.name = e)"
        >
          <quest-input
            :disabled="readonly || isInheritMode(overrides.name)"
            id="name"
            @change="updateSlug"
            :value="isInheritMode(overrides.name) ? parent.name : rate.name"
            @input="(e) => (rate.name = e)"
            :rules="{
              [REQUIRED]: true,
              [MAX]: { length: 60 },
            }"
          ></quest-input>
        </row>

        <row
          id="status"
          label="Status"
          tip="Immediately disable all future bookings using this rate by remvoing it from the system. When enabled, the rate is accessible based on the restrictions from the external base rate and criteria input below."
        >
          <quest-toggle
            id="status"
            :disabled="readonly"
            :value="
              parent
                ? rate.status === RateStatus.OverrideInherit
                : rate.status === RateStatus.Enabled
            "
            @input="
              (e) =>
                (rate.status = !!parent
                  ? e
                    ? RateStatus.OverrideInherit
                    : RateStatus.OverrideOptOut
                  : e
                  ? RateStatus.Enabled
                  : RateStatus.Disabled)
            "
            :checked="parent ? 'Opt In' : 'Enabled'"
            :unchecked="parent ? 'Opt out' : 'Disabled'"
            uncheckedTheme="danger"
          />
        </row>
        <row
          id="rateTable"
          label="External Rate"
          tip="Select the base rate from the integrated system. This is required to infer pricing and booking rules."
          :base="parent"
          :override="overrides.rateTables"
          @toggle="(e) => (overrides.rateTables = e)"
        >
          <quest-single-select
            :value="
              isInheritMode(overrides.rateTables)
                ? parent.rateTableId
                : rate.rateTableId
            "
            :disabled="readonly || isInheritMode(overrides.rateTables)"
            :options="context"
            id="rateTable"
            name="Base rate"
            placeholder="Select base rate"
            :keepPlaceholder="true"
            @update="updateBaseRate"
            :rules="{
              [REQUIRED]: true,
            }"
          ></quest-single-select>
        </row>
        <row
          id="additionalRateTables"
          label="Additional Rates"
          tip="These rates will be calculated assuming the primary rate is valid. Use this to check for better discounts based on external policies such as weekly and monthly breakpoints."
        >
          <quest-multi-select
            :disabled="readonly || isInheritMode(overrides.rateTables)"
            :value="
              isInheritMode(overrides.rateTables)
                ? parent.additionalRateTables
                : rate.additionalRateTables
            "
            id="additionalRateTables"
            :options="context"
            @input="(e) => (rate.additionalRateTables = e)"
            :uid="'key'"
            :text="'value'"
          ></quest-multi-select>
        </row>
        <row
          id="variance_variance"
          label="Standard Discount"
          tip="A standard discount is always applied to the base rate BEFORE any other discounts are calculated for the final quote."
          :base="parent"
          :override="overrides.variance"
          @toggle="(e) => (overrides.variance = e)"
        >
          <quest-single-select
            :disabled="readonly || isInheritMode(overrides.variance)"
            :value="
              isInheritMode(overrides.variance)
                ? parent.variance.variance
                : rate.variance.variance
            "
            :options="varianceTypes"
            id="varaince_variance"
            @update="updateVarianceType"
          ></quest-single-select>
        </row>
      </div>
      <slide
        :toggle="
          isInheritMode(overrides.variance)
            ? parent.variance.variance != 'None'
            : rate.variance.variance != 'None'
        "
      >
        <row
          id="varianceAmount"
          :label="discountAmountLabel"
          :tip="discountAmountTip"
        >
          <quest-input
            name="Discount amount"
            :disabled="readonly || isInheritMode(overrides.variance)"
            :value="
              isInheritMode(overrides.variance)
                ? parent.variance.amount
                : rate.variance.amount
            "
            @input="(e) => (rate.variance.amount = e)"
            id="varianceAmount"
            :rules="{
              [REQUIRED]: true,
            }"
          ></quest-input>
        </row>
      </slide>
      <div class="container">
        <row
          id="loginOnly"
          label="Members only rate"
          tip="Members only rates use the linked external rate and standard discount as the base for pricing. The rate will only be available to users who have authenticated on the website. For rates with member discount variants, use the 'Members external rate' field."
        >
          <quest-toggle
            id="loginOnly"
            :value="parent ? parent.loginOnly : rate.loginOnly"
            :disabled="readonly || parent"
            @input="(e) => (rate.loginOnly = e)"
            checked="True"
            unchecked="False"
          />
        </row>
      </div>
      <slide :toggle="!rate.loginOnly">
        <row
          id="memberRateTable"
          label="Members' External Rate"
          tip="Select the base rate from the integrated system for the members rate variation. If no members rate is selected, only non-authenticated variations of this rate will be available. You may use the same external rate as the base external rate."
        >
          <quest-single-select
            :disabled="readonly || isInheritMode(overrides.rateTables)"
            :value="
              isInheritMode(overrides.rateTables)
                ? parent.loggedInRateTableId
                : rate.loggedInRateTableId
            "
            :options="context"
            id="memberRateTable"
            placeholder="No member rate"
            :keepPlaceholder="true"
            @update="updateMemberRate"
          ></quest-single-select>
        </row>
      </slide>
      <slide
        :toggle="
          isInheritMode(overrides.rateTables)
            ? !parent.loginOnly && parent.loggedInRateTableId
            : !rate.loginOnly && rate.loggedInRateTableId
        "
      >
        <row
          id="loggedInadditionalRateTables"
          label="Additional Members' Rates"
          tip="These rates will be calculated assuming the primary rate is valid. Use this to check for better discounts based on external policies such as weekly and monthly breakpoints."
        >
          <quest-multi-select
            :disabled="readonly || isInheritMode(overrides.rateTables)"
            :value="
              isInheritMode(overrides.rateTables)
                ? parent.loggedInAdditionalRateTables
                : rate.loggedInAdditionalRateTables
            "
            id="loggedInAdditionalRateTables"
            :options="context"
            @input="(e) => (rate.loggedInAdditionalRateTables = e)"
            :uid="'key'"
            :text="'value'"
          ></quest-multi-select>
        </row>
        <row
          id="memberDiscount"
          label="Members' discount (%)"
          tip="Discount applied to the members rate base rate AFTER the standard discount is applied. E.g. $200 - $20 (standard discount) - 10% (member discount) results in a $162 quote."
        >
          <quest-input
            :disabled="readonly || isInheritMode(overrides.loggedInRateTableId)"
            :value="
              isInheritMode(overrides.loggedInRateTableId)
                ? parent.loggedInDiscount
                : rate.loggedInDiscount
            "
            @input="(e) => (rate.loggedInDiscount = e)"
            id="memberDiscount"
            v-model="rate.loggedInDiscount"
          ></quest-input>
        </row>
      </slide>
      <div class="container">
        <row
          id="isDeal"
          label="Rate Type"
          tip="Standard rates are not promoted as special offers on the website. Promoted rate types have extra content delivered to the user for campaigns and greater conversion."
        >
          <quest-toggle
            :value="parent?.isDeal || rate.isDeal"
            @input="(e) => (rate.isDeal = e)"
            :disabled="readonly || parent"
            id="isDeal"
            checked="Promotional"
            unchecked="Standard"
          />
        </row>
      </div>
      <slide :toggle="parent?.isDeal || rate.isDeal">
        <row
          id="image"
          label="Promotional Image"
          tip="The hero image promoting this rate."
          :base="parent"
          :override="overrides.image"
          @toggle="(e) => (overrides.image = e)"
        >
          <quest-image
            :disabled="readonly || isInheritMode(overrides.image)"
            :image="isInheritMode(overrides.image) ? parent.image : rate.image"
            single
            @change="$refs.splash.open()"
            :rules="{
              [REQUIRED]: true,
            }"
          ></quest-image>
        </row>

        <quest-image-picker
          v-if="!readonly"
          ref="splash"
          title="Select rate image"
          :albumName="'deals'"
          :current="[rate.image]"
          @update="updateSplash"
          required
        ></quest-image-picker>

        <row
          id="slug"
          label="Slug"
          tip="The URL slug. You may leave this auto generated based on the rate name. The website will use this slug to identify the rate. Used for campaign direct linking."
          :base="parent"
          :override="overrides.slug"
          @toggle="(e) => (overrides.slug = e)"
        >
          <quest-input
            :value="isInheritMode(overrides.slug) ? parent.slug : rate.slug"
            :disabled="readonly || isInheritMode(overrides.slug)"
            @input="(e) => (rate.slug = e)"
            id="slug"
            :rules="{
              [REQUIRED]: true,
            }"
          ></quest-input>
        </row>

        <row
          id="description"
          label="Description"
          tip="A short description used to inform users of what makes this offer so special."
          :base="parent"
          :override="overrides.description"
          @toggle="(e) => (overrides.description = e)"
        >
          <quest-text-area
            :value="
              isInheritMode(overrides.description)
                ? parent.description
                : rate.description
            "
            @input="(e) => (rate.description = e)"
            :disabled="readonly || isInheritMode(overrides.description)"
            name="Description"
            id="description"
            :rules="{
              [REQUIRED]: true,
            }"
          ></quest-text-area>
        </row>

        <row
          id="disclaimer"
          label="Disclaimer"
          tip="Terms and conditions of use explicit to this rate."
          :base="parent"
          :override="overrides.disclaimer"
          @toggle="(e) => (overrides.disclaimer = e)"
        >
          <quest-text-area
            :disabled="readonly || isInheritMode(overrides.disclaimer)"
            name="disclaimer"
            id="disclaimer"
            @input="(e) => (rate.disclaimer = e)"
            :value="
              isInheritMode(overrides.disclaimer)
                ? parent.disclaimer
                : rate.disclaimer
            "
            :rules="{
              [REQUIRED]: true,
            }"
          ></quest-text-area>
        </row>

        <row
          id="metaTitle"
          label="SEO Title"
          tip="Title used for SEO. Can leave this blank to use the default naming convention used by the website."
          :base="parent"
          :override="overrides.metaTitle"
          @toggle="(e) => (overrides.metaTitle = e)"
        >
          <quest-input
            id="metaTitle"
            name="SEO title"
            @input="(e) => (rate.metaTitle = e)"
            :value="
              isInheritMode(overrides.metaTitle)
                ? parent.metaTitle
                : rate.metaTitle
            "
            :rules="{ [MAX]: { length: 60 } }"
            :disabled="readonly || isInheritMode(overrides.metaTitle)"
          ></quest-input>
        </row>

        <row
          id="metaDescription"
          label="SEO Description"
          tip="Summary used for SEO. Can leave this blank to use the default naming convention used by the website."
          :base="parent"
          :override="overrides.metaDescription"
          @toggle="(e) => (overrides.metaDescription = e)"
        >
          <quest-text-area
            @input="(e) => (rate.metaDescription = e)"
            :disabled="readonly || isInheritMode(overrides.metaDescription)"
            name="SEO description"
            id="metaDescription"
            :value="
              isInheritMode(overrides.metaDescription)
                ? parent.metaDescription
                : rate.metaDescription
            "
            :rules="{ [MAX]: { length: 120 } }"
          ></quest-text-area>
        </row>
      </slide>
      <div class="container">
        <row
          id="minOccupants"
          label="Occupants restriction"
          tip="Occupants restrictions set limitations on the minimum and maximum number of occupants a booking must be between to enable this rate. Note that external rate rules still apply. Leave minimum occupants (Min) blank to set no minimum restriction. Leave maximum occupants (Max) blank to set no maximum restrictions."
          :base="parent"
          :override="overrides.occupants"
          @toggle="(e) => (overrides.occupants = e)"
        >
          <div class="row">
            <div class="col-12 col-md-6">
              <quest-input
                id="minOccupants"
                @input="(e) => (rate.minOccupants = e)"
                :value="
                  isInheritMode(overrides.occupants)
                    ? parent.minOccupants
                    : rate.minOccupants
                "
                :disabled="readonly || isInheritMode(overrides.occupants)"
                ><template v-slot:prepend>Min</template></quest-input
              >
            </div>
            <div class="col-12 col-md-6">
              <quest-input
                id="maxOccupants"
                @input="(e) => (rate.maxOccupants = e)"
                :value="
                  isInheritMode(overrides.occupants)
                    ? parent.maxOccupants
                    : rate.maxOccupants
                "
                :disabled="readonly || isInheritMode(overrides.occupants)"
                ><template v-slot:prepend>Max</template></quest-input
              >
            </div>
          </div>
        </row>

        <row
          id="minStay"
          label="Stay restriction"
          tip="Stay restrictions set limitations on the minimum and maximum number of nights a booking must be between to enable this rate. Note that external rate rules still apply. Leave minimum stay nights (Min) blank set no minimum restriction. Leave maximum stay nights (Max) blank to set no maximum restrictions."
          :base="parent"
          :override="overrides.stay"
          @toggle="(e) => (overrides.stay = e)"
        >
          <div class="row">
            <div class="col-12 col-md-6">
              <quest-input
                @input="(e) => (rate.minStay = e)"
                id="minStay"
                :value="
                  isInheritMode(overrides.stay) ? parent.minStay : rate.minStay
                "
                :disabled="readonly || isInheritMode(overrides.stay)"
                ><template v-slot:prepend>Min</template></quest-input
              >
            </div>
            <div class="col-12 col-md-6">
              <quest-input
                id="maxStay"
                @input="(e) => (rate.maxStay = e)"
                :value="
                  isInheritMode(overrides.stay) ? parent.maxStay : rate.maxStay
                "
                :disabled="readonly || isInheritMode(overrides.stay)"
                ><template v-slot:prepend>Max</template></quest-input
              >
            </div>
          </div>
        </row>

        <row
          id="leadTimeType"
          label="Lead time type"
          tip="The type of lead time to restrict when the rate can be booked. Advance lead time determines how far in advane a booking must take place as a minimum. Last Minute leade time determines the maximum number of nights in the future a booking can be made for."
          :base="parent"
          :override="overrides.leadTimeType"
          @toggle="(e) => (overrides.leadTimeType = e)"
        >
          <quest-single-select
            :value="
              isInheritMode(overrides.leadTimeType)
                ? parent.leadTimeType
                : rate.leadTimeType
            "
            :disabled="readonly || isInheritMode(overrides.leadTimeType)"
            :options="leadTimeTypes"
            id="leadTimeType"
            @update="updateLeadTimeType"
          ></quest-single-select>
        </row>
      </div>

      <slide
        :toggle="
          isInheritMode(overrides.leadTimeType)
            ? parent.leadTimeType != 'None'
            : rate.leadTimeType != 'None'
        "
      >
        <row
          id="leadTimeRelease"
          label="Lead time release"
          :tip="leadTimeReleaseTip"
        >
          <quest-input
            :disabled="readonly || isInheritMode(overrides.leadTimeType)"
            name="Lead time release"
            :value="
              isInheritMode(overrides.leadTimeType)
                ? parent.leadTimeRelease
                : rate.leadTimeRelease
            "
            @input="(e) => (rate.leadTimeRelease = e)"
            id="leadTimeRelease"
            :rules="{
              [REQUIRED]: true,
            }"
          ></quest-input>
        </row>
      </slide>

      <div class="container">
        <row
          id="groupCode"
          label="Group code"
          tip="The group / corporate / promotional code that must be entered before the rate is displayed on the website. Leave blank to disable this feature. This code does NOT bypass other restrictions such as lead time."
          :base="parent"
          :override="overrides.groupCode"
          @toggle="(e) => (overrides.groupCode = e)"
        >
          <quest-input
            id="groupCode"
            @input="(e) => (rate.groupCode = e)"
            :value="
              isInheritMode(overrides.groupCode)
                ? parent.groupCode
                : rate.groupCode
            "
            :disabled="readonly || isInheritMode(overrides.groupCode)"
          ></quest-input>
        </row>

        <row
          id="discountDealType"
          label="Special discounts"
          tip="Special rules for a discount can be selected here."
          :base="parent"
          :override="overrides.discountDealType"
          @toggle="(e) => (overrides.discountDealType = e)"
        >
          <quest-single-select
            :disabled="readonly || isInheritMode(overrides.discountDealType)"
            :value="
              isInheritMode(overrides.discountDealType)
                ? parent.discountDealType
                : rate.discountDealType
            "
            :options="discountDealTypes"
            id="discountDealType"
            @update="updateDiscountDealType"
          ></quest-single-select>
        </row>
      </div>

      <slide
        :toggle="
          isInheritMode(overrides.discountDealType)
            ? parent.discountDealType == 'StayPay'
            : rate.discountDealType == 'StayPay'
        "
      >
        <row
          id="stayPayStay"
          label="Stay X Pay Y"
          tip="Sets the parameters for the Stay X Pay Y discount. Enter 'Stay' to set the number of nights that a booking must take place for. Enter 'Pay' for the number of nights fully discounted for the duration of the stay. Note that the cheapest nights are reduced to a fee of 0. This does not include extras."
          :base="parent"
          :override="overrides.stayXPayY"
          @toggle="(e) => (overrides.stayXPayY = e)"
        >
          <div class="row">
            <div class="col-12 col-md-6">
              <quest-input
                :disabled="readonly || isInheritMode(overrides.stayXPayY)"
                name="Stay"
                @input="(e) => (rate.stayPayStay = e)"
                id="stayPayStay"
                :value="
                  isInheritMode(overrides.stayXPayY)
                    ? parent.stayPayStay
                    : rate.stayPayStay
                "
                :rules="{
                  [REQUIRED]: true,
                }"
                ><template v-slot:prepend>Stay</template></quest-input
              >
            </div>
            <div class="col-12 col-md-6">
              <quest-input
                :disabled="readonly || isInheritMode(overrides.stayXPayY)"
                @input="(e) => (rate.stayPayPay = e)"
                id="stayPayPay"
                name="Pay"
                :value="
                  isInheritMode(overrides.stayXPayY)
                    ? parent.stayPayPay
                    : rate.stayPayPay
                "
                :rules="{
                  [REQUIRED]: true,
                }"
                ><template v-slot:prepend>Pay</template></quest-input
              >
            </div>
          </div>
        </row>
      </slide>

      <div class="container">
        <row
          id="deposit"
          label="Deposit"
          tip="The type of deposit required by the guest."
          :base="parent"
          :override="overrides.depositType"
          @toggle="(e) => (overrides.depositType = e)"
        >
          <quest-single-select
            :disabled="readonly || isInheritMode(overrides.depositType)"
            :value="
              isInheritMode(overrides.depositType)
                ? parent.depositType
                : rate.depositType
            "
            :options="depositTypes"
            id="deposit"
            @update="updateDeposit"
          ></quest-single-select>
        </row>
      </div>

      <slide
        :toggle="
          isInheritMode(overrides.depositType)
            ? parent.depositType != 'None' && parent.depositType != 'Full'
            : rate.depositType != 'None' && rate.depositType != 'Full'
        "
      >
        <row
          id="depositAmount"
          :label="depositAmountLabel"
          :tip="depositAmountTip"
        >
          <quest-input
            :disabled="readonly || isInheritMode(overrides.depositType)"
            :value="
              isInheritMode(overrides.depositType)
                ? parent.depositAmount
                : rate.depositAmount
            "
            @input="(e) => (rate.depositAmount = e)"
            name="Deposit amount"
            id="depositAmount"
            :rules="{
              [REQUIRED]: true,
            }"
          ></quest-input>
        </row>
      </slide>

      <div class="container">
        <row
          id="stayNightsToBlock"
          label="Stay nights to block"
          tip="The nights of the week to EXCLUDE from a stay"
          :base="parent"
          :override="overrides.stayNightsToBlock"
          @toggle="(e) => (overrides.stayNightsToBlock = e)"
        >
          <quest-multi-select
            :disabled="readonly || isInheritMode(overrides.stayNightsToBlock)"
            :value="
              isInheritMode(overrides.stayNightsToBlock)
                ? parent.stayNightsToBlock
                : rate.stayNightsToBlock
            "
            id="stayNightsToBlock"
            :options="stayNights"
            @input="(e) => (rate.stayNightsToBlock = e)"
          ></quest-multi-select>
        </row>

        <row
          v-if="!property"
          id="optIn"
          label="Property inclusion"
          tip="Whether the rate automatically opts in properties (default), or they must be opted in at the global rate level."
          :base="parent"
        >
          <quest-toggle
            id="optIn"
            :value="rate.optIn"
            @input="(e) => (rate.optIn = e)"
            checked="Opt in specific properties"
            unchecked="Opt in global/region"
          />
        </row>
      </div>

      <slide :toggle="!property && rate.optIn">
        <row
          id="optInOnlyProperties"
          label="Opted in properties"
          tip="Select all properties you wish to opt in to this rate. This list is specific to the region the group rate is contained in"
        >
          <quest-multi-select
            :value="rate.optInOnlyProperties"
            id="optInOnlyProperties"
            :options="optInOnlyProperties"
            @input="(e) => (rate.optInOnlyProperties = e)"
            uid="id"
            text="name"
          ></quest-multi-select>
        </row>
      </slide>

      <div class="container">
        <date-range-list
          id="sellDates"
          :base="parent"
          :override="overrides.sellDates"
          :disabled="readonly || isInheritMode(overrides.sellDates)"
          @toggle="(e) => (overrides.sellDates = e)"
          title="Sell on the following dates"
          :records="
            isInheritMode(overrides.sellDates)
              ? parent.sellDates
              : rate.sellDates
          "
          @replace="rate.sellDates = $event"
          :emptyText="`Rate will always sell.${
            !(readonly || isInheritMode(overrides.sellDates))
              ? ' Click here to add a range.'
              : ''
          }`"
          addText="Add additional sell date range"
        ></date-range-list>
        <date-range-list
          id="excludeSellDates"
          :base="parent"
          :override="overrides.excludeSellDates"
          :disabled="readonly || isInheritMode(overrides.excludeSellDates)"
          @toggle="(e) => (overrides.excludeSellDates = e)"
          title="Sell exclusion on the following dates"
          :records="
            isInheritMode(overrides.excludeSellDates)
              ? parent.excludeSellDates
              : rate.excludeSellDates
          "
          @replace="rate.excludeSellDates = $event"
          :emptyText="`Rate will follow sell date setup.${
            !(readonly || isInheritMode(overrides.excludeSellDates))
              ? ' Click here to add an exclusion.'
              : ''
          }`"
          addText="Add additional sell date exclusion"
        ></date-range-list>
        <date-range-list
          id="stayDates"
          :base="parent"
          :override="overrides.stayDates"
          :disabled="readonly || isInheritMode(overrides.stayDates)"
          @toggle="(e) => (overrides.stayDates = e)"
          title="Stay over the following dates"
          :records="
            isInheritMode(overrides.stayDates)
              ? parent.stayDates
              : rate.stayDates
          "
          @replace="rate.stayDates = $event"
          :emptyText="`Rate will always available with inventory.${
            !(readonly || isInheritMode(overrides.stayDates))
              ? ' Click here to add a range.'
              : ''
          }`"
          addText="Add additional stay date range"
        ></date-range-list>
        <date-range-list
          id="excludeStayDates"
          :base="parent"
          :override="overrides.excludeStayDates"
          :disabled="readonly || isInheritMode(overrides.excludeStayDates)"
          @toggle="(e) => (overrides.excludeStayDates = e)"
          title="Exclude stays on the following dates"
          :records="
            isInheritMode(overrides.excludeStayDates)
              ? parent.excludeStayDates
              : rate.excludeStayDates
          "
          @replace="rate.excludeStayDates = $event"
          :emptyText="`Rate will follow stay date setup.${
            !(readonly || isInheritMode(overrides.excludeStayDates))
              ? ' Click here to add an exclusion.'
              : ''
          }`"
          addText="Add additional stay date exclusion"
        ></date-range-list>

        <rate-extras-list
          v-if="property"
          id="extras"
          :property="property.id"
          :records="rate.extras"
          @replace="rate.extras = $event"
        ></rate-extras-list>
      </div>

      <template v-slot:modal-footer>
        <button class="btn btn-secondary btn-sm" type="button" @click="close">
          Cancel</button
        ><button
          class="btn btn-primary btn-sm"
          type="button"
          @click="save"
          v-if="!readonly"
        >
          Save
        </button>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import { assignDefined, error, slugify } from '@/helpers'
import { NexusRatesService, PropertyManagementService } from '@/services'
import RateEditorBase from './rate-editor-base'
import { Rate } from '@/models'

export default {
  name: 'rate-editor',
  extends: RateEditorBase,
  mounted() {
    if (this.account) {
      Promise.all([
        new PropertyManagementService().extract(this.account),
        new NexusRatesService().listTables(this.account),
      ]).then((data) => {
        if (data.every((p) => p)) {
          this.properties = data[0]
          this.context = data[1]
        } else {
          error('Error connecting to Quest Nexus. Please reload the wait.')
        }
      })
    } else {
      error('No account available to browse rates.')
    }
  },
  computed: {
    isInheritMode() {
      return (override) => !!this.parent && !override
    },
    rateNightsToBlock() {
      return rate.stayNightsToBlock?.map((x) => x.toString) || []
    },
  },
  methods: {
    updateSlug() {
      this.rate.slug = slugify(this.rate.name)
    },
    async read(id) {
      this.readonly = true
      this.edit(id)
    },
    /* NEW (MASTER) */
    async new(scope, country, state, property, room) {
      this.newScopeSetup(scope, country, state, property, room)
      this.baseSetup()
      this.mapRateFromScope()

      if (!this.property) {
        new PropertyManagementService()
          .getRegional(this.account, this.country?.key || null, this.state)
          .then((data) => {
            this.optInOnlyProperties = data
            this.$refs['modal'].show()
          })
          .catch((_) => error('Unable to access contextual properties'))
      } else {
        this.$refs['modal'].show()
      }
    },

    /* NEW (OVERRIDE) */
    async override(parent, property, room) {
      if (!parent || !property)
        throw `Parent and property id must be present to generate override`

      await new NexusRatesService()
        .get(this.account, parent)
        .then((data) => (this.parent = data))
        .catch((_) => {})

      if (this.parent == null) throw `Could not locate parent entity`

      this.property = this.properties?.find((x) => x.id == property)
      if (!this.property) {
        throw 'Property is not defined correctly'
      }

      if (room) {
        this.room = this.property.rooms.find((x) => x.id === room)
        if (!this.room) {
          throw 'Room is not defined correctly'
        }
      }

      this.parent.stayNightsToBlock = this.parent.stayNightsToBlock?.map((x) =>
        x.toString()
      )

      this.rate = Object.assign({}, this.parent, {
        id: null,
        status: this.RateStatus.OverrideInherit,
        parentId: this.parent.id,
      })
      this.rate.additionalRateTables = [
        ...(this.parent.additionalRateTables || []),
      ]
      this.rate.loggedInAdditionalRateTables = [
        ...(this.parent.loggedInAdditionalRateTables || []),
      ]
      this.rate.sellDates = [...(this.parent.sellDates || [])]
      this.rate.excludeSellDates = [...(this.parent.excludeSellDates || [])]
      this.rate.stayDates = [...(this.parent.stayDates || [])]
      this.rate.excludeStayDates = [...(this.parent.excludeStayDates || [])]

      this.mapRateFromScope()

      if (!this.rate.rateTableId) {
        this.rate.rateTableId = ''
      }

      if (!this.rate.loggedInRateTableId) {
        this.rate.loggedInRateTableId = ''
      }

      this.$refs['modal'].show()
    },

    /* EDIT EXISTING (MASTER) */
    async edit(id) {
      await new NexusRatesService()
        .get(this.account, id)
        .then((data) => (this.rate = Rate.from(data)))
        .catch((_) => {})

      this.rate.stayNightsToBlock = this.rate.stayNightsToBlock?.map((x) =>
        x.toString()
      )

      this.workspaceSetup()

      if (!this.rate.rateTableId) {
        this.rate.rateTableId = ''
      }

      if (!this.rate.loggedInRateTableId) {
        this.rate.loggedInRateTableId = ''
      }

      if (!this.property) {
        new PropertyManagementService()
          .getRegional(this.account, this.country?.key || null, this.state)
          .then((data) => {
            this.optInOnlyProperties = data
            this.$refs['modal'].show()
          })
          .catch((_) => error('Unable to access contextual properties'))
      } else {
        this.$refs['modal'].show()
      }
    },

    /* UPDATE (OVERRIDE) */
    async revise(id) {
      await new NexusRatesService()
        .get(this.account, id)
        .then((data) => (this.rate = Rate.from(data)))
        .catch((_) => {})

      this.rate.stayNightsToBlock = this.rate.stayNightsToBlock?.map((x) =>
        x.toString()
      )

      if (!this.rate.parentId)
        throw `Unable to determine parent identifier for override`

      await new NexusRatesService()
        .get(this.account, this.rate.parentId)
        .then((data) => (this.parent = Rate.from(data)))
        .catch((_) => {})

      if (!this.parent) throw `Unable to access parent data for override`

      if (this.parent.parentId) {
        await new NexusRatesService()
          .get(this.account, this.parent.parentId)
          .then((data) => (this.parent = assignDefined(this.parent, data)))
          .catch((_) => {})
      }

      let sellDates = [...(this.rate.sellDates || this.parent?.sellDates || [])]
      let excludeSellDates = [
        ...(this.rate.excludeSellDates || this.parent?.excludeSellDates || []),
      ]
      let stayDates = [...(this.rate.stayDates || this.parent?.stayDates || [])]
      let excludeStayDates = [
        ...(this.rate.excludeStayDates || this.parent?.excludeStayDates || []),
      ]
      let additionalRateTables = [
        ...((!!this.rate.rateTableId
          ? this.rate.additionalRateTables
          : this.parent?.additionalRateTables) || []),
      ]
      let loggedInAdditionalRateTables = [
        ...((!!this.rate.rateTableId
          ? this.rate.loggedInAdditionalRateTables
          : this.parent?.loggedInAdditionalRateTables) || []),
      ]

      this.extractOverrides()
      this.rate = assignDefined({}, this.parent, this.rate, {
        sellDates,
        excludeSellDates,
        stayDates,
        excludeStayDates,
        additionalRateTables,
        loggedInAdditionalRateTables,
      })
      this.workspaceSetup()

      if (!this.rate.rateTableId) {
        this.rate.rateTableId = ''
      }

      if (!this.rate.loggedInRateTableId) {
        this.rate.loggedInRateTableId = ''
      }

      this.$refs['modal'].show()
    },
  },
}
</script>

import AdminNexusHttpClient from './admin-nexus-http-client'

export default class QuoteService {
  constructor() {
    this.httpClient = new AdminNexusHttpClient('/admin/quote')
  }

  async explore(id, checkIn, checkOut, adults, children, isLoggedIn, code) {
    return await this.httpClient.post(`explore`, {
      id,
      checkIn,
      checkOut,
      isLoggedIn,
      code,
      groups: [{
        id: 1,
        adults,
        children
      }]
    })
  }
}
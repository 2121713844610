import AdminNexusHttpClient from './admin-nexus-http-client'

export default class RoomTypesManagementService {
  constructor() {
    this.httpClient = new AdminNexusHttpClient('/admin/room-types')
  }
  async list(property) {
    return await this.httpClient.get(`${property}`, '')
  }

  async update(roomTypes) {
    return await this.httpClient.post('', roomTypes)
  }
}

<template>
  <ValidationObserver ref="vobserver" v-slot="{ errors }">
    <quest-validation-summary :errors="errors"></quest-validation-summary>
    <quest-main
      id="host-form"
      :title="host && host.id ? host.name || '(untitled)' : 'Create Host'"
      :loading="loading"
      :saving="saving"
    >
      <template v-slot:head>
        <button
          @click="save()"
          class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
        >
          <font-awesome-icon
            icon="save"
            size="sm"
            class="text-white-50"
          ></font-awesome-icon>
          Save
        </button>
      </template>
      <template v-slot:body>
        <quest-card title="Host Information">
          <div class="row">
            <div class="col-md-6">
              <quest-input
                id="name"
                name="Name"
                label="Name"
                v-model="host.name"
                help=""
                :rules="{ [REQUIRED]: true }"
              ></quest-input>
            </div>
            <div class="col-md-6">
              <quest-input
                id="domain"
                label="Domain"
                v-model="host.domain"
                help=""
              ></quest-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <quest-input
                id="culture"
                label="Culture"
                v-model="host.culture"
                help=""
              ></quest-input>
            </div>
            <div class="col-md-6">
              <quest-single-select
                v-model="host.engineId"
                :options="engines"
                id="engine"
                label="Engine"
                placeholder="Select an engine"
                help=""
                @update="updateEngine"
              ></quest-single-select>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <quest-input
                id="regionIdentifier"
                label="Region Identifier"
                v-model="host.regionIdentifier"
                help=""
              ></quest-input>
            </div>
          </div>

          <div class="form-group">
            <label>Pattern</label>
            <div class="d-flex h-100">
              <quest-image
                :image="host.pattern"
                single
                @change="$refs.pattern.open()"
                isPattern
              ></quest-image>
            </div>
          </div>
          <quest-image-picker
            ref="pattern"
            title="Select pattern"
            albumName="patterns"
            :current="[host.pattern]"
            @update="updatePattern"
            manage
          ></quest-image-picker>

          <div class="row">
            <div class="col">
              <quest-multi-select
                id="countries"
                label="Countries"
                v-model="host.countries"
                :options="countries"
                help="A selection of associated countries"
                @update="updateCountries"
              ></quest-multi-select>
            </div>
          </div>

          <hr />

          <h5 class="mb-3">Currency</h5>
          <div class="row">
            <div class="col-md-6">
              <quest-input
                id="currencyCharacter"
                label="Character"
                v-model="host.currencyCharacter"
                help=""
              ></quest-input>
            </div>
            <div class="col-md-6">
              <quest-input
                id="currencyCode"
                label="Code"
                v-model="host.currencyCode"
                help=""
              ></quest-input>
            </div>
          </div>
          <hr />

          <h5 class="mb-3">Promotions</h5>

          <div class="row">
            <div class="col-md-6">
              <quest-input
                id="promoPrefix"
                label="Prefix"
                v-model="host.promoPrefix"
                help=""
              ></quest-input>
            </div>
            <div class="col-md-6">
              <quest-input
                id="promoCode"
                label="Code"
                v-model="host.promoCode"
                help=""
              ></quest-input>
            </div>
          </div>
        </quest-card>

        <quest-card
          title="Corporate social responsibility (CSR)"
          :collapsable="true"
          :collapsed="false"
          id="csrForm"
        >
          <div class="row">
            <div class="col-12">
              <quest-input
                id="csrTitle"
                label="Title"
                name="CSR Title"
                v-model="csr.title"
                help="A heading describing the default corporate social responsibility for this host"
                :rules="{ [REQUIRED]: true }"
              >
              </quest-input>
            </div>
            <div class="col-12">
              <quest-text-area
                id="csrCopy"
                name="CSR Summary"
                label="Summary"
                v-model="csr.copy"
                help="Summary of the default social responsibility and commitment for this host"
                :rules="{ [REQUIRED]: true, [MAX]: { length: 170 } }"
              ></quest-text-area>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Banner Image</label>
                <div class="d-flex h-100">
                  <quest-image
                    :image="csr.image"
                    single
                    @change="$refs.hero.open()"
                  ></quest-image>
                </div>
              </div>
            </div>
          </div>
          <quest-image-picker
            ref="hero"
            title="Select banner image"
            albumName="csr"
            :current="[csr.image]"
            @update="updateCSRImage"
          ></quest-image-picker>
        </quest-card>
      </template>
    </quest-main>
  </ValidationObserver>
</template>

<script>
import { REQUIRED, MAX } from '@/validation-types'
import { mapState } from 'vuex'
import { success, error } from '../helpers'
import {
  QuestCard,
  QuestMain,
  QuestStatCard,
  QuestValidationSummary,
} from './structure'
import {
  QuestInput,
  QuestTextArea,
  QuestEditor,
  QuestDatePicker,
  QuestToggle,
  QuestSingleSelect,
  QuestMultiSelect,
  QuestImage,
  QuestImagePicker,
} from './form'
import {
  SitesManagementService,
  EngineService,
  CSRManagementService,
} from '../services'

export default {
  name: 'host',
  components: {
    QuestCard,
    QuestMain,
    QuestInput,
    QuestTextArea,
    QuestEditor,
    QuestDatePicker,
    QuestToggle,
    QuestSingleSelect,
    QuestMultiSelect,
    QuestStatCard,
    QuestImage,
    QuestImagePicker,
    QuestValidationSummary,
  },
  mounted() {
    this.load()
  },
  computed: {
    ...mapState(['countries']),
  },
  data() {
    return {
      REQUIRED,
      MAX,
      loading: true,
      saving: false,
      host: {
        pattern: '',
      },
      csr: {},
      engines: [],
    }
  },
  methods: {
    updatePattern(selected) {
      this.host.pattern = selected
    },
    updateEngine(engine) {
      this.host.engineId = engine
    },
    updateCountries(countries) {
      this.host.countries = countries
    },
    updateCSRImage(selected) {
      this.csr.image = selected
    },
    async save() {
      if (!this.$refs.vobserver) return
      let validated = await this.$refs.vobserver.validate()
      if (!validated) {
        error('Please ensure all errors are resolved')
        return
      }
      this.saving = true

      Promise.all([
        new SitesManagementService().update(this.host),
        new CSRManagementService().setForHost({
          id: this.$route.params.id,
          payload: this.csr,
        }),
      ])
        .then((data) => {
          if (data.every((p) => p)) {
            this.load()
            success(`Updated host #`, this.host.name)
          } else {
            error(`Failed to update host #`, this.host.name)
          }
        })
        .finally((_) => {
          this.saving = false
        })
    },
    load() {
      if (this.$route.params.id) {
        Promise.all([
          new SitesManagementService().get(this.$route.params.id),
          new EngineService().list(),
          new SitesManagementService().getCSR(this.$route.params.id),
        ])
          .then((data) => {
            this.host = data[0]
            this.engines = data[1]
            this.csr = data[2]
          })
          .catch((_) => {
            this.host = null
            this.engines = []
            this.loading = false
          })
          .finally((_) => {
            this.loading = false
            this.$nextTick(() => {
              if (this.$refs && this.$refs.vobserver) {
                this.$refs.vobserver.validate()
              }
            })
          })
      } else {
        this.loading = false
      }
    },
  },
}
</script>

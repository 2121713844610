import { ApplicableType } from '@/router/applicable-type'
import { ExternalServiceType } from '@/models'

export function applicableRoute(applicable, accounts, account, isAdmin) {
  return (
    !applicable ||
    (applicable.includes(ApplicableType.ADMIN) && isAdmin) ||
    (applicable.includes(ExternalServiceType.RMS) &&
      accounts.find((x) => x.id === account)?.service ===
        ExternalServiceType.RMS) ||
    (applicable.includes(ExternalServiceType.TBB) &&
      accounts.find((x) => x.id === account)?.service ===
        ExternalServiceType.TBB) ||
    applicable.includes(ApplicableType.MULTI)
  )
}

import AdminNexusHttpClient from './admin-nexus-http-client'

export default class PurgeService {
  constructor() {
    this.httpClient = new AdminNexusHttpClient('/admin/purge')
  }

  async quotes(id) {
    return await this.httpClient.get(`quotes/${id}`)
  }
}

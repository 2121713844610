<template>
  <ValidationObserver ref="vobserver" v-slot="{}">
    <b-modal
      ref="modal"
      centered
      size="lg"
      :title="extra.id ? 'Manage extra' : 'Create extra'"
      body-class="p-0"
      @hidden="hidden"
    >
      <div class="container mt-3">
        <row
          id="name"
          label="Name"
          tip="The name of the 'extra' is publicly displayed to users. Maximum character limit of 60."
        >
          <quest-input
            id="name"
            v-model="extra.name"
            :rules="{
              [REQUIRED]: true,
              [MAX]: { length: 60 },
            }"
          ></quest-input>
        </row>

        <row
          id="description"
          label="Description"
          tip="A short description for the extra, publicly displayed to users. Maximum character limit of 300."
        >
          <quest-text-area
            v-model="extra.description"
            name="Description"
            id="description"
            :rules="{
              [REQUIRED]: true,
              [MAX]: { length: 300 },
            }"
          ></quest-text-area>
        </row>

        <row
          id="applicability"
          label="Applicable dates"
          tip="The dates the extra is avaialble for a given reservation. Leave blank to keep the start and/or end date open ended."
        >
          <div class="row">
            <div class="col-12 col-md-6">
              <quest-date-picker
                id="applicableFrom"
                label=""
                v-model="extra.applicableFrom"
                clearable="true"
                help=""
                icon="sign-in"
              ></quest-date-picker>
            </div>
            <div class="col-12 col-md-6">
              <quest-date-picker
                id="applicableTo"
                label=""
                v-model="extra.applicableTo"
                clearable="true"
                icon="sign-out"
                help=""
              ></quest-date-picker>
            </div>
          </div>
        </row>

        <row
          id="gross"
          label="Gross"
          tip="Gross cost per unit (based on selected interval). Gross price will be locked to unit price of the sundry if the selected 'requirement' has the flag 'Use sundry amount' enabled."
        >
          <quest-input
            id="gross"
            :value="
              requirement?.useSundryAmount &&
              (requirement.sundryId || extra.sundryId)
                ? sundry.unitPrice
                : extra.gross
            "
            :disabled="
              requirement?.useSundryAmount &&
              (requirement.sundryId || extra.sundryId)
            "
            @input="(e) => (extra.gross = e)"
            :rules="{
              [REQUIRED]: true,
            }"
            ><template v-slot:prepend>$</template></quest-input
          >
        </row>

        <row
          id="isDefault"
          label="Default"
          tip="The default setting for all rates given this extra. When opted in, all rates will have this extra as an optional charge. When opted out, a rate will explicitly require this extra to be added as a possible charge."
        >
          <quest-toggle
            v-model="extra.isDefault"
            id="isDefault"
            checked="Opt in"
            unchecked="Opt out"
          />
        </row>

        <row
          id="interval"
          label="Interval"
          tip="The interval to charge the gross unit price. If thee selected 'requirement' has the flag 'Charge required daily' enabled, interval types will be reduced to 'per night' options."
        >
          <quest-single-select
            placeholder="Select interval"
            v-model="extra.interval"
            :options="filteredIntervals"
            id="interval"
            @update="updateInterval"
            otext="label"
            :rules="{
              [REQUIRED]: true,
            }"
          ></quest-single-select>
        </row>

        <row
          id="image"
          label="Image"
          tip="An image promoting this extra to users."
        >
          <quest-image
            :image="extra.image"
            single
            @change="$refs.splash.open()"
          ></quest-image>
        </row>

        <quest-image-picker
          ref="splash"
          title="Select extra image"
          albumName="extras"
          :current="extra.image ? [extra.image] : []"
          @update="updateImage"
          required
          manage
        ></quest-image-picker>

        <row
          id="type"
          label="Extra Type"
          tip="The type of asset to map from the RMS system. An add-on type is a goods/service rented by a guest from an inventory pool. Requirements are fungible goods/services consumed by guests with unlimited supplies. Requirements have availability limitations imposed by RMS. Manual extras are not mapped to RMS and instead referenced in the OTA note. Packages are a group of sundries without an inventory pool."
        >
          <quest-single-select
            @update="updateExtraType"
            v-model="extra.type"
            :keepPlaceholder="false"
            :options="[
              {
                key: 0,
                value: 'Manual',
              },
              {
                key: 1,
                value: 'Requirement',
              },
              {
                key: 2,
                value: 'Addon Type',
              },
              {
                key: 3,
                value: 'Package',
              },
            ]"
          ></quest-single-select>
        </row>

        <row
          v-if="extra.type === 1"
          id="requirementId"
          label="Requirement"
          tip="Requirements are fungible goods/services consumed by guests with unlimited supplies. Requirements have availability limitations imposed by RMS."
        >
          <quest-single-select
            :keepPlaceholder="true"
            placeholder="No requirement selected"
            name="Requirement"
            v-model="extra.requirementId"
            :options="requirements"
            id="requirementId"
            @update="(e) => updateRequirement(e)"
            okey="id"
            otext="name"
            :rules="{
              [REQUIRED]: true,
            }"
          ></quest-single-select>
        </row>

        <row
          v-if="extra.type === 2"
          id="addOnTypeId"
          label="Addon Type"
          tip="An add-on type is a goods/service rented by a guest from an inventory pool."
        >
          <quest-single-select
            :keepPlaceholder="true"
            name="Add-on type"
            placeholder="No add-on selected"
            v-model="extra.addOnTypeId"
            :options="addons"
            id="addOnTypeId"
            @update="(e) => updateAddOnType(e)"
            :rules="{
              [REQUIRED]: true,
            }"
          ></quest-single-select>
        </row>

        <row
          v-if="extra.addOnTypeId"
          id="sundryId"
          label="Sundry"
          tip="Sundries identify the charge/line item linked to the extra, similar to a 'rate'."
        >
          <quest-single-select
            :keepPlaceholder="true"
            name="Sundry"
            placeholder="No sundry selected"
            :value="extra.sundries.length ? extra.sundries[0] : ''"
            :options="sundries"
            id="sundryId"
            @update="(e) => updateSundry(e)"
            okey="id"
            otext="name"
            :rules="{
              [REQUIRED]: true,
            }"
          ></quest-single-select>
        </row>

        <row
          v-if="extra.type === 3"
          id="sundries"
          label="Sundries"
          tip="Sundries identify the charge/line item linked to the extra, similar to a 'rate'. Packages may contain multiple sundries."
        >
          <quest-multi-select
            id="sundries"
            v-model="extra.sundries"
            :options="sundries"
            @update="(e) => updateSundries(e)"
            uid="id"
            text="name"
          ></quest-multi-select>
        </row>
      </div>

      <template v-slot:modal-footer>
        <button class="btn btn-secondary btn-sm" type="button" @click="close">
          Cancel</button
        ><button class="btn btn-primary btn-sm" type="button" @click="save">
          Save
        </button>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import {
  ExtrasService,
  PropertyManagementService,
  SundryService,
  AddonService,
  RequirementService,
} from '@/services'

import { mapState } from 'vuex'
import { success, error } from '@/helpers'
import { REQUIRED, MAX } from '@/validation-types'

import {
  QuestMain,
  QuestTable,
  QuestPager,
  QuestCard,
  QuestModal,
  QuestPreloader,
} from '../structure'

import {
  QuestInput,
  QuestSingleSelect,
  QuestToggle,
  QuestTextArea,
  QuestMultiSelect,
  QuestDatePicker,
  QuestImage,
  QuestImagePicker,
  Row,
} from '../form'

import { ExtraInterval, ExtraIntervalListing } from '@/models'

export default {
  name: 'extra-editor',
  components: {
    QuestMain,
    QuestTable,
    QuestPager,
    QuestCard,
    QuestModal,
    QuestPreloader,
    QuestInput,
    QuestSingleSelect,
    QuestToggle,
    QuestTextArea,
    QuestMultiSelect,
    QuestDatePicker,
    QuestImage,
    QuestImagePicker,
    Row,
  },
  data() {
    return {
      /* IMPORTS */
      REQUIRED,
      MAX,

      /* PROPERTIES */
      saving: false,
      property: null,
      extra: {
        type: 0,
        image: '',
        gross: 0,
        interval: ExtraInterval.Booking,
        propertyId: null,
        requirementId: '',
        addOnTypeId: '',
        sundries: [],
      },

      /* DYNAMIC COLLECTIONS */
      sundries: [],
      addons: [],
      requirements: [],
    }
  },
  mounted() {
    if (this.account) {
      Promise.all([new PropertyManagementService().extract(this.account)]).then(
        (data) => {
          if (data.every((p) => p)) {
            this.properties = data[0]
            this.context = data[1]
          } else {
            error('Error connecting to Quest Nexus. Please reload the wait.')
          }
        }
      )
    } else {
      error('No account available to browse extras.')
    }
  },
  computed: {
    ...mapState(['accounts', 'account']),
    filteredIntervals() {
      if (this.requirement?.chargeRequiredDaily) {
        let arr = [
          ExtraInterval.Booking,
          ExtraInterval.Room,
          ExtraInterval.Person,
        ]
        if (arr.includes(this.extra.interval)) {
          this.extra.interval = ExtraInterval.RoomNight
        }
        return ExtraIntervalListing.filter((x) => !arr.includes(x.key))
      }
      return ExtraIntervalListing
    },
    requirement() {
      if (this.extra.requirementId && this.requirements.length) {
        return (
          this.requirements.find((x) => x.id === this.extra.requirementId) ||
          null
        )
      }
      return null
    },
    sundry() {
      if (this.sundries.length) {
        if (this.extra.sundryId) {
          return this.sundries.find((x) => x.id === this.extra.sundryId) || null
        } else if (this.requirement?.sundryId) {
          return (
            this.sundries.find((x) => x.id === this.requirement.sundryId) ||
            null
          )
        }
      }
      return null
    },
  },
  methods: {
    updateExtraType(selected) {
      this.extra.type = selected
      this.extra.requirementId = ''
      this.extra.addOnTypeId = ''
      this.extra.sundries = []
    },
    load(property) {
      Promise.all([
        new PropertyManagementService().getProperty(property),
        new SundryService().list(property),
        new AddonService().list(property),
        new RequirementService().list(property),
      ]).then((data) => {
        if (data.every((p) => p)) {
          this.properties = data[0]
          this.sundries = data[1]
          this.addons = data[2]
          this.requirements = data[3]
          this.$refs['modal'].show()
        } else {
          error('Error connecting to Quest Nexus. Please reload the wait.')
        }
      })
    },
    updateInterval(selected) {
      this.extra.interval = selected
    },
    updateImage(selected) {
      this.extra.image = selected
    },
    updateRequirement(selected) {
      this.extra.requirementId = selected
    },
    updateAddOnType(selected) {
      this.extra.addOnTypeId = selected
    },
    updateSundry(selected) {
      this.extra.sundries = !!selected ? [selected] : []
    },
    updateSundries(selected) {
      this.extra.sundries = selected
    },
    /* NEW (MASTER) */
    async new(property) {
      if (property == undefined || property == null) {
        throw 'Property is not defined correctly'
      }
      this.extra.propertyId = property
      this.load(property)
    },
    /* EDIT EXISTING (MASTER) */
    async edit(id) {
      await new ExtrasService()
        .get(id)
        .then(async (data) => {
          this.extra = data
          this.load(this.extra.propertyId)
        })
        .catch((_) => {
          error('Error loading extra')
        })
    },
    async save() {
      if (!this.$refs.vobserver) return
      let validated = await this.$refs.vobserver.validate()
      if (!validated) {
        error('Please ensure all errors are resolved')
        return
      }
      this.$emit('saving')
      if (this.extra.id) {
        this.update()
      } else {
        this.create()
      }
      return false
    },
    /* Set defaults */
    reset() {
      this.saving = false
      this.property = null
      this.extra = {
        type: 0,
        gross: 0,
        image: '',
        interval: ExtraInterval.Booking,
        propertyId: null,
        requirementId: '',
        addOnTypeId: '',
        sundryId: '',
      }
    },
    /* Manage modal */
    hidden() {
      this.reset()
    },
    close() {
      this.$refs['modal'].hide()
    },
    /* SAVE (NEW) */
    async create() {
      await new ExtrasService()
        .add(this.extra)
        .then((data) => {
          if (typeof data === 'object' && !!data.value) {
            this.$emit('saved')
            this.close()
            success(`Created rate #`, this.extra.name)
          } else {
            error(`Failed to create rate #`, this.extra.name)
          }
        })
        .then((_) => {
          this.$emit('failed')
        })
    },
    /* SAVE (UPDATE) */
    async update() {
      await new ExtrasService()
        .update(this.extra)
        .then((data) => {
          if (data === true) {
            this.$emit('saved')
            this.close()
            success(`Updated rate #`, this.extra.name)
          } else {
            error(`Failed to update rate #`, this.extra.name)
          }
        })
        .then((_) => {
          this.$emit('failed')
        })
    },
  },
}
</script>

import {
  Preloads,
  Preload,
  Properties,
  Property,
  Deals,
  Deal,
  DealCreateSelection,
  DealCreateGroup,
  DealCreateLocal,
  PropertyLink,
  Hosts,
  Host,
  Engines,
  Engine,
  Themes,
  Amenities,
  Snippets,
  ProofPoints,
  ProofPoint,
  Snippet,
  Theme,
  Localities,
  Facilities,
  Announcements,
  Announcement,
  ExternalAccounts,
  ExternalAccount,
  RateListing,
  ExtraListing,
} from '../components'

import * as PERMISSION from './permission-types'
import { ApplicableType } from './applicable-type'
import { ExternalServiceType } from '@/models'

const PROPERTY_ROUTES = [
  {
    path: 'properties/create',
    component: PropertyLink,
    name: 'properties-add',
    meta: {
      permission: PERMISSION.Properties,
      applicable: [ExternalServiceType.TBB],
    },
  },
  {
    path: 'properties/:id',
    component: Property,
    name: 'properties-edit',
    meta: {
      permission: PERMISSION.Properties,
      applicable: [ExternalServiceType.RMS, ExternalServiceType.TBB],
    },
  },
  {
    path: 'properties',
    component: Properties,
    name: 'properties',
    meta: {
      permission: PERMISSION.Properties,
      applicable: [ExternalServiceType.RMS, ExternalServiceType.TBB],
    },
  },
]

const DEAL_ROUTES = [
  {
    path: 'deals/create',
    component: DealCreateSelection,
    name: 'deals-add',
    meta: {
      permission: PERMISSION.Deals,
      applicable: [ExternalServiceType.TBB],
    },
  },
  {
    path: 'deals/create/group',
    component: DealCreateGroup,
    name: 'deals-add-group',
    meta: {
      permission: PERMISSION.Deals,
      applicable: [ExternalServiceType.TBB],
    },
  },
  {
    path: 'deals/create/local',
    component: DealCreateLocal,
    name: 'deals-add-local',
    meta: {
      permission: PERMISSION.Deals,
      applicable: [ExternalServiceType.TBB],
    },
  },
  {
    path: 'deals/:id',
    component: Deal,
    name: 'deals-edit',
    meta: {
      permission: PERMISSION.Deals,
      applicable: [ExternalServiceType.TBB],
    },
  },
  {
    path: 'deals',
    component: Deals,
    name: 'deals',
    meta: {
      permission: PERMISSION.Deals,
      applicable: [ExternalServiceType.TBB],
    },
  },
]

const THEME_ROUTES = [
  {
    path: 'themes/create',
    component: Theme,
    name: 'themes-add',
    meta: {
      permission: PERMISSION.Themes,
      applicable: [ApplicableType.MULTI],
    },
  },
  {
    path: 'themes/:id',
    component: Theme,
    name: 'themes-edit',
    meta: {
      permission: PERMISSION.Themes,
      applicable: [ApplicableType.MULTI],
    },
  },
  {
    path: 'themes',
    component: Themes,
    name: 'themes',
    meta: {
      permission: PERMISSION.Themes,
      applicable: [ApplicableType.MULTI],
    },
  },
]

const ANNOUNCEMENT_ROUTES = [
  {
    path: 'announcements/create',
    component: Announcement,
    name: 'announcements-add',
    meta: {
      permission: PERMISSION.Announcements,
      applicable: [ApplicableType.MULTI],
    },
  },
  {
    path: 'announcements/:id',
    component: Announcement,
    name: 'announcements-edit',
    meta: {
      permission: PERMISSION.Announcements,
      applicable: [ApplicableType.MULTI],
    },
  },
  {
    path: 'announcements',
    component: Announcements,
    name: 'announcements',
    meta: {
      permission: PERMISSION.Announcements,
      applicable: [ApplicableType.MULTI],
    },
  },
]

const PROOF_POINT_ROUTES = [
  {
    path: 'proof-points/create',
    component: ProofPoint,
    name: 'proof-points-add',
    meta: {
      permission: PERMISSION.ProofPoints,
      applicable: [ApplicableType.MULTI],
    },
  },
  {
    path: 'proof-points/:id',
    component: ProofPoint,
    name: 'proof-points-edit',
    meta: {
      permission: PERMISSION.ProofPoints,
      applicable: [ApplicableType.MULTI],
    },
  },
  {
    path: 'proof-points',
    component: ProofPoints,
    name: 'proof-points',
    meta: {
      permission: PERMISSION.ProofPoints,
      applicable: [ApplicableType.MULTI],
    },
  },
]

const SNIPPET_ROUTES = [
  {
    path: 'snippets/create',
    component: Snippet,
    name: 'snippets-add',
    meta: {
      permission: PERMISSION.Snippets,
      applicable: [ApplicableType.MULTI],
    },
  },
  {
    path: 'snippets/:id',
    component: Snippet,
    name: 'snippets-edit',
    meta: {
      permission: PERMISSION.Snippets,
      applicable: [ApplicableType.MULTI],
    },
  },
  {
    path: 'snippets',
    component: Snippets,
    name: 'snippets',
    meta: {
      permission: PERMISSION.Snippets,
      applicable: [ApplicableType.MULTI],
    },
  },
]

const AMENITY_ROUTES = [
  {
    path: 'amenities',
    component: Amenities,
    name: 'amenities',
    meta: {
      permission: PERMISSION.Amenities,
      applicable: [ApplicableType.MULTI],
    },
  },
]

const FACILITY_ROUTES = [
  {
    path: 'facilities',
    component: Facilities,
    name: 'facilities',
    meta: {
      permission: PERMISSION.Facilities,
      applicable: [ApplicableType.MULTI],
    },
  },
]

const LOCALITY_ROUTES = [
  {
    path: 'localities',
    component: Localities,
    name: 'localities',
    meta: {
      permission: PERMISSION.Localities,
      applicable: [ApplicableType.MULTI],
    },
  },
]

const PRELOAD_ROUTES = [
  {
    path: 'preload/create',
    component: Preload,
    name: 'preload-add',
    meta: {
      permission: PERMISSION.Preloads,
      applicable: [ExternalServiceType.TBB],
    },
  },
  {
    path: 'preload/:id',
    component: Preload,
    name: 'preload-edit',
    meta: {
      permission: PERMISSION.Preloads,
      applicable: [ExternalServiceType.TBB],
    },
  },
  {
    path: 'preload',
    component: Preloads,
    name: 'preload',
    meta: {
      permission: PERMISSION.Preloads,
      applicable: [ExternalServiceType.TBB],
    },
  },
]

const HOST_ROUTES = [
  {
    path: 'hosts/:id',
    component: Host,
    name: 'host-edit',
    meta: {
      permission: PERMISSION.Hosts,
      applicable: [ApplicableType.ADMIN],
    },
  },
  {
    path: 'hosts',
    component: Hosts,
    name: 'hosts',
    meta: {
      permission: PERMISSION.Hosts,
      applicable: [ApplicableType.ADMIN],
    },
  },
]

const ENGINE_ROUTES = [
  {
    path: 'engines/:id',
    component: Engine,
    name: 'engine-edit',
    meta: {
      permission: PERMISSION.Engines,
      applicable: [ExternalServiceType.TBB],
    },
  },
  {
    path: 'engines',
    component: Engines,
    name: 'engines',
    meta: {
      permission: PERMISSION.Engines,
      applicable: [ExternalServiceType.TBB],
    },
  },
]

const EXTERNAL_ACCOUNT_ROUTES = [
  {
    path: 'external-accounts/create',
    component: ExternalAccount,
    name: 'external-accounts-add',
    meta: {
      permission: PERMISSION.ExternalAccounts,
      applicable: [ApplicableType.ADMIN],
    },
  },
  {
    path: 'external-accounts/:id',
    component: ExternalAccount,
    name: 'external-accounts-edit',
    meta: {
      permission: PERMISSION.ExternalAccounts,
      applicable: [ApplicableType.ADMIN],
    },
  },
  {
    path: 'external-accounts',
    component: ExternalAccounts,
    name: 'external-accounts',
    meta: {
      permission: PERMISSION.ExternalAccounts,
      applicable: [ApplicableType.ADMIN],
    },
  },
]

const RATE_ROUTES = [
  {
    path: 'rates',
    component: RateListing,
    name: 'rates',
    meta: {
      permission: PERMISSION.Rates,
      applicable: [ExternalServiceType.RMS],
    },
  },
]

const EXTRA_ROUTES = [
  {
    path: 'extras',
    component: ExtraListing,
    name: 'extras',
    meta: {
      permission: PERMISSION.Extras,
      applicable: [ExternalServiceType.RMS],
    },
  },
]
export default [
  ...LOCALITY_ROUTES,
  ...FACILITY_ROUTES,
  ...AMENITY_ROUTES,
  ...SNIPPET_ROUTES,
  ...PROOF_POINT_ROUTES,
  ...THEME_ROUTES,
  ...DEAL_ROUTES,
  ...PROPERTY_ROUTES,
  ...PRELOAD_ROUTES,
  ...ENGINE_ROUTES,
  ...HOST_ROUTES,
  ...ANNOUNCEMENT_ROUTES,
  ...EXTERNAL_ACCOUNT_ROUTES,
  ...RATE_ROUTES,
  ...EXTRA_ROUTES,
]

<template>
  <div class="border rounded p-3 mb-3 bg-white" v-if="rates.length">
    <b-table
      :items="rates"
      :fields="fields"
      :tbody-tr-class="rowClass"
      small
      hover
      fixed
      stacked="md"
      borderless
      caption-top
    >
      <template #table-caption>{{ caption }}</template>
      <template #cell(name)="data">
        <a
          class="pointer"
          v-if="!data.item.isInherited"
          @click="
            $emit(!data.item.isOverride ? 'edit' : 'revise', data.value.id)
          "
          >{{ data.value.name }}</a
        >
        <a
          class="pointer text-gray-400"
          v-if="data.item.isInherited"
          @click="
            $emit(
              'read',
              data.item.on != RateStatus.Inherit
                ? data.value.id
                : data.item.master
            )
          "
          >{{ data.value.name }}</a
        >
      </template>
      <template #cell(groupCode)="data">
        {{
          data.item.room
            ? data.item.room.groupCode
            : data.item.property
            ? data.item.property.groupCode
            : data.item.group.groupCode
        }}
      </template>
      <template #cell(status)="data">
        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <a
            v-if="activeInherit(data.item)"
            :id="data.item.id + '-status-popover-target'"
            class="pointer text-secondary mr-1"
          >
            <font-awesome-icon
              :icon="['fas', 'info-circle']"
              fixed-width
            ></font-awesome-icon>
            <b-popover
              :target="data.item.id + '-status-popover-target'"
              triggers="hover"
              placement="top"
            >
              <template #title>Inherited</template>
              This status has been inherited by the rate
            </b-popover>
          </a>
          <span :class="activeClass(data.value)">{{
            activeStatus(data.item)
          }}</span>
        </div>
      </template>
      <template #cell(context)="data">
        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <a
            :id="data.item.id + '-context-popover-target'"
            class="pointer text-secondary"
          >
            <font-awesome-icon
              :icon="['fas', 'info-circle']"
              fixed-width
            ></font-awesome-icon>
          </a>
          <b-popover
            :target="data.item.id + '-context-popover-target'"
            triggers="hover"
            placement="top"
          >
            <template #title>{{
              contextTitle(
                data.item.context.type,
                data.item.isInherited,
                data.item.isOverride
              )
            }}</template>
            {{
              contextSummary(
                data.item.context.type,
                data.item.isInherited,
                data.item.context.label,
                data.item.isOverride
              )
            }}
          </b-popover>
          {{ data.item.context.label }}
        </div>
      </template>
      <template #cell(options)="data">
        <b-dropdown no-caret variant="link" class="b-dropdown-p-0">
          <template #button-content>
            <font-awesome-icon
              icon="ellipsis-v"
              size="sm"
              fixed-width
              class="text-gray-400"
            ></font-awesome-icon>
          </template>
          <b-dropdown-header>Actions</b-dropdown-header>
          <b-dropdown-item
            v-if="data.item.isInherited"
            @click="send('override', data.item)"
            >Override</b-dropdown-item
          >
          <b-dropdown-item
            v-if="!data.item.isInherited"
            @click="
              $emit(!data.item.isOverride ? 'edit' : 'revise', data.item.id)
            "
            >Update</b-dropdown-item
          >
          <b-dropdown-item
            v-if="
              data.item.on !== RateStatus.Enabled &&
              !data.item.isInherited &&
              !data.item.isOverride
            "
            @click="send('enable', data.item)"
            >Enable</b-dropdown-item
          >
          <b-dropdown-item
            v-if="data.item.on === RateStatus.Enabled && !data.item.isInherited"
            @click="send('disable', data.item)"
            >Disable</b-dropdown-item
          >
          <b-dropdown-item
            v-if="
              data.item.on === RateStatus.OptOut ||
              data.item.on === RateStatus.OverrideOptOut
            "
            @click="send('optIn', data.item)"
            >Opt in</b-dropdown-item
          >
          <b-dropdown-item
            v-if="
              data.item.on !== RateStatus.OptOut &&
              data.item.on !== RateStatus.OverrideOptOut &&
              (data.item.isOverride || data.item.isInherited)
            "
            @click="send('optOut', data.item)"
            >Opt out</b-dropdown-item
          >
          <template v-if="!data.item.isInherited">
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item-button
              @click="send('delete', data.item)"
              text="danger"
              variant="danger"
              >Delete</b-dropdown-item-button
            >
          </template>
        </b-dropdown>
      </template>
    </b-table>
  </div>
</template>

<script>
import { RateStatus, RateStatusConvert } from '@/models'
export default {
  name: 'rate-listing-table',
  props: {
    caption: { required: true },
    items: { required: true },
    fields: { required: true },
    term: { default: '' },
  },
  data() {
    return {
      RateStatus,
      RateStatusConvert,
    }
  },
  computed: {
    rates() {
      return this.items.filter(
        (x) =>
          x.name.toLowerCase().includes(this.term.toLowerCase()) ||
          (x.group?.groupCode &&
            x.group?.groupCode
              .toLowerCase()
              .includes(this.term.toLowerCase())) ||
          (x.property?.groupCode &&
            x.property?.groupCode
              .toLowerCase()
              .includes(this.term.toLowerCase())) ||
          (x.room?.groupCode &&
            x.room?.groupCode.toLowerCase().includes(this.term.toLowerCase()))
      )
    },
  },
  methods: {
    send(event, item) {
      this.$emit(event, {
        id: item.master !== item.id ? item.id : null,
        master: item.master,
        name: item.name,
      })
    },
    rowClass(item, type) {
      if (item && type === 'row') {
        if (item.isInherited === true && !item.isOverride) {
          return 'text-gray-400'
        } else {
          return null
        }
      } else {
        return null
      }
    },
    activeClass(value) {
      if (
        [
          RateStatus.Disabled,
          RateStatus.OptOut,
          RateStatus.OverrideOptOut,
        ].includes(value)
      ) {
        return 'text-danger'
      } else {
        return 'text-success'
      }
    },
    contextTitle(value, isInherited, isOverride) {
      return (
        value.substring(0, 1).toUpperCase() +
        value.substring(1) +
        ' Context' +
        (isInherited ? ' (Inherent)' : '') +
        (isOverride ? ' (Override)' : '')
      )
    },
    contextSummary(value, isInherited, label, isOverride) {
      if (value === 'global') {
        if (isInherited) {
          return 'This rate has been inherited as it covers all properties and rooms within this group/account'
        } else {
          return 'This rate will be inherited by all properties and their rooms within this group/account'
        }
      } else if (value === 'country' || value === 'state') {
        if (isInherited) {
          return `This rate has been inherited as it covers all properties and rooms within '${label}'.`
        } else {
          return `This rate will be inherited by all properties and their rooms within '${label}'.`
        }
      } else if (value === 'property') {
        if (isInherited) {
          return (
            `This rate has been inherited as it covers all rooms within '${label}'.` +
            (isOverride
              ? ' It inherits base behaviour from a group-wide rate.'
              : '')
          )
        } else {
          return (
            `This rate will be inherited by all rooms within '${label}'.` +
            (isOverride
              ? ' It inherits base behaviour from a group-wide rate.'
              : '')
          )
        }
      } else if (value === 'room') {
        return `This rate is only applicable to the room '${label}' in this property. It inherits base behaviour from the a property-wide rate.`
      } else {
        return 'ERROR'
      }
    },
    activeStatus(item) {
      return ![RateStatus.OptOut, RateStatus.OverrideOptOut].includes(
        item.status
      )
        ? RateStatusConvert[item.status]
        : 'Opted out'
    },
    activeInherit(item) {
      return (
        (item.isInherited || item.on === RateStatus.OverrideInherit) &&
        ![RateStatus.OptOut, RateStatus.OverrideOptOut].includes(item.on)
      )
    },
  },
}
</script>

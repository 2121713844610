<template>
  <nav
    class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow"
  >
    <button
      id="sidebarToggleTop"
      class="btn btn-link d-md-none rounded-circle mr-3"
      @click="$emit('toggle')"
    >
      <font-awesome-icon :icon="['fas', 'bars']"></font-awesome-icon>
    </button>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item no-arrow" v-if="showDashboard">
        <a
          @click="$router.push({ name: 'dashboard' })"
          class="nav-link text-gray-600 pointer"
        >
          <span class="mr-2 d-inline">Dashboard</span>
          <font-awesome-icon
            :icon="['fas', 'tachometer-alt']"
            fixed-width
          ></font-awesome-icon>
        </a>
      </li>

      <li class="nav-item no-arrow">
        <a
          @click="$router.push({ name: 'help' })"
          class="nav-link text-gray-600 pointer"
        >
          <span class="mr-2 d-md-inline d-none">Contact support</span>
          <font-awesome-icon
            :icon="['fas', 'user-headset']"
            fixed-width
          ></font-awesome-icon>
        </a>
      </li>

      <b-nav-item-dropdown
        no-caret
        variant="link"
        right
        v-if="!!account && accounts.length > 0"
      >
        <template #button-content>
          <font-awesome-icon
            class="text-gray-600"
            :icon="['fas', 'link']"
            fixed-width
          ></font-awesome-icon>
          <span
            class="text-gray-600 d-md-inline d-none ml-2"
            v-if="currentAccount"
            ><b-badge>{{ currentAccount.service }}</b-badge
            >&nbsp;<span>{{ currentAccount.label }}</span></span
          >
          <span
            class="text-danger d-md-inline d-none ml-2"
            v-if="!currentAccount"
            >ERROR</span
          >
        </template>
        <b-dropdown-item
          v-for="item in accounts"
          :key="item.key"
          @click="setAccount(item.id)"
        >
          <b-badge>{{ item.service }}</b-badge
          >&nbsp;<span>{{ item.label }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>

      <li class="nav-item no-arrow">
        <a @click="logout" class="nav-link text-gray-600 pointer">
          <span class="mr-2 d-md-inline d-none">Log out</span>
          <font-awesome-icon
            :icon="['fas', 'sign-out']"
            fixed-width
          ></font-awesome-icon>
        </a>
      </li>
    </ul>
  </nav>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { SET_AUTHENTICATED } from '@/store/action-types'

export default {
  name: 'admin-header',
  props: {
    showDashboard: Boolean,
  },
  methods: {
    ...mapActions([SET_AUTHENTICATED]),
    setAccount(value) {
      this.$emit('changeAccount', value)
    },
    logout() {
      this.SET_AUTHENTICATED(null)
      this.$router.push({ name: 'login' })
    },
  },
  computed: {
    ...mapState(['accounts', 'account']),
    currentAccount() {
      return this.accounts?.find((x) => x.id === this.account)
    },
  },
}
</script>

import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faTimes,
  faMale,
  faChild,
  faBars as fasBars,
  faSearch as fasSearch,
  faSync as fasSync,
  faCalendarPlus,
  faCalendarEdit,
  faPlus,
  faEllipsisV,
  faSave,
  faQuoteRight,
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faFire,
  faEdit,
  faAngleRight,
  faNewspaper,
  faSignIn,
  faSignOut,
  faSort,
  faCogs,
  faBadgeDollar,
  faDownload,
  faSpinner,
  faPalette,
  faHotel,
  faLink,
  faTags,
  faBell,
  faCoffee,
  faFilter,
  faImage,
  faCalendar,
  faEnvelope,
  faUserHeadset,
  faMapMarkerAlt,
  faPhone,
  faGlobeAsia,
  faUser,
  faIcons,
  faWifi,
  faWifiSlash,
  faStars,
  faLock,
  faSearchDollar,
  faUnlock,
  faTachometerAlt,
  faClone,
  faFlask,
  faInfoCircle,
  faExclamation,
  faCommentAltExclamation,
} from '@fortawesome/pro-solid-svg-icons'

import {
  faSearch as farSearch,
  faBold,
  faItalic,
  faStrikethrough,
  faUnderline,
  faCode,
  faParagraph,
  faH1,
  faH2,
  faHeading,
  faClock,
  faH3,
  faListUl,
  faListOl,
} from '@fortawesome/pro-regular-svg-icons'

import { faBars as falBars } from '@fortawesome/pro-light-svg-icons'

import { faSync as fadSync } from '@fortawesome/pro-duotone-svg-icons'

import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

/* Register Font Awesome library */
library.add(
  faMale,
  faChild,
  faTimes,
  faClone,
  faInfoCircle,
  fasSearch,
  faStars,
  faLock,
  faUnlock,
  farSearch,
  faFlask,
  falBars,
  faWifiSlash,
  fasBars,
  faWifi,
  fadSync,
  faUserHeadset,
  faFacebook,
  faInstagram,
  faSearchDollar,
  faClock,
  fasSync,
  faSignIn,
  faSignOut,
  faPalette,
  faEnvelope,
  faHotel,
  faIcons,
  faImage,
  faNewspaper,
  faCogs,
  faCoffee,
  faTags,
  faEdit,
  faPhone,
  faFilter,
  faBold,
  faItalic,
  faStrikethrough,
  faBell,
  faSort,
  faSpinner,
  faFire,
  faUnderline,
  faExclamation,
  faMapMarkerAlt,
  faLink,
  faCode,
  faBadgeDollar,
  faParagraph,
  faH1,
  faH2,
  faH3,
  faHeading,
  faListUl,
  faListOl,
  faCalendarPlus,
  faCalendarEdit,
  faPlus,
  faEllipsisV,
  faCommentAltExclamation,
  faSave,
  faAngleDown,
  faQuoteRight,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faDownload,
  faCalendar,
  faGlobeAsia,
  faTachometerAlt,
  faUser
)

<template>
  <quest-main
    title="Deal creation"
    copy="Select the type of deal you would like to create"
  >
    <template v-slot:body>
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card o-hidden border-0 shadow-lg my-5">
            <div
              class="card-body p-0 btn btn-primary"
              @click="$router.push({ name: 'deals-add-group' })"
            >
              <div class="row">
                <div class="col">
                  <div class="p-5">
                    <div class="text-center">
                      <h4 class="h4 my-0">Group</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card o-hidden border-0 shadow-lg my-5">
            <div
              class="card-body p-0 btn btn-primary"
              @click="$router.push({ name: 'deals-add-local' })"
            >
              <div class="row">
                <div class="col">
                  <div class="p-5">
                    <div class="text-center">
                      <h4 class="h4 my-0">Local</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </quest-main>
</template>

<script>
import { QuestMain, QuestCard } from './structure'
export default {
  name: 'deal-create-selection',
  components: {
    QuestCard,
    QuestMain,
  },
}
</script>

import axios from 'axios'
import queryString from 'query-string'

export default class HttpClient {
  constructor(baseUrl, timeout, headers) {
    let headersToUse = headers || {}

    headersToUse = Object.assign(headersToUse, {
      'Content-Type': 'application/json; charset=UTF-8',
    })

    this.axios = axios.create({
      baseURL: baseUrl,
      timeout,
      headers: headersToUse,
    })

    this.axios.interceptors.request.use(
      (config) => {
        let accessToken = JSON.parse(
          localStorage.getItem('nexus:identity') || '{}'
        ).token
        if (accessToken) {
          config.headers = Object.assign(
            {
              Authorization: `Basic ${accessToken}`,
            },
            config.headers
          )
        }
        return config
      },
      (error) => Promise.reject(error)
    )
  }

  async get(url, data, timeout, inject) {
    return await this.sendGET(url, data, timeout, 'get', inject)
  }

  async post(url, data, timeout, inject) {
    return await this.sendPOST(url, data, timeout, 'post', inject)
  }

  async put(url, data, timeout, inject) {
    return await this.sendPOST(url, data, timeout, 'put', inject)
  }

  async delete(url, timeout, inject) {
    return await this.sendGET(url, null, timeout, 'delete', inject)
  }

  async sendPOST(url, data, timeout, method, inject) {
    const options = typeof timeout !== 'undefined' ? { timeout } : {}
    return await this.axios[method](url, data || {}, { ...options })
      .catch((err) => err.response)
      .then(async (res) => (inject ? await inject(res) : res))
      .then((res) => (res ? res.data : null))
  }

  async sendGET(url, data, timeout, method, inject) {
    const query = queryString.stringify(data)
    const fullUrl = `${url}${query ? `?${query}` : ''}`
    const options = typeof timeout !== 'undefined' ? { timeout } : {}
    return await this.axios[method](fullUrl, { ...options })
      .catch((err) => err.response)
      .then(async (res) => (inject ? await inject(res) : res))
      .then((res) => (res && res.status == 200 ? res.data : null))
  }
}

import AdminNexusHttpClient from './admin-nexus-http-client'

export default class GeoService {
  constructor() {
    this.httpClient = new AdminNexusHttpClient('/geo')
  }

  async coordinate(address) {
    return await this.httpClient.get('coordinate', { address })
  }

  async getCountries() {
    return await this.httpClient.get('countries')
  }

  async getStates() {
    return await this.httpClient.get('states')
  }
}

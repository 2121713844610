<template>
  <quest-main
    title="Preloads"
    copy="Manage background preloaded rates by selecting from the table below"
    :loading="loading"
  >
    <template v-slot:head>
      <router-link
        :to="{ name: 'preload-add' }"
        class="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
      >
        <font-awesome-icon
          icon="plus"
          size="sm"
          class="text-white-50"
        ></font-awesome-icon>
        Add</router-link
      >
    </template>
    <template v-slot:body>
      <quest-card title="Quote preload criteria">
        <quest-table id="preload-table" :columns="columns">
          <template v-if="preloads.length > 0">
            <tr
              class="small align-middle"
              v-for="preload in preloads"
              :key="preload.id"
            >
              <td>
                <router-link
                  :to="{ name: 'preload-edit', params: { id: preload.id } }"
                  >{{ preload.name }}</router-link
                >
              </td>
              <td>{{ preload.isEnabled ? 'Enabled' : 'Disabled' }}</td>
              <td>{{ lastRun(preload) }}</td>
              <td>
                <b-dropdown no-caret variant="link">
                  <template #button-content>
                    <font-awesome-icon
                      icon="ellipsis-v"
                      size="sm"
                      fixed-width
                      class="text-gray-400"
                    ></font-awesome-icon>
                  </template>
                  <b-dropdown-header>Actions</b-dropdown-header>
                  <b-dropdown-item
                    :to="{
                      name: 'preload-edit',
                      params: { id: preload.id },
                    }"
                    >View</b-dropdown-item
                  >
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item-button
                    @click="promptDelete(preload)"
                    text="danger"
                    variant="danger"
                    >Delete</b-dropdown-item-button
                  >
                </b-dropdown>
              </td>
            </tr>
          </template>
          <template v-if="preloads.length === 0">
            <tr class="align-middle">
              <td :colspan="columns.length">
                <div class="small text-center py-5">
                  There are no preloaders in the system
                </div>
              </td>
            </tr>
          </template>
        </quest-table>
      </quest-card>
      <b-modal v-model="showModal" centered title="Delete">
        <p class="my-4" v-if="toDelete">
          Are you sure you wish to delete preload '{{ toDelete.name }}'?
        </p>
        <template v-slot:modal-footer>
          <button
            class="btn btn-secondary btn-sm"
            type="button"
            @click="cancelPromptDelete"
          >
            Cancel</button
          ><button
            class="btn btn-danger btn-sm"
            type="button"
            @click="confirmPromptDelete"
          >
            Delete
          </button>
        </template>
      </b-modal>
    </template>
  </quest-main>
</template>

<script>
import { success, error } from '../helpers'
import moment from 'moment'
import 'moment-timezone'
import { QuestMain, QuestTable, QuestCard, QuestModal } from './structure'
import { PreloadService } from '../services'

export default {
  name: 'preloads',
  components: {
    QuestMain,
    QuestTable,
    QuestCard,
    QuestModal,
  },
  data() {
    return {
      preloads: [],
      columns: ['Name', 'Status', 'Last Run', ''],
      showModal: false,
      toDelete: null,
      loading: true,
    }
  },
  mounted() {
    new PreloadService()
      .list()
      .then((data) => (this.preloads = data))
      .finally((_) => (this.loading = false))
  },
  methods: {
    promptDelete(preload) {
      this.toDelete = preload
      this.showModal = true
    },
    lastRun(preload) {
      return preload.lastAttemptUTC
        ? moment(`${preload.lastAttemptUTC}Z`)
            .tz('Australia/Melbourne')
            .format('lll')
        : 'Never'
    },
    cancelPromptDelete() {
      this.showModal = false
      this.toDelete = null
    },
    confirmPromptDelete() {
      new PreloadService()
        .delete(this.toDelete.id)
        .then((data) => {
          this.preloads = this.preloads.filter((x) => x.id !== this.toDelete.id)
          this.showModal = false

          if (data) {
            success(`Deleted preload #`, this.toDelete.name)
          } else {
            error(`Failed to delete preload #`, this.toDelete.name)
          }
        })
        .finally((_) => {
          this.toDelete = null
        })
    },
  },
}
</script>

import AdminNexusHttpClient from './admin-nexus-http-client'

export default class ConnectStatsService {
  constructor() {
    this.httpClient = new AdminNexusHttpClient('/admin/stats')
  }

  async get(id) {
    return await this.httpClient.get(id)
  }
}

<template>
  <quest-card title="Basic Information" :collapsable="true" id="basicInfo">
    <div class="row">
      <div class="col-md-6">
        <quest-input
          id="name"
          label="Name"
          name="Name"
          v-model="deal.name"
          @change="updateSlug"
          :rules="{ [REQUIRED]: true }"
          help="The deal name must match the desired TBB deal name"
        ></quest-input>
      </div>
      <div class="col-md-6">
        <quest-input
          id="code"
          label="Code"
          v-model="deal.code"
          help="The deal code must match the desired TBB deal code"
        ></quest-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <quest-toggle
          id="isEnabled"
          label="Run Deal"
          v-model="deal.isEnabled"
          checked="Enabled"
          unchecked="Disabled"
          help="Enable deal throughout Quest"
        />
      </div>
      <div class="col-md-6">
        <quest-toggle
          id="isSilent"
          label="Silence Deal"
          v-model="deal.isSilent"
          checked="Enabled"
          unchecked="Disabled"
          help="Display price in lowest price listings"
        />
      </div>
    </div>

    <div class="form-group">
      <label>Promotional Image</label>
      <div class="d-flex h-100">
        <quest-image
          :image="deal.image"
          single
          @change="$refs.splash.open()"
        ></quest-image>
      </div>
    </div>
    <quest-image-picker
      ref="splash"
      title="Select deal image"
      :albumName="'deals'"
      :current="[deal.image]"
      @update="updateSplash"
      required
    ></quest-image-picker>
  </quest-card>
</template>

<script>
import Card from './deal-card'
import { slugify } from '../../helpers'

export default {
  name: 'basic-card',
  extends: Card,
  methods: {
    updateSlug(v) {
      this.deal.slug = slugify(v)
    },
    updateSplash(selected) {
      this.deal.image = selected
    },
  },
}
</script>

export const PropertyView = Object.freeze({
  Config: 'Config',
  Content: 'Content',
  Conference: 'Conference',
  Locality: 'Locality',
  Rooms: 'Rooms',
  WebPreview: 'WebPreview',
  MetaPreview: 'MetaPreview',
  Rates: 'Rates',
  Extras: 'Extras',
  Explorer: 'Explorer',
})

import store from '@/store'

export const hasRouteAccess = (view, id) => {
  return (
    !view ||
    store.getters.isAdmin ||
    hasViewPermissions(getUserPermissions(store.getters.roles), view, id)
  )
}

export const hasFieldAccess = (view, field) => {
  return (
    store.getters.isAdmin ||
    hasFieldPermissions(getUserPermissions(store.getters.roles), view, field)
  )
}

export const hasSideBarView = () => {
  if (store.getters.isAdmin) return true
  return getUserPermissions(store.getters.roles)
    .map((x) => x.Sidebar)
    .some((x) => x === true)
}

const getUserPermissions = (roles) => {
  return Object.entries(window.$permissions)
    .filter(([key, value]) => roles.includes(key))
    .map((role) => role[1])
}

const getAllPermissionsForView = (permissions, permission) => {
  if (permissions.some((x) => x === '*')) return '*'
  return merge(
    permissions
      .map((x) => {
        let p = Object.entries(x).find(([key, _]) => key === permission)
        return p && p.length ? p[1] : null
      })
      .filter((x) => x !== null)
  )
}

const hasFieldPermissions = (permissions, view, field) => {
  if (permissions === '*') return true
  let viewPermissions = getAllPermissionsForView(permissions, view)
  if (viewPermissions === '*') return true
  if (
    checkViewPermissionsExists(viewPermissions) &&
    (viewPermissions.Fields === '*' || viewPermissions.Fields.includes(field))
  ) {
    return true
  }
  return false
}

const checkViewPermissionsExists = (permissions) => {
  if (
    permissions &&
    permissions.Fields &&
    permissions.Fields.length &&
    permissions.Items &&
    permissions.Items.length
  )
    return true
  return false
}

const hasViewPermissions = (permissions, permission, id) => {
  if (permissions === '*') return true
  let perms = getAllPermissionsForView(permissions, permission)
  if (perms === '*') return true
  return (
    checkViewPermissionsExists(perms) && checkViewPermissionGranted(perms, id)
  )
}

const checkViewPermissionGranted = (permissions, id) => {
  return permissions.Items === '*' || store.getters.properties.includes(id)
}

const merge = (map) => {
  let target = {}
  if (map.some((x) => x === '*')) return '*'
  for (var i = 0; i < map.length; i++) {
    var from = map[i]
    if (typeof from !== 'object') continue
    for (var j in from) {
      if (from.hasOwnProperty(j)) {
        if (typeof from[j] === 'object' && !Array.isArray(from[j])) {
          target[j] = merge([target[j], from[j]])
        } else if (
          Array.isArray(from[j]) &&
          (Array.isArray(target[j]) || target[j] === undefined)
        ) {
          target[j] = [...from[j], ...(target[j] || [])]
        } else target[j] = from[j]
      }
    }
  }
  return target
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationObserver',{ref:"vobserver",scopedSlots:_vm._u([{key:"default",fn:function({}){return [_c('b-modal',{ref:"modal",attrs:{"centered":"","size":"lg","title":_vm.extra.id ? 'Manage extra' : 'Create extra',"body-class":"p-0"},on:{"hidden":_vm.hidden},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('button',{staticClass:"btn btn-secondary btn-sm",attrs:{"type":"button"},on:{"click":_vm.close}},[_vm._v(" Cancel")]),_c('button',{staticClass:"btn btn-primary btn-sm",attrs:{"type":"button"},on:{"click":_vm.save}},[_vm._v(" Save ")])]},proxy:true}],null,true)},[_c('div',{staticClass:"container mt-3"},[_c('row',{attrs:{"id":"name","label":"Name","tip":"The name of the 'extra' is publicly displayed to users. Maximum character limit of 60."}},[_c('quest-input',{attrs:{"id":"name","rules":{
            [_vm.REQUIRED]: true,
            [_vm.MAX]: { length: 60 },
          }},model:{value:(_vm.extra.name),callback:function ($$v) {_vm.$set(_vm.extra, "name", $$v)},expression:"extra.name"}})],1),_c('row',{attrs:{"id":"description","label":"Description","tip":"A short description for the extra, publicly displayed to users. Maximum character limit of 300."}},[_c('quest-text-area',{attrs:{"name":"Description","id":"description","rules":{
            [_vm.REQUIRED]: true,
            [_vm.MAX]: { length: 300 },
          }},model:{value:(_vm.extra.description),callback:function ($$v) {_vm.$set(_vm.extra, "description", $$v)},expression:"extra.description"}})],1),_c('row',{attrs:{"id":"applicability","label":"Applicable dates","tip":"The dates the extra is avaialble for a given reservation. Leave blank to keep the start and/or end date open ended."}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('quest-date-picker',{attrs:{"id":"applicableFrom","label":"","clearable":"true","help":"","icon":"sign-in"},model:{value:(_vm.extra.applicableFrom),callback:function ($$v) {_vm.$set(_vm.extra, "applicableFrom", $$v)},expression:"extra.applicableFrom"}})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('quest-date-picker',{attrs:{"id":"applicableTo","label":"","clearable":"true","icon":"sign-out","help":""},model:{value:(_vm.extra.applicableTo),callback:function ($$v) {_vm.$set(_vm.extra, "applicableTo", $$v)},expression:"extra.applicableTo"}})],1)])]),_c('row',{attrs:{"id":"gross","label":"Gross","tip":"Gross cost per unit (based on selected interval). Gross price will be locked to unit price of the sundry if the selected 'requirement' has the flag 'Use sundry amount' enabled."}},[_c('quest-input',{attrs:{"id":"gross","value":_vm.requirement?.useSundryAmount &&
            (_vm.requirement.sundryId || _vm.extra.sundryId)
              ? _vm.sundry.unitPrice
              : _vm.extra.gross,"disabled":_vm.requirement?.useSundryAmount &&
            (_vm.requirement.sundryId || _vm.extra.sundryId),"rules":{
            [_vm.REQUIRED]: true,
          }},on:{"input":(e) => (_vm.extra.gross = e)},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_vm._v("$")]},proxy:true}],null,true)})],1),_c('row',{attrs:{"id":"isDefault","label":"Default","tip":"The default setting for all rates given this extra. When opted in, all rates will have this extra as an optional charge. When opted out, a rate will explicitly require this extra to be added as a possible charge."}},[_c('quest-toggle',{attrs:{"id":"isDefault","checked":"Opt in","unchecked":"Opt out"},model:{value:(_vm.extra.isDefault),callback:function ($$v) {_vm.$set(_vm.extra, "isDefault", $$v)},expression:"extra.isDefault"}})],1),_c('row',{attrs:{"id":"interval","label":"Interval","tip":"The interval to charge the gross unit price. If thee selected 'requirement' has the flag 'Charge required daily' enabled, interval types will be reduced to 'per night' options."}},[_c('quest-single-select',{attrs:{"placeholder":"Select interval","options":_vm.filteredIntervals,"id":"interval","otext":"label","rules":{
            [_vm.REQUIRED]: true,
          }},on:{"update":_vm.updateInterval},model:{value:(_vm.extra.interval),callback:function ($$v) {_vm.$set(_vm.extra, "interval", $$v)},expression:"extra.interval"}})],1),_c('row',{attrs:{"id":"image","label":"Image","tip":"An image promoting this extra to users."}},[_c('quest-image',{attrs:{"image":_vm.extra.image,"single":""},on:{"change":function($event){return _vm.$refs.splash.open()}}})],1),_c('quest-image-picker',{ref:"splash",attrs:{"title":"Select extra image","albumName":"extras","current":_vm.extra.image ? [_vm.extra.image] : [],"required":"","manage":""},on:{"update":_vm.updateImage}}),_c('row',{attrs:{"id":"type","label":"Extra Type","tip":"The type of asset to map from the RMS system. An add-on type is a goods/service rented by a guest from an inventory pool. Requirements are fungible goods/services consumed by guests with unlimited supplies. Requirements have availability limitations imposed by RMS. Manual extras are not mapped to RMS and instead referenced in the OTA note. Packages are a group of sundries without an inventory pool."}},[_c('quest-single-select',{attrs:{"keepPlaceholder":false,"options":[
            {
              key: 0,
              value: 'Manual',
            },
            {
              key: 1,
              value: 'Requirement',
            },
            {
              key: 2,
              value: 'Addon Type',
            },
            {
              key: 3,
              value: 'Package',
            },
          ]},on:{"update":_vm.updateExtraType},model:{value:(_vm.extra.type),callback:function ($$v) {_vm.$set(_vm.extra, "type", $$v)},expression:"extra.type"}})],1),(_vm.extra.type === 1)?_c('row',{attrs:{"id":"requirementId","label":"Requirement","tip":"Requirements are fungible goods/services consumed by guests with unlimited supplies. Requirements have availability limitations imposed by RMS."}},[_c('quest-single-select',{attrs:{"keepPlaceholder":true,"placeholder":"No requirement selected","name":"Requirement","options":_vm.requirements,"id":"requirementId","okey":"id","otext":"name","rules":{
            [_vm.REQUIRED]: true,
          }},on:{"update":(e) => _vm.updateRequirement(e)},model:{value:(_vm.extra.requirementId),callback:function ($$v) {_vm.$set(_vm.extra, "requirementId", $$v)},expression:"extra.requirementId"}})],1):_vm._e(),(_vm.extra.type === 2)?_c('row',{attrs:{"id":"addOnTypeId","label":"Addon Type","tip":"An add-on type is a goods/service rented by a guest from an inventory pool."}},[_c('quest-single-select',{attrs:{"keepPlaceholder":true,"name":"Add-on type","placeholder":"No add-on selected","options":_vm.addons,"id":"addOnTypeId","rules":{
            [_vm.REQUIRED]: true,
          }},on:{"update":(e) => _vm.updateAddOnType(e)},model:{value:(_vm.extra.addOnTypeId),callback:function ($$v) {_vm.$set(_vm.extra, "addOnTypeId", $$v)},expression:"extra.addOnTypeId"}})],1):_vm._e(),(_vm.extra.addOnTypeId)?_c('row',{attrs:{"id":"sundryId","label":"Sundry","tip":"Sundries identify the charge/line item linked to the extra, similar to a 'rate'."}},[_c('quest-single-select',{attrs:{"keepPlaceholder":true,"name":"Sundry","placeholder":"No sundry selected","value":_vm.extra.sundries.length ? _vm.extra.sundries[0] : '',"options":_vm.sundries,"id":"sundryId","okey":"id","otext":"name","rules":{
            [_vm.REQUIRED]: true,
          }},on:{"update":(e) => _vm.updateSundry(e)}})],1):_vm._e(),(_vm.extra.type === 3)?_c('row',{attrs:{"id":"sundries","label":"Sundries","tip":"Sundries identify the charge/line item linked to the extra, similar to a 'rate'. Packages may contain multiple sundries."}},[_c('quest-multi-select',{attrs:{"id":"sundries","options":_vm.sundries,"uid":"id","text":"name"},on:{"update":(e) => _vm.updateSundries(e)},model:{value:(_vm.extra.sundries),callback:function ($$v) {_vm.$set(_vm.extra, "sundries", $$v)},expression:"extra.sundries"}})],1):_vm._e()],1)])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
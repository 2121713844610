<template>
  <quest-card
    title="Imagery"
    id="imageryForm"
    :collapsable="true"
    :collapsed="true"
  >
    <div class="form-group">
      <label>Hero Image</label>
      <div class="d-flex h-100">
        <quest-image
          :image="property.image"
          single
          @change="$refs.splash.open()"
        ></quest-image>
      </div>
    </div>

    <div class="form-group rounded border p-2">
      <div class="d-flex justify-content-between">
        <label>Carousel</label>
        <button @click="$refs.gallery.open()" class="btn btn-primary btn-sm">
          Update Carousel
        </button>
      </div>
      <template v-if="property.gallery.length > 0">
        <div class="d-flex h-100 flex-wrap mt-2">
          <div
            class="col-12 col-md-4 col-lg-3 mb-3 text-center"
            v-for="item in property.gallery"
            :key="item.mediaId"
          >
            <quest-image :image="item.mediaId"> </quest-image>
            <ValidationProvider
              name="Property image tag"
              :rules="{ [MAX]: { length: 36 } }"
              v-slot="{ errors, classes }"
            >
              <input
                type="text"
                class="form-control"
                :class="classes"
                placeholder="Image Tag"
                v-model="item.label"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </div>
        </div>
      </template>
      <template v-else>
        <small>No images added to carousel</small>
      </template>
    </div>
    <quest-image-picker
      ref="splash"
      title="Select hero image"
      :propertyId="
        property.siteminderPropertyId != '00000000-0000-0000-0000-000000000000'
          ? property.siteminderPropertyId
          : property.id
      "
      :albumName="property.name"
      :current="property.image ? [property.image.mediaId] : []"
      @update="updateSplash"
      required
      manage
    ></quest-image-picker>
    <quest-image-picker
      title="Select carousel images"
      ref="gallery"
      :propertyId="
        property.siteminderPropertyId != '00000000-0000-0000-0000-000000000000'
          ? property.siteminderPropertyId
          : property.id
      "
      :albumName="property.name"
      :current="property.gallery.map((s) => s.mediaId)"
      folder="Gallery"
      @update="updateGallery"
      multiple
      manage
    ></quest-image-picker>
  </quest-card>
</template>

<script>
import Card from './property-card'

export default {
  extends: Card,
  methods: {
    updateSplash(selected) {
      this.property.image = selected
    },
    updateGallery(selected) {
      this.property.gallery = selected.map((x) => ({
        mediaId: x,
        label: ((x) => {
          const g = this.property.gallery.find((p) => p.mediaId === x)
          return g ? g.label : ''
        })(x),
      }))
    },
  },
}
</script>

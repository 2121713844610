import { capitalizeFirstLetter } from '@/helpers'

export default {
  props: {
    id: String,
    name: { default: '' },
    value: { required: true },
    label: String,
    type: { default: 'text' },
    help: String,
    disabled: { default: false },
    placeholder: { default: '' },
    maxCount: { default: 0 },
    rules: { default: () => ({}) },
  },
  data() {
    return {
      remainingCount: 0,
    }
  },
  computed: {
    visibleName() {
      return this.name || (!!this.id ? capitalizeFirstLetter(this.id) : '')
    },
    input: {
      get() {
        return this.value
      },
      set(value) {
        this.handleInput({
          target: {
            value,
          },
        })
      },
    },
  },
  methods: {
    handleInput(event) {
      this.$emit('input', event.target.value)
      this.$emit('change', event.target.value)
    },
    countdown() {
      if (maxCount > 0) {
        this.remainingCount = this.maxCount - this.value.length
      }
    },
  },
}

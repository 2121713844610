<template>
  <div v-if="!loading">
    <quest-card :panel="true">
      <div class="row">
        <div class="col-6 col-offset-md-4 col-md-4">
          <quest-single-select
            v-model="room"
            :options="rooms"
            label="Room"
            id="room"
            :placeholder="property ? 'Any' : 'None'"
            okey="id"
            otext="name"
            :keepPlaceholder="true"
            @update="updateRoom"
          ></quest-single-select>
        </div>
        <div class="col-6 col-md-4 offset-md-4">
          <div class="form-group text-right">
            <label class="d-block">&nbsp;</label>
            <button
              class="btn btn-primary btn-sm"
              @click="() => add({ scope: false, property: property })"
            >
              <span class="icon text-white-50">
                <font-awesome-icon
                  fixed-width
                  :icon="['fas', 'plus']"
                ></font-awesome-icon>
              </span>

              <span class="text">Add</span>
            </button>
          </div>
        </div>
      </div>
    </quest-card>

    <rate-listing-view
      ref="view"
      @init="() => search()"
      :properties="properties"
    ></rate-listing-view>
  </div>
</template>

<script>
import { PropertyManagementService } from '@/services'
import { QuestMain, QuestCard } from '../structure'
import { QuestInput, QuestSingleSelect, QuestToggle } from '../form'

import RateListingBase from './rate-listing-base'
import RateListingView from './rate-listing-view.vue'
import CreateRateSelection from './create-rate-selection.vue'

export default {
  name: 'property-rate-listing',
  extends: RateListingBase,
  data() {
    return {
      room: '',
    }
  },
  components: {
    CreateRateSelection,
    QuestCard,
    RateListingView,
    QuestMain,
    QuestSingleSelect,
    QuestInput,
    QuestToggle,
  },
  mounted() {
    new PropertyManagementService().extract(this.account).then((data) => {
      this.properties = data
      this.rooms =
        this.properties
          .find((x) => x.id === this.property)
          ?.rooms?.filter((x) => x.isActive) || []
      this.loading = false
    })
  },
  props: {
    property: { required: true },
  },
  methods: {
    add(e) {
      this.$refs.view.add(e)
    },
    async search() {
      this.$refs['view'].search(false, null, null, this.property, this.room)
    },
  },
}
</script>

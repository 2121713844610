export const ExtraInterval = {
  Booking: 0,
  Room: 1,
  RoomNight: 2,
  Person: 3,
  PersonNight: 4,
}

export const ExtraIntervalListing = [
  { key: ExtraInterval.Booking, label: 'Per booking' },
  { key: ExtraInterval.Room, label: 'Per room' },
  { key: ExtraInterval.RoomNight, label: 'Per room per night' },
  { key: ExtraInterval.Person, label: 'Per person' },
  { key: ExtraInterval.PersonNight, label: 'Per person per night' },
]

import AdminNexusHttpClient from './admin-nexus-http-client'

export default class RateService {
  constructor() {
    this.rateTableClient = new AdminNexusHttpClient('/admin/rate-tables')
    this.nexusRateClient = new AdminNexusHttpClient('/admin/rates')
  }

  async listTables(externalId) {
    return await this.rateTableClient.get('', { externalId })
  }

  async view(externalAccount, global, country, state, property, room) {
    return await this.nexusRateClient.get('view', {
      externalAccount,
      global,
      country,
      state,
      property,
      room,
    })
  }

  async get(account, id) {
    return await this.nexusRateClient.get(`${account}/${id}`)
  }

  async add(payload) {
    return await this.nexusRateClient.put(``, payload)
  }

  async update(payload) {
    return await this.nexusRateClient.post(``, payload)
  }

  async check(payload) {
    return await this.nexusRateClient.post('check', payload)
  }

  async delete(_, id) {
    return await this.nexusRateClient.delete(`${id}`)
  }

  async enable(account, id) {
    return await this.nexusRateClient.get(`${account}/${id}/enable`)
  }

  async disable(account, id) {
    return await this.nexusRateClient.get(`${account}/${id}/disable`)
  }

  async optin(account, master, id, property, room) {
    return await this.nexusRateClient.post(`opt-in`, {
      externalAccountId: account,
      value: master,
      supportingId: id,
      propertyId: property,
      roomId: room,
    })
  }

  async optout(account, master, id, property, room) {
    return await this.nexusRateClient.post(`opt-out`, {
      externalAccountId: account,
      value: master,
      supportingId: id,
      propertyId: property,
      roomId: room,
    })
  }
}

<template>
  <div class="container-xl" :id="id">
    <quest-disconnected v-if="noconnect"></quest-disconnected>
    <quest-preloader
      v-else-if="loading"
      :header="loadingHeader"
      :copy="loadingCopy"
    ></quest-preloader>
    <quest-preloader
      v-else-if="saving"
      header="Saving"
      copy="This may take a moment"
      saving
    ></quest-preloader>
    <template v-else-if="title">
      <template v-if="!copy">
        <div
          class="d-flex align-items-center mb-4"
          :class="{
            'justify-content-between': !center,
            'justify-content-center': center,
          }"
        >
          <h1 class="h3 mb-0 text-gray-800">{{ title }}</h1>
          <slot name="head"></slot>
        </div>
      </template>
      <template v-else>
        <div
          class="d-flex align-items-center"
          :class="{
            'justify-content-between': !center,
            'justify-content-center': center,
          }"
        >
          <h1 class="h3 mb-0 text-gray-800">{{ title }}</h1>
          <slot name="head"></slot>
        </div>
        <p class="mb-4">{{ copy }}</p>
      </template>
      <slot name="body"></slot>
    </template>
    <template v-else>
      <slot name="body"></slot>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import QuestPreloader from './quest-preloader.vue'
import QuestDisconnected from './quest-disconnected.vue'

export default {
  name: 'quest-main',
  components: {
    QuestPreloader,
    QuestDisconnected,
  },
  props: {
    id: String,
    title: { default: '' },
    copy: String,
    center: Boolean,
    loading: { default: false },
    saving: { default: false },
    loadingHeader: String,
    loadingCopy: String,
  },
  computed: {
    ...mapState(['noconnect']),
  },
}
</script>

import Card from '../card'
import { REQUIRED, MAX } from '@/validation-types'

export default {
  data() {
    return {
      REQUIRED,
      MAX,
    }
  },
  props: {
    property: { required: true },
    configuration: { required: true },
    csr: { type: Object, default: () => ({}) },
    announcement: { type: Object, default: () => ({}) },
  },
  extends: Card,
}

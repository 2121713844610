export const RateQuoteStatus = {
  Error: 0,
  Success: 1,
  Inactive: 2,
  CodeBlocked: 3,
  NoSell: 4,
  NoStay: 5,
  OutOfNightRange: 6,
  LoginOnly: 7,
  StayPayBlocked: 8,
  StayNightBlocked: 9,
  AdvanceBlock: 10,
  LastMinuteBlock: 11,
  OccupancyBlock: 12,
}

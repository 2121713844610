<template>
  <ValidationObserver ref="vobserver" v-slot="{ errors }">
    <quest-validation-summary :errors="errors"></quest-validation-summary>
    <quest-main
      id="snippet-form"
      :title="
        snippet && snippet.id ? snippet.name || '(untitled)' : 'Create Snippet'
      "
      :loading="loading"
      :saving="saving"
    >
      <template v-slot:head>
        <a
          @click="save()"
          class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
          ><font-awesome-icon
            icon="save"
            size="sm"
            class="text-white-50"
          ></font-awesome-icon>
          Save
        </a>
      </template>
      <template v-slot:body>
        <quest-card title="Basic Information">
          <quest-input
            id="name"
            label="Name"
            name="Name"
            v-model="snippet.name"
            :rules="{ [REQUIRED]: true }"
            help="Name of promo/guarantee displayed to users"
          ></quest-input>

          <quest-text-area
            id="copy"
            label="Copy"
            v-model="snippet.copy"
            help="Description of promo/guarantees"
          ></quest-text-area>

          <div class="form-group">
            <label>Image</label>
            <div class="d-flex h-100">
              <quest-image
                :image="snippet.image"
                single
                @change="$refs.splash.open()"
              ></quest-image>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <quest-multi-select
                id="sites"
                label="Displayed sites"
                v-model="snippet.sites"
                :options="sites"
                help="The sites which may display this snippet"
                uid="id"
                text="name"
              ></quest-multi-select>
            </div>
          </div>

          <quest-image-picker
            ref="splash"
            title="Select splash image"
            albumName="snippets"
            :current="[snippet.image]"
            @update="updateSplash"
            required
            manage
          ></quest-image-picker>
        </quest-card>
      </template>
    </quest-main>
  </ValidationObserver>
</template>

<script>
import { REQUIRED } from '@/validation-types'
import { success, error } from '../helpers'
import { mapState } from 'vuex'
import { SnippetService } from '../services'
import {
  QuestCard,
  QuestMain,
  QuestStatCard,
  QuestValidationSummary,
} from './structure'
import {
  QuestInput,
  QuestTextArea,
  QuestEditor,
  QuestDatePicker,
  QuestToggle,
  QuestSingleSelect,
  QuestMultiSelect,
  QuestImagePicker,
  QuestImage,
} from './form'

export default {
  name: 'snippet',
  components: {
    QuestCard,
    QuestMain,
    QuestInput,
    QuestTextArea,
    QuestEditor,
    QuestDatePicker,
    QuestToggle,
    QuestSingleSelect,
    QuestMultiSelect,
    QuestStatCard,
    QuestImagePicker,
    QuestImage,
    QuestValidationSummary,
  },
  data() {
    return {
      REQUIRED,
      snippet: {
        image: '',
        sites: [],
      },
      loading: true,
      saving: false,
    }
  },
  computed: {
    ...mapState(['sites']),
  },
  mounted() {
    this.load()
  },
  methods: {
    updateSplash(selected) {
      this.snippet.image = selected
    },
    async save() {
      if (!this.$refs.vobserver) return
      let validated = await this.$refs.vobserver.validate()
      if (!validated) {
        error('Please ensure all errors are resolved')
        return
      }
      this.saving = true
      try {
        if (this.snippet.id) {
          new SnippetService().update(this.snippet).then((data) => {
            if (data) {
              this.load()
              success(`Updated snippet #`, this.snippet.name)
            } else {
              error(`Failed to update snippet #`, this.snippet.name)
            }
          })
        } else {
          new SnippetService().add(this.snippet).then((data) => {
            if (data) {
              this.$router.push({
                name: 'snippets-edit',
                params: { id: data.value },
              })
              this.load()
              success(`Created snippet #`, this.snippet.name)
            } else {
              error(`Failed to create snippet #`, this.snippet.name)
            }
          })
        }
      } finally {
        this.saving = false
      }
    },
    load() {
      if (this.$route.params.id) {
        new SnippetService()
          .get(this.$route.params.id)
          .then((data) => (this.snippet = data))
          .catch((_) => (this.snippet = null))
          .finally((_) => {
            this.loading = false
            this.$nextTick(() => {
              if (this.$refs && this.$refs.vobserver) {
                this.$refs.vobserver.validate()
              }
            })
          })
      } else {
        this.loading = false
      }
    },
  },
}
</script>

<template>
  <div class="form-group">
    <ValidationProvider
      :name="visibleName"
      :rules="rules"
      v-slot="{ errors, classes }"
    >
      <label v-if="label" :for="id">{{ label }}</label>
      <textarea
        class="form-control"
        :class="classes"
        :id="id"
        :value="value"
        :aria-describedby="id + 'Help'"
        @input="handleInput"
        v-on:keydown="countdown"
        v-on:keyup="countdown"
        :disabled="disabled"
        :rows="rows"
        :name="id"
      ></textarea>
      <small v-if="help" :id="id + 'Help'" class="form-text text-muted">{{
        help
      }}</small>
      <small class="text-danger">{{ errors[0] }}</small>
    </ValidationProvider>
  </div>
</template>

<script>
import form from './form'

export default {
  name: 'quest-text-area',
  extends: form,
  props: {
    rows: { default: 3 },
    disabled: { default: false },
  },
  methods: {
    countdown() {},
  },
}
</script>

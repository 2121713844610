import AdminNexusHttpClient from './admin-nexus-http-client'

export default class AddonService {
  constructor() {
    this.httpClient = new AdminNexusHttpClient('/admin/add-on-types')
  }

  async list(property) {
    return await this.httpClient.get(`list/${property}`)
  }
}

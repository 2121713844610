<template>
  <div v-if="loaded">
    <file-pond
      name="fpupload"
      ref="pond"
      label-idle="Drag images here or <span class='filepond--label-action'>Browse</span>"
      :allow-multiple="true"
      accepted-file-types="image/jpeg, image/png"
      :files="files"
      :allowFileRename="true"
      :allowImagePreview="true"
      :server="server"
      :instantUpload="false"
      :beforeRemoveFile="beforeRemoveFile"
      :onactivatefile="activateFile"
      credits="false"
      :imageCropAspectRatio="'3:2'"
      :imagePreviewHeight="200"
      :imagePreviewMinHeight="200"
      :imagePreviewMaxHeight="200"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import vueFilePond from 'vue-filepond'

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileRename from 'filepond-plugin-file-rename'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImageEdit from 'filepond-plugin-image-edit'

import { ResourcesService } from '../../services'

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileRename,
  FilePondPluginImageExifOrientation,
  FilePondPluginImageEdit
)

export default {
  name: 'quest-image-upload',
  props: {
    propertyId: String,
    albumName: String,
    folder: { default: '' },
  },
  computed: {
    ...mapState({
      authentication: (state) => state.authentication,
    }),
  },
  mounted() {
    this.load()
  },
  data: function () {
    return {
      loaded: false,
      files: [],
      server: {
        url: `${$config.cms.uri}/quest-api/nexusmedia`,
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          const formData = new FormData()
          formData.append(fieldName, file, file.name)
          formData.append('id', this.propertyId)
          formData.append('propertyName', this.albumName)
          formData.append('folder', this.folder)
          const request = new XMLHttpRequest()
          request.open(
            'POST',
            `${$config.cms.uri}/quest-api/nexusmedia/process`
          )
          request.setRequestHeader(
            'Authorization',
            `Basic ${this.authentication.token}`
          )
          request.upload.onprogress = (e) => {
            progress(e.lengthComputable, e.loaded, e.total)
          }
          request.onload = function () {
            if (request.status >= 200 && request.status < 300) {
              load(request.responseText)
            } else {
              error('Error during Upload!')
            }
          }
          request.send(formData)
          return {
            abort: () => {
              request.abort()
              abort()
            },
          }
        },
        revert: (uniqueFileId, load, error, progress) => {
          const request = new XMLHttpRequest()
          request.open(
            'DELETE',
            `${$config.cms.uri}/quest-api/nexusmedia/revert`
          )
          request.setRequestHeader(
            'Authorization',
            `Basic ${this.authentication.token}`
          )
          request.onload = function () {
            if (request.status >= 200 && request.status < 300) {
              load()
            } else {
              error('Error while removing file!')
            }
          }
          request.send(uniqueFileId)
        },
        remove: (source, load, error) => {
          const request = new XMLHttpRequest()
          request.open(
            'DELETE',
            `${$config.cms.uri}/quest-api/nexusmedia/revert`
          )
          request.setRequestHeader(
            'Authorization',
            `Basic ${this.authentication.token}`
          )
          request.onload = function () {
            if (request.status >= 200 && request.status < 300) {
              load()
            } else {
              error('Error while removing file!')
            }
          }
          request.send(source)
        },
        load: (source, load, error, progress, abort, headers) => {
          fetch(
            new Request(
              `${this.$config.cms.uri}/quest-api/nexusmedia/load/${source}`
            )
          ).then(function (response) {
            response.blob().then(function (blob) {
              blob.name = response.headers.get('x-file-name')
              load(blob)
            })
          })
        },
      },
    }
  },
  methods: {
    load() {
      new ResourcesService()
        .gallery(this.propertyId, this.albumName, this.folder)
        .then((data) => {
          this.files = data.Images.map((src) => ({
            source: src.Key,
            options: {
              type: 'local',
            },
          }))
          this.loaded = true
        })
    },
    beforeRemoveFile() {
      return confirm('Are you sure you wish to permanently delete this image?')
    },
    activateFile(file) {
      // console.log(file)
    },
  },
  components: {
    FilePond,
  },
}
</script>

<template>
  <div class="sq-image border mr-2 mb-2" :class="{ single }">
    <div v-if="loading" class="sq-image-loading">
      <font-awesome-icon
        :icon="['fas', 'spinner']"
        pulse
        fixed-width
        size="2x"
      ></font-awesome-icon>
    </div>
    <div
      v-if="!loading && !value"
      class="d-flex justify-content-center align-items-center"
      style="height: 150px"
    >
      <div>
        <font-awesome-icon
          :icon="['fas', 'exclamation']"
          fixed-width
          size="2x"
        ></font-awesome-icon>
        <small class="d-block text-center">Error</small>
      </div>
    </div>
    <div v-if="!loading && value && error" class="sq-image-loading text-center">
      <font-awesome-icon
        :icon="['fas', 'image']"
        fixed-width
        size="2x"
      ></font-awesome-icon>
    </div>
    <button
      v-if="single && !disabled"
      class="btn btn-primary btn-sm"
      @click="$emit('change')"
    >
      Update
    </button>
    <div
      v-if="!loading && value && !error"
      class="sq-image-display"
      :style="imageDisplayBg"
    >
      <img :src="value" class="mw-150 mh-100 w-100" />
    </div>
  </div>
</template>

<script>
import { ResourcesService } from '../../services'

export default {
  name: 'quest-image',
  props: {
    image: { required: true },
    single: Boolean,
    isPattern: Boolean,
    disabled: { default: false },
  },
  data() {
    return {
      value: null,
      loading: true,
      error: false,
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    imageDisplayBg() {
      return this.isPattern ? { 'background-color': '#fff' } : {}
    },
  },
  methods: {
    load() {
      this.loading = true
      this.error = false
      if (this.image) {
        new ResourcesService().image(this.image).then((data) => {
          this.value = this.$config.cms.uri + data
          if (!data) {
            this.error = true
          }
          this.loading = false
        })
      } else {
        this.value = ''
        this.loading = false
      }
    },
  },
  watch: {
    image() {
      this.load()
    },
  },
}
</script>

<template>
  <quest-card
    title="Relationships"
    id="relationshipsForm"
    :collapsable="true"
    :collapsed="true"
  >
    <quest-multi-select
      id="factSheets"
      label="Fact Sheets"
      v-model="property.factSheets"
      :options="factsheets"
      :uid="'Key'"
      :text="'Value'"
      help="Select related fact sheet documents"
    ></quest-multi-select>

    <quest-multi-select
      id="proofpoints"
      label="Proof Points"
      v-model="property.proofPoints"
      :options="points"
      help="Select related proof points"
      :max="8"
    ></quest-multi-select>
  </quest-card>
</template>

<script>
import Card from './property-card'
import { ProofPointService } from '../../services'
import { mapState } from 'vuex'

export default {
  name: 'relationships-card',
  extends: Card,
  computed: {
    ...mapState(['factsheets']),
  },
  data() {
    return {
      points: [],
    }
  },
  mounted() {
    new ProofPointService().all().then((data) => (this.points = data))
  },
}
</script>

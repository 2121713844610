export const RateStatus = {
  Disabled: 0,
  Enabled: 1,
  Inherit: 2,
  OptOut: 3,
  OverrideInherit: 4,
  OverrideOptOut: 5,
}

export const RateStatusConvert = Object.keys(RateStatus).reduce(function (
  acc,
  key
) {
  return (acc[RateStatus[key]] = key), acc
},
{})
